import ReactModal from 'react-modal';
import mergeStyles from './utils/mergeStyles';

const styles = {
  overlay: {
    zIndex: 10,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default function Modal({ children, ...props }) {
  const mergedStyles = mergeStyles(styles, props.styles);

  return (
    <ReactModal
      style={mergedStyles}
      onRequestClose={() => false}
      shouldCloseOnOverlayClick={false}
      {...props}
    >
      {children}
    </ReactModal>
  );
}
