import get from 'lodash/get';
import update from 'immutability-helper';

import { getNextStatus } from 'components/RotaTable/ItemModule/itemModule';
import { getErrorMessage } from 'config/errors';

export function updateItemWholeState(state, item, typeKey = 'items') {
  const { date: dateKey, id: itemKey } = item;

  return update(state, {
    [typeKey]: {
      [dateKey]: {
        [itemKey]: { $set: item },
      },
    },
  });
}

export function updateItem(state, item) {
  const { date: dateKey, id: itemKey } = item;

  return update(state, {
    [dateKey]: {
      [itemKey]: { $set: item },
    },
  });
}

export const hasItemEditAccess = user => user.admin;

// TODO: getCompositeId can just accept columnDay if it already has a date inside
//       iterate over all occurences
export const getItemCompositeId = ({ columnDayId, userTeamId }) => `${columnDayId}-${userTeamId}`;

export const addOrUpdateItem = (array, item, checkCallback) => {
  const index = array.findIndex(checkCallback);

  if (index === -1) {
    array.push(item);
  } else {
    array[index] = item;
  }

  return array;
};

export const addColumnHelper = (columns, dateKey, column) => {
  if (columns[dateKey] === undefined) {
    columns[dateKey] = [column];
  } else {
    addOrUpdateItem(columns[dateKey], column, item => item.id === column.id);
  }
};

export const removeColumnHelper = (columns, dateKey, column) => {
  if (columns[dateKey] === undefined) {
    return true;
  } else {
    columns[dateKey] = columns[dateKey].filter(_col => _col.id !== column.id);
  }
};

export const removeItem = (list, id, idKey = 'id') => list.filter(item => item[idKey] !== id);

export const updateItemFields = (list, id, fields, idKey = 'id') =>
  list.map(item => (item[idKey] === id ? { ...item, ...fields } : item));

export function validateType(type) {
  if (typeof type !== 'string') {
    throw new Error('Type is not a string!');
  }

  const arr = type.split('.');
  if (arr.length !== 2) {
    throw new Error(`Invalid change request type format: ${type}`);
  }
}

export function getChangeRequestType(type) {
  validateType(type);

  return type
    .split('.')
    .reverse()[0]
    .toUpperCase();
}

export function getChangeRequestModelType(type) {
  validateType(type);

  return type.split('.')[0].toLowerCase();
}

export function convertArrayToObject(array, idKey = 'id') {
  const obj = {};
  array.forEach(item => {
    obj[item[idKey]] = item;
  });
  return obj;
}

export function convertObjectToArray(object, idKey = 'id') {
  const array = [];
  for (const key in object) {
    array.push({ ...object[key], [idKey]: object[key][idKey] });
  }
  return array;
}

export function checkPayloadId(payload) {
  if (!get(payload, 'id')) {
    throw new Error('Change request payload item does not have an ID!');
  }
}

export function applyChangeRequests(list, changeRequests, idKey = 'id', editCallbacks = {}) {
  const state = convertArrayToObject(list, idKey);

  changeRequests.forEach(changeRequest => {
    const type = getChangeRequestType(changeRequest.type);
    const id = get(changeRequest, `payload.${idKey}`);

    if (type !== 'ADD') {
      checkPayloadId(changeRequest.payload);
    }

    switch (type) {
      case 'ADD':
        // This is a hack for the backend, for being able to add ID
        // On the client side, there should be no ID modifications
        // Should return item with ID specified
        if (!id && editCallbacks.add) {
          const item = editCallbacks.add(changeRequest);
          state[item.id] = item;
        } else {
          state[id] = { ...changeRequest.payload, [idKey]: id };
        }

        break;

      case 'REMOVE':
        if (editCallbacks.remove) {
          editCallbacks.remove(changeRequest);
        }

        delete state[id];
        break;

      case 'UPDATE':
        if (editCallbacks.modify) {
          editCallbacks.modify(changeRequest);
        }

        state[id] = Object.assign({}, state[id], changeRequest.payload);
        break;
    }
  });

  return convertObjectToArray(state);
}

export const getItemWithNextStatus = item => {
  const status = getNextStatus(item.status);

  return status !== null
    ? {
        ...item,
        status,
      }
    : null;
};

export const toggleItem = item => (item ? null : item);

export async function processRequest(request) {
  try {
    const response = await request;
    return response;
  } catch ({ response }) {
    if (typeof response.data === 'string') {
      const message = getErrorMessage(response.data);
      if (message !== undefined) {
        throw new Error(message);
      } else {
        throw new Error(response.data);
      }
    } else {
      const message = get(response, 'data.message') || get(response, 'data.error_description');

      throw new Error(message);
    }
  }
}
