import { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Toast from 'components/Toast';
import deepDiff from 'deep-diff';

import RotaTable, { RotaTableFilter } from 'components/RotaTable';
import {
  updateItem,
  removeItem,
  getModifyRequestForItemPair,
} from 'components/RotaTable/ItemModule';
import { getItemCompositeId } from 'contexts/utils';
import { toastTimeout, autoSaveTimeout } from 'config/supportAndService';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 36px 0px 18px 0px;
  box-shadow: 0 0 15px rgba(60, 60, 60, 0.15);

  @media (max-width: 719px) {
    margin: 0px 0px 18px 0px;
  }
`;

const applyDiff = (propState, state, diffs) => {
  diffs.forEach(diff => {
    const { kind, rhs, path } = diff;

    const fullPath = path.join('.');

    switch (kind) {
      case 'E':
      case 'N':
        set(state, fullPath, rhs);
        break;

      case 'D':
        unset(state, fullPath);
        break;
    }
  });
};

export default class EditableRotaTable extends Component {
  static propTypes = {
    backgroundItems: PropTypes.any,
    calculateSums: PropTypes.bool,
    canEdit: PropTypes.func,
    columns: PropTypes.any,
    currentUser: PropTypes.object,
    dateFrom: PropTypes.any,
    dateTo: PropTypes.any,
    deadlines: PropTypes.any,
    getBackgroundItemCellColor: PropTypes.func,
    getForegroundItemCellColor: PropTypes.func,
    getOriginalItem: PropTypes.func,
    items: PropTypes.any,
    onCanvasClick: PropTypes.func,
    onClickConfirm: PropTypes.func,
    onItemClick: PropTypes.func,
    onItemSave: PropTypes.func,
    onMoveDate: PropTypes.func,
    onSetDateSpan: PropTypes.func,
    renderCanvas: PropTypes.func,
    renderItem: PropTypes.func,
    renderUser: PropTypes.func,
    users: PropTypes.any,
  };

  // Done only on init?? 99% sure, like in ctor
  constructor(props) {
    super(props);

    // TODO: check this copying??
    const { backgroundItems, columns, dateFrom, dateTo, deadlines, items, users } = cloneDeep(
      props,
    );

    this.state = {
      backgroundItems,
      columns,
      dateFrom,
      dateTo,
      deadlines,
      items,
      saveStatus: null,
      users,
    };
  }

  saveTimeouts = new Map();
  pendingRequests = new Map();
  toastTimeoutID = null;

  componentDidUpdate(previousProps) {
    const newState = {};
    let hasDiff = false;

    if (this.props.items) {
      const previousItems = Object.assign({}, previousProps.items);
      const nextItems = Object.assign({}, this.props.items);

      const itemsDiff = deepDiff(previousItems, nextItems);

      if (itemsDiff) {
        const currentItems = Object.assign({}, this.state.items);
        applyDiff(null, currentItems, itemsDiff);

        newState['items'] = currentItems;
        hasDiff = true;
      }
    }

    if (this.props.backgroundItems) {
      const previousBackgroundItems = Object.assign({}, previousProps.backgroundItems);
      const nextBackgroundItems = Object.assign({}, this.props.backgroundItems);
      const backgroundItemsDiff = deepDiff(previousBackgroundItems, nextBackgroundItems);

      if (backgroundItemsDiff) {
        const currentBackgroundItems = Object.assign({}, this.state.backgroundItems);
        applyDiff(null, currentBackgroundItems, backgroundItemsDiff);

        newState['backgroundItems'] = currentBackgroundItems;
        hasDiff = true;
      }
    }

    if (hasDiff) {
      this.setState(newState);
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const { dateFrom, dateTo, columns, deadlines } = props;
    const hasDatesChanged = dateFrom !== state.dateFrom || dateTo !== state.dateTo;

    const newState = Object.assign({}, state, { dateFrom, dateTo }, { columns }, { deadlines });

    if (hasDatesChanged) {
      return newState;
    } else {
      return null;
    }
  };

  componentWillUnmount() {
    this.saveTimeouts.forEach(timeout => {
      clearTimeout(timeout.id);
    });

    if (this.toastTimeoutID) {
      clearTimeout(this.toastTimeoutID);
    }
  }

  render() {
    return (
      <Wrapper id="editable-rota-table">
        {get(this.state, 'saveStatus.type') === 'error' && (
          <Toast status="critical">Error: {this.state.saveStatus.message}</Toast>
        )}
        <RotaTableFilter
          backgroundItems={this.state.backgroundItems}
          calculateSums={this.props.calculateSums}
          columns={this.state.columns}
          currentUser={this.props.currentUser}
          dateFrom={this.state.dateFrom}
          dateTo={this.state.dateTo}
          deadlines={this.state.deadlines}
          items={this.state.items}
          users={this.state.users}
        >
          {({ table, deadlinesLookup, users, columns, getColumnData, sums }) => (
            <RotaTable
              columns={columns}
              currentUser={this.props.currentUser}
              dateFrom={this.state.dateFrom}
              dateTo={this.state.dateTo}
              deadlines={deadlinesLookup}
              getBackgroundItemCellColor={this.props.getBackgroundItemCellColor}
              getColumnData={getColumnData}
              getForegroundItemCellColor={this.props.getForegroundItemCellColor}
              modifyHandler={this.modifyHandler}
              onCanvasClick={this.handleCanvasClick}
              onItemClick={this.handleItemClick}
              onMoveDate={this.props.onMoveDate}
              onSetDateSpan={this.props.onSetDateSpan}
              renderCanvas={this.props.renderCanvas}
              renderItem={this.props.renderItem}
              renderUser={this.props.renderUser}
              sums={sums}
              table={table}
              users={users}
            />
          )}
        </RotaTableFilter>
      </Wrapper>
    );
  }

  handleCanvasClick = (userTeamId, columnData) => {
    if (!this.props.canEdit(userTeamId)) {
      return;
    }

    const { date, id: columnDayId } = columnData;

    if (columnDayId === undefined) {
      return;
    }

    const item = this.props.onCanvasClick({ userTeamId, columnDayId, date }, this.props.items);
    const originalItem = this.props.getOriginalItem(
      { date, columnDayId, userTeamId },
      this.props.items,
    );

    const modifyRequest = getModifyRequestForItemPair([originalItem, item]);

    this.modifyHandler(
      null,
      item,
      () => this.props.onItemSave(modifyRequest),
      'Cannot add item. Please try again.',
    );
  };

  handleItemClick = item => {
    if (!this.props.canEdit(item.userTeamId)) {
      return;
    }

    if (this.props.onClickConfirm) {
      if (!this.props.onClickConfirm(item)) {
        return;
      }
    }

    const newItem = this.props.onItemClick(item, this.props.items);
    const originalItem = this.props.getOriginalItem(newItem || item, this.props.items);

    const modifyRequest = getModifyRequestForItemPair([originalItem, newItem]);

    this.modifyHandler(
      item,
      newItem,
      () => this.props.onItemSave(modifyRequest),
      'Cannot edit item. Please try again.',
    );
  };

  modifyHandler = (item, newItem, request, errorMessage, { extraRemove, extraAdd } = {}) => {
    const originalItem = this.props.getOriginalItem(newItem || item, this.props.items);
    const timeoutKey = getItemCompositeId(newItem || item);

    if (this.pendingRequests.get(timeoutKey)) {
      // Disable click when there is any pending request for given item going on
      return;
    }

    const timeout = this.saveTimeouts.get(timeoutKey);

    // TODO: test extraRemove, extraAdd thoroughly
    // TODO: add batching here...
    if (newItem === null) {
      this.setState(state => removeItem(state, item));
    } else {
      this.setState(state => updateItem(state, { ...newItem }));
    }

    if (extraRemove) {
      this.setState(state => removeItem(state, extraRemove));
    }

    if (extraAdd) {
      this.setState(state => updateItem(state, extraAdd));
    }

    if (isEqual(newItem, originalItem)) {
      if (timeout) {
        clearTimeout(timeout.id);
      }
    } else {
      if (timeout) {
        clearTimeout(timeout.id);
        this.saveTimeouts.delete(timeoutKey);
      }

      const timeoutId = setTimeout(async () => {
        try {
          this.pendingRequests.set(timeoutKey, true);

          await request();

          this.saveTimeouts.delete(timeoutKey);
          this.pendingRequests.delete(timeoutKey);
        } catch (error) {
          if (typeof errorMessage === 'function') {
            this.showToast('error', errorMessage(error));
          } else {
            this.showToast('error', errorMessage);
          }

          if (originalItem === null) {
            this.setState(state => removeItem(state, newItem));
          } else {
            this.setState(state => updateItem(state, originalItem));
          }

          if (extraRemove) {
            this.setState(state => updateItem(state, extraRemove));
          }

          if (extraAdd && this.props.getOriginalItem(extraAdd, this.props.items) === null) {
            this.setState(state => removeItem(state, extraAdd));
          }
        }
      }, autoSaveTimeout);

      this.saveTimeouts.set(
        timeoutKey,
        cloneDeep({
          id: timeoutId,
          item,
        }),
      );
    }
  };

  showToast = (type, message) => {
    if (this.toastTimeoutID) {
      clearTimeout(this.toastTimeoutID);
    }

    this.setState({ saveStatus: { type, message } });

    this.toastTimeoutID = setTimeout(() => {
      this.setState({ saveStatus: null });
    }, toastTimeout);
  };
}
