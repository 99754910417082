/* eslint-disable react/prop-types */
import { Trans } from '@lingui/react';
import { WarningSubtitle } from 'components/ui/WarningSubtitle';
import { Box } from 'grommet';
import { Clear } from 'grommet-icons';
import { noop } from 'lodash';
import { Loader } from 'page/components/routing';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useIsMobile } from 'utils/responsive';

export const NoResults = styled(WarningSubtitle)`
  font-size: 16px;
`;

export const MoreShiftsAvailable = styled.div`
  font-size: 14px;
  color: #bdbdbd;
`;

export function ItemsList({
  error,
  filterItems = () => true,
  items,
  loading,
  noItemsAvailableMessage = <Trans id="items-calendar.no-items" />,
  onRefresh,
  renderItem = noop,
  sortItems = () => true,
}) {
  const isMobile = useIsMobile();

  const filteredItems = useMemo(() => items.filter(filterItems), [items, filterItems]);
  const visibleItems = useMemo(() => filteredItems.sort(sortItems).slice(0, 3), [
    filteredItems,
    sortItems,
  ]);
  const moreItemsAvailable = filteredItems.length - visibleItems.length;

  return (
    <Box>
      <Loader loading={loading} error={error}>
        {filteredItems.length === 0 ? (
          <Box align={isMobile ? 'center' : 'start'}>
            <NoResults pad="24px" justify="center" align="center" gap="12px">
              <Clear size="36px" />
              <div>{noItemsAvailableMessage}</div>
            </NoResults>
          </Box>
        ) : (
          <>
            <Box>{visibleItems.map(item => renderItem({ item, onRefresh }))}</Box>
            {moreItemsAvailable > 0 && (
              <MoreShiftsAvailable>
                <Trans id="items-calendar.more-available" values={{ amount: moreItemsAvailable }} />
              </MoreShiftsAvailable>
            )}
          </>
        )}
      </Loader>
    </Box>
  );
}
