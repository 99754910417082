/* eslint-disable react/prop-types */
import { ItemRow, ItemWrapper } from 'components/ui/ItemsCalendar/Item';
import { Box } from 'grommet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { convertStartDurationToStartEnd, getPrettyDayFromDate } from 'utils/Dates';
import { WarningSubtitle } from 'components/ui/WarningSubtitle';

const PlainLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export function ShiftBase({ date, columnDayId, column, children, disabled, description }) {
  const { header, start: startTime, duration } = column;
  const { start, end } = convertStartDurationToStartEnd(startTime, duration);
  const to = `/work/shifts/${columnDayId}`;

  return (
    <PlainLink to={to}>
      <ItemWrapper direction="row" align="center" disabled={disabled}>
        <Box flex={{ grow: 1, shrink: 1 }} basis="100%" direction="column" gap="8px">
          <ItemRow>
            <Box direction="row" justify="between">
              <div>{getPrettyDayFromDate(date)}</div>
              <div>
                {start} - {end}
              </div>
            </Box>
          </ItemRow>

          <ItemRow>
            {header} {description && <WarningSubtitle>{description}</WarningSubtitle>}
          </ItemRow>

          {children}
        </Box>
      </ItemWrapper>
    </PlainLink>
  );
}
