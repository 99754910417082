// import Network from 'utils/network';

export default {
  isIntegrated: () => new Promise(resolve => setTimeout(() => resolve({ data: false })), 1000),
  //Network.get(`/telegram/is-integrated/`),

  generateToken: () =>
    new Promise(resolve =>
      setTimeout(() => resolve({ data: parseInt(Math.random() * 1000000, 10) }), 1000),
    ),
  //Network.get(`/telegram/get-token/`),
};
