import Network from 'utils/network';

export default {
  isIntegrated: () => Network.get(`/facebook/is-integrated/`),

  generateToken: () => Network.get(`/facebook/get-token/`),

  notifyNewColumns: teamId => Network.post(`/facebook-notifications/new-columns/${teamId}`),

  notifyNewRoster: teamId => Network.post(`/facebook-notifications/new-roster/${teamId}`),
};
