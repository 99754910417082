/* eslint-disable react/prop-types */
import { Trans } from '@lingui/react';
import { pulsePrimaryColor, pulseSecondaryColor } from 'config/colors';
import { Button } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import get from 'lodash/get';
import { Component } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

export const FixedWrapper = styled.div`
  position: fixed;
  top: 10px;
  ${props => (props.isMobile ? 'right: 10px' : 'left: 10px')};
  z-index: 10000;
`;

const ENTER_ANIMATION_DURATION = 0.5; // In seconds

function getWrapperAnimation(props) {
  const animation = 'shadow-pulse 1.25s infinite';

  if (props.isEntering) {
    return animation + `, scale-in-center ${ENTER_ANIMATION_DURATION}s`;
  }
  return animation;
}

const Wrapper = styled.div`
  border-radius: 5px;
  animation: ${getWrapperAnimation};

  &:hover {
    animation: none;
  }

  @keyframes shadow-pulse {
    0% {
      box-shadow: 0 0 0 0px ${pulsePrimaryColor};
    }
    100% {
      box-shadow: 0 0 0 20px ${pulseSecondaryColor};
    }
  }

  @keyframes scale-in-center {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export class GuideHistoryHint extends Component {
  state = {
    showTip: false,
    isEntering: false,
    isMobile: true,
  };

  isEnteringTimeout = null;

  componentDidUpdate(prevProps) {
    const prevPathname = get(prevProps, 'location.pathname');
    const pathname = get(this.props, 'location.pathname');

    if (
      this.state.showTip &&
      pathname !== prevPathname &&
      (pathname === '/guide' || (prevPathname !== '/guide' && pathname !== '/guide'))
    ) {
      if (this.isEnteringTimeout) {
        clearTimeout(this.isEnteringTimeout);
      }

      this.setState({ showTip: false, isEntering: false });
      return;
    }

    if (prevPathname !== '/guide') {
      return;
    }

    if (pathname !== prevPathname) {
      this.setState({ showTip: true, isEntering: true });

      this.isEnteringTimeout = setTimeout(() => {
        this.setState({ isEntering: false });
      }, ENTER_ANIMATION_DURATION * 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.isEnteringTimeout);
  }

  render() {
    return this.state.showTip ? (
      <FixedWrapper isMobile={this.props.isMobile}>
        <Wrapper isEntering={this.state.isEntering}>
          <Button
            primary
            icon={<LinkPrevious />}
            label={<Trans id="guide.buttons.back-to-guide" />}
            onClick={this.goBack}
          />
        </Wrapper>
      </FixedWrapper>
    ) : null;
  }

  goBack = () => {
    this.props.history.goBack();
  };
}

export default withRouter(GuideHistoryHint);
