import PropTypes from 'prop-types';
import { Button } from 'grommet';

export default function HeaderButton({ label, icon, onClick, ...props }) {
  return (
    <Button
      icon={icon}
      size="small"
      onClick={onClick}
      style={{ margin: '0px 2px' }}
      label={label}
      {...props}
    />
  );
}

HeaderButton.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  onClick: PropTypes.func,
};
