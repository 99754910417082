import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import Tooltip from 'reactstrap/lib/Tooltip';

import Icon from 'components/Icon';
import { getDateKey } from 'utils/Dates';
import { TODAY } from 'config/dates';
import { getDeadlineState, getDeadlineIconName } from '../RotaTable/ItemModule/itemModule';
import translatedMessage from 'translatedMessage';
import { i18nMark } from '@lingui/react';

export default class DeadlineRow extends Component {
  static propTypes = {
    adminMode: PropTypes.bool,
    deadline: PropTypes.object,
    onRemoveDeadline: PropTypes.func,
  };

  state = {
    error: null,
  };

  errorMessageTimeoutId = null;

  componentWillUnmount() {
    if (this.errorMessageTimeoutId) {
      clearTimeout(this.errorMessageTimeoutId);
    }
  }

  render() {
    return (
      <tr>
        <td>{getDateKey(this.props.deadline.deadlineDate)}</td>
        <td>{getDateKey(this.props.deadline.periodStart)}</td>
        <td>{getDateKey(this.props.deadline.periodEnd)}</td>
        <td>
          <span style={{ position: 'relative' }}>
            <Icon name={getDeadlineIconName(getDeadlineState(TODAY, this.props.deadline))} />
          </span>
        </td>
        {this.props.adminMode && (
          <td>
            <Button
              color="danger"
              id={`remove-deadline-${this.props.deadline.id}`}
              onClick={this.handleRemoveDeadline}
              outline
              size="sm"
            >
              <Icon name="delete" />
            </Button>
            <Tooltip
              target={`remove-deadline-${this.props.deadline.id}`}
              placement="right"
              isOpen={Boolean(this.state.error)}
              autohide
            >
              {this.state.error}
            </Tooltip>
          </td>
        )}
      </tr>
    );
  }

  handleRemoveDeadline = async () => {
    i18nMark('deadlines-table.confirm-delete');
    const confirmed = window.confirm(translatedMessage('deadlines-table.confirm-delete'));

    if (!confirmed) {
      return;
    }

    this.setState({ error: null });

    try {
      await this.props.onRemoveDeadline(this.props.deadline.id);
    } catch (exception) {
      const errorMessage = exception.message || exception.error;
      this.setState({ error: errorMessage });
    } finally {
      this.errorMessageTimeoutId = setTimeout(() => {
        this.setState({ error: null });
      }, 3000);
    }
  };
}
