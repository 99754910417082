import { createContext, useContext } from 'react';

const I18nFuncContext = createContext(null);
export const I18nFuncContextProvider = I18nFuncContext.Provider;

export function useI18n() {
  const i18n = useContext(I18nFuncContext);
  if (!i18n) {
    throw new Error('No context found');
  }
  return i18n;
}

export default I18nFuncContext;
