/* eslint-disable react/prop-types */
import { CalendarBar } from 'components/ui/ItemsCalendar/CalendarBar';
import { ItemsCalendar } from 'components/ui/ItemsCalendar/ItemsCalendar';
import { Loader } from 'page/components/routing';
import { useMonthQueryParam } from 'utils/hooks';
import { ShiftsList, useGetShifts } from './components/ShiftsList';

export function UpcomingShifts({ dateFrom, dateTo }) {
  return <ShiftsList url="/shifts/mine/{{ teamId }}" dateFrom={dateFrom} dateTo={dateTo} />;
}

export function ShiftsCalendar() {
  const { dateFrom, dateTo, month, onMoveForward, onMoveBackward } = useMonthQueryParam();
  const [{ shifts = [], loading, error }] = useGetShifts({
    url: '/shifts/mine/{{ teamId }}',
    dateFrom,
    dateTo,
  });

  return (
    <Loader loading={loading} error={error}>
      <CalendarBar
        month={month}
        onMoveDateBackward={onMoveBackward}
        onMoveDateForward={onMoveForward}
      />
      <ItemsCalendar month={month} items={shifts} />
    </Loader>
  );
}
