import { Box, Text } from 'grommet';
import { StatusCritical, StatusGood, StatusUnknown, StatusWarning } from 'grommet-icons';

const getStatusToBackground = status => `status-${status}`;

function StatusIcon({ status }) {
  switch (status) {
    case 'critical':
      return <StatusCritical />;
    case 'ok':
      return <StatusGood />;
    case 'warning':
      return <StatusWarning />;
    default:
      return <StatusUnknown />;
  }
}

// eslint-disable-next-line react/prop-types
export function Notification({ message, status = 'ok' }) {
  return (
    <Box
      align="center"
      direction="row"
      gap="small"
      justify="between"
      round="medium"
      elevation="medium"
      pad={{ vertical: 'xsmall', horizontal: 'small' }}
      background={getStatusToBackground(status)}
    >
      <Box align="center" direction="row" gap="xsmall">
        <StatusIcon status={status} />
        <Text>{message}</Text>
      </Box>
    </Box>
  );
}
