import { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { NavLink } from 'react-router-dom';

import RotaTable, { RotaTableFilter } from 'components/RotaTable';
import { getAvailabilitiesForegroundColor } from 'components/itemCellColors';
import { applyDaysOffset } from 'utils/Dates';
import {
  fetchColumnsAction,
  fetchDeadlinesAction,
  fetchRotaTableDataAction,
} from 'contexts/actions';
import {
  AvailabilityConsumer,
  ChangeRequestConsumer,
  ColumnConsumer,
  DeadlineConsumer,
  UserConsumer,
} from 'contexts';

import { defaultDateSpan } from 'config/dates';
import ChangeRequestsTable from 'components/ChangeRequestsTable';

//TODO:
// - get dateFrom, dateTo from query params?
export class Availabilities extends Component {
  static propTypes = {
    availabilities: PropTypes.object.isRequired,
    changeRequests: PropTypes.array.isRequired,
    columns: PropTypes.object.isRequired,
    deadlines: PropTypes.array.isRequired,
    fetchData: PropTypes.func,
    users: PropTypes.any,
  };

  state = {
    dateFrom: defaultDateSpan.dateFrom,
    dateTo: defaultDateSpan.dateTo,
    loaded: false,
  };

  async componentDidMount() {
    await this.handleMoveDate(0);

    this.setState({ loaded: true });
  }

  render() {
    return (
      <div>
        <NavLink to="/availabilities/edit">Edit</NavLink>
        {this.state.loaded ? (
          <RotaTableFilter
            dateFrom={this.state.dateFrom}
            dateTo={this.state.dateTo}
            deadlines={this.props.deadlines}
            items={this.props.availabilities}
            users={this.props.users}
            columns={this.props.columns}
          >
            {({ table, users, columns, getColumnData, deadlinesLookup }) => (
              <RotaTable
                columns={columns}
                deadlines={deadlinesLookup}
                getColumnData={getColumnData}
                getForegroundItemCellColor={getAvailabilitiesForegroundColor}
                onMoveDateBackward={() => this.handleMoveDate(-1)}
                onMoveDateForward={() => this.handleMoveDate(1)}
                onSetDateSpan={this.onSetDateSpan}
                table={table}
                users={users}
              />
            )}
          </RotaTableFilter>
        ) : (
          <div>Loading ...</div>
        )}
        <div>
          <ChangeRequestsTable changeRequests={this.props.changeRequests} />
        </div>
      </div>
    );
  }

  handleMoveDate = async daysOffset => {
    const { dateFrom, dateTo } = this.state;

    const dateFromNext = applyDaysOffset(dateFrom, daysOffset);
    const dateToNext = applyDaysOffset(dateTo, daysOffset);

    await this.props.fetchData(dateFromNext, dateToNext);

    this.setState({
      dateFrom: dateFromNext,
      dateTo: dateToNext,
    });
  };

  onSetDateSpan = daySpan => {
    const dateTo = applyDaysOffset(this.state.dateFrom, daySpan - 1);

    this.setState({
      dateTo,
    });
  };
}

export default function ContextWrapper(props) {
  return (
    <UserConsumer>
      {({ currentTeam }) => (
        <ColumnConsumer>
          {({ addColumns, columns }) => (
            <ChangeRequestConsumer>
              {({ changeRequests }) => (
                <DeadlineConsumer>
                  {({ addDeadlines, deadlines }) => (
                    <AvailabilityConsumer>
                      {({ availabilities, fetchAvailabilities }) => (
                        <Availabilities
                          availabilities={availabilities}
                          changeRequests={changeRequests}
                          columns={columns}
                          deadlines={deadlines}
                          fetchData={fetchRotaTableDataAction({
                            fetchAvailabilitiesAction: fetchAvailabilities,
                            fetchColumnsAction: fetchColumnsAction({
                              addColumns,
                              teamId: currentTeam.id,
                            }),
                            fetchDeadlinesAction: fetchDeadlinesAction({
                              addDeadlines,
                              teamId: currentTeam.id,
                            }),
                            fetchShiftsAction: noop,
                          })}
                          users={currentTeam.users}
                          {...props}
                        />
                      )}
                    </AvailabilityConsumer>
                  )}
                </DeadlineConsumer>
              )}
            </ChangeRequestConsumer>
          )}
        </ColumnConsumer>
      )}
    </UserConsumer>
  );
}
