import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon';
const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  margin: 4px;
`;

export default function AbsoluteIcon({ size, name, id }) {
  return (
    <IconWrapper size={size} id={id}>
      <Icon name={name} size={size} />
    </IconWrapper>
  );
}

AbsoluteIcon.propTypes = {
  size: PropTypes.number,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
};

AbsoluteIcon.defaultProps = {
  size: 14,
};
