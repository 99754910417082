import { Trans } from '@lingui/react';
import { Box, Table, TableBody, TableCell, TableHeader, TableRow } from 'grommet';
import { UserManager } from 'grommet-icons';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { getUserTextForDisplay } from '../utils';

function ManagerIcon() {
  return (
    <Fragment>
      <UserManager data-tip="Manager" />
      <ReactTooltip />
    </Fragment>
  );
}

export default class UserList extends Component {
  static propTypes = {
    users: PropTypes.array,
  };

  state = {
    sortIndex: 0,
    sortAscending: false,
    sortBy: 'displayname',
  };

  fields = ['displayname'];

  render() {
    const sortedUsers = orderBy(
      this.props.users,
      this.state.sortBy,
      this.state.sortAscending ? 'asc' : 'desc',
    );

    return (
      <Box fill="horizontal">
        <Table alignSelf="stretch">
          <TableHeader>
            <TableRow>
              <TableCell>
                <Trans id="team.users-list.table.name" />
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedUsers.map(user => (
              <TableRow key={user.userTeamId}>
                <TableCell>
                  {getUserTextForDisplay(user)}
                  {user.admin && <ManagerIcon />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  }

  handleSort = (sortIndex, sortAscending) => {
    const sortBy = this.fields[sortIndex];

    this.setState({ sortIndex, sortBy, sortAscending });
  };
}
