import { useI18n } from 'contexts/i18nWrapper';
import { Box, Button } from 'grommet';
import { Notification, Schedule, UserWorker } from 'grommet-icons';
import PropTypes from 'prop-types';
import MenuWrapper from './Menu';

const NewColumnsButton = () => {
  const i18n = useI18n();
  return i18n._('calendar-notification-bar.menu.new-columns');
};

const NewRosterButton = () => {
  const i18n = useI18n();
  return i18n._('calendar-notification-bar.menu.new-roster');
};

export default function CalendarNotificationBar({ onNewColumnNotify, onNewRosterNotify }) {
  return (
    <Box direction="row" align="center" alignContent="center">
      <MenuWrapper icon={<Notification />} inline={false} primary={false} closeOnClick={false}>
        <Button
          icon={<Schedule />}
          plain
          label={<NewColumnsButton />}
          onClick={onNewColumnNotify}
        />
        <Button
          icon={<UserWorker />}
          plain
          label={<NewRosterButton />}
          onClick={onNewRosterNotify}
        />
      </MenuWrapper>
    </Box>
  );
}

CalendarNotificationBar.propTypes = {
  onNewColumnNotify: PropTypes.func,
  onNewRosterNotify: PropTypes.func,
};
