import qs from 'qs';

export default function updateDateSpanQueryParams(history, dateSpan) {
  const {
    location: { search },
  } = history;

  const { dateFrom: from, dateTo: to } = dateSpan;

  const newSearch = qs.stringify(
    {
      ...qs.parse(search, {
        ignoreQueryPrefix: true,
      }),
      from,
      to,
    },
    { addQueryPrefix: true },
  );

  history.replace({
    search: newSearch,
  });
}
