import Network from 'utils/network';
import qs from 'qs';

export default {
  generateReport: (teamId, dateFrom, dateTo) => {
    const queryParams = qs.stringify({ dateFrom, dateTo }, { addQueryPrefix: true });
    return Network.get(`/raports/worked-hours/${teamId}${queryParams}`, {
      responseType: 'blob',
    });
  },
};
