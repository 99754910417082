import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Box } from 'grommet';
import Toast from 'components/Toast';

import analytics from 'analytics';
import PendingExchangesRow from './PendingExchangesRow';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

import { Component } from 'react';
import { getItemCompositeId } from 'contexts/utils';
import errorMessageHandler from 'utils/errorMessageHandler';
import { Trans } from '@lingui/react';

export default class PendingExchanges extends Component {
  static propTypes = {
    currentUserId: PropTypes.any,
    exchanges: PropTypes.array,
    onTake: PropTypes.func,
  };

  state = {
    error: null,
  };

  render() {
    return (
      <Box>
        {this.state.error && (
          <Toast status="critical">
            <Trans id="error" />: {this.state.error}
          </Toast>
        )}
        <h4>
          <Trans id="available-shifts-table.header" />
        </h4>
        <Wrapper>
          {this.props.exchanges.map(exchange => (
            <PendingExchangesRow
              key={getItemCompositeId(exchange)}
              exchange={exchange}
              onTake={this.handleTake}
            />
          ))}
        </Wrapper>
      </Box>
    );
  }

  handleTake = async exchange => {
    this.setState({ error: null });

    try {
      await this.props.onTake(exchange, this.props.currentUserId);
      analytics.track({ category: 'Take shift', action: 'From list' });
    } catch (error) {
      this.setState({ error: errorMessageHandler(error) });
      throw error;
    }
  };
}
