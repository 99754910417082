import Network from 'utils/network';
import qs from 'qs';

export default {
  getUser: userId => Network.get(`/users/get/${userId}`),

  registerUser: (userRegistrationKey, userName, password) => {
    const headers = {
      Authorization: 'Basic cm90YTpyb3Rhc2VjcmV0',
    };

    return Network.put(
      `/users/activate-account`,
      { userRegistrationKey, displayname: userName, password },
      { headers },
    );
  },

  registerAndCreateTeam: (teamName, email) => {
    const headers = {
      Authorization: 'Basic cm90YTpyb3Rhc2VjcmV0',
    };

    return Network.post(
      `/teams/create`,
      {
        creatorEmail: email,
        displayname: teamName,
      },
      { headers },
    );
  },

  changePassword: (oldPassword, newPassword) =>
    Network.put(`/users/change-password`, { oldPassword, newPassword }),

  requestPasswordReset: email => Network.post(`/users/send-password-reset-email`, { email }),

  passwordReset: (newPassword, key) => Network.put(`/users/reset-password`, { newPassword, key }),

  addTeamMembers: (users, teamId) =>
    // TODO: now only adds a single user, change this to multiple
    Network.post(`/users-teams/create`, { ...users[0], teamId }),

  removeTeamMember: userTeamId => {
    const queryParams = qs.stringify({ userTeamId }, { addQueryPrefix: true });

    return Network.delete(`/users-teams/delete${queryParams}`);
  },

  changeDisplayName: displayName =>
    Network.put(`/users/change-displayname`, displayName, {
      headers: { 'Content-Type': 'text/plain' },
    }),
};
