import PropTypes from 'prop-types';
import styled from 'styled-components';

function getBorderSize(size) {
  return size ? `${parseInt(size, 10) / 5}px` : '6px';
}

const Wrapper = styled.div`
  border: ${props => getBorderSize(props.size)} solid #eceff1;
  border-top: ${props => getBorderSize(props.size)} solid #0277bd;
  border-radius: 50%;
  width: ${props => props.size || '32px'};
  height: ${props => props.size || '32px'};
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

Wrapper.propTypes = {
  size: PropTypes.string,
};

export default Wrapper;
