import Network from 'utils/network';
import qs from 'qs';

export default {
  getTeam: teamId => Network.get(`/teams/get/${teamId}`),
  getTeamUsers: teamId => Network.get(`/users-teams/get-users/${teamId}`),
  removeTeamMember: userTeamId => {
    const queryParams = qs.stringify({ userTeamId }, { addQueryPrefix: true });
    return Network.delete(`/users-teams/delete${queryParams}`);
  },
  addTeamMember: user => Network.post(`/users-teams/create`, user),
  getPendingTeamInvitations: () => Network.get(`/users/pending-team-invitations`),
  acceptTeamInvitation: teamId => Network.post(`/users/accept-team-invitation`, { teamId }),
  rejectTeamInvitation: teamId => Network.post(`/users/reject-team-invitation`, { teamId }),
};
