import analytics from 'analytics';
import { getAvailabilitiesBackgroundColor, shiftColor } from 'components/itemCellColors';
import AdjustmentsBoxModal from 'components/RotaTable/AdjustmentsBoxModal';
import { DescriptionBoxModal } from 'components/RotaTable/DescriptionBoxModal';
import { getErrorMessage } from 'config/errors';
import { useI18n } from 'contexts/i18nWrapper';
import { Box, Menu } from 'grommet';
import { CircleQuestion, Clock, More, Sync } from 'grommet-icons';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import styled from 'styled-components';
import errorMessageHandler from 'utils/errorMessageHandler';
import Cell from './Cell';
import { Description } from './Description';

const GiveAwayAndNotifyButton = () => {
  const i18n = useI18n();
  return i18n._('shift-cell.menu.give-away-and-notify');
};

const GiveAwayButton = () => {
  const i18n = useI18n();
  return i18n._('shift-cell.menu.give-away');
};

const AdjustmentsButton = () => {
  const i18n = useI18n();
  return i18n._('shift-cell.menu.adjustments');
};

const DescriptionButton = () => {
  const i18n = useI18n();
  return i18n._('shift-cell.menu.description');
};

const TakeButton = () => {
  const i18n = useI18n();
  return i18n._('shift-cell.menu.take');
};

const UndoButton = () => {
  const i18n = useI18n();
  return i18n._('shift-cell.menu.undo');
};

const ShiftWrapper = styled.div`
  background: ${props => props.background};

  display: flex;
  justify-content: flex-end;

  flex: 5;
`;

const AvailabilityWrapper = styled.div`
  background: ${props => props.background};
  flex: 2;
`;

export class ShiftMenuItem extends PureComponent {
  static propTypes = {
    adminMode: PropTypes.bool,
    column: PropTypes.object,
    currentUserId: PropTypes.any,
    exchangeable: PropTypes.bool,
    item: PropTypes.object,
    modifyHandler: PropTypes.func,
    onAdjustmentAccept: PropTypes.func,
    onAdjustmentReject: PropTypes.func,
    onAdjustmentRemove: PropTypes.func,
    onAdjustmentSave: PropTypes.func,
    onAdjustmentSuggestion: PropTypes.func,
    onAdjustmentSuggestionRemove: PropTypes.func,
    onDescriptionEdit: PropTypes.func,
    onGiveAway: PropTypes.func,
    onTake: PropTypes.func,
    onUndo: PropTypes.func,
    userTeamId: PropTypes.any,
  };

  state = {
    showAdjustmentsModal: false,
    showDescriptionModal: false,
  };

  render() {
    const { adminMode, userTeamId, item, currentUserId, column } = this.props;
    const { exchangeable, description } = item;
    const belongsToUser = userTeamId === currentUserId;

    // Truth table and logic functions:
    // https://docs.google.com/spreadsheets/d/1nUf-jOHo4N-w84gMPPxvRDcTVo0Ea19GeFPh7eZi4eo/edit#gid=0
    const showGiveAway = !exchangeable && (adminMode || belongsToUser);
    const showTake = exchangeable && !belongsToUser;
    const showUndo = exchangeable && (adminMode || belongsToUser);
    const showDescriptionEdit = adminMode;

    const showAdjustmentSuggestionIcon = Boolean(item.suggestedAdjustmentMinutes);
    const showAdjustmentIcon = Boolean(item.adjustmentMinutes);

    return (
      <Box
        justify="between"
        direction="row"
        fill="vertical"
        data-test-status="cell"
        style={{ width: '100%' }}
        responsive={false}
        onClick={this.handleBoxClick}
      >
        <Box direction="row" justify="start" align="center" gap="4px">
          {exchangeable && <Sync size="14px" />}
          {showAdjustmentSuggestionIcon ? (
            <CircleQuestion size="14px" />
          ) : showAdjustmentIcon ? (
            <Clock size="14px" />
          ) : null}
        </Box>
        {description && <Description>{description}</Description>}
        <AdjustmentsBoxModal
          adjustmentMinutes={item.adjustmentMinutes}
          column={column}
          isManager={adminMode}
          shift={item}
          isOpen={this.state.showAdjustmentsModal}
          onAdjustmentAccept={this.props.onAdjustmentAccept}
          onAdjustmentReject={this.props.onAdjustmentReject}
          onAdjustmentRemove={this.props.onAdjustmentRemove}
          onAdjustmentSave={this.props.onAdjustmentSave}
          onAdjustmentSuggestion={this.props.onAdjustmentSuggestion}
          onAdjustmentSuggestionRemove={this.props.onAdjustmentSuggestionRemove}
          onClose={this.handleCloseAdjustmentsBox}
          suggestedAdjustmentMinutes={item.suggestedAdjustmentMinutes}
          toggle={false}
        />
        {this.state.showDescriptionModal && (
          <DescriptionBoxModal
            shift={item}
            isOpen={this.state.showDescriptionModal}
            onEdit={this.props.onDescriptionEdit}
            onClose={this.handleCloseDescriptionBox}
          />
        )}
        <Box>
          <Menu
            onClick={this.handleMenuClick}
            icon={<More size="10px" />}
            dropProps={{
              onClick: event => {
                event.preventDefault();
                event.stopPropagation();
              },
            }}
            responsive={true}
            inline={false}
            size="xsmall"
            className="override_menu_xxs rota-shift-cell-menu"
            items={[
              ...(showGiveAway
                ? [
                    {
                      label: <GiveAwayAndNotifyButton id="rota-shift-cell-menu-give-away" />,
                      onClick: this.handleGiveAwayAndNotify,
                    },
                    {
                      label: <GiveAwayButton id="rota-shift-cell-menu-give-away-silently" />,
                      onClick: this.handleGiveAwaySilently,
                    },
                  ]
                : []),
              {
                label: <AdjustmentsButton />,
                onClick: this.handleShowAdjustmentsBox,
              },
              ...(showDescriptionEdit
                ? [
                    {
                      label: <DescriptionButton />,
                      onClick: this.handleShowDescriptionBox,
                    },
                  ]
                : []),
              ...(showUndo
                ? [
                    {
                      label: <UndoButton />,
                      onClick: this.handleUndo,
                    },
                  ]
                : []),
              ...(showTake
                ? [
                    {
                      label: <TakeButton id="rota-shift-cell-menu-take" />,
                      onClick: this.handleTake,
                    },
                  ]
                : []),
            ]}
          />
        </Box>
      </Box>
    );
  }

  handleBoxClick = event => {
    if (this.state.showAdjustmentsModal || this.state.showDescriptionModal) {
      event.stopPropagation();
    }
  };

  handleMenuClick = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  handleGiveAwayAndNotify = event => {
    event.preventDefault();
    event.stopPropagation();

    analytics.track({ category: 'Shift', action: 'Give away & notify', label: 'Menu' });
    this.handleGiveAwayHelper(true);
  };

  handleGiveAwaySilently = () => {
    analytics.track({ category: 'Shift', action: 'Give away', label: 'Menu' });
    this.handleGiveAwayHelper(false);
  };

  handleGiveAwayHelper = (silently = true) => {
    const { item: shift } = this.props;

    this.props.modifyHandler(
      shift,
      { ...shift, exchangeable: true },
      () => this.props.onGiveAway(shift, silently),
      getErrorMessage('frontend.CANNOT_GIVE_AWAY'),
    );
  };

  handleUndo = () => {
    const { item: shift } = this.props;

    analytics.track({ category: 'Shift', action: 'Undo give away', label: 'Menu' });

    this.props.modifyHandler(
      shift,
      { ...shift, exchangeable: false },
      () => this.props.onUndo(shift),
      getErrorMessage('frontend.CANNOT_UNDO_GIVE_AWAY'),
    );
  };

  handleTake = () => {
    const { item: shift, currentUserId } = this.props;
    const newShift = { ...shift, userTeamId: currentUserId, exchangeable: false };

    this.props.modifyHandler(
      shift,
      null,
      async () => {
        await this.props.onTake(shift, currentUserId);
        analytics.track({ category: 'Shift', action: 'Take', label: 'Menu' });
      },
      errorMessageHandler,
      {
        extraAdd: newShift,
      },
    );
  };

  handleShowAdjustmentsBox = () => {
    analytics.track({ category: 'Shift', action: 'Adjustments', label: 'Menu' });
    this.setState({ showAdjustmentsModal: true });
  };

  handleCloseAdjustmentsBox = () => {
    this.setState({ showAdjustmentsModal: false });
  };

  handleShowDescriptionBox = event => {
    event.stopPropagation();
    analytics.track({ category: 'Shift', action: 'Description', label: 'Menu' });
    this.setState({ showDescriptionModal: true });
  };

  handleCloseDescriptionBox = () => {
    this.setState({ showDescriptionModal: false });
  };
}

export default function ShiftCell({
  adminMode,
  backgroundItem,
  column,
  currentUserId,
  modifyHandler,
  onAdjustmentAccept,
  onAdjustmentReject,
  onAdjustmentRemove,
  onAdjustmentSave,
  onAdjustmentSuggestion,
  onAdjustmentSuggestionRemove,
  onCanvasClick,
  onClick,
  onDescriptionEdit,
  onGiveAway,
  onTake,
  onUndo,
  primaryItem,
  userTeamId,
  ...props
}) {
  const available = backgroundItem ? backgroundItem.available : null;

  const foregroundColor = primaryItem ? shiftColor : null;
  const backgroundColor = backgroundItem ? getAvailabilitiesBackgroundColor(available) : null;

  const clickHandler = primaryItem
    ? () => onClick(primaryItem)
    : () => onCanvasClick(userTeamId, column);

  return (
    <Cell onClick={clickHandler} {...props}>
      <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
        <ShiftWrapper background={foregroundColor}>
          {primaryItem && (
            <ShiftMenuItem
              adminMode={adminMode}
              column={column}
              currentUserId={currentUserId}
              item={primaryItem}
              modifyHandler={modifyHandler}
              onAdjustmentAccept={onAdjustmentAccept}
              onAdjustmentReject={onAdjustmentReject}
              onAdjustmentRemove={onAdjustmentRemove}
              onAdjustmentSave={onAdjustmentSave}
              onAdjustmentSuggestion={onAdjustmentSuggestion}
              onAdjustmentSuggestionRemove={onAdjustmentSuggestionRemove}
              onDescriptionEdit={onDescriptionEdit}
              onGiveAway={onGiveAway}
              onTake={onTake}
              onUndo={onUndo}
              userTeamId={userTeamId}
            />
          )}
        </ShiftWrapper>
        <AvailabilityWrapper background={backgroundColor} />
      </div>
    </Cell>
  );
}

ShiftCell.propTypes = {
  modifyHandler: PropTypes.func,
  onAdjustmentAccept: PropTypes.func,
  onAdjustmentRemove: PropTypes.func,
  onAdjustmentSave: PropTypes.func,
  onAdjustmentSuggestion: PropTypes.func,
  onAdjustmentSuggestionRemove: PropTypes.func,
  onClick: PropTypes.func,
  primaryItem: PropTypes.object,
};
