import { Box } from 'grommet';
import { Children } from 'react';
import { useIsMobile } from 'utils/responsive';
import MenuWrapper from './Menu';

// eslint-disable-next-line react/prop-types
export default function ResponsiveMenu({ children }) {
  const isMobile = useIsMobile();

  return isMobile ? (
    <MenuWrapper label="Menu" items={Children.map(children, child => ({ label: child }))} />
  ) : (
    <Box direction="column" width="280px">
      {children}
    </Box>
  );
}
