import { Trans } from '@lingui/react';
import { Notification } from 'components/Notification';
import Spinner from 'components/Spinner';
import Toast from 'components/Toast';
import { Page, PageHeader, PageSection } from 'components/ui/Page';
import { UserConsumer } from 'contexts';
import { useI18n } from 'contexts/i18nWrapper';
import { Formik } from 'formik';
import { Box, Button, Footer, Form, FormField, TextInput } from 'grommet';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';

const UserChangedSuccesfully = () => {
  const i18n = useI18n();
  return i18n._('accounts.change-name.success');
};

function ChangeNameForm({ onChangeName, currentName }) {
  return (
    <Formik
      initialValues={{ newName: '', currentName }}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        const { newName } = values;
        try {
          await onChangeName(newName);
          setStatus({ type: 'success' });
          setSubmitting(false);
        } catch (exception) {
          setStatus({ type: 'error', message: 'Error occured. Please try again.' });
          setSubmitting(false);
        }
      }}
      validate={values => {
        const errors = {};

        if (!values['newName'] || values['newName'] === '') {
          errors['newName'] = 'Required!';
        }

        if (values['newName'] === values['currentName']) {
          errors['newName'] = 'Cannot be the same as previous one!';
        }

        return errors;
      }}
      render={({
        values,
        handleSubmit,
        handleBlur,
        handleChange,
        status,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form>
          <FormField
            label={<Trans id="account.change-name.form.current-name" />}
            className="rota-input-disabled"
          >
            <TextInput value={currentName} />
          </FormField>
          <FormField
            label={<Trans id="account.change-name.form.new-name" />}
            error={touched.newName && errors.newName}
          >
            <TextInput
              value={values.newName}
              name="newName"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
          <Footer>
            <Box justify="center" align="center" fill="horizontal" pad="large">
              <Button
                label={<Trans id="account.change-name.form.submit" />}
                primary={true}
                onClick={isSubmitting ? null : handleSubmit}
              />
              <div style={{ height: '40px', padding: '15px' }}>
                {isSubmitting && (
                  <div>
                    <Spinner size="16px" />
                  </div>
                )}
                {get(status, 'type') === 'success' && (
                  <Toast status="success">
                    <UserChangedSuccesfully />
                  </Toast>
                )}
                {get(status, 'type') === 'error' && (
                  <Notification message={status.message} size="medium" status="critical" />
                )}
              </div>
            </Box>
          </Footer>
        </Form>
      )}
    />
  );
}

ChangeNameForm.propTypes = {
  currentName: PropTypes.string,
  onChangeName: PropTypes.func,
};

export default class ChangeName extends Component {
  render() {
    return (
      <UserConsumer>
        {({ changeDisplayName, user }) => (
          <Page header={<PageHeader title={<Trans id="account.menu.change-name" />} />}>
            <PageSection>
              <ChangeNameForm currentName={user.displayname} onChangeName={changeDisplayName} />
            </PageSection>
          </Page>
        )}
      </UserConsumer>
    );
  }
}
