import { Trans } from '@lingui/react';
import googleApi from 'api/google';
import { Notification } from 'components/Notification';
import Spinner from 'components/Spinner';
import { Box, CheckBox, Paragraph } from 'grommet';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import errorMessageHandler from 'utils/errorMessageHandler';
import ServiceHeader from '../ServiceHeader';

export class GoogleCalendarNotificationsInner extends Component {
  static propTypes = {
    isIntegrated: PropTypes.func,
    enableIntegration: PropTypes.func,
  };

  state = {
    isIntegrated: null,
    loading: true,
    requestOngoing: false,
  };

  async componentDidMount() {
    const { data: isIntegrated } = await this.props.isIntegrated();

    this.setState({ isIntegrated, loading: false });
  }

  render() {
    return (
      <Fragment>
        <ServiceHeader
          name={<Trans id="services.google-calendar" />}
          iconUrl="https://www.gstatic.com/images/branding/product/1x/calendar_96dp.png"
        />
        <Paragraph>
          <Trans id="account.notifications.google-calendar.info" />
        </Paragraph>

        {this.state.loading ? (
          <div>
            <Spinner size="small" />
          </div>
        ) : (
          <Fragment>
            <div>
              <Box pad="medium" direction="row">
                <CheckBox
                  label={<Trans id="account.notifications.google-calendar.integration-toggle" />}
                  toggle
                  reverse
                  disabled={this.state.requestOngoing}
                  checked={this.state.isIntegrated}
                  onChange={this.handleIntegrate}
                />
                {this.state.requestOngoing && <Spinner size="small" />}
              </Box>
            </div>
            {this.state.isIntegrated && (
              <div>
                <Trans id="account.notifications.google-calendar.integrated" />
              </div>
            )}
          </Fragment>
        )}
        <Paragraph>
          {this.state.error && (
            <Notification message={this.state.error} size="medium" status="critical" />
          )}
        </Paragraph>
      </Fragment>
    );
  }

  handleIntegrate = async event => {
    const { checked } = event.target;

    try {
      this.setState({ requestOngoing: true });
      await this.props.enableIntegration(checked);
      this.setState({ isIntegrated: checked });
    } catch (error) {
      this.setState({ error: errorMessageHandler(error) });
    } finally {
      this.setState({ requestOngoing: false });
    }
  };
}

export default function GoogleCalendarNotifications() {
  return (
    <GoogleCalendarNotificationsInner
      isIntegrated={googleApi.isCalendarIntegrated}
      enableIntegration={googleApi.enableIntegration}
    />
  );
}
