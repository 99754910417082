import { themeColor } from 'config/colors';
import { Box, Button } from 'grommet';
import { FormPreviousLink, Menu } from 'grommet-icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useIsMobile } from 'utils/responsive';
import { DropdownButton } from '../DropdownButton/DropdownButton';

const PlainLink = styled(Link)`
  color: ${themeColor};

  &:hover {
    text-decoration: none;
  }
`;

const Wrapper = styled(Box)`
  margin-top: ${props => (props.hasLabel ? '0px' : '24px')};
  margin-bottom: 18px;

  margin-left: ${props => (props.isMobile ? '0px' : '16px')};

  h1 {
    font-family: Roboto;
    margin: 0;
    font-weight: 900;
  }
`;

// eslint-disable-next-line react/prop-types
export function PageHeader({ title, backTo, hasLabel, menuItems }) {
  const isMobile = useIsMobile();

  return (
    <Wrapper align="center" direction="row" gap="8px" isMobile={isMobile} hasLabel={hasLabel}>
      {backTo && (
        <PlainLink to={backTo}>
          <Button plain icon={<FormPreviousLink color={themeColor} size="32px" />} />
        </PlainLink>
      )}
      {/* {showBack && (
        <Button
          plain
          icon={<FormPreviousLink color={themeColor} size="32px" />}
          onClick={history.goBack}
        />
      )} */}

      <h1>{title}</h1>

      {menuItems && (
        <Box flex="grow" direction="row" justify="end">
          <DropdownButton label={<Menu />} items={menuItems} />
        </Box>
      )}
    </Wrapper>
  );
}
