import moment from 'moment';
import ENV from 'utils/env';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_MONTHS = 'YYYY-MM';
export const DATE_FORMAT_DAY = 'DD';
export const DATE_FORMAT_TIME = 'HH:mm';

export const DATE_FORMAT_MONTH_DAY = 'DD-MM';

export const HUMAN_READABLE_FORMAT = 'DD-MM-YYYY';
export const MAXIMUM_DAY_SPAN = 7;

export const TODAY = moment().format('YYYY-MM-DD');

export const defaultDateSpan =
  ENV.NODE_ENV === 'test'
    ? {
        dateFrom: '2018-10-07',
        dateTo: '2018-10-09',
      }
    : {
        dateFrom: moment().format(DATE_FORMAT),
        dateTo: moment()
          .add(0, 'days')
          .format(DATE_FORMAT),
      };
