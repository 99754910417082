import { Trans } from '@lingui/react';
import { PageNavContainer } from 'components/ui/PageNavContainer';
import { UserConsumer } from 'contexts';
import { Box } from 'grommet';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ChangeName from './ChangeName';
import ChangePassword from './ChangePassword';
import Notifications from './Notifications/Notifications';
import AccountOverview from './Overview';
import Teams from './Teams';

export class Account extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  render() {
    return (
      <Box direction="row-responsive" className="rota-Box-fix">
        <PageNavContainer
          navItems={[
            { to: '/account', label: <Trans id="account.menu.overview" /> },
            { to: '/account/teams', label: <Trans id="account.menu.teams" /> },
            { to: '/account/notifications', label: <Trans id="account.menu.notifications" /> },
            { to: '/account/change-password', label: <Trans id="account.menu.change-password" /> },
            { to: '/account/change-name', label: <Trans id="account.menu.change-name" /> },
          ]}
        >
          <Switch>
            <Route exact path="/account/teams" component={Teams} />
            <Route exact path="/account/notifications" component={Notifications} />
            <Route exact path="/account/change-password" component={ChangePassword} />
            <Route exact path="/account/change-name" component={ChangeName} />
            <Route
              exact
              path="/account"
              render={props => <AccountOverview user={this.props.user} {...props} />}
            />
          </Switch>
        </PageNavContainer>
      </Box>
    );
  }
}

export default function ContextWrapper(props) {
  return <UserConsumer>{({ user }) => <Account user={user} {...props} />}</UserConsumer>;
}
