// getUpcomingDeadlines

import { useGetUpcomingDeadlines } from 'api/deadline';
import { ItemRow, ItemWrapper } from 'components/ui/ItemsCalendar/Item';
import { DATE_FORMAT, DATE_FORMAT_MONTH_DAY } from 'config/dates';
import { UserContext } from 'contexts/User/UserContext';
import { Box } from 'grommet';
import moment from 'moment';
import { memo, useContext } from 'react';
import styled from 'styled-components';
import { ItemsList } from 'page/components/ItemsList';

const DayName = styled.span`
  text-transform: capitalize;
`;

export function useGetDeadlines() {
  const { currentTeam } = useContext(UserContext);
  return useGetUpcomingDeadlines(currentTeam.id);
}

const Deadline = memo(function({ periodStart, periodEnd, deadlineDate }) {
  const dayName = moment(deadlineDate, DATE_FORMAT).format('dddd');
  const start = moment(periodStart, DATE_FORMAT).format(DATE_FORMAT_MONTH_DAY);
  const end = moment(periodEnd, DATE_FORMAT).format(DATE_FORMAT_MONTH_DAY);
  const date = moment(deadlineDate, DATE_FORMAT).format(DATE_FORMAT_MONTH_DAY);

  return (
    <ItemWrapper>
      <Box gap="8px" fill="horizontal">
        <ItemRow>
          <DayName>{dayName}</DayName>, {date}
        </ItemRow>
        <ItemRow>
          Na: {start} - {end}
        </ItemRow>
      </Box>
    </ItemWrapper>
  );
});

export function UpcomingDeadlines() {
  const [{ data: deadlines = [], loading, error }, refetch] = useGetDeadlines();

  return (
    <ItemsList
      items={deadlines}
      loading={loading}
      error={error}
      onRefresh={refetch}
      renderItem={({ item }) => <Deadline key={item.id} {...item} />}
    />
  );
}
