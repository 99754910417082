import 'bootstrap/dist/css/bootstrap.min.css';

import 'page/styles';
import App from 'page/App';
import Public from 'page/Public';

import SessionResolver, { hasValidSession } from 'contexts/SessionResolver';
import LoggedInContainer from 'page/App/LoggedInContainer';

export default function Page() {
  return (
    <div>
      <LoggedInContainer
        style={{ height: '100%' }}
        hasValidSession={hasValidSession}
        publicChildren={<Public />}
        securedChildren={
          <SessionResolver>
            <App />
          </SessionResolver>
        }
      />
    </div>
  );
}
