import { Trans } from '@lingui/react';
import { DateFormField } from 'components/DateFormField';
import { DATE_FORMAT } from 'config/dates';
import { Formik } from 'formik';
import { Box, Form, FormField } from 'grommet';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import GenerateButton from './GenerateButton';

export default function MonthForm({ onGenerateReport }) {
  return (
    <Formik
      initialValues={{ from: '01/09/2018', to: '30/09/2018' }}
      validate={values => {
        const errors = {};

        if (!values.from) {
          errors['from'] = <Trans id="forms.required" />;
        }

        if (!values.to) {
          errors['to'] = <Trans id="forms.required" />;
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const { from, to } = values;

        const dateFrom = moment(from, 'DD/MM/YYYY').format(DATE_FORMAT);
        const dateTo = moment(to, 'DD/MM/YYYY').format(DATE_FORMAT);

        setSubmitting(true);
        try {
          await onGenerateReport(dateFrom, dateTo);
          setSubmitting(false);
        } finally {
          setSubmitting(false);
        }
      }}
      render={({
        values,
        isSubmitting,
        handleBlur,
        submitForm,
        touched,
        errors,
        setFieldValue,
      }) => (
        <Fragment>
          <Box pad="medium" direction="column" align="start">
            <Form>
              <FormField
                label={<Trans id="team.generate-reports.tab.form.from" />}
                error={touched.from && errors.from}
              >
                <DateFormField
                  id="generate-report-period-from-input"
                  name="from"
                  format="DD/MM/YYYY"
                  onChange={value => setFieldValue('from', value, true)}
                  onBlur={handleBlur}
                  value={values.from}
                />
              </FormField>
              <FormField
                label={<Trans id="team.generate-reports.tab.form.to" />}
                error={touched.to && errors.to}
              >
                <DateFormField
                  id="generate-report-period-to-input"
                  name="to"
                  format="DD/MM/YYYY"
                  onChange={value => setFieldValue('to', value, true)}
                  onBlur={handleBlur}
                  value={values.to}
                />
              </FormField>
            </Form>
          </Box>
          <Box pad="medium" direction="column" align="start">
            <GenerateButton disabled={isSubmitting} onClick={isSubmitting ? null : submitForm} />
          </Box>
        </Fragment>
      )}
    />
  );
}

MonthForm.propTypes = {
  onGenerateReport: PropTypes.func,
};
