import update from 'immutability-helper';
import { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import api from './api';

const UserContext = createContext({
  list: {},
});

const { Consumer, Provider } = UserContext;

export { Consumer, UserContext };

export class UserProviderInner extends Component {
  static propTypes = {
    api: PropTypes.object,
    children: PropTypes.any,
  };

  state = {
    _availableTeams: {},
    isChangingTeam: false,
  };

  render() {
    return <Provider value={this.getValue()}>{this.props.children}</Provider>;
  }

  // TODO: refactor this to just state
  getValue = () => ({
    ...this.state,
    addTeamMember: this.addTeamMember,
    addTeamMembers: this.addTeamMembers,
    availableTeams: this.getAvailableTeams(),
    changeDisplayName: this.changeDisplayName,
    currentTeam: this.getCurrentTeam(),
    currentUserTeamId: get(this.getCurrentTeam(), 'userTeamId'),
    currentTeamMembers: this.getCurrentTeamUsers(),
    getUser: this.getUser,
    isAdminInCurrentTeam: this.isAdminInCurrentTeam,
    login: this.login,
    registerUser: this.registerUser,
    registerAndCreateTeam: this.registerAndCreateTeam,
    removeTeamMember: this.removeTeamMember,
    setAvailableTeams: this.setAvailableTeams,
    setChangingTeam: this.setChangingTeam,
    requestPasswordReset: this.requestPasswordReset,
    passwordReset: this.passwordReset,
    setCurrentTeam: this.setCurrentTeam,
    setTeamUsers: this.setTeamUsers,
    setUser: this.setUser,
  });

  getAvailableTeams = () => Object.values(this.state._availableTeams);

  getCurrentTeam = () => this.state._availableTeams[this.state.currentTeamId];

  getCurrentTeamUsers = () => get(this.getCurrentTeam(), 'users', []);

  isAdminInCurrentTeam = () => this.getCurrentTeam().admin;

  setAvailableTeams = _availableTeams => this.setState({ _availableTeams });

  setCurrentTeam = teamId => this.setState({ currentTeamId: teamId });

  setTeamUsers = (teamId, users) => {
    this.setState(state => {
      if (state._availableTeams[teamId] === undefined) {
        state._availableTeams[teamId] = {};
      }

      return update(state, {
        _availableTeams: {
          [teamId]: {
            users: {
              $set: users,
            },
          },
        },
      });
    });
  };

  // TODO: change this to current team only?
  removeTeamMember = async userTeamId => {
    await this.props.api.removeTeamMember(userTeamId);
    const teamId = this.getCurrentTeam().id;

    this.setState(state =>
      update(state, {
        _availableTeams: {
          [teamId]: {
            users: {
              $set: state._availableTeams[teamId].users.filter(
                user => user.userTeamId !== userTeamId,
              ),
            },
          },
        },
      }),
    );
  };

  addTeamMember = (user, teamId) => {
    this.setState(state =>
      update(state, {
        _availableTeams: {
          [teamId]: {
            users: {
              $push: [user],
            },
          },
        },
      }),
    );
  };

  getUser = userTeamId => this.getCurrentTeam().users.find(user => user.userTeamId === userTeamId);

  setUser = user => this.setState({ user, isLogged: Boolean(user) });

  login = user => this.setUser(user);

  registerUser = (userRegistrationKey, userName, password) =>
    this.props.api.registerUser(userRegistrationKey, userName, password);

  registerAndCreateTeam = (teamName, email) =>
    this.props.api.registerAndCreateTeam(teamName, email);

  requestPasswordReset = email => this.props.api.requestPasswordReset(email);

  passwordReset = (newPassword, key) => this.props.api.passwordReset(newPassword, key);

  setChangingTeam = changingTeam => {
    this.setState({ isChangingTeam: changingTeam });
  };

  addTeamMembers = async (users, teamId) => {
    const { data } = await this.props.api.addTeamMembers(users, teamId);
    this.addTeamMember(data, teamId);
  };

  changeDisplayName = async name => {
    await this.props.api.changeDisplayName(name);

    this.setState(state => {
      const currentUserId = state._availableTeams[state.currentTeamId].userTeamId;
      const updatedTeamUsers = state._availableTeams[state.currentTeamId].users.map(
        user => (user.userTeamId === currentUserId ? { ...user, displayname: name } : user),
      );

      const updatedTeams = state._availableTeams;
      updatedTeams[state.currentTeamId].users = updatedTeamUsers;

      return {
        user: {
          ...state.user,
          displayname: name,
        },
        _availableTeams: updatedTeams,
      };
    });
  };
}

export default function UserProvider(props) {
  return <UserProviderInner api={api} {...props} />;
}
