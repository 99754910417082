import PropTypes from 'prop-types';
import { Fragment, Component } from 'react';
import Toast from 'components/Toast';

import CalendarNotificationBar from 'components/CalendarNotificationBar';
import { UserConsumer } from 'contexts';
import facebookApi from 'api/facebook';
import errorMessageHandler from 'utils/errorMessageHandler';
import translatedMessage from 'translatedMessage';
import { i18nMark } from '@lingui/react';

export class NotificationsBar extends Component {
  static propTypes = {
    currentTeam: PropTypes.object,
    onNewColumnNotify: PropTypes.func,
    onNewRosterNotify: PropTypes.func,
  };

  state = {
    notificationError: null,
  };

  render() {
    return (
      <Fragment>
        {this.state.notificationError && (
          <Toast status="critical">Error: {this.state.notificationError}</Toast>
        )}
        <CalendarNotificationBar
          onNewColumnNotify={this.handleNewColumnNotify}
          onNewRosterNotify={this.handleNewRosterNotify}
        />
      </Fragment>
    );
  }

  handleNewColumnNotify = () => {
    i18nMark('notifications-bar.new-columns');
    const sendNotifications = window.confirm(translatedMessage('notifications-bar.new-columns'));

    if (!sendNotifications) {
      return;
    }

    const { id: teamId } = this.props.currentTeam;
    const request = this.props.onNewColumnNotify(teamId);

    this.handleNotificationRequest(request);
  };

  handleNewRosterNotify = () => {
    i18nMark('notifications-bar.new-roster');
    const sendNotifications = window.confirm(translatedMessage('notifications-bar.new-roster'));

    if (!sendNotifications) {
      return;
    }

    const { id: teamId } = this.props.currentTeam;
    const request = this.props.onNewRosterNotify(teamId);

    this.handleNotificationRequest(request);
  };

  handleNotificationRequest = async request => {
    this.setState({ notificationError: null });

    try {
      await request;
    } catch (error) {
      this.setState({ notificationError: errorMessageHandler(error) });
    }
  };
}

export default function ContextWrapper() {
  return (
    <UserConsumer>
      {({ currentTeam }) => (
        <NotificationsBar
          currentTeam={currentTeam}
          onNewColumnNotify={facebookApi.notifyNewColumns}
          onNewRosterNotify={facebookApi.notifyNewRoster}
        />
      )}
    </UserConsumer>
  );
}
