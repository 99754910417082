import {
  backgroundColor,
  backgroundSecondaryColor,
  bodyBackgroundColor,
  navbarActiveTextColor,
  navbarTextColor,
  themeColor,
} from 'config/colors';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body, html {
    height: 100%;
    width: 100%;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    background: ${bodyBackgroundColor};
    color: #343434;
  }

  .navbar a {
    color: #313131;
  }
  
  .navbar a:hover {
    color: white;
  }
  
  .navbar .dropdown-toggle.nav-link {
    color: #313131;
  }
  
  .navbar .dropdown-toggle.nav-link:hover {
    color: white;
  }
  
  .btn-primary {
    background: ${themeColor} !important;
    border-color: ${themeColor} !important;
  }

  .table-fixed-layout > table.grommetux-table__table {
    table-layout: fixed;
  }

  .override_menu_xxs span.grommetux-button__icon {
    padding: 0px !important;
    opacity: 0.2;

    transition: opacity 0.15s ease-in-out;

    &:hover {
      opacity: 1.0;
    }
  }

  .override_menu_xxs {
    flex: 1;
    display: flex;
    align-items: flex-start;
  }

  .override_menu_xxs > div{
      padding: 6px 8px;
    
  }

  .override_menu_xxs__drop span.grommetux-button__icon {
    padding: 0px !important;
  }

  .rota-horizontal-form {
    width: auto !important;

    .grommetux-form-fields {
      display: flex;
      flex-direction: column;
    }
  }

  .rota-form-hide-error-message {
    .grommetux-form-field__error {
      display: none !important;
    }
  }

  .rota-split-fix {
    padding-top: 1em;

    .grommetux-split__column {
      height: 100% !important;
    }
  }

  .rota-pulse {
    display: block;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0 rgb(255, 214, 2, 0.4);
    animation: pulse 2s infinite;
  }
  .rota-pulse:hover {
    animation: none;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgb(255, 214, 2, 0.4);
    }
    50% {
        -webkit-box-shadow: 0 0 0 20px rgb(255, 214, 2, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgb(255, 214, 2, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgb(255, 214, 2, 0.4);
      box-shadow: 0 0 0 0 rgb(255, 214, 2, 0.4);
    }
    50% {
        -moz-box-shadow: 0 0 0 20px rgb(255, 214, 2, 0);
        box-shadow: 0 0 0 10px rgb(255, 214, 2, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgb(255, 214, 2, 0);
        box-shadow: 0 0 0 0 rgb(255, 214, 2, 0);
    }
  }

  .rota-popover-bs-fix {
    border: none;

    .grommet {
      background: rgb(245, 245, 245);
    }
  }

  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }

  .navbar {
    a, .nav-link {
      color: ${backgroundSecondaryColor} !important;
    }

    .dropdown-item a {
      color: #212529 !important;
    }
  }

  .rota-sgb-width-fix {
    @media (min-width: 720px) {
      max-width: calc(100vw - 25px);
    }
  }

  .rota-small-grommet-buttons {
    margin: -5px -10px 0px -10px;
    background: ${themeColor};

    .grommetux-button__icon {
      padding: 0px 6px !important;
    }

    .grommetux-button {
      padding: 2px 8px !important;
      height: 32px !important;
    }

    @media (max-width: 719px) {
      .grommetux-button {
        padding: 2px !important;
      } 

      margin: 0px;
    }
  }

  .rota-app-fluid {
    max-width: initial !important;
  }

  .rt-fixed {
    @media (max-width: 719px) {
      width: 25%;
    }

    width: 15%;
  }

  .rota-input-disabled {
    background: #f0f0f0 !important;
    cursor: not-allowed;

    & * {
      cursor: not-allowed;
    }
  }

  .rota-table-sticky {
    position: sticky !important; 
    background: white;
  }

  .rota-table-sticky-zero {
    top: 0px !important;
  }
  
  .table-responsive {
    overflow-x: visible !important;
  }

  .rota-rota-table-container {
    overflow:auto;
    width:100%;
    max-height: calc(100vh - 108px);
  }

  .rota-rota-table {
    width: calc(100% - 1px);
    font-size: 14px;
    font-weight: 400;

    @media (min-width: 720px) {
      table-layout: fixed;
    }

    .DeadlinedIcon {
      & > div {
        margin: 1px;
      }
    }

    td,
    th {
      border: 1px solid #f0f0f0;
      background-clip: padding-box;
    }
    
    table {
      table-layout: fixed;
      width:100%;
    }
    
    td:first-child, th:first-child {
      position:sticky;
      left:0;
      z-index:1;
      width: 15%;
      height: 100%;
      height: 42px;
      min-height: 42px;
    }

    tbody {
      tr:nth-child(2n+1) {
        background: ${backgroundColor};
      }
    }
    
    thead {
      tr th {
        position:sticky;
        top:0;
        background: ${backgroundSecondaryColor};
      }

      tr:nth-child(2n+1) {
        background: ${backgroundSecondaryColor};
      }
    }

    th:first-child, th:last-child {
      z-index:2;
    }
  }  

  .rota-accordion-no-padding > div {
    padding-left: 0;
    padding-right: 10px;
  }

  .navbar-link {
    color: ${navbarTextColor};
    font-weight: 600;
    box-sizing: border-box;

    &:hover {
      color: ${themeColor};
      text-decoration: none;  
    }
    
    @media (min-width: 720px) {
      &:hover {

        color: white;
      }
    }

    @media (min-width: 720px) {
      padding: 8px 12px;
    }
  }

  .navbar-link-active {
    color: ${navbarActiveTextColor} !important;
    font-weight: 600;

    @media (min-width: 720px) {
      color: ${themeColor} !important;
      background ${bodyBackgroundColor};
      border-radius: 20px;
    }
    

    .navbar-mobile-link-label {
      color: ${navbarActiveTextColor} !important;
    }

    svg {
      stroke: ${navbarActiveTextColor};
      fill: ${navbarActiveTextColor};
  }
  }
`;
