import { Box } from 'grommet';

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  /* max-width: 1100px; */
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;

  @media (min-width: 400px) {
    width: 85%;
    padding: 0;
  }
`;

// eslint-disable-next-line react/prop-types
export function Page({ children, header }) {
  return (
    <Wrapper>
      <Box fill="horizontal">
        {header}

        <Box direction="row" fill="horizontal" wrap>
          {children}
        </Box>
      </Box>
    </Wrapper>
  );
}
