import { Children } from 'react';
import { I18n } from '@lingui/react';
import { I18nFuncContextProvider } from 'contexts/i18nWrapper';
import { Menu, Box } from 'grommet';
import { useIsMobile } from 'utils/responsive';
import { I18nContextWrapper } from 'i18n';

function RotaMenu({ children, ...props }) {
  const items = Children.map(children, child => ({
    label: (
      <Box align="center" justify="center">
        {child}
      </Box>
    ),
  }));

  return <Menu items={items} {...props} />;
}

export default function MenuWrapper({ ...props }) {
  return (
    <I18n>
      {({ i18n }) => (
        <I18nFuncContextProvider value={i18n}>
          <RotaMenu {...props} />
        </I18nFuncContextProvider>
      )}
    </I18n>
  );
}

export function ResponsiveMenu({ children, ...props }) {
  const isMobile = useIsMobile();

  if (isMobile) {
    const items = Children.map(children, child => ({
      label: (
        <Box fill="vertical" pad={{ vertical: 'small' }}>
          {child}
        </Box>
      ),
    }));

    return (
      <I18nContextWrapper>
        <RotaMenu items={items} {...props} />
      </I18nContextWrapper>
    );
  }
  return (
    <Box direction="row" gap="medium" justify="end" {...props}>
      {Children.map(children, child => (
        <Box alignSelf="center" fill="vertical" align="center">
          {child}
        </Box>
      ))}
    </Box>
  );
}
