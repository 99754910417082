import { MaskedInput } from 'grommet';
import { forwardRef, useMemo } from 'react';

const MASKS = {
  hh: {
    length: [1, 2],
    options: Array.from({ length: 24 }, (v, k) => k + 1),
    regexp: /^(2[0-3]|[01]?[0-9])$/,
    placeholder: 'HH',
  },
  mm: {
    length: 2,
    options: ['00', '15', '30', '45'],
    regexp: /^[0-5][0-9]$|^[0-9]$/,
    placeholder: 'mm',
  },
};

function getMasksFromFormat(format = 'HH:mm') {
  const chunks = format.split(':');

  return chunks.reduce((acc, chunk, index) => {
    const chunkIdentifier = chunk.toLowerCase();
    const mask = MASKS[chunkIdentifier] || {};

    acc.push(mask);

    if (chunkIdentifier === 'hh' && index + 1 <= chunks.length && chunks[index + 1] === 'mm') {
      acc.push({ fixed: ':' });
    }

    return acc;
  }, []);
}

export const TimeFormField = forwardRef(
  ({ value, onChange, onBlur, format = 'HH:mm', ...props }, ref) => {
    const mask = useMemo(() => getMasksFromFormat(format), [format]);

    return (
      <MaskedInput
        ref={ref}
        mask={mask}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
    );
  },
);
