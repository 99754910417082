import { i18nMark } from '@lingui/react';
import translatedMessage from 'translatedMessage';

// Mark all errors here for translation:
i18nMark('error-codes.AVAILABILITIES_EXIST_ON_GIVEN_COLUMNDAY');
i18nMark('error-codes.OVERLAPPING_DEADLINES');
i18nMark('error-codes.SHIFTS_EXIST_ON_GIVEN_COLUMNDAY');
i18nMark('error-codes.USER_ALREADY_REGISTERED');
i18nMark('error-codes.USER_BUSY_ON_SHIFT');
i18nMark('error-codes.WRONG_DATES_COMBINATION');
i18nMark('error-codes.SHIFT_NOT_AWAITING_CHANGE');
i18nMark('error-codes.SHIFT_ALREADY_AWAITING_CHANGE');
i18nMark('error-codes.ACCESS_DENIED');
i18nMark('error-codes.UNKNOWN-ERROR-SHAPE');

// Created on frontend purely
i18nMark('error-codes.LANGUAGE_NOT_AVAILABLE');
i18nMark('error-codes.frontend.CANNOT_GIVE_AWAY');
i18nMark('error-codes.frontend.CANNOT_UNDO_GIVE_AWAY');

export function getErrorMessage(responseCode = '') {
  if (typeof responseCode !== 'string') {
    return translatedMessage('error-codes.UNKNOWN-ERROR-SHAPE');
  }

  return translatedMessage(`error-codes.${responseCode.toUpperCase()}`);
}
