import { ItemsCalendar } from 'components/ui/ItemsCalendar/ItemsCalendar';
import { Page, PageHeader } from 'components/ui/Page';
import { Loader } from 'page/components/routing';
import { getTwoWeeksDateInterval } from 'utils/Dates';
import { useDateFromToQueryParams } from 'utils/hooks';
import { ShiftsList, useGetShifts } from './components/ShiftsList';
import { ShiftToGiveAway } from './components/ShiftToGiveAway';

export function ShiftsToGiveAway({ dateFrom, dateTo }) {
  return (
    <ShiftsList
      url="/shifts/mine/{{ teamId }}/exchangeable"
      dateFrom={dateFrom}
      dateTo={dateTo}
      renderItem={({ item: shift, ...props }) => <ShiftToGiveAway shift={shift} {...props} />}
    />
  );
}

export function ShiftsToGiveAwayCalendar() {
  const { dateFrom, dateTo } = useDateFromToQueryParams({
    initialDates: getTwoWeeksDateInterval(),
  });
  const [{ shifts = [], loading, error }] = useGetShifts({
    url: '/shifts/mine/{{ teamId }}/exchangeable',
    dateFrom,
    dateTo,
  });

  return (
    <Page header={<PageHeader title="Oddawane zmiany" backTo="/work" />}>
      <Loader loading={loading} error={error}>
        <ItemsCalendar
          showWeeks
          dateFrom={dateFrom}
          dateTo={dateTo}
          items={shifts}
          renderItem={({ item: shift, ...props }) => <ShiftToGiveAway shift={shift} {...props} />}
          renderItemMobile={({ item: shift, ...props }) => (
            <ShiftToGiveAway shift={shift} {...props} />
          )}
        />
      </Loader>
    </Page>
  );
}
