import Spinner from 'components/Spinner';
import { warningColor } from 'config/colors';
import { Box } from 'grommet';
import styled from 'styled-components';

const ErrorWrapper = styled.div`
  font-weight: 800;
  color: ${warningColor};
`;

// eslint-disable-next-line react/prop-types
export function Loader({ loading, error, children, pad = '16px' }) {
  if (loading) {
    return (
      <Box fill="horizontal" justify="center" pad={pad}>
        <Spinner size="32px" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box fill="horizontal" pad={{ vertical: pad }}>
        <ErrorWrapper>Error loading</ErrorWrapper>
      </Box>
    );
  }

  return (
    <Box fill="horizontal" pad={{ vertical: pad }}>
      {children}
    </Box>
  );
}
