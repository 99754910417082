import Network from 'utils/network';
import qs from 'qs';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import { getDateKey, convertStartDurationToStartEnd } from 'utils/Dates';
import { DATE_FORMAT } from 'config/dates';
import { sortColumns } from 'utils/columns';

// Helper function to transform backend response with arrayed steps to objectified version
// with date as a first key in the object chain and columns as an array
function convertToObjectifiedColumns(columnsBatch) {
  // TODO: change to zipBy and just functional approach instead of mutating object
  const newColumns = {};

  columnsBatch.forEach(columnData => {
    const { date, column, id } = columnData;
    const dateKey = getDateKey(date);

    const { start: startString, duration: durationString } = column;

    if (!newColumns[dateKey]) {
      newColumns[dateKey] = [];
    }

    const { start, end } = convertStartDurationToStartEnd(startString, durationString);

    newColumns[dateKey].push({
      ...cloneDeep(column),
      start,
      end,
      date,
      id,
      columnId: column.id,
    });
  });

  // Sort columns by time, then name
  for (const dateKey in newColumns) {
    newColumns[dateKey] = sortColumns(newColumns[dateKey]);
  }

  return newColumns;
}

export default {
  getTeamColumns: async (dateFrom, dateTo, teamId) => {
    const queryParams = qs.stringify({ dateFrom, dateTo }, { addQueryPrefix: true });
    const response = await Network.get(`/columns-days/get/${teamId}${queryParams}`);

    response.data = convertToObjectifiedColumns(response.data);

    return response;
  },

  getPredefinedColumnsForTeam: teamId => Network.get(`/predefined-columns/get/${teamId}`),

  getDayTemplatesForTeam: teamId => Network.get(`/day-templates/get/${teamId}`),

  createColumns: async (columns, teamId) => {
    const body = {
      columnDaysDTOs: [],
      teamId,
    };

    let maxDate = '1970-01-01',
      minDate = '2099-01-01';

    columns.forEach(column => {
      const date = moment(column.date, 'DD-MM-YYYY');

      if (date.isBefore(minDate)) {
        minDate = date.format(DATE_FORMAT);
      }

      if (date.isAfter(maxDate)) {
        maxDate = date.format(DATE_FORMAT);
      }

      body.columnDaysDTOs.push({
        column: {
          header: column.header,
          start: column.start,
          duration: column.duration,
        },
        date: date.format(DATE_FORMAT),
      });
    });

    const response = await Network.post(`/columns-days/create/`, body);
    response.data = convertToObjectifiedColumns(response.data);

    return response;
  },

  saveDayTemplate: (header, columns, teamId) =>
    Network.post(`/day-templates/create`, {
      dayTemplateDTOs: [{ columns, header }],
      teamId,
    }),

  deleteDayTemplates: dayTemplates =>
    Network.delete(`/day-templates/delete`, {
      data: dayTemplates,
    }),

  deleteColumns: columnDayIds => Network.post('/columns-days/remove', columnDayIds),

  createPredefinedColumns: (predefinedColumns, teamId) =>
    Network.post(`/predefined-columns/create`, {
      columnDTOs: predefinedColumns,
      teamId,
    }),

  deletePredefinedColumns: predefinedColumns =>
    Network.delete(`/predefined-columns/delete`, {
      data: predefinedColumns,
    }),

  editColumn: async columnDay => {
    const response = await Network.put(`/columns/update`, [columnDay]);

    response.data = response.data.map(column => ({
      ...column,
      ...convertStartDurationToStartEnd(column.start, column.duration),
    }));

    return response;
  },
};
