import Cookies from 'js-cookie';
import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Anchor, Box } from 'grommet';
import { Notification } from 'components/Notification';
import * as Sentry from '@sentry/browser';

import FullscreenLoader from 'components/FullscreenLoader';
import { UserConsumer } from 'contexts';
import { setupTeamsData, logoutAction } from 'contexts/actions';
import { setAuthorizationHeader } from 'utils/network';
import { defaultDateSpan } from 'config/dates';
import { setSentryUser } from 'logging/sentry';
import FullscreenWrapper from 'components/FullscreenWrapper';

const SESSION_COOKIE_TOKEN_KEY = 'session';
const SESSION_COOKIE_USER_KEY = 'session-user';
const SESSION_COOKIE_USER_EMAIL = 'session-user-email';
const SESSION_COOKIE_USER_SELECTED_TEAM = 'session-user-selected-team';

export const hasValidSession = () => Boolean(Cookies.get(SESSION_COOKIE_TOKEN_KEY));

export const getSession = () => ({
  token: Cookies.get(SESSION_COOKIE_TOKEN_KEY),
  userId: Cookies.get(SESSION_COOKIE_USER_KEY),
  email: Cookies.get(SESSION_COOKIE_USER_EMAIL),
  selectedTeam: Cookies.get(SESSION_COOKIE_USER_SELECTED_TEAM),
});

export const setSelectedTeam = selectedTeam => {
  if (selectedTeam) {
    Cookies.set(SESSION_COOKIE_USER_SELECTED_TEAM, selectedTeam, {
      expires: 7,
    });
  }
};

export const setSession = (token, userId, email) => {
  Cookies.set(SESSION_COOKIE_TOKEN_KEY, token, { expires: 7 });
  Cookies.set(SESSION_COOKIE_USER_KEY, userId, { expires: 7 });
  Cookies.set(SESSION_COOKIE_USER_EMAIL, email, { expires: 7 });
};

export const cleanSession = () => {
  Cookies.remove(SESSION_COOKIE_TOKEN_KEY);
  Cookies.remove(SESSION_COOKIE_USER_KEY);
  Cookies.remove(SESSION_COOKIE_USER_EMAIL);
  Cookies.remove(SESSION_COOKIE_USER_SELECTED_TEAM);
};

export class SessionResolver extends Component {
  static propTypes = {
    children: PropTypes.any,
    getSession: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    setAuthorizationHeader: PropTypes.func.isRequired,
    setupSentry: PropTypes.func.isRequired,
    setupTeamsData: PropTypes.func.isRequired,
  };

  state = {
    sessionLoaded: false,
    error: null,
  };

  async componentDidMount() {
    const { token, userId, email, selectedTeam } = this.props.getSession();

    try {
      this.props.setAuthorizationHeader(token);

      const sentryEmail = email !== 'undefined' ? email : userId;
      this.props.setupSentry(sentryEmail);

      const isTeamSet = await this.props.setupTeamsData(userId, selectedTeam);

      this.setState({ sessionLoaded: true, isTeamSet });
    } catch (error) {
      if (error.message === 'Network Error') {
        this.props.logout();
      }

      // eslint-disable-next-line
      console.log("SessionResolver componentDidMount setupSession ", error);

      const statusCode = get(error, 'response.status', null);

      if (statusCode === 401) {
        this.props.logout();
      } else {
        this.setState({
          error: 'Loading session failed. Please try again.',
          sessionLoaded: false,
        });

        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'User logging with email: ' + email,
          data: {
            userId,
            selectedTeam,
            statusCode,
          },
          level: Sentry.Severity.Info,
        });

        Sentry.captureException(error);
      }
    }
  }

  render() {
    if (!this.state.sessionLoaded) {
      return (
        <FullscreenLoader text="Loading" error={this.state.error}>
          <Box pad="medium">
            <div>
              If the problem persists,{' '}
              <Anchor href="#" onClick={() => this.props.logout()} color="brand">
                click here
              </Anchor>{' '}
              to reset the session and log in again.
            </div>
          </Box>
        </FullscreenLoader>
      );
    } else if (!this.state.isTeamSet) {
      return (
        <FullscreenWrapper>
          <Box direction="column" align="center">
            <Box pad="large">
              <Notification
                message="You don't have any teams"
                state="Please contact your manager to resolve this issue"
                status="warning"
              />
            </Box>
            <Link to="/logout">Logout</Link>
          </Box>
        </FullscreenWrapper>
      );
    } else {
      return <Fragment>{this.props.children}</Fragment>;
    }
  }
}

export default function ContextWrapper(props) {
  return (
    <UserConsumer>
      {({ setAvailableTeams, setUser, setCurrentTeam }) => (
        <SessionResolver
          getSession={getSession}
          setAuthorizationHeader={setAuthorizationHeader}
          setupSentry={setSentryUser}
          setupTeamsData={setupTeamsData({
            dateFrom: defaultDateSpan.dateFrom,
            dateTo: defaultDateSpan.dateTo,
            setAvailableTeams,
            setUser,
            setCurrentTeam,
          })}
          logout={logoutAction}
          {...props}
        />
      )}
    </UserConsumer>
  );
}
