import UI from 'components/UI';
import FEATURE_FLAGS from 'config/featureFlags';
import { UserConsumer } from 'contexts';
import TeamChangeResolver from 'contexts/TeamChangeResolver';
import Page404 from 'page/App/404';
import Account from 'page/App/Account/Account';
import Admin from 'page/App/Admin/Admin';
import { AvailabilitiesEdit } from 'page/App/Availabilities';
import Columns from 'page/App/Columns/Columns';
import ColumnsCreator from 'page/App/Columns/Creator';
import Guide from 'page/App/Guide';
import Shifts from 'page/App/Shifts/Shifts';
import Team from 'page/App/Team';
import GenerateReport from 'page/App/Team/GenerateReport';
import TeamAddUsers from 'page/App/Team/TeamAddUsers';
import TeamManage from 'page/App/Team/TeamManage';
import Navbar from 'page/components/Navbar';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AvailabilityRoutes } from './Availability';
import GuideHistoryHint from './GuideHistoryHint';
import { WorkRoutes } from './Work';
import { Grommet } from 'components/Grommet';

export default function App() {
  return (
    <UserConsumer>
      {({ isAdminInCurrentTeam, isChangingTeam }) => (
        <Grommet>
          <UI navbar={<Navbar />}>
            <GuideHistoryHint />
            <TeamChangeResolver isChangingTeam={isChangingTeam}>
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/work" />} />
                <Route path="/work">
                  <WorkRoutes />
                </Route>
                <Route path="/availability">
                  <AvailabilityRoutes />
                </Route>
                <Route
                  path="/availabilities"
                  render={props => (
                    <AvailabilitiesEdit adminMode={isAdminInCurrentTeam()} {...props} />
                  )}
                />
                <Route path="/team/manage/add-users" component={TeamAddUsers} />
                <Route path="/team/manage/reports" component={GenerateReport} />
                <Route path="/team/manage" component={TeamManage} />
                <Route path="/team" component={Team} />
                <Route path="/columns/creator" component={ColumnsCreator} />
                <Route path="/columns" component={Columns} />
                <Route path="/shifts" component={Shifts} />
                <Route path="/account" component={Account} />
                <Route path="/guide" component={Guide} />
                {FEATURE_FLAGS.ADMIN_PANEL &&
                  isAdminInCurrentTeam() && <Route path="/admin" component={Admin} />}
                <Route component={Page404} />
              </Switch>
            </TeamChangeResolver>
          </UI>
        </Grommet>
      )}
    </UserConsumer>
  );
}
