export default {
  ADD_MULTIPLE_TEAM_USERS: false,
  ADMIN_PANEL: false,
  UPCOMING_DEADLINES: false,
  ENABLE_FACEBOOK_NOTIFICATIONS: true,
  ENABLE_TELEGRAM_NOTIFICATIONS: false,
  ENABLE_GOOGLE_CALENDAR_NOTIFICATIONS: false,
  WEEK_OVERVIEW: true,
  SHIFT_ADJUSTMENTS: false,
  WORKING_HOURS_SUMMARY: window.localStorage.getItem('FEATURE_WORKING_HOURS_SUMMARY') || true,
};
