import { Trans } from '@lingui/react';
import { PageSection } from 'components/ui/Page';
import { Page } from 'components/ui/Page/Page';
import { PageHeader } from 'components/ui/Page/PageHeader';
import { UserContext } from 'contexts/User/UserContext';
import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CallbacksSyncProvider } from 'utils/callbacksSync';
import { getTwoWeeksDateInterval } from 'utils/Dates';
import { ColumnDay } from './ColumnDay';
import { ShiftsCalendar, UpcomingShifts } from './Shifts';
import { ShiftsToGiveAway } from './ShiftsToGiveAway';
import { ShiftsToTake, ShiftsToTakeCalendar } from './ShiftsToTake';

function Work() {
  const { dateFrom, dateTo } = getTwoWeeksDateInterval();
  return (
    <Page header={<PageHeader title={<Trans id="shift-page.header" />} />}>
      <CallbacksSyncProvider>
        <PageSection title={<Trans id="shift-page.your-shifts" />} to="/work/shifts">
          <PageSection.Subtitle title={<Trans id="shift-page.your-shifts-subtitle" />} />
          <UpcomingShifts dateFrom={dateFrom} dateTo={dateTo} />
        </PageSection>

        <PageSection title={<Trans id="shift-page.shifts-to-take" />} to="/work/shifts-to-take">
          <ShiftsToTake dateFrom={dateFrom} dateTo={dateTo} />
        </PageSection>

        <PageSection title={<Trans id="shift-page.shifts-given-away" />}>
          <ShiftsToGiveAway dateFrom={dateFrom} dateTo={dateTo} />
        </PageSection>
      </CallbacksSyncProvider>
    </Page>
  );
}

export function WorkRoutes() {
  const { isAdminInCurrentTeam } = useContext(UserContext);

  return (
    <Route path="/work">
      <Switch>
        <Route exact path="/work">
          <Work />
        </Route>
        <Route path="/work/shifts/:columnDayId">
          <ColumnDay adminMode={isAdminInCurrentTeam()} />
        </Route>
        <Route path="/work/shifts">
          <Page
            header={<PageHeader title={<Trans id="shift-page.your-shifts" />} backTo="/work" />}
          >
            <PageSection full>
              <ShiftsCalendar />
            </PageSection>
          </Page>
        </Route>
        <Route path="/work/shifts-to-take">
          <Page
            header={<PageHeader title={<Trans id="shift-page.shifts-to-take" />} backTo="/work" />}
          >
            <PageSection full>
              <ShiftsToTakeCalendar />
            </PageSection>
          </Page>
        </Route>
        {/* <Route path="/work/shifts-giving-away">
            <ShiftsToGiveAwayCalendar />
          </Route> */}
      </Switch>
    </Route>
  );
}
