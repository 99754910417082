export default class Timer {
  constructor() {
    this.start = Date.now();
  }

  end() {
    this.end = Date.now();
    this.duration = (this.end - this.start) / 1000;
  }
}
