import { minutesToText } from 'components/AdjustmentsBox/AdjustmentRow';
import { backgroundSecondaryColor } from 'config/colors';
import { Box } from 'grommet';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import styled from 'styled-components';
import Button from './Button';

const Wrapper = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 0 0 0.5em !important;
  border-radius: 5px;
  margin: 0.2em 0.5em;

  background: ${backgroundSecondaryColor};

  & > * {
    flex: 1;
    margin-right: 3px;
  }

  & > .column-header {
    flex: 1;
    margin-right: 3px;
  }
`;

const ColumnHeader = styled.div`
  flex: 2;
  margin-right: 3px;
`;

const Adjustment = styled.div`
  flex: 0;
  margin: 0 12px;
`;

const Date = styled.div`
  flex: 0;
  white-space: nowrap;
  margin: 0 12px;
`;

export default class PendingExchangesRow extends PureComponent {
  state = {
    loading: false,
  };

  render() {
    const { userName, date, columnHeader, start, end } = this.props.adjustment;
    const adjustmentText = this._getAdjustmentText();

    return (
      <Wrapper>
        <div>{userName}</div>
        <Date>{date}</Date>
        <ColumnHeader>{columnHeader}</ColumnHeader>
        <Adjustment>
          {start}-{end}
        </Adjustment>
        <Adjustment>{adjustmentText}</Adjustment>
        <Box direction="row" justify="end" style={{ flex: 0 }}>
          <Button type="accept" loading={this.state.loading} onClick={this.handleAccept} />
          <Button type="reject" loading={this.state.loading} onClick={this.handleReject} />
        </Box>
      </Wrapper>
    );
  }

  handleAccept = async () => {
    this.setState({ loading: true });

    try {
      await this.props.onAccept(this.props.adjustment);
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  handleReject = async () => {
    this.setState({ loading: true });

    try {
      await this.props.onReject(this.props.adjustment);
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  _getAdjustmentText = () => {
    const { suggestedAdjustmentMinutes: minutes } = this.props.adjustment;
    const sign = minutes > 0 ? '+' : '-';

    return `${sign}${minutesToText(Math.abs(minutes))}`;
  };
}

PendingExchangesRow.propTypes = {
  column: PropTypes.string,
  date: PropTypes.string,
  adjustment: PropTypes.object,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  user: PropTypes.string,
};
