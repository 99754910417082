/* eslint-disable react/prop-types */
import { Trans } from '@lingui/react';
import { WarningSubtitle } from 'components/ui/WarningSubtitle';
import { Box } from 'grommet';
import { currentLanguage } from 'i18n';
import { chunk, flatten, get, groupBy, sortBy, fromPairs } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { sortColumns } from 'utils/columns';
import {
  getCurrentMonth,
  getDaysFromTo,
  getDaysWithExtrasForMonth,
  getPrettyDayFromDate,
} from 'utils/Dates';
import { useIsMobile } from 'utils/responsive';
import { PageSection } from '../Page';
import { WarningSubtitleWithIcon } from '../WarningSubtitle';
import { ItemRow, ItemWrapper } from './Item';

const PlainLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const Week = styled(Box)`
  width: 100%;
  min-height: 100px;
  flex-direction: column;

  @media (min-width: 720px) {
    flex-direction: row;
  }
`;

const Day = styled(Box)`
  flex: 0 0 calc(14.2857% - 5px);
  padding: 6px;
  margin-bottom: 5px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  background: white;

  border-radius: 10px;

  svg {
    width: 16px;
  }

  h4 {
    margin: 0;
  }
`;

function DayHeader({ date }) {
  return (
    <Box>
      {getPrettyDayFromDate(date, 'DD.MM')}, {getPrettyDayFromDate(date, 'dddd')}
    </Box>
  );
}

const DayHeaderWrapper = styled.h4`
  text-transform: capitalize;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DAYS = new Array(7).fill(0).map((_, index) =>
  new Date(`2020-01-2${index}`).toLocaleDateString(currentLanguage, {
    weekday: 'long',
  }),
);

const MobileCalendarItemsWrapper = styled(Box)`
  button {
    padding: 0;
    svg {
      width: 16px;
    }
  }

  h4 {
    margin: 0;
  }
`;

// eslint-disable-next-line react/prop-types
function MobileCalendarItems({
  weeks,
  showEmptyDays = false,
  groupedItems,
  renderItem,
  onRefresh,
  sectionMenuItems,
}) {
  const itemsToShow = useMemo(
    () =>
      showEmptyDays
        ? flatten(weeks).map(day => [day, get(groupedItems, day, [])])
        : Object.entries(groupedItems),
    [weeks, showEmptyDays, groupedItems],
  );

  return (
    <MobileCalendarItemsWrapper>
      {/* // TODO: show empty days too with a flag */}
      {itemsToShow.map(([day, items]) => (
        <Box>
          <Box pad={{ bottom: '8px' }}>
            <PageSection.Subtitle
              title={<DayHeader date={day} />}
              menuItems={
                sectionMenuItems &&
                sectionMenuItems.map(menuItem => ({
                  ...menuItem,
                  onClick: event => menuItem.onClick(event, get(groupedItems, day, []), day),
                }))
              }
            />
          </Box>

          {Object.keys(groupedItems).length === 0 && (
            <WarningSubtitleWithIcon>
              <Trans id="items-calendar.no-items" />
            </WarningSubtitleWithIcon>
          )}

          <Box>
            {items.length === 0 && (
              <Box pad={{ bottom: 'small' }}>
                <WarningSubtitle>
                  <Trans id="items-calendar.no-items" />
                </WarningSubtitle>
              </Box>
            )}
            {items.map(item => renderItem({ day, item, onRefresh }))}
          </Box>
        </Box>
      ))}
    </MobileCalendarItemsWrapper>
  );
}

function ItemHeader({ item }) {
  const { start, end } = get(item, 'column', {});
  const { description } = item;

  return (
    <Box direction="row" justify="between" fill="horizontal">
      <Box gap="8px">
        <ItemRow wrap>
          {get(item, 'column.header', 'Unknown header')}
          {description && <WarningSubtitle fontSize="12px">{description}</WarningSubtitle>}
        </ItemRow>
        <ItemRow wrap>
          {start} - {end}
        </ItemRow>
      </Box>
    </Box>
  );
}

// Todo: refactor this out because its not a generic item but a shift template
function Item({ children, item }) {
  const columnDayId = get(item, 'columnDayId');
  const to = `/work/shifts/${columnDayId}`;
  return (
    <PlainLink to={to}>
      <ItemWrapper small>
        <ItemHeader item={item} />

        {children}
      </ItemWrapper>
    </PlainLink>
  );
}

function MobileItem({ children, item }) {
  const columnDayId = get(item, 'columnDayId');
  const to = `/work/shifts/${columnDayId}`;

  return (
    <PlainLink to={to}>
      <ItemWrapper>
        <ItemHeader item={item} />

        {children}
      </ItemWrapper>
    </PlainLink>
  );
}

const WeekViewItemWrapper = styled(Box)`
  flex: 0 0 50%;
`;

function ItemsCalendarWeekDay({ date, items, renderItem, onRefresh }) {
  return (
    <>
      <Box pad={{ bottom: '8px' }}>
        <PageSection.Subtitle title={<DayHeader date={date} />} />
      </Box>
      <Box direction="row" gap="16px">
        {items.map(item => (
          <WeekViewItemWrapper>{renderItem({ date, item, onRefresh })}</WeekViewItemWrapper>
        ))}

        {items.length === 0 && (
          <WarningSubtitle>
            <Trans id="items-calendar.no-items" />
          </WarningSubtitle>
        )}
      </Box>
    </>
  );
}

function ItemsCalendarWeekView({
  items,
  // eslint-disable-next-line no-unused-vars
  renderDay,
  renderItem = Item,
  // eslint-disable-next-line no-unused-vars
  renderHeader,
  // eslint-disable-next-line no-unused-vars
  month,
  // eslint-disable-next-line no-unused-vars
  showWeeks,
  dateFrom,
  dateTo,
  onRefresh,
}) {
  const groupedItems = useMemo(() => groupBy(sortBy(items, 'date'), 'date'), [items]);
  const days = useMemo(() => getDaysFromTo(dateFrom, dateTo), [dateFrom, dateTo]);
  return (
    <>
      {days.map(day => (
        <ItemsCalendarWeekDay
          items={get(groupedItems, day, [])}
          date={day}
          renderItem={renderItem}
          onRefresh={onRefresh}
        />
      ))}
    </>
  );
}

// eslint-disable-next-line react/prop-types
export function ItemsCalendar({
  items,
  renderDay,
  renderItem = Item,
  renderItemMobile = MobileItem,
  renderHeader,
  renderHeaderDetails,
  dateFrom,
  dateTo,
  showWeeks,
  month = getCurrentMonth(),
  onRefresh,
  onDayClick,
  sectionMenuItems,
  showEmptyDays = false,
}) {
  useEffect(
    () => {
      if (showWeeks && !dateFrom && !dateTo) {
        throw new Error('passed showWeeks but not dateFrom and dateTo!');
      }
    },
    [showWeeks, dateFrom, dateTo],
  );

  const isMobile = useIsMobile();

  // TODO: extract to full month view
  const weeks = useMemo(
    () => {
      const days = getDaysWithExtrasForMonth(month);
      return chunk(days, 7);
    },
    [month],
  );

  const groupedItems = useMemo(
    () => {
      const _grouped = groupBy(sortBy(items, 'date'), 'date');
      return fromPairs(
        Object.entries(_grouped).map(([date, _items]) => [date, sortColumns(_items)]),
      );
    },
    [items],
  );
  return (
    <Box fill="horizontal">
      {isMobile ? (
        <MobileCalendarItems
          weeks={weeks}
          groupedItems={groupedItems}
          renderItem={renderItemMobile}
          onRefresh={onRefresh}
          sectionMenuItems={sectionMenuItems}
          showEmptyDays={showEmptyDays}
        />
      ) : showWeeks ? (
        <ItemsCalendarWeekView
          onRefresh={onRefresh}
          items={items}
          renderDay={renderDay}
          renderItem={renderItem}
          renderHeader={renderHeader}
          showWeeks={showWeeks}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      ) : (
        <Box>
          <Box direction="row">
            {DAYS.map(day => (
              <Day key={day}>
                <DayHeaderWrapper>{day}</DayHeaderWrapper>
              </Day>
            ))}
          </Box>
          {weeks.map((week, index) => (
            <Week key={`week-${get(week, '0')}-${index}`}>
              {week.map(day => (
                <Day
                  key={day}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();

                    if (onDayClick) {
                      onDayClick(day);
                    }
                  }}
                >
                  <Box
                    pad={{ bottom: '8px' }}
                    gap="8px"
                    direction="row"
                    align="center"
                    justify="between"
                  >
                    <PageSection.Subtitle
                      title={
                        <Box direction="row" align="center">
                          {getPrettyDayFromDate(day)}
                          {renderHeaderDetails && (
                            <Box pad={{ horizontal: '8px' }}>{renderHeaderDetails({ day })}</Box>
                          )}
                        </Box>
                      }
                      menuItems={
                        sectionMenuItems &&
                        sectionMenuItems.map(menuItem => ({
                          ...menuItem,
                          onClick: event =>
                            menuItem.onClick(event, get(groupedItems, day, []), day),
                        }))
                      }
                    />
                  </Box>

                  <Box className="items">
                    {get(groupedItems, day, []).map(item =>
                      renderItem({ date: day, item, onRefresh }),
                    )}
                  </Box>
                </Day>
              ))}
            </Week>
          ))}
        </Box>
      )}
    </Box>
  );
}
