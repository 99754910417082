import FullscreenWrapper from 'components/FullscreenWrapper';
import { Link } from 'components/Link';
import { Notification } from 'components/Notification';
import Spinner from 'components/Spinner';
import { emailValidationRegex } from 'config/supportAndService';
import { UserConsumer } from 'contexts';
import { withFormik } from 'formik';
import {
  Anchor,
  Box,
  Button,
  Footer,
  Form,
  FormField,
  Header,
  Heading,
  Image,
  TextInput,
} from 'grommet';
import { Favorite as FavoriteIcon } from 'grommet-icons';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Component } from 'react';

import analytics from 'analytics';
import errorMessageHandler from 'utils/errorMessageHandler';

const RegisterFormInner = props => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    status,
    touched,
    values,
  } = props;

  return (
    <FullscreenWrapper>
      <Box tag="section">
        <h2 />
      </Box>
      <Box tag="section">
        <Box fill="horizontal" justify="center" align="center" responsive pad="medium">
          <Form>
            <Header>
              <Box fill="horizontal" justify="center" align="center" direction="column">
                <Box width="medium" height="small">
                  <Image
                    src="https://s3.eu-central-1.amazonaws.com/app.rota.works.staticfiles/rota1.png"
                    fit="contain"
                  />
                </Box>
                <Heading level={3}>Załóż zespół</Heading>
              </Box>
            </Header>

            <FormField label="Nazwa zespołu" error={touched.teamName && errors.teamName}>
              <TextInput
                value={values.teamName}
                name="teamName"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormField>

            <FormField label="E-mail" error={touched.email && errors.email}>
              <TextInput
                type="email"
                id="register-and-create-team-input-email"
                value={values.email}
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormField>

            <Footer>
              <Box justify="center" align="center" fill="horizontal" pad="large">
                <Button
                  label={!isSubmitting ? 'Załóż!' : 'Tworzę zespół'}
                  primary={true}
                  onClick={handleSubmit}
                  icon={isSubmitting && <Spinner size="32px" />}
                  disabled={isSubmitting}
                  reverse
                />
                <Box pad="small">
                  {status && <Notification message={status} size="medium" status="critical" />}
                </Box>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ paddingBottom: '20px' }}>
                    Wyślemy Ci maila z linkiem aktywacyjnym, aby zakończyć proces rejestracji.
                  </div>
                  <hr />
                  <div>Posiadasz już konto?</div>
                  <Link to="/login">
                    <Anchor label="Strona logowania" />
                  </Link>
                </div>
              </Box>
            </Footer>
          </Form>
        </Box>
      </Box>
    </FullscreenWrapper>
  );
};

RegisterFormInner.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onRegisterAndCreateTeam: PropTypes.func,
  status: PropTypes.any,
  touched: PropTypes.object,
  values: PropTypes.object,
};

function analyticsTrackAccountCreatedEvent() {
  try {
    analytics.track({
      category: 'Leads',
      action: 'AccountCreated',
      label: 'RegisterPage',
      value: 1,
    });
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }
}

const RegisterForm = withFormik({
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { teamName, email } = values;
    try {
      await props.onRegisterAndCreateTeam(teamName, email);

      analyticsTrackAccountCreatedEvent();

      setStatus(null);
      setSubmitting(false);
    } catch (exception) {
      const errorMessage = errorMessageHandler(exception, 'Error occured. Please try again.');

      setStatus(errorMessage);
      setSubmitting(false);
    }
  },
  validate: values => {
    const errors = {};

    if (!values.teamName || values.teamName === '') {
      errors['teamName'] = 'Required';
    }

    if (!emailValidationRegex.test(values.email)) {
      errors['email'] = 'Invalid format';
    }

    return errors;
  },
})(RegisterFormInner);

export class UserJoin extends Component {
  static propTypes = {
    location: PropTypes.object,
    onRegisterAndCreateTeam: PropTypes.func,
  };

  state = {
    loading: true,
    teamName: '',
    toApp: false,
  };

  componentDidMount() {
    const { teamName, email } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    this.setState({
      email,
      loading: false,
      teamName,
    });
  }

  render() {
    return this.state.toApp ? (
      <FullscreenWrapper>
        <Box align="center" pad="large">
          <h3>Your team has been created!</h3>
          <h3>
            <FavoriteIcon size="large" />
          </h3>
          <h3>Check your email for an activation link.</h3>
        </Box>
      </FullscreenWrapper>
    ) : this.state.loading ? (
      <div>Loading ...</div>
    ) : (
      <RegisterForm
        teamName={this.state.teamName}
        email={this.state.email}
        onRegisterAndCreateTeam={this.handleTeamCreate}
      />
    );
  }

  handleTeamCreate = async (teamName, email) => {
    await this.props.onRegisterAndCreateTeam(teamName, email);
    this.setState({ toApp: true });
  };
}

export default function ContextWrapper(props) {
  return (
    <UserConsumer>
      {({ registerAndCreateTeam }) => (
        <UserJoin onRegisterAndCreateTeam={registerAndCreateTeam} {...props} />
      )}
    </UserConsumer>
  );
}
