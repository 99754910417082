import { Trans } from '@lingui/react';
import AbsoluteIcon from 'components/AbsoluteIcon';
import PropTypes from 'prop-types';
import { Component, useCallback, useState } from 'react';
import Tooltip from 'reactstrap/lib/Tooltip';
import Icon from './Icon';
import { getDeadlineIconName } from './RotaTable/ItemModule/itemModule';

export default class DeadlinedIcon extends Component {
  static propTypes = {
    date: PropTypes.string,
    id: PropTypes.string,
    status: PropTypes.any,
  };

  state = {
    tooltipOpen: false,
  };

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  render() {
    const deadlinedIconName = getDeadlineIconName([this.props.status]);

    return (
      <span className="DeadlinedIcon">
        <AbsoluteIcon size={14} name={deadlinedIconName} id={this.props.id} />
        {this.props.date && (
          <Tooltip
            placement="right"
            isOpen={this.state.tooltipOpen}
            target={this.props.id}
            toggle={this.toggle}
          >
            <Trans id="deadline-icon.set-for-this-date" /> {this.props.date}
          </Tooltip>
        )}
      </span>
    );
  }
}

// eslint-disable-next-line react/prop-types
export function RelativeDeadlineIcon({ date, status, id }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggle = useCallback(() => setShowTooltip(_s => !_s), []);

  const deadlinedIconName = getDeadlineIconName([status]);

  return (
    <span className="DeadlinedIcon">
      <Icon size={14} name={deadlinedIconName} id={id} />
      {date && (
        <Tooltip placement="right" isOpen={showTooltip} target={id} toggle={toggle}>
          <Trans id="deadline-icon.set-for-this-date" /> {date}
        </Tooltip>
      )}
    </span>
  );
}
