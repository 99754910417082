import { Component, createContext } from 'react';
import set from 'lodash/set';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import { getItemCompositeId } from 'contexts/utils';
import { getDateKey, getDateIntervalSteps } from 'utils/Dates';

import shiftApi from 'contexts/Shift/api';
import {
  applyModifyChanges,
  getModifyRequestForItemPair,
} from 'components/RotaTable/ItemModule/itemModule';

const ShiftContext = createContext({
  list: {},
});

const { Consumer, Provider } = ShiftContext;

export { ShiftContext, Consumer };

export default class ShiftProvider extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      acceptAdjustment: this.acceptAdjustment,
      addShifts: this.addShifts,
      clearShifts: this.clearShifts,
      editDescription: this.editDescription,
      fetchShifts: this.fetchShifts,
      giveAwayShift: this.giveAwayShift,
      modifyShifts: this.modifyShifts,
      rejectAdjustment: this.rejectAdjustment,
      removeAdjustment: this.removeAdjustment,
      removeAdjustmentSuggestion: this.removeAdjustmentSuggestion,
      saveAdjustment: this.saveAdjustment,
      shifts: {},
      suggestAdjustment: this.suggestAdjustment,
      takeShift: this.takeShift,
      undoGiveAwayShift: this.undoGiveAwayShift,
    };
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }

  addShifts = (shifts, dateSteps = []) => {
    const newShifts = Object.assign({}, this.state.shifts);

    // Filling days for shifts, to prevent flickering in quick updates on
    // empty day
    dateSteps.forEach(step => {
      if (!newShifts[step]) {
        newShifts[step] = {};
      }
    });

    shifts.forEach(shift => {
      const dateKey = getDateKey(shift.date);

      const { columnDayId, userTeamId } = shift;
      const id = getItemCompositeId({ columnDayId, userTeamId });

      set(newShifts, `${dateKey}.${id}`, shift);
    });

    this.setState({ shifts: newShifts });
  };

  clearShifts = () => {
    this.setState({ shifts: {} });
  };

  fetchShifts = async (dateFrom, dateTo, teamId) => {
    const { data } = await shiftApi.getTeamShifts(dateFrom, dateTo, teamId);
    const dateSteps = getDateIntervalSteps(dateFrom, dateTo);

    this.addShifts(data, dateSteps);
  };

  giveAwayShift = async ({ userTeamId, columnDayId, date }, sendNotifications = true) => {
    // eslint-disable-next-line
    const { data } = await shiftApi.giveAwayShift(userTeamId, columnDayId, date, sendNotifications);
    this.updateShift({ userTeamId, columnDayId, date }, { exchangeable: true });
  };

  takeShift = async (shift, currentUserTeamId) => {
    const { userTeamId, columnDayId, date } = shift;

    await shiftApi.takeShift(userTeamId, columnDayId, date);

    const newShift = { ...shift, exchangeable: false, userTeamId: currentUserTeamId };

    const changes = [
      getModifyRequestForItemPair([shift, null]),
      getModifyRequestForItemPair([null, newShift]),
    ];

    const { shifts } = applyModifyChanges(Object.assign({}, this.state.shifts), changes, 'shifts');
    this.setState({ shifts });

    return true;
  };

  updateShift = (shift, values) => {
    const newShifts = cloneDeep(this.state.shifts);

    const dateKey = getDateKey(shift.date);

    const { columnDayId, userTeamId } = shift;
    const id = getItemCompositeId({ columnDayId, userTeamId });

    const originalShift = cloneDeep(newShifts[dateKey][id]);

    const newShift = {
      ...originalShift,
      ...omit(values, 'columnDayId', 'userTeamId', 'date'),
    };

    newShifts[dateKey][id] = newShift;

    this.setState({ shifts: newShifts });
  };

  removeShift = shift => {
    const { columnDayId, userTeamId, date } = shift;

    const newShifts = Object.assign({}, this.state.shifts);
    const dateKey = getDateKey(date);
    const id = getItemCompositeId({ columnDayId, userTeamId });

    delete newShifts[dateKey][id];

    this.setState({ shifts: newShifts });
  };

  modifyShifts = async changes => {
    const { data } = await shiftApi.modifyShifts(changes);

    const { shifts } = applyModifyChanges(Object.assign({}, this.state.shifts), changes, 'shifts');
    this.setState({ shifts });

    return data;
  };

  undoGiveAwayShift = async ({ userTeamId, columnDayId, date }) => {
    // eslint-disable-next-line
    const { data } = await shiftApi.undoGiveAwayShift(userTeamId, columnDayId, date);
    this.updateShift({ userTeamId, columnDayId, date }, { exchangeable: false });
  };

  suggestAdjustment = async ({ userTeamId, columnDayId, date }, adjustmentMinutes) => {
    const { data } = await shiftApi.suggestAdjustments([
      { userTeamId, columnDayId, date, adjustmentMinutes },
    ]);
    this.updateShift({ userTeamId, columnDayId, date }, data[0]);
  };

  removeAdjustmentSuggestion = async ({ userTeamId, columnDayId, date }) => {
    const { data } = await shiftApi.removeAdjustmentSuggestion([{ userTeamId, columnDayId, date }]);
    this.updateShift({ userTeamId, columnDayId, date }, data[0]);
  };

  acceptAdjustment = async ({ userTeamId, columnDayId, date }) => {
    const { data } = await shiftApi.acceptAdjustment({ userTeamId, columnDayId, date });
    this.updateShift({ userTeamId, columnDayId, date }, data[0]);
  };

  rejectAdjustment = async ({ userTeamId, columnDayId, date }) => {
    const { data } = await shiftApi.rejectAdjustment({ userTeamId, columnDayId, date });
    this.updateShift({ userTeamId, columnDayId, date }, data[0]);
  };

  removeAdjustment = async ({ userTeamId, columnDayId, date }) => {
    const { data } = await shiftApi.removeAdjustments([{ userTeamId, columnDayId, date }]);
    this.updateShift({ userTeamId, columnDayId, date }, data[0]);
  };

  saveAdjustment = async ({ userTeamId, columnDayId, date }, adjustmentMinutes) => {
    const { data } = await shiftApi.saveAdjustments([
      { userTeamId, columnDayId, date, adjustmentMinutes },
    ]);
    this.updateShift({ userTeamId, columnDayId, date }, data[0]);
  };

  editDescription = async ({ userTeamId, columnDayId, date }, description) => {
    const { data } = await shiftApi.editDescription({ userTeamId, columnDayId, date }, description);
    this.updateShift({ userTeamId, columnDayId, date }, data[0]);
  };
}
