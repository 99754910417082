export const SHIFTS = [
  {
    id: 1,
    title: 'Morning',
    from: 8,
    to: 12,
  },
  {
    id: 2,
    title: 'Dinner',
    from: 12,
    to: 18,
  },
  {
    id: 3,
    title: 'Evening',
    from: 18,
    to: 24,
  },
];

export const getShift = id => SHIFTS.find(shift => shift.id === id);

// TODO: Add new state like 'null' and hide items based on that state
//       and then on saving just filter out those
//       this would be done instead of looking for
//       'originalItem' in EditableShiftaval
export const STATE_NAMES = ['Yes', 'No'];

export const getNextStateBase = (states, state) =>
  states[(states.indexOf(state) + 1) % states.length];

export const getNextState = state => getNextStateBase(STATE_NAMES, state);

export const getUserTextForDisplay = user => user.displayname || user.email;
