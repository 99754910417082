import { Component, createContext } from 'react';
import find from 'lodash/find';
import uniqWith from 'lodash/uniqWith';
import PropTypes from 'prop-types';

import api from './api';
import { getItemCompositeId } from '../utils';

const { Consumer, Provider } = createContext({
  list: {},
});

export { Consumer };

export class ChangeRequestProviderInner extends Component {
  static propTypes = {
    api: PropTypes.object,
    children: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      changeRequests: [],
      acceptChangeRequests: this.acceptChangeRequests,
      addChangeRequests: this.addChangeRequests,
      clearChangeRequests: this.clearChangeRequests,
      createChangeRequests: this.createChangeRequests,
      declineChangeRequests: this.declineChangeRequests,
      fetchChangeRequests: this.fetchChangeRequests,
    };
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }

  addChangeRequests = changeRequests => {
    const newChangeRequests = uniqWith(
      [...this.state.changeRequests, ...changeRequests],
      (a, b) => getItemCompositeId(a) === getItemCompositeId(b),
    );

    this.setState({ changeRequests: newChangeRequests });
  };

  removeChangeRequests = changeRequests => {
    const newChangeRequests = this.state.changeRequests.filter(
      changeRequest =>
        !find(
          changeRequests,
          changeRequestToDelete =>
            getItemCompositeId(changeRequestToDelete) === getItemCompositeId(changeRequest),
        ),
    );

    this.setState({ changeRequests: newChangeRequests });
  };

  fetchChangeRequests = async (dateFrom, dateTo, teamId) => {
    const { data } = await this.props.api.getTeamChangeRequests(dateFrom, dateTo, teamId);
    this.addChangeRequests(data);
  };

  acceptChangeRequests = async changeRequests => {
    await this.props.api.acceptChangeRequests(changeRequests);
    this.removeChangeRequests(changeRequests);
  };

  declineChangeRequests = async changeRequests => {
    await this.props.api.declineChangeRequests(changeRequests);
    this.removeChangeRequests(changeRequests);
  };

  clearChangeRequests = () => {
    this.setState({ changeRequests: [] });
  };

  createChangeRequests = async changeRequests => {
    await this.props.api.createChangeRequests(changeRequests);
    this.addChangeRequests(changeRequests);
  };
}

export default function ChangeRequestContext(props) {
  return <ChangeRequestProviderInner api={api} {...props} />;
}
