import { Trans } from '@lingui/react';
import { Page, PageHeader, PageSection } from 'components/ui/Page';
import { Box, Paragraph } from 'grommet';
import PropTypes from 'prop-types';
import ChangeLanguageForm from './ChangeLanguage';

export default function AccountOverview({ user }) {
  return (
    <Page
      header={
        <PageHeader
          title={
            <>
              <Trans id="account.overview.hello" /> {user.displayname}!
            </>
          }
        />
      }
    >
      <PageSection full>
        <Paragraph>
          <Trans id="account.overview.about" />
        </Paragraph>
      </PageSection>

      <PageSection title={<Trans id="change-language-form.header" />} full>
        <Box direction="row">
          <ChangeLanguageForm />
        </Box>
      </PageSection>
    </Page>
  );
}

AccountOverview.propTypes = {
  user: PropTypes.object,
};
