import styled from 'styled-components';
import PropTypes from 'prop-types';

const DescriptionWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const DescriptionTextWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  padding: 0px 6px;
  display: flex;
  align-items: center;
`;

const DescriptionText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function Description({ children }) {
  return (
    <DescriptionWrapper>
      <DescriptionTextWrapper>
        <DescriptionText>{children}</DescriptionText>
      </DescriptionTextWrapper>
    </DescriptionWrapper>
  );
}
Description.propTypes = {
  children: PropTypes.any,
};
