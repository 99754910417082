import { I18n } from '@lingui/react';
import { Toast as RotaToast } from 'components/RotaToast';
import { I18nFuncContextProvider } from 'contexts/i18nWrapper';

export default function Toast({ children, ...props }) {
  return (
    <I18n>
      {({ i18n }) => (
        <RotaToast {...props} i18n={i18n}>
          <I18nFuncContextProvider value={i18n}>{children}</I18nFuncContextProvider>
        </RotaToast>
      )}
    </I18n>
  );
}
