/* eslint-disable react/prop-types */
import {
  default as availabilitiesApi,
  default as availabilityApi,
} from 'contexts/Availability/api';
import columnApi from 'contexts/Column/api';
import update from 'immutability-helper';
import { cloneDeep, flatten, get, isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import errorMessageHandler from 'utils/errorMessageHandler';
import { autoSaveTimeout } from 'config/supportAndService';

const DEBOUNCED_BATCH_TIMEOUT = autoSaveTimeout;

function mergeAvailabilitiesWithColumnDays(availabilities, columnDays) {
  const availabilityLookup = flatten(Object.values(availabilities)).reduce(
    (acc, availability) => ({
      ...acc,
      ...{ [`${availability.date}-${availability.columnDayId}`]: availability },
    }),
    {},
  );

  function getKeyFromColumnDay(columnDay) {
    return `${columnDay.date}-${columnDay.id}`;
  }

  return flatten(Object.values(columnDays)).map(columnDay => ({
    ...columnDay,
    available: get(availabilityLookup, `${getKeyFromColumnDay(columnDay)}.available`, null),
  }));
}

function getItemKey(item) {
  return `${item.date}-${item.id}`;
}

function getModifyType(originalAvailable, newAvailable) {
  if (isNil(newAvailable)) {
    return 'REMOVE';
  } else if (isNil(originalAvailable)) {
    return 'ADD';
  }
  return 'UPDATE';
}

function getAvailabilityChangeRequests(availabilities, batchUpdates, userTeamId) {
  function getAvailability(id, date) {
    return availabilities.find(a => a.id === id && a.date === date);
  }

  return Object.values(batchUpdates)
    .map(batchUpdate => {
      const original = getAvailability(batchUpdate.id, batchUpdate.date);

      if (original.available === batchUpdate.available) {
        return null;
      }

      const modifyType = getModifyType(original.available, batchUpdate.available);
      return {
        item: {
          ...batchUpdate,
          columnDayId: batchUpdate.id,
          userTeamId,
        },
        modifyType,
      };
    })
    .filter(x => x !== null);
}

export function useGetAvailabilities({ teamId, dateFrom, dateTo }) {
  const [availabilities, setAvailabilities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(
    () => {
      async function fetchData() {
        setIsLoading(true);
        try {
          const [{ data: availabilitiesData }, { data: columnDaysData }] = await Promise.all([
            availabilitiesApi.getCurrentUserAvailabilities(dateFrom, dateTo, teamId),
            columnApi.getTeamColumns(dateFrom, dateTo, teamId),
          ]);

          setAvailabilities(mergeAvailabilitiesWithColumnDays(availabilitiesData, columnDaysData));

          setIsLoading(false);
        } catch (exception) {
          setError(errorMessageHandler(exception));
          setIsLoading(false);
        }
      }
      fetchData();
    },
    [teamId, dateFrom, dateTo],
  );

  return [{ availabilities, loading: isLoading, error }];
}

const AVAILABILITY_STATES = [true, false, null];
function getNextAvailabilityState(availability) {
  const { available } = availability;

  const nextStateIndex = AVAILABILITY_STATES.findIndex(state => state === available) + 1;
  return AVAILABILITY_STATES[nextStateIndex % AVAILABILITY_STATES.length];
}

export function useAvailability({ dateFrom, dateTo, teamId, userTeamId, onError }) {
  const [availabilities, setAvailabilities] = useState([]);
  const [batchUpdates, setBatchUpdates] = useState({});
  const [{ availabilities: fetchedAvailabilities, loading, error }] = useGetAvailabilities({
    dateFrom,
    dateTo,
    teamId,
  });
  const availabilitiesSnapshot = useRef(null);

  useEffect(
    () => {
      setAvailabilities(fetchedAvailabilities);
      availabilitiesSnapshot.current = cloneDeep(fetchedAvailabilities);
    },
    [fetchedAvailabilities],
  );

  const [updateAvailabilitiesDebounced] = useDebouncedCallback(
    async () => {
      if (Object.keys(batchUpdates).length === 0) {
        return;
      }

      // Update on availabilities is done optimistically. Will revert the changes in case
      // of a failed request. Clearing the original batchUpdates - to not interfere with
      // any subsequent clicks and to know what to delete later.
      // Not mutating batchUpdates here directly so it's remembered for the whole lifecycle
      // of this function.
      setBatchUpdates(_batchUpdates =>
        update(_batchUpdates, {
          $unset: Object.keys(batchUpdates),
        }),
      );
      setAvailabilities(_availabilities =>
        _availabilities.map(item => {
          const key = getItemKey(item);
          const batchUpdateItem = batchUpdates[key];

          if (batchUpdateItem) {
            return Object.assign({}, batchUpdateItem, { pending: true });
          }
          return item;
        }),
      );

      // Calling the API
      try {
        // Transforming availabilities to backend format.
        const availabilityChangeRequests = getAvailabilityChangeRequests(
          availabilities,
          batchUpdates,
          userTeamId,
        );

        await availabilityApi.modifyAvailabilities(availabilityChangeRequests);

        // In case of succesfull request, remove pending state from
        // the modified availabilities.
        setAvailabilities(_availabilities =>
          _availabilities.map(item => {
            const key = getItemKey(item);
            const batchUpdateItem = batchUpdates[key];

            if (batchUpdateItem) {
              return Object.assign({}, batchUpdateItem, { pending: false });
            }
            return item;
          }),
        );
      } catch (exception) {
        // Error occured - updated availabilities optimisticly before,
        // so need to revert the changes here.
        const errorMessage = errorMessageHandler(exception);
        onError(errorMessage);

        setAvailabilities(_availabilities =>
          _availabilities.map(item => {
            const originalItem =
              availabilities.find(oa => oa.id === item.id && oa.date === item.date) || item;

            return Object.assign({}, originalItem, { pending: false });
          }),
        );
      }
    },
    DEBOUNCED_BATCH_TIMEOUT,
    { maxWait: 2500 },
  );

  const handleMultipleChange = useCallback(
    items => {
      const { toReset, toUpdate } = items.reduce(
        (acc, item) => {
          const nextAvailable = getNextAvailabilityState(item);
          const originalAvailable = availabilities.find(
            oa => oa.id === item.id && oa.date === item.date,
          ).available;
          const key = getItemKey(item);

          if (nextAvailable === originalAvailable) {
            return update(acc, { toReset: { $push: [key] } });
          }

          return update(acc, {
            toUpdate: {
              [key]: { $set: { ...item, available: nextAvailable } },
            },
          });
        },
        { toReset: [], toUpdate: {} },
      );

      setBatchUpdates(
        update(batchUpdates, {
          $unset: toReset,
          $merge: toUpdate,
        }),
      );

      updateAvailabilitiesDebounced();
    },
    [availabilities, batchUpdates, setBatchUpdates],
  );

  const currentAvailabilities = useMemo(
    () =>
      availabilities.map(item => {
        const key = getItemKey(item);

        return Object.assign({}, batchUpdates[key] || item, { pending: item.pending });
      }),
    [availabilities, batchUpdates],
  );

  const handleClick = useCallback(item => handleMultipleChange([item]), [handleMultipleChange]);

  const handleSetAllToYes = useCallback(
    items => {
      // null because the actual state (next one) will be decided based on this one.
      // in this case, the next one is true whcih is "yes" we want to achieve.
      handleMultipleChange(items.map(item => ({ ...item, available: null })));
    },
    [handleMultipleChange],
  );

  const handleSetAllToNo = useCallback(
    items => {
      // true because the actual state (next one) will be decided based on this one.
      // in this case, the next one is true whcih is "no" we want to achieve.
      handleMultipleChange(items.map(item => ({ ...item, available: true })));
    },
    [handleMultipleChange],
  );

  return [
    {
      availabilities: currentAvailabilities,
      error,
      loading,
      onClick: handleClick,
      onSetAllToYes: handleSetAllToYes,
      onSetAllToNo: handleSetAllToNo,
    },
  ];
}
