import ResponsiveMenu from 'components/ResponsiveMenu';
import FEATURE_FLAGS from 'config/featureFlags';
import { UserConsumer } from 'contexts';
import { Anchor, Box } from 'grommet';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

export class Account extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  render() {
    return (
      <Box direction="row-responsive" className="rota-Box-fix">
        <div>
          <ResponsiveMenu
            responsive={true}
            primary={true}
            style={{ marginRight: '5em', paddingRight: '1em' }}
          >
            <Anchor path="/admin/">Overview</Anchor>
            <Anchor path="/admin/notifications">Notifications</Anchor>
          </ResponsiveMenu>
        </div>
        <div>
          <Switch>
            <Route exact path="/admin/notifications" render={() => <div>Notifications</div>} />
            {FEATURE_FLAGS.ADMIN_PANEL && (
              <Route exact path="/admin" render={() => <div>Admin panel</div>} />
            )}
          </Switch>
        </div>
      </Box>
    );
  }
}

export default function ContextWrapper(props) {
  return <UserConsumer>{({ user }) => <Account user={user} {...props} />}</UserConsumer>;
}
