import PropTypes from 'prop-types';
import FullscreenLoader from 'components/FullscreenLoader';

export default function TeamChangeResolver({ children, isChangingTeam }) {
  return isChangingTeam ? <FullscreenLoader text="Switching team" /> : children;
}

TeamChangeResolver.propTypes = {
  children: PropTypes.any,
  isChangingTeam: PropTypes.bool,
};
