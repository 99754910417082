import { Box } from 'grommet';
import styled from 'styled-components';
import { Clear } from 'grommet-icons';

export const warningSubtitleBaseStyles = `
  color: gray;
  font-style: italic;
  font-weight: 300;
  font-size: ${props => props.fontSize || '14px'};
  white-space: break-spaces;
`;

export const WarningSubtitle = styled(Box)`
  ${warningSubtitleBaseStyles};
`;

// eslint-disable-next-line react/prop-types
export function WarningSubtitleWithIcon({ fontSize, children }) {
  return (
    <WarningSubtitle pad="24px" justify="center" align="center" gap="12px" fontSize={fontSize}>
      <Clear size="36px" />
      <div>{children}</div>
    </WarningSubtitle>
  );
}
