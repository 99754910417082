import { Trans } from '@lingui/react';
import { Notification } from 'components/Notification';
import { TimeFormField } from 'components/TimeFormField';
import { Box, Button, CheckBox as GrommetCheckBox, FormField, Heading } from 'grommet';
import isFunction from 'lodash/isFunction';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import styled from 'styled-components';

export function stripText(text) {
  return text
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase();
}

export function durationToMinutes(date) {
  const duration = moment.duration(date);
  return duration.asMinutes();
}

export function minutesToText(duration) {
  const minutes = moment('00:00', 'HH:mm');
  minutes.add(duration, 'm');
  return minutes.format('HH:mm');
}

const CheckBox = styled(GrommetCheckBox)`
  .grommetux-check-box__label {
    word-break: normal;
  }
`;

export default class AdjustmentRow extends Component {
  static propTypes = {
    initialDuration: PropTypes.number,
    buttons: PropTypes.array,
    header: PropTypes.string,
    readOnly: PropTypes.bool,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const { duration, negative } = this._getDurationData(props);

    this.state = {
      duration: minutesToText(duration),
      error: null,
      negative,
    };
  }

  static defaultProps = {
    readOnly: false,
    initialDuration: 30,
    buttons: [],
  };

  componentDidUpdate = prevProps => {
    if (prevProps.initialDuration !== this.props.initialDuration) {
      const { duration, negative } = this._getDurationData(this.props);

      this.setState({
        duration: minutesToText(duration),
        negative,
      });
    }
  };

  render() {
    const { header, buttons, readOnly } = this.props;
    const { duration, negative } = this.state;

    return (
      <div style={{ marginBottom: '16px' }} data-test-id="adjustment-row">
        <Heading level={4} strong data-test-id="adjustment-row-header">
          {header}
        </Heading>
        <Box direction="row" align="end" justify="between">
          <Box direction="row" align="end" justify="between">
            <FormField label={<Trans id="adjustments-box.form.duration" />}>
              <TimeFormField
                className="column-creator-from-input"
                value={duration}
                name="end"
                onChange={this.handleChangeDuration}
                format="HH:mm"
                disabled={readOnly}
              />
            </FormField>
            <Box pad="small" style={{ marginRight: '18px' }}>
              <CheckBox
                label={<Trans id="adjustments-box.form.negative" />}
                onChange={this.handleChangeNegative}
                checked={negative}
                toggle
                disabled={readOnly}
              />
            </Box>
          </Box>
          <Box direction="row" align="center" fill="vertical" pad="small">
            {buttons.map(button => (
              <Button
                primary
                pad="small"
                style={{ padding: '8px' }}
                key={stripText(button.text)}
                data-test-id="adjustment-row-button"
                onClick={() => this.handleButtonClick(button.onClick)}
              >
                {button.text}
              </Button>
            ))}
          </Box>
        </Box>

        {this.state.error && (
          <div style={{ marginTop: '12px' }}>
            <Notification status="critical">{this.state.error}</Notification>
          </div>
        )}
      </div>
    );
  }

  handleButtonClick = async handler => {
    if (isFunction(handler)) {
      try {
        const duration = durationToMinutes(this.state.duration) * (this.state.negative ? -1 : 1);

        await handler(duration);
        this.setState({ success: 'Success!' });
      } catch (error) {
        const errorMessage = error.message || error.error;
        this.setState({ error: errorMessage });
      }
    }
  };

  handleChangeDuration = event => {
    const { value } = event.target;
    this.setState({ duration: value });
  };

  handleChangeNegative = () => {
    this.setState(state => ({ negative: !state.negative }));
  };

  _getDurationData = props => {
    const duration = props.initialDuration || 30;
    const negative = duration < 0;

    return {
      duration: Math.abs(duration),
      negative,
    };
  };
}
