import { Component } from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import Table from 'reactstrap/lib/Table';

import { Button } from 'grommet';
import { Add } from 'grommet-icons';
import SpreadHeader from 'components/SpreadHeader';
import AddDeadlineModal from 'components/DeadlinesTable/AddDeadlineModal';
import DeadlineRow from 'components/DeadlinesTable/DeadlineRow';
import { Trans } from '@lingui/react';

export default class DeadlinesTable extends Component {
  static propTypes = {
    adminMode: PropTypes.bool,
    deadlines: PropTypes.array,
    onAddDeadline: PropTypes.func,
    onRemoveDeadline: PropTypes.func,
  };

  state = {
    showAddModal: false,
  };

  render() {
    return (
      <Container>
        <AddDeadlineModal
          isOpen={this.state.showAddModal}
          toggle={this.toggleAddModal}
          onAddDeadline={this.props.onAddDeadline}
        />
        <SpreadHeader>
          <h4>
            <Trans id="deadlines-table.header" />
          </h4>
          {this.props.adminMode && <Button icon={<Add />} onClick={this.toggleAddModal} primary />}
        </SpreadHeader>
        <Table size="sm" responsive style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>
                <Trans id="deadlines-table.table.date" />
              </th>
              <th>
                <Trans id="deadlines-table.table.from" />
              </th>
              <th>
                <Trans id="deadlines-table.table.to" />
              </th>
              <th>
                <Trans id="deadlines-table.table.state" />
              </th>
              {this.props.adminMode && (
                <th>
                  <Trans id="deadlines-table.table.actions" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.props.deadlines.map(deadline => (
              <DeadlineRow
                key={`deadline-${deadline.id}`}
                adminMode={this.props.adminMode}
                deadline={deadline}
                onRemoveDeadline={this.props.onRemoveDeadline}
              />
            ))}
          </tbody>
        </Table>
      </Container>
    );
  }

  toggleAddModal = () => {
    this.setState({
      showAddModal: !this.state.showAddModal,
    });
  };
}
