import { cleanSession, setSelectedTeam } from 'contexts/SessionResolver';

import analytics from 'analytics';
import authApi from 'api/auth';
import availabilityApi from 'contexts/Availability/api';
import columnApi from 'contexts/Column/api';
import deadlineApi from 'api/deadline';
import { getDateIntervalSteps } from 'utils/Dates';
import teamApi from 'api/team';
import userApi from 'contexts/User/api';

// TODO: idea - split this for two actions? one that is finally setting the state, instead
//       of returning a callback
export const loginAction = ({ setSession }) => async (email, password) => {
  const { data } = await authApi.login(email, password);
  const { access_token, user_id: userId } = data;

  setSession(access_token, userId, email);
  analytics.track({ category: 'Login', action: 'Setup session cookies' });

  return access_token;
};

export const logoutAction = () => {
  cleanSession();
  window.location.replace('/');
};

export const clearDataAction = ({
  clearAvailabilities,
  clearChangeRequests,
  clearColumns,
  clearDeadlines,
  clearShifts,
}) => () => {
  clearAvailabilities();
  clearChangeRequests();
  clearColumns();
  clearDeadlines();
  clearShifts();
};
export const changeTeamAction = ({
  setCurrentTeam,
  setTeamUsers,
  availableTeams,
  clearDataAction,
  setChangingTeam,
}) => async teamId => {
  if (!teamId) {
    teamId = availableTeams[0].id;
  }

  const team = availableTeams.find(({ id }) => id === teamId);

  if (team === undefined) {
    throw Error('This team is not available!');
  }

  await setChangingTeam(true);
  clearDataAction();

  if (!team.users) {
    const { data: teamUsers } = await teamApi.getTeamUsers(teamId);
    setTeamUsers(teamId, teamUsers);
  }

  setCurrentTeam(teamId);
  setSelectedTeam(teamId);

  await setChangingTeam(false);

  return true;
};

export const fetchInitialData = async userId => {
  const { data: user } = await userApi.getUser(userId);

  const availableTeams = {};

  user.teams.forEach(team => {
    const { admin, userTeamId, teamId, teamName } = team;

    availableTeams[team.teamId] = {
      id: teamId,
      userTeamId,
      admin,
      name: teamName,
      displayname: teamName,
    };
  });

  return { user, availableTeams };
};

export const fetchHomeScreenData = ({ addDeadlines, teamId }) => async () => {
  const { data } = await deadlineApi.getUpcomingDeadlines(teamId);
  addDeadlines(data);
};

export const fetchAvailabilitiesAction = ({ addAvailabilities, teamId }) => async (
  dateFrom,
  dateTo,
) => {
  const { data } = await availabilityApi.getTeamAvailabilities(dateFrom, dateTo, teamId);
  addAvailabilities(data);
};

// TODO: check if this is necessary
export const fetchColumnsAction = ({ addColumns, teamId }) => async (dateFrom, dateTo) => {
  const { data } = await columnApi.getTeamColumns(dateFrom, dateTo, teamId);

  getDateIntervalSteps(dateFrom, dateTo).forEach(step => {
    if (data[step] === undefined) {
      data[step] = [];
    }
  });

  addColumns(data);
};

export const fetchDeadlinesAction = ({ addDeadlines, teamId }) => async (dateFrom, dateTo) => {
  const { data } = await deadlineApi.getTeamDeadlines(dateFrom, dateTo, teamId);
  addDeadlines(data);
};

export const fetchRotaTableDataActionBase = ({
  fetchAvailabilitiesAction,
  fetchChangeRequestsAction,
  fetchColumnsAction,
  fetchDeadlinesAction,
  fetchShiftsAction,
  teamId,
}) => (dateFrom, dateTo) => [
  fetchAvailabilitiesAction(dateFrom, dateTo, teamId),
  fetchChangeRequestsAction(dateFrom, dateTo, teamId),
  fetchColumnsAction(dateFrom, dateTo, teamId),
  fetchDeadlinesAction(dateFrom, dateTo, teamId),
  fetchShiftsAction(dateFrom, dateTo, teamId),
];

export const fetchRotaTableDataAction = ({
  fetchAvailabilitiesAction,
  fetchChangeRequestsAction,
  fetchColumnsAction,
  fetchDeadlinesAction,
  fetchShiftsAction,
  teamId,
}) => (dateFrom, dateTo) =>
  Promise.all(
    fetchRotaTableDataActionBase({
      fetchAvailabilitiesAction,
      fetchChangeRequestsAction,
      fetchColumnsAction,
      fetchDeadlinesAction,
      fetchShiftsAction,
      teamId,
    })(dateFrom, dateTo),
  );

export const fetchRotaTableShiftsDataAction = ({ fetchWorkingHoursAction, teamId, ...props }) => (
  dateFrom,
  dateTo,
) =>
  Promise.all([
    ...fetchRotaTableDataActionBase({ ...props, teamId })(dateFrom, dateTo),
    fetchWorkingHoursAction(dateFrom, teamId),
  ]);

export const fetchColumnCalendarDataAction = ({ fetchColumnsAction, teamId }) => (
  dateFrom,
  dateTo,
) => Promise.all([fetchColumnsAction(dateFrom, dateTo, teamId)]);

export const setupTeamsData = ({ setAvailableTeams, setCurrentTeam, setUser }) => async (
  userId,
  teamId,
) => {
  const response = await fetchInitialData(userId);
  const { user, availableTeams: availableTeamsData } = response;
  const availableTeams = Object.values(availableTeamsData);

  setUser(user);

  if (availableTeams.length === 0) {
    return false;
  }

  const isSelectedTeamAvailable = availableTeams.some(team => team.id === Number(teamId));
  const teamIdToFetch = isSelectedTeamAvailable ? teamId : availableTeams[0].id;

  if (!isSelectedTeamAvailable) {
    // eslint-disable-next-line
    console.warn('Tried to select unavailabe team. Selected first available instead.');
  }

  if (!teamIdToFetch) {
    throw new Error("Cannot read team's id to select desipte teams being available");
  }

  // fetching users for team
  const { data: teamUsers } = await teamApi.getTeamUsers(teamIdToFetch);

  availableTeamsData[teamIdToFetch].users = teamUsers;

  setCurrentTeam(teamIdToFetch);
  setSelectedTeam(teamIdToFetch);
  setAvailableTeams(availableTeamsData);

  return true;
};

export const addDeadlineAction = ({ addDeadline, teamId }) => async deadline => {
  const { data } = await deadlineApi.addDeadline(deadline, teamId);
  const { id } = data;

  addDeadline({ id, ...deadline });
};

export const removeDeadlineAction = ({ removeDeadline }) => async deadlineId => {
  await deadlineApi.removeDeadline(deadlineId);

  removeDeadline(deadlineId);
};
