import ENV from 'utils/env';

import * as Sentry from '@sentry/browser';

export const init = () => {
  if (ENV.DISABLE_SENTRY) {
    return;
  }

  Sentry.init({
    dsn:
      ENV.NODE_ENV === 'prod'
        ? 'https://edc615aa550249faa4621c06feff2f43@sentry.io/1300795'
        : 'https://7d5e99bea39b463682b0b41dbd1e32d1@sentry.io/1300801',
    release: ENV.ROTA_RELEASE_TAG ? ENV.ROTA_RELEASE_TAG : 'dev',
  });
};

export const setSentryUser = email => {
  Sentry.configureScope(scope => {
    scope.setUser({ email });
  });
};

export const logMessage = message => {
  Sentry.captureMessage(message);
};
