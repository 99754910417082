import { Status } from 'components/Status';
import Toast from 'components/Toast';
import { criticalColor } from 'config/colors';
import { FieldArray, Formik } from 'formik';
import { Box, Button, Footer, Form, FormField, Menu, Select, TextInput } from 'grommet';
import { Add, Trash } from 'grommet-icons';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import errorMessageHandler from 'utils/errorMessageHandler';
import { useIsMobile } from 'utils/responsive';

export const RequiredFieldsWarning = styled.h4`
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 8px;

  color: ${criticalColor};
`;

// TODO: extract this to some shared dir
const cx = (conditionalClasses, classNames) =>
  `${classNames} ` +
  Object.entries(conditionalClasses)
    .filter(entry => entry[1])
    .map(entry => entry[0])
    .join(' ');

export function AddUsersInner({ onSave, mobile }) {
  return (
    <Formik
      initialValues={{ users: [{ email: '', role: 'Employee' }] }}
      validate={values => {
        const errors = Object.assign(
          {},
          { users: new Array(values.users.length) },
          { anything: false },
        );

        const validate = condition => {
          if (condition) {
            errors.anything = true;
          }
          return condition;
        };

        values.users.forEach((user, index) => {
          errors.users[index] = {};

          if (validate(!user.email || user.email === '')) {
            errors.users[index]['email'] = 'Required!';
          }

          if (validate(!user.role)) {
            errors.users[index]['role'] = 'Required!';
          }
        });

        return errors.anything ? errors : {};
      }}
      onSubmit={async (values, { setStatus, setSubmitting, setValues }) => {
        const { users } = values;

        try {
          setStatus({});
          await onSave(users);
          setStatus({});
          setSubmitting(false);

          setStatus({ type: 'success' });
          setValues({ users: [{ email: '', role: 'Employee' }] });
        } catch (error) {
          setStatus({ type: 'error', message: errorMessageHandler(error) });
          setSubmitting(false);
        }
      }}
      render={({ values, submitForm, handleBlur, handleChange, status, errors, setFieldValue }) => (
        <div>
          {get(status, 'type') === 'success' && (
            <Toast status="success">Users have been added succesfully!</Toast>
          )}
          {get(status, 'type') === 'error' && (
            <Toast status="critical">Error: {status.message}</Toast>
          )}
          <Form className={cx({ 'rota-horizontal-form': !mobile }, 'rota-form-hide-error-message')}>
            <Box direction="row" align="center" justify="end" pad={{ between: 'medium' }}>
              <Button
                size="small"
                onClick={errors.users ? null : submitForm}
                icon={<Add />}
                label="Save"
              />
            </Box>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 10 }}>
                <h4>Email</h4>
              </div>
              <div style={{ flex: 4 }}>
                <h4>Role</h4>
              </div>
              <div style={{ flex: 1 }} />
            </div>
            <FieldArray
              name="users"
              render={arrayHelpers => (
                <div>
                  {values.users.map((user, index) => (
                    <div
                      style={{ display: 'flex', flexDirection: mobile ? 'column' : 'row' }}
                      key={index}
                    >
                      <div style={{ flex: 10 }}>
                        <FormField
                          error={
                            get(errors, `users[${index}].email`) ? errors.users[index].email : null
                          }
                        >
                          <TextInput
                            value={values.users[index].email}
                            name={`users.${index}.email`}
                            onChange={handleChange}
                            placeHolder="email"
                            onBlur={handleBlur}
                          />
                        </FormField>
                      </div>
                      <div style={{ flex: 4 }}>
                        <FormField
                          error={
                            get(errors, `users[${index}].role`) ? errors.users[index].role : null
                          }
                        >
                          <Select
                            options={['Manager', 'Employee']}
                            value={values.users[index].role}
                            labelKey="label"
                            valueKey="value"
                            onBlur={handleBlur}
                            onChange={({ value }) =>
                              setFieldValue(`users.${index}.role`, value, true)
                            }
                          />
                        </FormField>
                      </div>
                      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <Button
                          onClick={() => arrayHelpers.remove(index)}
                          size="small"
                          icon={<Trash />}
                        />
                      </div>
                      {mobile && <hr />}
                    </div>
                  ))}

                  <Footer justify="start">
                    <Box
                      direction="row"
                      fill="horizontal"
                      alignContent="between"
                      justify="between"
                      pad={{ between: 'medium' }}
                    >
                      <Menu
                        direction="row"
                        responsive={false}
                        size="small"
                        dropAlign={{ right: 'right' }}
                      >
                        <Button
                          onClick={() =>
                            arrayHelpers.push({
                              email: '',
                              role: 'Employee',
                            })
                          }
                          size="small"
                          plain
                          label="Add user"
                          icon={<Add />}
                        />
                      </Menu>

                      {errors.users && (
                        <Box direction="row" align="center">
                          <Status value="critical" />
                          <RequiredFieldsWarning>Fill the required fields!</RequiredFieldsWarning>
                        </Box>
                      )}
                    </Box>
                  </Footer>
                </div>
              )}
            />
          </Form>
        </div>
      )}
    />
  );
}

AddUsersInner.propTypes = {
  mobile: PropTypes.bool,
  onSave: PropTypes.func,
};

export default function AddUserModal(props) {
  const isMobile = useIsMobile();

  return <AddUsersInner mobile={isMobile} {...props} />;
}
