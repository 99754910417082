import { i18nMark } from '@lingui/react';
import analytics from 'analytics';
import Timer from 'analytics/timer';
import { getAvailabilitiesBackgroundColor, getShiftColor } from 'components/itemCellColors';
import PendingAdjustments from 'components/PendingAdjustments';
import PendingExchanges from 'components/PendingExchanges';
import { EditableRotaTable } from 'components/RotaTable';
import ShiftCanvas from 'components/RotaTable/Cells/ShiftCanvas';
import ShiftCell from 'components/RotaTable/Cells/ShiftCell';
import getDateSpanFromLocation from 'components/utils/getDateSpanFromLocation';
import updateDateSpanQueryParams from 'components/utils/updateDateSpanQueryParams';
import { defaultDateSpan, MAXIMUM_DAY_SPAN } from 'config/dates';
import FEATURE_FLAGS from 'config/featureFlags';
import {
  AvailabilityConsumer,
  ColumnConsumer,
  DeadlineConsumer,
  ShiftConsumer,
  UserConsumer,
} from 'contexts';
import { fetchColumnsAction, fetchRotaTableShiftsDataAction } from 'contexts/actions';
import { getShiftsWithPendingAdjustments, getTakeableShifts } from 'contexts/selectors';
import { ShiftWorkingHoursContext } from 'contexts/ShiftWorkingHours/ShiftWorkingHours';
import { getItemCompositeId, toggleItem } from 'contexts/utils';
import every from 'lodash/every';
import get from 'lodash/get';
import noop from 'lodash/noop';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component, useContext } from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import translatedMessage from 'translatedMessage';
import { applyDaysOffset, getDateKey } from 'utils/Dates';
import styled from 'styled-components';
import { Box } from 'grommet';
import { getScreenSizeToDaySpan, useScreenSize } from 'utils/responsive';

const TableUsername = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  overflow-x: overlay;
`;

const WorkingHours = styled.span`
  padding: 0px 8px;
  margin-right: 4px;
  color: black;
  background: ${props => props.color};
  border-radius: 15px;
`;

export class ShiftsEdit extends Component {
  static propTypes = {
    adminMode: PropTypes.bool,
    availabilities: PropTypes.object.isRequired,
    columns: PropTypes.any,
    currentUser: PropTypes.object,
    currentUserTeamId: PropTypes.any,
    deadlines: PropTypes.any,
    fetchData: PropTypes.func,
    getColumn: PropTypes.func,
    getUser: PropTypes.func,
    getUserWorkingHours: PropTypes.func,
    onAdjustmentAccept: PropTypes.func,
    onAdjustmentReject: PropTypes.func,
    onAdjustmentRemove: PropTypes.func,
    onAdjustmentSave: PropTypes.func,
    onAdjustmentSuggestion: PropTypes.func,
    onAdjustmentSuggestionRemove: PropTypes.func,
    onDescriptionEdit: PropTypes.func,
    onModifyUserWorkingHours: PropTypes.func,
    onSave: PropTypes.func,
    screenSize: PropTypes.string,
    shifts: PropTypes.object.isRequired,
    users: PropTypes.any,
  };

  constructor(props) {
    super(props);
    const dateSpan = getDateSpanFromLocation(props.location) || defaultDateSpan;
    this.state = {
      ...dateSpan,
      loaded: false,
      status: null,
    };
  }

  async componentDidMount() {
    const dateSpan = getScreenSizeToDaySpan(this.props.screenSize);
    this.onSetDateSpan(dateSpan);

    await this.handleMoveDate(0);

    this.setState({ loaded: true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.screenSize !== this.props.screenSize) {
      const dateSpan = getScreenSizeToDaySpan(this.props.screenSize);
      this.onSetDateSpan(dateSpan);
    }
  }

  render() {
    // TODO:  resolve this hack in some nice way. Reason it's there is because race condition
    //        between setting (setState in ContextProviders) the fetched columns and shifts.
    const hasColumnsData = every(Object.keys(this.props.shifts), step => this.props.columns[step]);

    // TODO: memoize this?
    const exchanges = hasColumnsData
      ? getTakeableShifts(this.props.shifts, this.props.columns, this.props.users)
      : [];

    const showSuggestedAdjustments = hasColumnsData && this.props.adminMode;
    const suggestedAdjustments = showSuggestedAdjustments
      ? getShiftsWithPendingAdjustments(this.props.shifts, this.props.columns, this.props.users)
      : [];

    return (
      <div>
        {this.state.loaded ? (
          <EditableRotaTable
            backgroundItems={this.props.availabilities}
            calculateSums={this.props.adminMode}
            canEdit={this.canEdit}
            columns={this.props.columns}
            currentUser={{ ...this.props.currentUser, userTeamId: this.props.currentUserTeamId }}
            dateFrom={this.state.dateFrom}
            dateTo={this.state.dateTo}
            deadlines={this.props.deadlines}
            getBackgroundItemCellColor={getAvailabilitiesBackgroundColor}
            getForegroundItemCellColor={getShiftColor}
            getNextItemOnClick={toggleItem}
            getOriginalItem={this.getOriginalItem}
            items={this.props.shifts}
            renderItem={props => (
              <ShiftCell
                id={props.id}
                adminMode={this.props.adminMode}
                currentUserId={this.props.currentUserTeamId}
                onAdjustmentAccept={this.props.onAdjustmentAccept}
                onAdjustmentReject={this.props.onAdjustmentReject}
                onAdjustmentRemove={this.props.onAdjustmentRemove}
                onAdjustmentSave={this.props.onAdjustmentSave}
                onAdjustmentSuggestion={this.props.onAdjustmentSuggestion}
                onAdjustmentSuggestionRemove={this.props.onAdjustmentSuggestionRemove}
                onDescriptionEdit={this.props.onDescriptionEdit}
                onGiveAway={this.props.giveAwayShift}
                onTake={this.props.takeShift}
                onUndo={this.props.undoGiveAwayShift}
                {...props}
              />
            )}
            renderCanvas={props => <ShiftCanvas {...props} />}
            renderUser={this.renderUser}
            onCanvasClick={this.handleCanvasClick}
            onItemClick={this.handleItemClick}
            onItemSave={this.handleItemSave}
            onClickConfirm={this.handleItemConfirm}
            onMoveDate={this.handleMoveDate}
            onSave={this.handleOnSave}
            onSetDateSpan={this.onSetDateSpan}
            users={this.props.users}
          />
        ) : (
          <div>Loading ...</div>
        )}
        <div>
          <Row>
            <Col>
              {showSuggestedAdjustments && (
                <PendingAdjustments
                  adjustments={suggestedAdjustments}
                  onAccept={this.props.onAdjustmentAccept}
                  onReject={this.props.onAdjustmentReject}
                />
              )}
            </Col>
            <Col>
              <PendingExchanges
                availabilities={this.props.availabilities}
                currentUserId={this.props.currentUserTeamId}
                exchanges={exchanges}
                onTake={this.props.takeShift}
                shifts={this.props.shifts}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  handleMoveDate = async (daysOffset = 1) => {
    const { dateFrom, dateTo } = this.state;

    const dateFromNext = applyDaysOffset(dateFrom, daysOffset);
    const dateToNext = applyDaysOffset(dateTo, daysOffset);

    const timer = new Timer();
    await this.props.fetchData(dateFromNext, dateToNext);
    timer.end();

    analytics.timing('DataFetch', 'Shifts', timer.duration);

    updateDateSpanQueryParams(this.props.history, {
      dateFrom: dateFromNext,
      dateTo: dateToNext,
    });

    this.setState({
      dateFrom: dateFromNext,
      dateTo: dateToNext,
      loaded: true,
    });
  };

  getOriginalItem = item => {
    const { date } = item;
    const dateKey = getDateKey(date);
    const compositeKey = getItemCompositeId(item);

    return get(this.props.shifts, `${dateKey}.${compositeKey}`, null);
  };

  handleCanvasClick = (item, propItems) => {
    const { userTeamId, columnDayId, date } = item;
    this.modifyUserWorkingHours(userTeamId, date, columnDayId);

    const canvasItem = {
      userTeamId,
      columnDayId,
      date,
      exchangeable: false,
      description: '',
    };

    const newItem = this.getOriginalItem(item, propItems) || canvasItem;

    return newItem;
  };

  // Returning null, because EditableRotaTable will delete the item then.
  // Shifts have only two states: they are there or they dont :D
  handleItemClick = item => {
    const { userTeamId, columnDayId, date } = item;
    this.modifyUserWorkingHours(userTeamId, date, columnDayId, true);

    return null;
  };

  handleItemSave = modifyRequest => this.props.onSave([modifyRequest]);

  handleItemConfirm = shift => {
    i18nMark('shifts.confirm-delete'); // Needed to be picked up by babel plugin
    return shift.description ? window.confirm(translatedMessage('shifts.confirm-delete')) : true;
  };

  onSetDateSpan = daySpan => {
    this.setState({
      loaded: false,
    });

    const dateFrom =
      daySpan === MAXIMUM_DAY_SPAN
        ? moment(this.state.dateFrom).startOf('isoWeek')
        : this.state.dateFrom;
    const dateTo = applyDaysOffset(dateFrom, daySpan - 1);

    this.setState(
      {
        dateFrom,
        dateTo,
      },
      async () => {
        await this.handleMoveDate(0);
      },
    );
  };

  canEdit = () => this.props.adminMode;

  renderUser = user => {
    const { hours: userWorkingHours, color } = this.props.getUserWorkingHours(user.userTeamId);

    const suffix = FEATURE_FLAGS.WORKING_HOURS_SUMMARY ? userWorkingHours.toFixed(1) : '';

    return (
      <TableUsername>
        <Box flex="shrink">
          <span>{user.displayname}</span>
        </Box>
        {FEATURE_FLAGS.WORKING_HOURS_SUMMARY && (
          <Box>
            <WorkingHours color={color}>{suffix}</WorkingHours>
          </Box>
        )}
      </TableUsername>
    );
  };

  modifyUserWorkingHours = (userTeamId, date, columnDayId, negative = false) => {
    if (!FEATURE_FLAGS.WORKING_HOURS_SUMMARY) {
      return;
    }

    const { duration } = this.props.columns[date].find(column => column.id === columnDayId);

    const momentDuration = moment.duration(duration).asHours();
    const multiplier = negative ? -1 : 1;
    const delta = momentDuration * multiplier;

    this.props.onModifyUserWorkingHours(userTeamId, delta);
  };
}

export default function ContextWrapper(props) {
  const { fetchWorkingHours, getUserWorkingHours, modifyUserWorkingHours } = useContext(
    ShiftWorkingHoursContext,
  );

  const screenSize = useScreenSize();

  return (
    <UserConsumer>
      {({ currentTeam, user, isAdminInCurrentTeam, getUser }) => (
        <ColumnConsumer>
          {({ addColumns, columns, getColumn }) => (
            <DeadlineConsumer>
              {({ fetchDeadlines, deadlines }) => (
                <ShiftConsumer>
                  {({
                    acceptAdjustment,
                    editDescription,
                    fetchShifts,
                    giveAwayShift,
                    modifyShifts,
                    rejectAdjustment,
                    removeAdjustment,
                    removeAdjustmentSuggestion,
                    saveAdjustment,
                    shifts,
                    suggestAdjustment,
                    takeShift,
                    undoGiveAwayShift,
                  }) => (
                    <AvailabilityConsumer>
                      {({ availabilities, fetchAvailabilities }) => (
                        <ShiftsEdit
                          screenSize={screenSize}
                          adminMode={isAdminInCurrentTeam()}
                          availabilities={availabilities}
                          columns={columns}
                          currentUser={{ ...user, admin: isAdminInCurrentTeam() }}
                          currentUserTeamId={currentTeam.userTeamId}
                          deadlines={deadlines}
                          getColumn={getColumn}
                          giveAwayShift={giveAwayShift}
                          takeShift={takeShift}
                          getUser={getUser}
                          shifts={shifts}
                          onSave={modifyShifts}
                          undoGiveAwayShift={undoGiveAwayShift}
                          onAdjustmentAccept={acceptAdjustment}
                          onAdjustmentReject={rejectAdjustment}
                          onAdjustmentRemove={removeAdjustment}
                          onAdjustmentSave={saveAdjustment}
                          onAdjustmentSuggestion={suggestAdjustment}
                          onAdjustmentSuggestionRemove={removeAdjustmentSuggestion}
                          onDescriptionEdit={editDescription}
                          getUserWorkingHours={getUserWorkingHours}
                          onModifyUserWorkingHours={modifyUserWorkingHours}
                          fetchData={fetchRotaTableShiftsDataAction({
                            fetchAvailabilitiesAction: fetchAvailabilities,
                            fetchChangeRequestsAction: noop,
                            fetchColumnsAction: fetchColumnsAction({
                              addColumns,
                              teamId: currentTeam.id,
                            }),
                            fetchDeadlinesAction: fetchDeadlines,
                            fetchShiftsAction: fetchShifts,
                            fetchWorkingHoursAction: fetchWorkingHours,
                            teamId: currentTeam.id,
                          })}
                          users={currentTeam.users}
                          {...props}
                        />
                      )}
                    </AvailabilityConsumer>
                  )}
                </ShiftConsumer>
              )}
            </DeadlineConsumer>
          )}
        </ColumnConsumer>
      )}
    </UserConsumer>
  );
}
