import { Component, createContext } from 'react';
import set from 'lodash/set';
import PropTypes from 'prop-types';

import api from './api';
import { getItemCompositeId } from 'contexts/utils';
import { getDateKey, getDateIntervalSteps } from 'utils/Dates';
import { applyModifyChanges } from 'components/RotaTable/ItemModule/itemModule';

const { Consumer, Provider } = createContext({
  list: {},
});

export { Consumer };

export class AvailabilityProviderInner extends Component {
  static propTypes = {
    api: PropTypes.object,
    children: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      availabilities: {},
      addAvailabilities: this.addAvailabilities,
      clearAvailabilities: this.clearAvailabilities,
      fetchAvailabilities: this.fetchAvailabilities,
      modifyAvailabilities: this.modifyAvailabilities,
    };
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }

  addAvailabilities = (availabilities, dateSteps = []) => {
    const baseState = Object.assign({}, this.state.availabilities);

    this.setAvailabilities(availabilities, dateSteps, baseState);
  };

  clearAvailabilities = () => {
    this.setState({
      availabilities: {},
    });
  };

  modifyAvailabilities = async changes => {
    const { data } = await this.props.api.modifyAvailabilities(changes);

    const { availabilities } = applyModifyChanges(
      Object.assign({}, this.state.availabilities),
      changes,
      'availabilities',
    );
    this.setState({ availabilities });

    return data;
  };

  setAvailabilities = (availabilities, dateSteps = [], newAvailabilitiesBase = {}) => {
    // Filling days for availabilities, to prevent flickering in quick updates on
    // empty day
    dateSteps.forEach(step => {
      if (!newAvailabilitiesBase[step]) {
        newAvailabilitiesBase[step] = {};
      }
    });

    availabilities.forEach(availability => {
      const dateKey = getDateKey(availability.date);

      const { columnDayId, userTeamId } = availability;
      // TODO: getCompositeId can just accept columnDay if it already has a date inside
      const id = getItemCompositeId({ columnDayId, userTeamId });

      set(newAvailabilitiesBase, `${dateKey}.${id}`, availability);
    });

    this.setState({ availabilities: newAvailabilitiesBase });
  };

  fetchAvailabilities = async (dateFrom, dateTo, teamId) => {
    const { data } = await this.props.api.getTeamAvailabilities(dateFrom, dateTo, teamId);
    const dateSteps = getDateIntervalSteps(dateFrom, dateTo);

    this.setAvailabilities(data, dateSteps, {});
  };
}

export default function AvailabilityContext(props) {
  return <AvailabilityProviderInner api={api} {...props} />;
}
