import { Trans } from '@lingui/react';
import Spinner from 'components/Spinner';
import { Box, Button, Heading as _Heading, Paragraph } from 'grommet';
import {
  Calendar,
  CircleQuestion,
  Cycle,
  Edit,
  LineChart,
  Next,
  Plan,
  Previous,
  Revert,
  Trophy,
  Update,
} from 'grommet-icons';
import { Link } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import styled from 'styled-components';

const Heading = styled(_Heading)`
  position: relative;
  display: block;
`;

const StepContainer = styled('div')`
  min-height: 100%;
  width: 100%;

  display: flex;
  flex: 1;
  padding: 1em;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: auto;
`;

const Wrapper = styled('div')`
  position: absolute;
  top: 48px;
  bottom: 0;
  right: 0;
  left: 0;

  & > div,
  & > div > div,
  & > div > div > div {
    height: 100%;
  }
`;

const ParagraphSection = styled('div')`
  padding-top: 1em;
`;

//eslint-disable-next-line
function NavButtons({ caption, nextStep, previousStep, firstStep }) {
  return (
    <Box pad="medium" direction="row" gap="12px">
      {previousStep && (
        <Button
          icon={<Previous />}
          label={<Trans id="guide.buttons.back" />}
          onClick={previousStep}
          plain
        />
      )}
      {firstStep && (
        <Button
          icon={<Revert />}
          label={<Trans id="guide.buttons.go-to-beginning" />}
          onClick={firstStep}
        />
      )}
      {nextStep && <Button icon={<Next />} label={caption} onClick={nextStep} />}
    </Box>
  );
}

const LogoContainer = styled.div`
  position: relative;
  width: 360px;
  height: 360px;
  max-width: calc(100vw - 24px);
  max-height: calc(100vw - 24px);

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    max-width: 100vw;
  }
`;

const CircleImage = styled.img`
  animation: rotateIn 3s;

  @keyframes rotateIn {
    0% {
      transform-origin: center;
      transform: rotate3d(0, 0, 1, -360deg);
      opacity: 0.1;
    }

    20% {
      transform-origin: center;
      opacity: 0.2;
    }

    100% {
      transform-origin: center;
      transform: translate3d(0, 0, 1);
      opacity: 1;
    }
  }
`;

const TickImage = styled.img`
  animation: fadeIn 3s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    30% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

//eslint-disable-next-line
function Step1({ nextStep }) {
  return (
    <StepContainer>
      <Heading level={3}>
        <Trans id="guide.step1.heading" />
      </Heading>

      <Box direction="row">
        <Box justify="center" align="center">
          <LogoContainer>
            <CircleImage src="/static/rota-logo-circle.svg" />
            <TickImage src="/static/rota-logo-tick.svg" />
          </LogoContainer>
        </Box>
        <Box justify="center" align="center">
          <Paragraph size="large">
            <ParagraphSection style={{ textAlign: 'center' }}>
              <Trans id="guide.step1.info" />
            </ParagraphSection>
            <Box align="center" pad="medium">
              <NavButtons nextStep={nextStep} caption={<Trans id="guide.step1.button-next" />} />
            </Box>
          </Paragraph>
        </Box>
      </Box>
    </StepContainer>
  );
}

//eslint-disable-next-line
function Step2({ nextStep, previousStep }) {
  return (
    <StepContainer>
      <Heading level={3}>
        <Trans id="guide.step2.shift-management" />
      </Heading>
      <Paragraph size="large">
        <Trans id="guide.step2.info" />
      </Paragraph>

      <Box direction="row">
        <Box justify="center" pad="medium" align="center">
          <Cycle size="xlarge" responsive={false} />
        </Box>
        <Box>
          <Paragraph size="large">
            <ParagraphSection>
              <Trans id="guide.step2.creating-shifts" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step2.waiting" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step2.assigning-shifts" />
            </ParagraphSection>
          </Paragraph>
        </Box>
      </Box>

      <NavButtons
        nextStep={nextStep}
        previousStep={previousStep}
        caption={<Trans id="guide.step2.button-next" />}
      />
    </StepContainer>
  );
}

//eslint-disable-next-line
function Step3({ nextStep, previousStep }) {
  return (
    <StepContainer>
      <Heading level={3}>
        <Trans id="guide.step3.creating-a-shift" />
      </Heading>

      <Box direction="row">
        <Box>
          <Paragraph size="large">
            <ParagraphSection>
              {/* TODO: rename to columns-page-link */}
              <Trans id="guide.step3.shifts-page-link" components={[<Link to="/columns" />]} />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step3.how-to-create-shift" />"<Trans id="calendar-day.add-column" />"
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step3.shift-example">
                It can be for example "Morning shift, from 9AM to 1PM"
              </Trans>
            </ParagraphSection>
          </Paragraph>
        </Box>
        <Box justify="center" pad="medium" align="center">
          <Calendar size="xlarge" responsive={false} />
        </Box>
      </Box>

      <NavButtons
        previousStep={previousStep}
        nextStep={nextStep}
        caption={<Trans id="guide.step3.button-next" />}
      />
    </StepContainer>
  );
}

//eslint-disable-next-line
function Step4({ nextStep, previousStep }) {
  return (
    <StepContainer>
      <Heading level={3}>
        <Trans id="guide.step4.availabilities" />
      </Heading>

      <Box direction="row">
        <Box justify="center" pad="medium" align="center">
          <Spinner size="xlarge" />
        </Box>
        <Box>
          <Paragraph size="large">
            <ParagraphSection>
              <Trans id="guide.step4.waiting">
                It's the time to wait for the employees to fill out their availabilities for the
                created shifts.
              </Trans>
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step4.modifications" components={[<Link to="/availabilities" />]}>
                You can see and as a manager, modify them on the{' '}
                <Link to="/availabilities">Availabilities</Link> page.
              </Trans>
            </ParagraphSection>
          </Paragraph>
        </Box>
      </Box>

      <NavButtons
        previousStep={previousStep}
        nextStep={nextStep}
        caption={<Trans id="guide.step4.button-next" />}
      />
    </StepContainer>
  );
}

//eslint-disable-next-line
function Step5({ nextStep, previousStep }) {
  return (
    <StepContainer>
      <Heading level={3}>
        <Trans id="guide.step5.assigning-shifts" />
      </Heading>

      <Box direction="row">
        <Box>
          <Paragraph size="large">
            <ParagraphSection>
              <Trans id="guide.step5.assigning-shifts-howto" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step5.availability-info" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step5.shifts-page-link" components={[<Link to="/shifts" />]} />
            </ParagraphSection>
          </Paragraph>
        </Box>
        <Box justify="center" pad="medium" align="center">
          <Plan size="xlarge" responsive={false} />
        </Box>
      </Box>

      <NavButtons
        previousStep={previousStep}
        nextStep={nextStep}
        caption={<Trans id="guide.step5.button-next" />}
      />
    </StepContainer>
  );
}

//eslint-disable-next-line
function Step6({ nextStep, previousStep }) {
  return (
    <StepContainer>
      <Heading level={3}>
        <Trans id="guide.step6.heading">But... 🔥</Trans>
      </Heading>

      <Box direction="row">
        <Box justify="center" pad="medium" align="center">
          <CircleQuestion size="xlarge" responsive={false} />
        </Box>
        <Box>
          <Paragraph size="large">
            <ParagraphSection>
              <Trans id="guide.step6.info">
                Things aren't always that easy. People often change their plans or have some urgent
                and unexpected matters to deal with.
              </Trans>
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step6.employee-features">
                To make your work easier, there are a few features, your employees can use.
              </Trans>
            </ParagraphSection>
          </Paragraph>
        </Box>
      </Box>

      <NavButtons
        previousStep={previousStep}
        nextStep={nextStep}
        caption={<Trans id="guide.step6.button-next">"Go on!"</Trans>}
      />
    </StepContainer>
  );
}

//eslint-disable-next-line
function Step7({ nextStep, previousStep }) {
  return (
    <StepContainer>
      <Heading level={3}>
        <Trans id="guide.step7.heading" />
      </Heading>

      <Box direction="row">
        <Box>
          <Paragraph size="large">
            <ParagraphSection>
              <Trans id="guide.step7.first-feature" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step7.give-away" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step7.other-employees-actions" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step7.shifts-page-link" components={[<Link to="/shifts" />]} />
            </ParagraphSection>
          </Paragraph>
        </Box>
        <Box justify="center" pad="medium" align="center">
          <Update size="xlarge" responsive={false} />
        </Box>
      </Box>

      <NavButtons
        previousStep={previousStep}
        nextStep={nextStep}
        caption={<Trans id="guide.step7.button-next" />}
      />
    </StepContainer>
  );
}

//eslint-disable-next-line
function Step8({ nextStep, previousStep }) {
  return (
    <StepContainer>
      <Heading level={3}>
        <Trans id="guide.step8.shift-adjustments" />
      </Heading>
      <Box direction="row">
        <Box justify="center" pad="medium" align="center">
          <Edit size="xlarge" responsive={false} />
        </Box>
        <Box>
          <Paragraph size="large">
            <ParagraphSection>
              <Trans id="guide.step8.irregular-shifts" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step8.problem-with-tracking" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step8.modifications" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step8.acceptance-needed" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step8.shifts-page-link" components={[<Link to="/shifts" />]} />
            </ParagraphSection>
          </Paragraph>
        </Box>
      </Box>

      <NavButtons
        previousStep={previousStep}
        nextStep={nextStep}
        caption={<Trans id="guide.step8.button-next" />}
      />
    </StepContainer>
  );
}

//eslint-disable-next-line
function Step9({ nextStep, previousStep }) {
  return (
    <StepContainer>
      <Heading level={3}>
        <Trans id="guide.step9.heading" />
      </Heading>
      <Box directOion="row">
        <Box justify="center" pad="medium" align="center">
          <LineChart size="xlarge" responsive={false} />
        </Box>
        <Box>
          <Paragraph size="large">
            <ParagraphSection>
              <Trans id="guide.step9.counting-hours" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans id="guide.step9.rota-covers-it" />
            </ParagraphSection>
            <ParagraphSection>
              <Trans
                id="guide.step9.reports-page-link"
                components={[<Link to="/team/manage/reports" />]}
              />
            </ParagraphSection>
          </Paragraph>
        </Box>
      </Box>

      <NavButtons
        previousStep={previousStep}
        nextStep={nextStep}
        caption={<Trans id="guide.step9.button-next" />}
      />
    </StepContainer>
  );
}

//eslint-disable-next-line
function Step10({ previousStep, firstStep }) {
  return (
    <StepContainer>
      <Heading level={3}>
        <Trans id="guide.step10.heading">We've got it!</Trans>
      </Heading>
      <Box justify="center" pad="medium" align="center">
        <Trophy size="huge" responsive={false} />
      </Box>
      <Box direction="row">
        <Paragraph size="large">
          <ParagraphSection>
            <Trans
              id="guide.step10.team-page-link"
              components={[<Link to="/team/manage" />, <Link to="/columns" />]}
            >
              Now, go to <Link to="/team/manage">Team</Link> dashboard, add your employees, create
              some shifts on the <Link to="/columns">Columns</Link> page... and get rolling! 🔥
            </Trans>
          </ParagraphSection>
          <ParagraphSection>
            <Trans
              id="guide.step10.help"
              components={[
                <a href="mailto: kontakt@rota.works" />,
                <a href="https://m.me/app.rota" target="_blank" />,
              ]}
            />
          </ParagraphSection>
        </Paragraph>
      </Box>

      <NavButtons firstStep={firstStep} previousStep={previousStep} />
    </StepContainer>
  );
}

function scrollToTop() {
  setTimeout(() => {
    document.querySelector('#root').scrollIntoView({});
  });
}

export default function Guide() {
  return (
    <Wrapper>
      <StepWizard onStepChange={scrollToTop} isHashEnabled>
        <Step1 />
        <Step2 />
        <Step3 />
        <Step4 />
        <Step5 />
        <Step6 />
        <Step7 />
        <Step8 />
        <Step9 />
        <Step10 />
      </StepWizard>
    </Wrapper>
  );
}
