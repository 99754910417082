import PropTypes from 'prop-types';
import { Component } from 'react';

import FullscreenLoader from 'components/FullscreenLoader';
import { logoutAction } from 'contexts/actions';

export class Logout extends Component {
  propTypes = {
    logout: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.logout();
  }

  render() {
    return <FullscreenLoader text="Logging out..." />;
  }
}

export default function Wrapper() {
  return <Logout logout={logoutAction} />;
}
