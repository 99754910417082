import Network from 'utils/network';
import qs from 'qs';

export function stripAdjustments(adjustments) {
  return adjustments.map(adjustment => {
    delete adjustment['adjustmentMinutes'];
    delete adjustment['suggestedAdjustmentMinutes'];
    return adjustment;
  });
}

const api = {
  getTeamShifts: (dateFrom, dateTo, teamId) => {
    const queryParams = qs.stringify({ dateFrom, dateTo }, { addQueryPrefix: true });
    return Network.get(`/shifts/get/${teamId}${queryParams}`);
  },

  giveAwayShift: (userTeamId, columnDayId, date, sendNotifications = true) => {
    const queryParams = qs.stringify({ sendNotifications }, { addQueryPrefix: true });
    return Network.put(`/shifts/give-away${queryParams}`, {
      userTeamId,
      columnDayId,
      date,
    });
  },

  takeShift: (userTeamId, columnDayId, date) =>
    Network.put(`/shifts/take`, { userTeamId, columnDayId, date }),

  modifyShifts: changes => {
    const changesMappedToBackendTypes = changes.map(change => ({
      modifyType: change.modifyType,
      shiftDTO: change.item,
    }));

    return Network.post(`/shifts/modify`, changesMappedToBackendTypes);
  },

  assignUserToShift: (user, shift) => {
    const changes = [
      {
        modifyType: 'ADD',
        shiftDTO: {
          ...shift,
          userTeamId: user.userTeamId,
        },
      },
    ];

    return Network.post(`/shifts/modify`, changes);
  },

  removeUserFromShift: (user, shift) => {
    const changes = [
      {
        modifyType: 'REMOVE',
        shiftDTO: {
          ...shift,
          userTeamId: user.userTeamId,
        },
      },
    ];

    return Network.post(`/shifts/modify`, changes);
  },

  undoGiveAwayShift: (userTeamId, columnDayId, date) =>
    Network.put(`/shifts/undo-give-away`, { userTeamId, columnDayId, date }),

  suggestAdjustments: adjustments =>
    Network.put(`/shift-adjustments/suggestion/update`, adjustments),

  saveAdjustments: adjustments => Network.put(`/shift-adjustments/update`, adjustments),

  removeAdjustments: adjustments => {
    const strippedAdjustments = stripAdjustments(adjustments);
    return Network.put(`/shift-adjustments/update`, strippedAdjustments);
  },

  removeAdjustmentSuggestion: adjustments => {
    const strippedAdjustments = stripAdjustments(adjustments);
    return api.suggestAdjustments(strippedAdjustments);
  },

  acceptAdjustment: ({ userTeamId, columnDayId, date }) =>
    Network.post(`/shift-adjustments/suggestion/accept`, [{ userTeamId, columnDayId, date }]),

  rejectAdjustment: ({ userTeamId, columnDayId, date }) =>
    Network.post(`/shift-adjustments/suggestion/reject`, [{ userTeamId, columnDayId, date }]),

  editDescription: ({ userTeamId, columnDayId, date }, description) => {
    const changeMappedToBackendTypes = {
      modifyType: 'UPDATE',
      shiftDTO: {
        userTeamId,
        columnDayId,
        date,
        description,
      },
    };

    return Network.post(`/shifts/modify`, [changeMappedToBackendTypes]);
  },
};

export default api;
