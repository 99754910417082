import Spinner from 'components/Spinner';
import { Checkmark, Close } from 'grommet-icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../itemCellColors';

const Wrapper = styled.div`
  text-align: right;
  margin: 2px 0px;
`;

const BaseButton = styled.button`
  border: none;
  margin: 0;
  padding: 5px;
`;

const AcceptButton = styled(BaseButton)`
  border-radius: 5px;

  background: ${colors.foreground.YES};

  &:hover {
    cursor: pointer;
    background: ${colors.background.YES};
  }
`;

const RejectButton = styled(BaseButton)`
  border-radius: 5px;

  background: ${colors.foreground.NO};

  &:hover {
    cursor: pointer;
    background: ${colors.background.NO};
  }
`;

export default function Button({ onClick, loading, type }) {
  return (
    <Wrapper>
      {type === 'accept' ? (
        <AcceptButton onClick={onClick}>{loading ? <Spinner /> : <Checkmark />}</AcceptButton>
      ) : type === 'reject' ? (
        <RejectButton onClick={onClick}>{loading ? <Spinner /> : <Close />}</RejectButton>
      ) : null}
    </Wrapper>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.string,
};
