import { Component } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import last from 'lodash/last';

import { getColumnsFromTo } from 'components/RotaTable/ItemModule';
import { getDaysWithExtrasForMonth } from 'utils/Dates';

export default class EditableColumnCalendarFilter extends Component {
  static propTypes = {
    children: PropTypes.any,
    columns: PropTypes.any,
    month: PropTypes.string.isRequired,
  };

  // TODO: memoize each param?
  columnsFilter = memoize(
    (columns, month) => {
      // TODO: replace state callback with just arguments?
      const daysInterval = getDaysWithExtrasForMonth(month);
      const dateFrom = daysInterval[0],
        dateTo = last(daysInterval);

      const columnsFromTo = getColumnsFromTo(dateFrom, dateTo)({ columns });

      return { columns: columnsFromTo, month };
    },
    // TODO: Fix this somehow
    () => false,
  );

  render() {
    const { columns: propColumns, month: propMonth } = this.props;
    const { columns, month } = this.columnsFilter(propColumns, propMonth);

    return this.props.children({
      columns,
      month,
    });
  }
}
