import { Trans } from '@lingui/react';
import { Page, PageHeader, PageSection } from 'components/ui/Page';
import { UserConsumer } from 'contexts';
import { Box, Button } from 'grommet';
import { UserAdd } from 'grommet-icons';
import PropTypes from 'prop-types';
import { Component } from 'react';
import AddUserModal from './AddUserModal';
import AdminUserList from './AdminUserList';

class TeamManage extends Component {
  static propTypes = {
    adminMode: PropTypes.bool,
    onRemoveTeamMember: PropTypes.func,
    onSaveTeamMembers: PropTypes.func,
    teamId: PropTypes.any,
    teamMembers: PropTypes.array,
    teamName: PropTypes.string,
  };

  state = {
    sortBy: 'name',
    showAddUserModal: false,
  };

  render() {
    return (
      <>
        {this.state.showAddUserModal && (
          <AddUserModal onSave={this.handleUserSave} onClose={this.handleCloseAddUserModal} />
        )}
        <Page header={<PageHeader title={<Trans id="team.header" />} backTo="/team" />}>
          <PageSection
            title={
              <Box fill="horizontal" direction="row" justify="between">
                <Trans id="team.members-header" />

                {this.props.adminMode && (
                  <Button icon={<UserAdd />} onClick={this.handleOpenAddUserModal} />
                )}
              </Box>
            }
            full
          >
            <AdminUserList
              users={this.props.teamMembers}
              onRemoveTeamMember={this.props.onRemoveTeamMember}
            />
          </PageSection>
        </Page>
      </>
    );
  }

  handleSortBy = sortBy => {
    this.setState({ sortBy });
  };

  getSorted = (array, sortBy) => array.sort((a, b) => a[sortBy] > b[sortBy]);

  handleOpenAddUserModal = () => {
    this.setState({ showAddUserModal: true });
  };

  handleCloseAddUserModal = () => {
    this.setState({ showAddUserModal: false });
  };

  handleUserSave = user => {
    this.props.onSaveTeamMembers([user], this.props.teamId);
  };
}

export default function ContextWrapper(props) {
  return (
    <UserConsumer>
      {({
        addTeamMembers,
        currentTeam,
        currentTeamMembers,
        isAdminInCurrentTeam,
        removeTeamMember,
      }) => (
        <TeamManage
          adminMode={isAdminInCurrentTeam()}
          teamId={currentTeam.id}
          teamName={currentTeam.name}
          teamMembers={currentTeamMembers}
          onSaveTeamMembers={addTeamMembers}
          onRemoveTeamMember={removeTeamMember}
          {...props}
        />
      )}
    </UserConsumer>
  );
}
