import FullscreenWrapper from 'components/FullscreenWrapper';
import { Notification } from 'components/Notification';
import Spinner from 'components/Spinner';
import { UserConsumer } from 'contexts';
import { withFormik } from 'formik';
import { Anchor, Box, Button, Footer, Form, FormField, Header, Heading, TextInput } from 'grommet';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Component } from 'react';
import { Redirect } from 'react-router';
import errorMessageHandler from 'utils/errorMessageHandler';

const UserJoinFormInner = props => {
  const {
    email,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    status,
    teamName,
    touched,
    values,
  } = props;

  return (
    <FullscreenWrapper>
      <Box tag="section">
        <Box fill="horizontal" justify="center" align="center" responsive pad="medium">
          <Form>
            <Header>
              <Heading level={3}>Complete your registration to join {teamName}!</Heading>
            </Header>

            <FormField label="Name" error={touched.userName && errors.userName}>
              <TextInput
                value={values.userName}
                name="userName"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormField>
            {email && (
              <FormField label="Email" className="rota-input-disabled">
                <TextInput value={email} disabled />
              </FormField>
            )}
            <FormField label="Password">
              <TextInput
                type="password"
                value={values.password}
                name="password"
                onChange={handleChange}
              />
            </FormField>
            <FormField
              label="Confirm password"
              error={touched.passwordConfirmation && errors.passwordConfirmation}
            >
              <TextInput
                type="password"
                value={values.passwordConfirmation}
                name="passwordConfirmation"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormField>

            <Footer>
              <Box justify="center" align="center" fill="horizontal" pad="large">
                <Button
                  label="Join the team!"
                  primary={true}
                  onClick={isSubmitting ? null : handleSubmit}
                />
                <div style={{ height: '40px', padding: '15px' }}>
                  {isSubmitting && <Spinner />}
                  {status && <Notification message={status} size="medium" status="critical" />}
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div>Already registered?</div>
                  <Anchor label="Go to login page" path="/login" reverse={false} />
                </div>
              </Box>
            </Footer>
          </Form>
        </Box>
      </Box>
    </FullscreenWrapper>
  );
};

UserJoinFormInner.propTypes = {
  email: PropTypes.string,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onRegister: PropTypes.func,
  status: PropTypes.any,
  teamName: PropTypes.string,
  touched: PropTypes.object,
  values: PropTypes.object,
};

const UserJoinForm = withFormik({
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { userName, password } = values;
    try {
      await props.onRegister(userName, password);
      setStatus(null);
      setSubmitting(false);
    } catch (exception) {
      const errorMessage = errorMessageHandler(exception, 'Error occured. Please try again.');

      setStatus(errorMessage);
      setSubmitting(false);
    }
  },
  validate: values => {
    const errors = {};

    if (!values.userName || values.userName === '') {
      errors['userName'] = 'Required!';
    }

    if (values.password !== values.passwordConfirmation) {
      errors['passwordConfirmation'] = 'Passwords do not match';
    }

    return errors;
  },
})(UserJoinFormInner);

export class UserJoin extends Component {
  static propTypes = {
    location: PropTypes.object,
    onRegister: PropTypes.func,
  };

  state = {
    loading: true,
    userRegistrationKey: '',
    teamName: '',
    toApp: false,
  };

  componentDidMount() {
    const { userRegistrationKey, teamName, email } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    this.setState({
      email,
      loading: false,
      teamName,
      userRegistrationKey,
    });
  }

  render() {
    return this.state.toApp ? (
      <Redirect
        to={{
          pathname: '/login',
          search: `?message=${encodeURI(
            `You have been succesfully added to the ${this.state.teamName}team!`,
          )}`,
        }}
      />
    ) : this.state.loading ? (
      <div>Loading ...</div>
    ) : (
      <UserJoinForm
        teamName={this.state.teamName}
        email={this.state.email}
        onRegister={this.handleRegister}
      />
    );
  }

  handleRegister = async (displayName, password) => {
    await this.props.onRegister(this.state.userRegistrationKey, displayName, password);
    this.setState({ toApp: true });
  };
}

export default function ContextWrapper(props) {
  return (
    <UserConsumer>
      {({ registerUser }) => <UserJoin onRegister={registerUser} {...props} />}
    </UserConsumer>
  );
}
