import reportApi from 'api/reports';
import ReportGenerator from 'components/ReportGenerator';
import { UserConsumer } from 'contexts';
import PropTypes from 'prop-types';
import { Component } from 'react';
import downloadFile from 'utils/downloadFIle';

class GenerateReport extends Component {
  static propTypes = {
    onGenerateReport: PropTypes.func,
  };

  render() {
    return <ReportGenerator onGenerateReport={this.props.onGenerateReport} />;
  }
}

export default function ContextWrapper(props) {
  return (
    <UserConsumer>
      {({ currentTeam }) => (
        <GenerateReport
          onGenerateReport={async (dateFrom, dateTo) => {
            const response = await reportApi.generateReport(currentTeam.id, dateFrom, dateTo);

            const filename = `rota-report-${currentTeam.name}-to-${dateFrom}-${dateTo}`;

            downloadFile(response, filename, 'csv');
          }}
          {...props}
        />
      )}
    </UserConsumer>
  );
}
