import { Trans } from '@lingui/react';
import Toast from 'components/Toast';
import { ItemWrapper } from 'components/ui/ItemsCalendar/Item';
import { useI18n } from 'contexts/i18nWrapper';
import { Anchor, Box, Drop } from 'grommet';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import errorMessageHandler from 'utils/errorMessageHandler';
import { Link } from 'react-router-dom';

const PlainLink = styled(Link)`
  color: #444444;
  &:hover {
    color: #444444;
    text-decoration: none;
  }
`;

const Wrapper = styled(ItemWrapper).attrs({ className: 'column-calendar-item' })`
  padding: 4px 10px;
  margin-bottom: 10px;
  font-size: 14px;

  .header {
    font-weight: 600;
  }

  .duration {
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
  }
`;

const TimeWrapper = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
`;

const CopyMenuButton = () => {
  const i18n = useI18n();
  return i18n._('column-calendar-item.copy');
};
const EditMenuButton = () => {
  const i18n = useI18n();
  return i18n._('column-calendar-item.edit');
};
const DeleteMenuButton = () => {
  const i18n = useI18n();
  return i18n._('column-calendar-item.delete');
};

export default function ColumnItem({ adminMode, column, onCopy, onEdit, onDelete }) {
  const [showMenu, setShowMenu] = useState(false);
  const [errorOnDelete, setErrorOnDelete] = useState(null);
  const node = useRef();
  const { date, header, start, end, columnId } = column;
  const target = `column-${date}-${columnId}`;

  const toggleMenu = useCallback(
    () => {
      setShowMenu(_showMenu => !_showMenu);
    },
    [setShowMenu],
  );

  const handleColumnClick = event => {
    event.preventDefault();
    event.stopPropagation();
    toggleMenu();
  };

  const handleEdit = () => {
    onEdit(column);
    setTimeout(() => {
      setShowMenu(false);
    });
  };

  const handleCopy = () => {
    onCopy(column);

    setTimeout(() => {
      setShowMenu(false);
    });
  };

  const handleDelete = async () => {
    setErrorOnDelete(null);
    const { date, columnDayId: id } = column;

    try {
      await onDelete([{ date, id }]);
    } catch (exception) {
      setErrorOnDelete(errorMessageHandler(exception));
      setShowMenu(false);
    }
  };

  return (
    <Wrapper onClick={handleColumnClick}>
      {errorOnDelete && (
        <Toast status="critical">
          <Trans id="error" /> : {errorOnDelete}
        </Toast>
      )}
      <div id={target} ref={node} className="header">
        {header}
      </div>
      <TimeWrapper>
        {start} - {end}
      </TimeWrapper>
      {showMenu &&
        adminMode && (
          <Drop
            onEsc={() => setShowMenu(false)}
            onClickOutside={() => setShowMenu(false)}
            target={node.current}
            align={{ top: 'top', left: 'right' }}
            stretch={false}
          >
            <Box
              pad="small"
              gap="small"
              justify="start"
              className="override_menu_xxs column-calendar-column-item-menu"
            >
              <PlainLink to={`/work/shifts/${column.columnDayId}`}>
                <Anchor>
                  <Trans id="column-calendar-item.details" />
                </Anchor>
              </PlainLink>
              <Anchor onClick={handleCopy}>
                <CopyMenuButton />
              </Anchor>
              <Anchor onClick={handleEdit} id="column-calendar-item-edit">
                <EditMenuButton />
              </Anchor>

              <Anchor onClick={handleDelete} id="column-calendar-item-delete">
                <DeleteMenuButton />
              </Anchor>
            </Box>
          </Drop>
        )}
    </Wrapper>
  );
}

ColumnItem.propTypes = {
  adminMode: PropTypes.bool,
  column: PropTypes.object,
  onCopy: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};
