import PropTypes from 'prop-types';
import { Checkmark } from 'grommet-icons';
import Spinner from 'components/Spinner';
import styled from 'styled-components';

import { colors } from '../itemCellColors';

const Wrapper = styled.div`
  text-align: right;
  margin: 2px 0px;
`;

const BaseButton = styled.button`
  border: none;
  margin: 0;
  padding: 5px;
`;

const AcceptButton = styled(BaseButton)`
  border-radius: 5px;

  background: ${colors.foreground.YES};

  &:hover {
    cursor: pointer;
    background: ${colors.background.YES};
  }
`;

export default function TakeButton({ onTake, taking }) {
  return (
    <Wrapper>
      <AcceptButton onClick={onTake}>{taking ? <Spinner /> : <Checkmark />}</AcceptButton>
    </Wrapper>
  );
}

TakeButton.propTypes = {
  onTake: PropTypes.func,
  taking: PropTypes.bool,
};
