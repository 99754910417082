import { Button } from 'grommet';
import { Download } from 'grommet-icons';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/react';

export default function GenerateButton({ onClick, ...props }) {
  return (
    <Button
      icon={<Download />}
      label={<Trans id="team.generate-reports.generate-button" />}
      onClick={onClick}
      {...props}
    />
  );
}

GenerateButton.propTypes = {
  onClick: PropTypes.func,
};
