import { DATE_FORMAT_MONTHS } from 'config/dates';
import { Box, Button } from 'grommet';
import { FormNext, FormPrevious } from 'grommet-icons';
import moment from 'moment';
import styled from 'styled-components';
import { useIsMobile } from 'utils/responsive';

const Month = styled(Box)`
  text-transform: capitalize;
`;

// eslint-disable-next-line react/prop-types
export function CalendarBar({ onMoveDateBackward, onMoveDateForward, month, leftControls }) {
  const isMobile = useIsMobile();

  return (
    <Box direction="row" justify="between" align="center">
      <Box direction="row" justify="start" flex={isMobile ? 'shrink' : 'grow'} basis="0">
        {leftControls}
      </Box>

      <Box direction="row" justify="center" flex="grow" basis="0" gap="8px" pad="small">
        <Button plain icon={<FormPrevious />} onClick={onMoveDateBackward} />
        <Month>
          {moment(month, DATE_FORMAT_MONTHS).format(isMobile ? 'MM.YYYY' : 'MMMM YYYY')}
        </Month>
        <Button plain icon={<FormNext />} onClick={onMoveDateForward} />
      </Box>
      <Box direction="row" justify="end" flex={isMobile ? 'shrink' : 'grow'} basis="0" />
    </Box>
  );
}
