import { setupI18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

// TODO: make this lazy imported?
import catalogEn from './locales/en/messages.js';
import catalogPl from './locales/pl/messages.js';
import { I18nFuncContextProvider } from 'contexts/i18nWrapper.js';
import moment from 'moment';

const CURRENT_LANGUAGE_KEY = 'CURRENT_LANGUAGE';
const DEFAULT_LANGUAGE = 'en';
export const AVAILABLE_LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Polski', value: 'pl' },
];

const isAvailableLanguage = language =>
  AVAILABLE_LANGUAGES.some(availableLanguage => availableLanguage.value === language);

const catalogs = { en: catalogEn, pl: catalogPl };

export const loadLanguageCode = () =>
  window.localStorage.getItem(CURRENT_LANGUAGE_KEY) || DEFAULT_LANGUAGE;

export const loadLanguage = () => {
  const languageCode = loadLanguageCode();
  return AVAILABLE_LANGUAGES.find(availableLanguage => availableLanguage.value === languageCode);
};

export const changeLanguage = language => {
  if (!isAvailableLanguage(language)) {
    throw new Error('LANGUAGE_NOT_AVAILABLE');
  }

  window.localStorage.setItem(CURRENT_LANGUAGE_KEY, language);
  window.location.reload();
};

export const currentLanguage = loadLanguageCode();

if (currentLanguage === 'pl') {
  // eslint-disable-next-line
  import('moment/locale/pl');
  moment.locale('pl', {
    week: {
      dow: 1,
    },
  });
} else if (currentLanguage === 'en') {
  moment.locale('en', {
    week: {
      dow: 1,
    },
  });
}

export const i18n = setupI18n({
  catalogs,
  language: currentLanguage,
});

export default function I18n({ children, ...props }) {
  return (
    <I18nProvider i18n={i18n} {...props}>
      <I18nFuncContextProvider value={i18n}>{children}</I18nFuncContextProvider>
    </I18nProvider>
  );
}

// eslint-disable-next-line react/prop-types
export function I18nContextWrapper({ children }) {
  return (
    <I18n>
      {children}
      {/* {({ i18n }) => <I18nFuncContextProvider value={i18n}>{children}</I18nFuncContextProvider>} */}
    </I18n>
  );
}
