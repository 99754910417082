import { Trans } from '@lingui/react';
import { List, ListItem } from 'components/List';
import { Anchor, Box, Menu } from 'grommet';
import PropTypes from 'prop-types';

export default function ItemList({ items, header, onItemClick, menuOptions }) {
  return (
    <div>
      {header && <h4>{header}</h4>}
      <List pad="none">
        {items.length === 0 && <Trans id="items-list.none" />}
        {items.map(item => (
          <ListItem key={item.id} onClick={() => onItemClick(item)}>
            <Box
              align="center"
              fill="horizontal"
              justify="between"
              direction="row"
              responsive={false}
            >
              <Box flex="grow">{item.header}</Box>
              {menuOptions && (
                <Menu
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  responsive={true}
                  inline={false}
                  size="xsmall"
                  className="override_menu_xxs"
                >
                  {menuOptions.map(({ id, title, handler }) => (
                    <Anchor id={id} onClick={() => handler(item)}>
                      {title}
                    </Anchor>
                  ))}
                </Menu>
              )}
            </Box>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

ItemList.propTypes = {
  items: PropTypes.array,
  header: PropTypes.string,
  onItemClick: PropTypes.func,
  menuOptions: PropTypes.array,
};
