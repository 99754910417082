import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// TODO: move those helpers here
import { getAvailabilitiesForegroundColor } from 'components/itemCellColors';

import Cell from './Cell';

const Wrapper = styled.div`
  background: ${props => getAvailabilitiesForegroundColor(props.available)};
  height: 100%;
`;

export default class AvailabilityCell extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    isDeadlined: PropTypes.bool,
    onAddChangeRequest: PropTypes.func,
    onClick: PropTypes.func,
    primaryItem: PropTypes.object,
  };

  render() {
    const { available } = this.props.primaryItem;

    return (
      <Cell {...this.props} onClick={this.handleClick}>
        <Wrapper available={available} />
      </Cell>
    );
  }

  handleClick = () => {
    const { isDeadlined, isAdmin, primaryItem } = this.props;

    if (isDeadlined && !isAdmin) {
      this.props.onAddChangeRequest(primaryItem);
    } else {
      this.props.onClick(primaryItem);
    }
  };
}
