import Network from 'utils/network';
import qs from 'qs';

export default {
  getTeamAvailabilities: (dateFrom, dateTo, teamId) => {
    const queryParams = qs.stringify({ dateFrom, dateTo }, { addQueryPrefix: true });
    return Network.get(`/availabilities/get/${teamId}${queryParams}`);
  },

  getCurrentUserAvailabilities: (dateFrom, dateTo, teamId) => {
    const queryParams = qs.stringify({ dateFrom, dateTo }, { addQueryPrefix: true });
    return Network.get(`/availabilities/mine/${teamId}${queryParams}`);
  },

  modifyAvailabilities: changes => {
    const changesMappedToBackendTypes = changes.map(change => ({
      modifyType: change.modifyType,
      availabilityDTO: change.item,
    }));

    return Network.post(`/availabilities/modify`, changesMappedToBackendTypes);
  },
};
