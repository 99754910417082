import { PageSection } from 'components/ui/Page';
import { Page } from 'components/ui/Page/Page';
import { PageHeader } from 'components/ui/Page/PageHeader';
import { Route, Switch } from 'react-router-dom';
import { UpcomingDeadlines } from './UpcomingDeadlines';
import { Availability } from './Availability';
import { Trans } from '@lingui/react';

export function AvailabilityRoutes() {
  return (
    <Route path="/availability">
      <Switch>
        <Route exact path="/availability">
          <Page header={<PageHeader title={<Trans id="availibility-page.your-availability" />} />}>
            <PageSection full>
              <Availability />
            </PageSection>

            <PageSection title={<Trans id="availibility-page.deadlines" />}>
              <PageSection.Subtitle title={<Trans id="availibility-page.deadlines-subtitle" />} />
              <UpcomingDeadlines />
            </PageSection>
          </Page>
        </Route>
      </Switch>
    </Route>
  );
}
