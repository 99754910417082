import axios from 'axios';
import { configure } from 'axios-hooks';

// TODO: use after full support of local development
// eslint-disable-next-line
import ENV from 'utils/env';

export const PROD_API_URL = 'https://api.rota.works/v1';
export const DEV_API_URL = 'https://api.dev.rota.works/v1';
export const LOCAL_API_URL = 'http://localhost:8080/v1';
export const TEST_API_URL = '/test-api';

function getBaseURL() {
  if (ENV.NODE_ENV === 'prod' || ENV.PROD_API) {
    return PROD_API_URL;
  } else if (ENV.NODE_ENV === 'test') {
    return TEST_API_URL;
  } else if (ENV.DEV_API) {
    return DEV_API_URL;
  } else {
    return LOCAL_API_URL;
  }
}

export const baseURL = getBaseURL();

const Network = axios.create({ baseURL });

configure({ axios: Network, cache: null });

export default Network;

export const setAuthorizationHeader = token => {
  Network.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const removeAuthorizationHeader = () => {
  delete Network.defaults.headers.common['Authorization'];
};
