import { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import uniqWith from 'lodash/uniqWith';
import find from 'lodash/find';

const { Consumer, Provider } = createContext({
  deadlines: {},
});

export { Consumer };

export default class DeadlineProvider extends Component {
  static propTypes = {
    api: PropTypes.object,
    children: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      deadlines: [],
      addDeadline: this.addDeadline,
      addDeadlines: this.addDeadlines,
      clearDeadlines: this.clearDeadlines,
      fetchDeadlines: this.fetchDeadlines,
      removeDeadline: this.removeDeadline,
      removeDeadlines: this.removeDeadlines,
    };
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }

  // TODO: Change to something like append/insertDeadlines and
  // also implement API calls here. In the effect, it will
  // replace actions from actions.js
  addDeadlines = deadlines => {
    const newDeadlines = uniqWith([...this.state.deadlines, ...deadlines], (a, b) => a.id === b.id);

    this.setState({ deadlines: newDeadlines });
  };

  setDeadlines = deadlines => {
    this.setState({ deadlines });
  };

  addDeadline = deadline => this.addDeadlines([deadline]);

  removeDeadlines = deadlineIds => {
    const deadlines = this.state.deadlines.filter(
      deadline => !find(deadlineIds, id => id === deadline.id),
    );

    this.setState({ deadlines });
  };

  removeDeadline = deadlineId => this.removeDeadlines([deadlineId]);

  fetchDeadlines = async (dateFrom, dateTo, teamId) => {
    const { data } = await this.props.api.getTeamDeadlines(dateFrom, dateTo, teamId);
    this.setDeadlines(data);
  };

  clearDeadlines = () => {
    this.setState({ deadlines: [] });
  };
}
