import update from 'immutability-helper';
import get from 'lodash/get';
import set from 'lodash/set';

import { getDateIntervalSteps, getDateKey } from 'utils/Dates';
import { DEADLINE_STATES, DEADLINE_STATE_TO_ICON_NAME } from 'config/deadlines';
import { getItemCompositeId } from 'contexts/utils';

import * as sentry from 'logging/sentry';

export function setItem(item) {
  return {
    payload: item,
  };
}

function getInitialState() {
  return {
    items: {},
  };
}

// Move to "module utils" or something similar
export function updateItem(state, item, typeKey = 'items') {
  const dateKey = getDateKey(item.date);
  const itemKey = item.id ? item.id : getItemCompositeId(item);

  if (state[typeKey][dateKey] === undefined) {
    state[typeKey][dateKey] = {};
  }

  return update(state, {
    [typeKey]: {
      [dateKey]: {
        [itemKey]: { $set: item },
      },
    },
  });
}

export function removeItem(state, item, typeKey = 'items') {
  const dateKey = getDateKey(item.date);
  const itemKey = item.id ? item.id : getItemCompositeId(item);

  return update(state, {
    [typeKey]: {
      [dateKey]: {
        $unset: [itemKey],
      },
    },
  });
}

const AVAILABLE_STATUSES = [null, true, false];
export const getNextStatus = status =>
  AVAILABLE_STATUSES[(AVAILABLE_STATUSES.indexOf(status) + 1) % AVAILABLE_STATUSES.length];

export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    default:
      return Object.assign({}, state);
  }
};

// Select helpers
export const getItemsForDay = (date, itemsKey = 'items') => state =>
  get(state, `${itemsKey}.${date}`, []);

export const getItemsArrayFromTo = (dateFrom, dateTo, itemsKey = 'items') => state => {
  const intervals = getDateIntervalSteps(dateFrom, dateTo);

  const items = [];

  intervals.forEach(date => {
    const itemsForDay = getItemsForDay(date, itemsKey)(state);
    items.push(...Object.values(itemsForDay));
  });

  return items;
};

export const getItemsMapFromTo = (dateFrom, dateTo) => state => {
  const intervals = getDateIntervalSteps(dateFrom, dateTo);

  const itemsMap = {};

  intervals.forEach(interval => {
    const items = getItemsForDay(interval)(state);
    itemsMap[interval] = items;
  });

  return itemsMap;
};

export const getColumnsFromTo = (dateFrom, dateTo) => state => {
  const intervals = getDateIntervalSteps(dateFrom, dateTo);

  const columns = {};

  intervals.forEach(date => {
    columns[date] = get(state.columns, date, state.columns.default);
  });

  return columns;
};

const getColumnKey = (date, start) => `${date}-${(start + '').padStart(2, '0')}`;

export const getShiftKey = (date, columnDayId) => `${getDateKey(date)}-${columnDayId}`;

// TODO: test this shit
const createColumnIndexLookup = columns => {
  const columnLookup = {};
  const reverseLookup = {};

  Object.entries(columns)
    .map(([date, dayColumn]) => [
      ...dayColumn.map(column => [getColumnKey(date, column.start) + column.id, date, column]),
    ])
    .reduce((acc, prev) => [...acc, ...prev], [])
    .sort(([a], [b]) => {
      const an = a.replace(/[-:]/g, '');
      const bn = b.replace(/[-:]/g, '');

      return an.localeCompare(bn);
    })
    // eslint-disable-next-line
    .forEach(([sortDate, date, column], index) => {
      const columnKey = getShiftKey(date, column.id);
      columnLookup[columnKey] = index;
      reverseLookup[index] = {
        date,
        id: column.id,
        header: column.header,
        start: column.start,
        end: column.end,
      };
    });

  return [columnLookup, reverseLookup];
};

const createUserIndexLookup = users => {
  const usersLookup = {};

  Object.values(users).forEach((user, index) => {
    const userTeamId = user.userTeamId;

    if (userTeamId === undefined) {
      throw new Error(`Undefined userTeamId for a user ${JSON.stringify(user)}`);
    }

    usersLookup[userTeamId] = index;
  });

  return usersLookup;
};

const calculateColumnSums = (table, reverseColumnLookup) => {
  const sums = {};

  table.forEach(tableRow => {
    tableRow.forEach((cell, index) => {
      if (!get(cell, 'primaryItem')) {
        const { date: cellDate, id } = reverseColumnLookup[index];
        const emptyColumnKey = getShiftKey(cellDate, id);

        if (!sums[emptyColumnKey]) {
          sums[emptyColumnKey] = 0;
        }
        return;
      }

      const { columnDayId, date } = cell.primaryItem;
      const key = getShiftKey(date, columnDayId);

      if (!sums[key]) {
        sums[key] = 1;
      } else {
        sums[key]++;
      }
    });
  });

  return sums;
};

export const create2DTable = (users, columns, items, backgroundItems = [], calculateSums) => {
  const [columnLookup, reverseColumnLookup] = createColumnIndexLookup(columns);
  const userLookup = createUserIndexLookup(users);

  const columnsLength = Object.keys(columnLookup).length;
  const usersLength = Object.keys(userLookup).length;

  const table = Array(usersLength)
    .fill(0)
    .map(() => Array(columnsLength).fill(null));

  const fillTableWithItems = (table, items, itemKey = 'primaryItem') => {
    items.forEach(item => {
      const columnIndex = columnLookup[getShiftKey(item.date, item.columnDayId)];

      if (item.userTeamId === undefined) {
        sentry.logMessage(`Incomplete item passed to create2DTable: ${JSON.stringify(item)}`);
      }

      const rowIndex = userLookup[item.userTeamId];

      set(table, `[${rowIndex}][${columnIndex}][${itemKey}]`, item);
    });
  };

  fillTableWithItems(table, items);
  fillTableWithItems(table, backgroundItems, 'backgroundItem');

  const sums = calculateSums ? calculateColumnSums(table, reverseColumnLookup) : null;

  return { table, reverseColumnLookup, sums };
};

export const getItemsDiff = (originalItems, modifiedItems) => {
  // TODO: extract this function
  const hasItemChanged = (a, b) =>
    a.status !== b.status || a.available !== b.available || a.description !== b.description;
  const didExist = shift => shift !== null;

  const diffedItems = [];

  Object.entries(modifiedItems).forEach(([date, dayItems]) => {
    Object.entries(dayItems).forEach(([itemId, item]) => {
      const originalItem = get(originalItems, `${date}.${itemId}`, null);

      if (!didExist(originalItem)) {
        diffedItems.push([null, item]);
      } else if (hasItemChanged(item, originalItem)) {
        diffedItems.push([originalItem, item]);
      }
    });
  });

  Object.entries(originalItems).forEach(([date, dayItems]) => {
    Object.entries(dayItems).forEach(([itemId, originalItem]) => {
      const modifiedItem = get(modifiedItems, `${date}.${itemId}`, null);

      if (modifiedItem === null) {
        diffedItems.push([originalItem, null]);
      }
    });
  });

  return diffedItems;
};

export const getDeadlineState = (dateToday, deadline) =>
  deadline.deadlineDate < dateToday ? DEADLINE_STATES.AFTER_ALREADY : DEADLINE_STATES.AFTER_NOT_YET;

export const getDeadlineIconName = status => DEADLINE_STATE_TO_ICON_NAME[status];

export const getDeadlinesForDays = (dateToday, dateFrom, dateTo, deadlines) => {
  const intervals = getDateIntervalSteps(dateFrom, dateTo);

  const deadlinesLookup = {};
  intervals.forEach(step => {
    deadlinesLookup[step] = DEADLINE_STATES.BEFORE_NOT_YET;
  });

  deadlines.forEach(deadline => {
    const lastDay = deadline.periodEnd > dateTo ? dateTo : deadline.periodEnd;

    const deadlinedDays = getDateIntervalSteps(deadline.periodStart, lastDay);
    deadlinedDays.forEach(day => {
      const state =
        deadline.deadlineDate < dateToday
          ? DEADLINE_STATES.AFTER_ALREADY
          : DEADLINE_STATES.AFTER_NOT_YET;

      deadlinesLookup[day] = state;
    });
  });

  return deadlinesLookup;
};

export const getDeadlinesForDaysWithData = (dateToday, dateFrom, dateTo, deadlines) => {
  const intervals = getDateIntervalSteps(dateFrom, dateTo);

  const deadlinesLookup = {};
  intervals.forEach(step => {
    deadlinesLookup[step] = { status: DEADLINE_STATES.BEFORE_NOT_YET };
  });

  deadlines.forEach(deadline => {
    const lastDay = deadline.periodEnd > dateTo ? dateTo : deadline.periodEnd;

    const deadlinedDays = getDateIntervalSteps(deadline.periodStart, lastDay);
    deadlinedDays.forEach(day => {
      const status =
        deadline.deadlineDate < dateToday
          ? DEADLINE_STATES.AFTER_ALREADY
          : DEADLINE_STATES.AFTER_NOT_YET;

      deadlinesLookup[day] = { status, date: deadline.deadlineDate };
    });
  });

  return deadlinesLookup;
};

const changeRequest = (type, item) => ({ modifyType: type, item });
export const getModifyRequestForItemPair = ([a, b]) => {
  if (a === null) {
    return changeRequest('ADD', b);
  } else if (b === null) {
    return changeRequest('REMOVE', a);
  } else {
    return changeRequest('UPDATE', b);
  }
};

export const applyModifyChanges = (initialValue, changes, itemsKey) => {
  const newState = changes.reduce(
    (state, change) => {
      const { modifyType, item } = change;

      switch (modifyType) {
        case 'ADD':
        case 'UPDATE':
          return updateItem(state, item, itemsKey);

        case 'REMOVE':
          return removeItem(state, item, itemsKey);

        default:
          throw new Error('Invalid modify request type!');
      }
    },
    { [itemsKey]: initialValue },
  );

  return newState;
};

function displaySortError(user) {
  // eslint-disable-next-line
  console.warn(`User ${user}} cannot be sorted by 'displayname' or 'email'`);
}

function checkSortUserData(userASortname, userA, userBSortname, userB) {
  let valid = true;

  if (userASortname === null) {
    displaySortError(userA);
    valid = false;
  }

  if (userBSortname === null) {
    displaySortError(userB);
    valid = false;
  }

  return valid;
}

function getUserSortKey(user) {
  const displayname = get(user, 'displayname');

  if (displayname !== null && displayname !== undefined) {
    return displayname;
  }

  return get(user, 'email', 'error');
}

export const sortUsersByDisplayNameOrEmail = (a, b) => {
  const an = getUserSortKey(a);
  const bn = getUserSortKey(b);

  if (!checkSortUserData(an, a, bn, b)) {
    return -1;
  }

  return an.localeCompare(bn, 'pl', { sensitivity: 'base' });
};
