import {
  DATE_FORMAT,
  DATE_FORMAT_MONTHS,
  DATE_FORMAT_MONTH_DAY,
  DATE_FORMAT_TIME,
} from 'config/dates';
import moment from 'moment';
import { nth } from 'lodash';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export function getDayDifference(timeStart, timeEnd) {
  const start = moment(timeStart);
  const end = moment(timeEnd);

  const duration = moment.duration(end.diff(start));
  return duration.asDays();
}

// TODO: cleanup those that are not using moment.js
export function daysForward(offset = 0) {
  return new Date(Date.now() + offset * MS_PER_DAY);
}

export function daysBackward(offset = 0) {
  return daysForward(-offset);
}

export function add(date, offset = 0) {
  return new Date(new Date(date).getTime() + offset * MS_PER_DAY);
}

export function prettifyDate(date) {
  return new Date(date).toISOString().substr(0, 10);
}

export const getRelativeDate = daysDifference => prettifyDate(add(new Date(), daysDifference));

export const days = daysForward;

// MomentJS based:

export const getDateIntervalSteps = (dateFrom, dateTo) => {
  const startStep = moment(dateFrom, DATE_FORMAT);
  const endStep = moment(dateTo, DATE_FORMAT);

  const steps = [startStep];

  if (!startStep.isValid()) {
    throw new Error('Invalid dateFrom format!');
  }

  if (dateFrom === dateTo) {
    return [dateFrom];
  }

  const MAX_STEPS = 43;
  for (let i = 0; ; i++) {
    if (i > MAX_STEPS) {
      throw new Error(`Too long days difference! Max: ${MAX_STEPS}`);
    }

    const nextStep = startStep.clone().add(i + 1, 'days');
    steps.push(nextStep);

    if (nextStep.format(DATE_FORMAT) === endStep.format(DATE_FORMAT)) {
      break;
    }
  }

  return steps.map(step => step.format(DATE_FORMAT));
};

export const applyDaysOffset = (date, daysOffset) => {
  const startStep = moment(date, DATE_FORMAT);
  return startStep.add(daysOffset, 'days').format(DATE_FORMAT);
};

export const getDayByOffset = daysOffset => applyDaysOffset(moment(), daysOffset);

export const getDateKey = (date, format = DATE_FORMAT) => moment(date).format(format);

export const getDaysWithExtrasForMonth = (month = getCurrentMonth()) => {
  const startingDay = moment(month, DATE_FORMAT_MONTHS).weekday();
  const daysAmount = moment(month).daysInMonth();

  const dateFrom = moment(month)
    .add(-startingDay, 'days')
    .format(DATE_FORMAT);

  const extraDays =
    6 -
    moment(month)
      .add(daysAmount - 1, 'days')
      .weekday();

  const dateTo = moment(month)
    .add(daysAmount - 1 + extraDays, 'days')
    .format(DATE_FORMAT);

  return getDateIntervalSteps(dateFrom, dateTo);
};

export const getDaysFromTo = (dateFrom, dateTo) => {
  const start = moment(dateFrom, DATE_FORMAT);
  const end = moment(dateTo, DATE_FORMAT);
  const duration = moment.duration(end.diff(start)).asDays();

  return new Array(duration).fill(0).map((_, index) =>
    moment(start)
      .add(index, 'days')
      .format(DATE_FORMAT),
  );
};

export const getFromToDatesForMonth = month => {
  const dateFrom = moment(month).format(DATE_FORMAT);
  const daysAmount = moment(month).daysInMonth();
  const dateTo = moment(month)
    .add(daysAmount - 1, 'days')
    .format(DATE_FORMAT);

  return { dateFrom, dateTo };
};

export const convertStringStartEndToMoment = (start, end) => {
  const startMoment = moment(start.padStart('0', 2), 'HH:mm');
  const endMoment = moment(end, 'HH:mm');

  return [startMoment, endMoment];
};

export const isStartEndOvernight = (start, end) => {
  const [startMoment, endMoment] = convertStringStartEndToMoment(start, end);

  return endMoment.diff(startMoment) < 0;
};

export const convertStartEndToStartDuration = (start, end) => {
  const [startMoment, endMoment] = convertStringStartEndToMoment(start, end);

  const isOvernight = isStartEndOvernight(start, end);

  if (isOvernight) {
    endMoment.add(24, 'hours');
  }

  const durationObj = moment.duration(endMoment.diff(startMoment));
  const duration = `${durationObj
    .hours()
    .toString()
    .padStart(2, '0')}:${durationObj
    .minutes()
    .toString()
    .padStart(2, '0')}:00.0`;

  const convertedStart = startMoment.format('HH:mm');

  return {
    duration,
    start: convertedStart,
  };
};

export const convertStartEndToPrettyDuration = (start, end) => {
  const { duration } = convertStartEndToStartDuration(start, end);

  return moment(duration, 'HH:mm:ss').format('HH:mm');
};

export const convertStartDurationToStartEnd = (start, duration) => {
  const _duration = moment(duration, 'HH:mm:ss');

  const _start = moment(start, 'HH:mm:ss').format(DATE_FORMAT_TIME);
  const end = moment(start, 'HH:mm:ss');
  end.add(_duration.hour(), 'hour');
  end.add(_duration.minute(), 'minute');

  return {
    start: _start,
    end: end.format(DATE_FORMAT_TIME),
  };
};

export const validateTimeFormat = time => moment(time, DATE_FORMAT_TIME).isValid();

export const convertDDMMYYtoYYMMDD = date => moment(date, 'DD-MM-YYYY').toISOString();

export const convertISOtoFormat = (date, format) => moment(date).format(format);

export const convertFromFormatToISO = (date, format) => moment(date, format).toISOString();

export function getPrettyDayFromDate(date, format = DATE_FORMAT_MONTH_DAY) {
  return moment(date, DATE_FORMAT)
    .format(format)
    .replace('-', '.');
}

export function getTwoWeeksDateInterval() {
  return {
    dateFrom: moment().format(DATE_FORMAT),
    dateTo: moment()
      .add(14, 'days')
      .format(DATE_FORMAT),
  };
}

export function getCurrentMonthDatesIntervalWithOverlappingDays(month) {
  const days = getDaysWithExtrasForMonth(month);

  return {
    dateFrom: nth(days, 0),
    dateTo: nth(days, -1),
  };
}

export function getCurrentMonth() {
  return moment().format(DATE_FORMAT_MONTHS);
}

export function getRelativeMonth(month, offset) {
  return moment(month, DATE_FORMAT_MONTHS)
    .add(offset, 'months')
    .format(DATE_FORMAT_MONTHS);
}
