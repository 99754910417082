import Network from 'utils/network';
import qs from 'qs';
import { processRequest } from 'contexts/utils';

export default {
  login: (email, password) => {
    const trimmedEmail = email.trim();

    const requestData = qs.stringify({ username: trimmedEmail, password, grant_type: 'password' });
    const headers = {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic cm90YTpyb3Rhc2VjcmV0',
    };

    return processRequest(Network.post(`/oauth/token`, requestData, { headers }));
  },
};
