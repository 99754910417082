import { Component, Fragment } from 'react';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Table, Box, TableHeader, TableRow, TableCell, TableBody } from 'grommet';
import { Trans } from '@lingui/react';

import AdminUserListRow from './AdminUserListRow';
import errorMessageHandler from 'utils/errorMessageHandler';
import Toast from 'components/Toast';
import { useI18n } from 'contexts/i18nWrapper';

const UserRemovedSuccesfully = () => {
  const i18n = useI18n();
  return i18n._('team.team-manage.remove-user-success');
};

const ToastError = () => {
  const i18n = useI18n();
  return i18n._('error');
};

export default class UserList extends Component {
  static propTypes = {
    users: PropTypes.array,
    onRemoveTeamMember: PropTypes.func,
  };

  state = {
    sortIndex: 1,
    sortAscending: false,
    sortBy: 'displayname',
    removeError: null,
  };

  fields = ['userTeamId', 'displayname', 'email', 'admin'];

  render() {
    // TODO: add mapping to lowercase names for proper sorting
    const sortedUsers = orderBy(
      this.props.users,
      this.state.sortBy,
      this.state.sortAscending ? 'asc' : 'desc',
    );

    return (
      <Fragment>
        {get(this.state.removeError, 'status') === 'success' && (
          <Toast status="success">
            <UserRemovedSuccesfully />
          </Toast>
        )}
        {get(this.state.removeError, 'status') === 'error' && (
          <Toast status="critical">
            <ToastError />: {this.state.removeError.message}
          </Toast>
        )}
        <Box fill="horizontal">
          <Table size="xsmall" alignSelf="stretch">
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Trans id="team.team-manage.user-list.table.name" />
                </TableCell>

                <TableCell>
                  <Trans id="team.team-manage.user-list.table.email" />
                </TableCell>

                <TableCell>
                  <Trans id="team.team-manage.user-list.table.role" />
                </TableCell>

                <TableCell>
                  <Trans id="team.team-manage.user-list.table.controls" />
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedUsers.map(user => (
                <AdminUserListRow key={user.userTeamId} user={user} onRemove={this.handleRemove} />
              ))}
            </TableBody>
          </Table>
        </Box>
      </Fragment>
    );
  }

  handleSort = (sortIndex, sortAscending) => {
    if (sortIndex > this.fields.length) {
      return;
    }

    const sortBy = this.fields[sortIndex];

    this.setState({ sortIndex, sortBy, sortAscending });
  };

  handleRemove = async userTeamId => {
    try {
      this.setState({ removeError: null });
      await this.props.onRemoveTeamMember(userTeamId);
      this.setState({
        removeError: {
          status: 'success',
          message: 'User succesfully removed',
        },
      });
    } catch (error) {
      this.setState({
        removeError: {
          status: 'error',
          message: errorMessageHandler(error),
        },
      });
    }
  };
}
