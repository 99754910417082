import { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

import api from './api';
import {
  addOrUpdateItem,
  addColumnHelper,
  removeColumnHelper,
  updateItemFields,
} from 'contexts/utils';
import { getDateKey } from 'utils/Dates';

const ColumnContext = createContext({
  list: {},
});

const { Provider, Consumer } = ColumnContext;

export { Consumer, ColumnContext };

export class ColumnProviderInner extends Component {
  static propTypes = {
    api: PropTypes.object,
    children: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      addColumns: this.addColumns,
      clearColumns: this.clearColumns,
      columns: {},
      createColumns: this.createColumns,
      createPredefinedColumns: this.createPredefinedColumns,
      deletePredefinedColumns: this.deletePredefinedColumns,
      dayTemplates: [],
      deleteColumns: this.deleteColumns,
      editColumn: this.editColumn,
      fetchColumns: this.fetchColumns,
      fetchDayTemplates: this.fetchDayTemplates,
      fetchPredefinedColumns: this.fetchPredefinedColumns,
      getColumn: this.getColumn,
      predefinedColumns: [],
      saveDayTemplate: this.saveDayTemplate,
      deleteDayTemplates: this.deleteDayTemplates,
    };
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }

  addColumns = columns => {
    const newColumns = Object.assign({}, this.state.columns);

    Object.entries(columns).forEach(([dateKey, _columns]) => {
      _columns.forEach(_column => {
        addColumnHelper(newColumns, dateKey, _column);
      });

      if (_columns.length === 0) {
        // TODO: Hack for handling empty columns
        addColumnHelper(newColumns, dateKey, { start: 0, id: null });
      } else {
        removeColumnHelper(newColumns, dateKey, { id: null });
      }
    });

    this.setState({ columns: newColumns });
  };

  addPredefinedColumns = columns => {
    const newPredefinedColumns = cloneDeep(this.state.predefinedColumns);

    columns.forEach(column => {
      addOrUpdateItem(newPredefinedColumns, column, item => item.id === column.id);
    });

    this.setState({ predefinedColumns: newPredefinedColumns });
  };

  addDayTemplates = dayTemplates => {
    const newDayTemplates = cloneDeep(this.state.dayTemplates);

    dayTemplates.forEach(column => {
      addOrUpdateItem(newDayTemplates, column, item => item.id === column.id);
    });

    this.setState({ dayTemplates: newDayTemplates });
  };

  getColumn = (columnDayId, date) => {
    const dateKey = getDateKey(date);
    const columnsForDate = this.state.columns[dateKey];

    if (Array.isArray(columnsForDate)) {
      return columnsForDate.find(column => column.id === columnDayId) || columnDayId;
    }

    return { header: columnDayId };
  };

  fetchColumns = async (dateFrom, dateTo, teamId) => {
    const { data } = await this.props.api.getTeamColumns(dateFrom, dateTo, teamId);
    this.addColumns(data);
  };

  fetchPredefinedColumns = async teamId => {
    const { data } = await this.props.api.getPredefinedColumnsForTeam(teamId);
    this.addPredefinedColumns(data);
  };

  // TODO: maybe also per given userId?
  fetchDayTemplates = async teamId => {
    const { data } = await this.props.api.getDayTemplatesForTeam(teamId);
    this.addDayTemplates(data);
  };

  createColumns = async (columns, teamId) => {
    const { data } = await this.props.api.createColumns(columns, teamId);
    this.addColumns(data);
  };

  saveDayTemplate = async (header, columns, teamId) => {
    const { data } = await this.props.api.saveDayTemplate(header, columns, teamId);
    this.addDayTemplates(data);
  };

  deleteDayTemplates = async dayTemplates => {
    const dayTemplatesIds = dayTemplates.map(dayTemplate => dayTemplate.id);
    await this.props.api.deleteDayTemplates(dayTemplatesIds);
    this.removeDayTemplates(dayTemplates);
  };

  clearColumns = () => {
    this.setState({ columns: {}, predefinedColumns: [], dayTemplates: [] });
  };

  deleteColumns = async columns => {
    const columnIds = columns.map(column => column.id);
    await this.props.api.deleteColumns(columnIds);
    this.removeColumns(columns);
  };

  removeColumns = columns => {
    const newColumns = cloneDeep(this.state.columns);

    columns.forEach(column => {
      const dateKey = getDateKey(column.date);
      removeColumnHelper(newColumns, dateKey, column);
    });

    this.setState({ columns: newColumns });
  };

  removePredefinedColumns = (columns = []) => {
    const newColumns = cloneDeep(this.state.predefinedColumns).filter(
      column => !columns.some(col => col.id === column.id),
    );

    this.setState({ predefinedColumns: newColumns });
  };

  removeDayTemplates = (dayTemplates = []) => {
    const newDayTemplates = cloneDeep(this.state.dayTemplates).filter(
      dayTemplate => !dayTemplates.some(dt => dt.id === dayTemplate.id),
    );

    this.setState({ dayTemplates: newDayTemplates });
  };

  createPredefinedColumns = async (columns, teamId) => {
    const { data } = await this.props.api.createPredefinedColumns(columns, teamId);
    this.addPredefinedColumns(data);
  };

  deletePredefinedColumns = async columns => {
    const columnIds = columns.map(column => column.id);
    await this.props.api.deletePredefinedColumns(columnIds);
    this.removePredefinedColumns(columns);
  };

  updateColumns = columns => {
    const newColumns = Object.assign({}, this.state.columns);

    columns.forEach(column => {
      const { date } = column;
      newColumns[date] = updateItemFields(
        newColumns[date],
        column.id,
        omit(column, 'id'),
        'columnId',
      );
    });

    this.setState({ columns: newColumns });
  };

  editColumn = async (column, date) => {
    const { data } = await this.props.api.editColumn(column);
    this.updateColumns([{ ...data[0], date }]);
  };
}

export default function ColumnContextProvider(props) {
  return <ColumnProviderInner api={api} {...props} />;
}
