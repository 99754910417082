import { Trans } from '@lingui/react';
import { Page, PageHeader, PageSection } from 'components/ui/Page';
import { UserConsumer } from 'contexts';
import { Anchor, Box, Button } from 'grommet';
import { Edit } from 'grommet-icons';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import UserList from './UserList';

class Team extends Component {
  static propTypes = {
    adminMode: PropTypes.bool,
    teamMembers: PropTypes.array,
    teamName: PropTypes.string,
  };

  state = {
    sortBy: 'name',
  };

  render() {
    const membersAmount = this.props.teamMembers.length;

    return (
      <Page header={<PageHeader title="Zespół" />}>
        <PageSection title={this.props.teamName}>
          {this.props.adminMode && (
            <div>
              <Trans id="team.user-is-manager" />
            </div>
          )}

          <div>Members: {membersAmount}</div>

          {this.props.adminMode && (
            <div>
              <Link to="/team/manage/reports">
                <Anchor label={<Trans id="team.generate-reports" />} />
              </Link>
            </div>
          )}
        </PageSection>

        <PageSection
          // TODO: get rid of this hack with fill Box in title
          title={
            <Box fill="horizontal" direction="row" justify="between">
              <Trans id="team.members-header" />

              {this.props.adminMode && (
                <Link to="/team/manage">
                  <Button icon={<Edit />} />
                </Link>
              )}
            </Box>
          }
        >
          <UserList users={this.props.teamMembers} />
        </PageSection>
      </Page>
    );
  }

  handleSortBy = sortBy => {
    this.setState({ sortBy });
  };

  getSorted = (array, sortBy) => array.sort((a, b) => a[sortBy] > b[sortBy]);
}

export default function ContextWrapper(props) {
  return (
    <UserConsumer>
      {({ currentTeam, isAdminInCurrentTeam }) => (
        <Team
          adminMode={isAdminInCurrentTeam()}
          teamName={currentTeam.name}
          teamMembers={currentTeam.users}
          {...props}
        />
      )}
    </UserConsumer>
  );
}
