/* eslint-disable react/prop-types */
import { Trans } from '@lingui/react';
import { DropdownButton } from 'components/ui/DropdownButton/DropdownButton';
import { Page, PageHeader, PageSection } from 'components/ui/Page';
import { WarningSubtitle } from 'components/ui/WarningSubtitle';
import { Box } from 'grommet';
import { Calendar, Checkmark, Clock, Menu, Sync } from 'grommet-icons';
import { Loader } from 'page/components/routing';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import { convertStartEndToPrettyDuration, getPrettyDayFromDate } from 'utils/Dates';
import { useIsMobile } from 'utils/responsive';
import { useColumnDay } from './useColumnDay';

function DetailsRow({ icon, children }) {
  return (
    <Box direction="row" gap="16px">
      <Box>{icon}</Box>
      <Box>{children}</Box>
    </Box>
  );
}

const DayName = styled.span`
  text-transform: capitalize;
`;

const UserName = styled(Box)`
  text-overflow: ellipsis;
  max-width: 100%;
  overflow-x: hidden;
`;

export function ColumnDay({ adminMode }) {
  const { addToast } = useToasts();
  const handleColumnChangeError = useCallback(
    message => {
      addToast(message, {
        appearance: 'error',
        autoDismiss: true,
      });
    },
    [addToast],
  );
  const handleColumnChangeSuccess = useCallback(
    message => {
      addToast(message, {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    [addToast],
  );
  const { columnDayId } = useParams();
  const [
    {
      assignUserToShift,
      columnDay,
      error,
      loading,
      removeUserFromShift,
      showEditModal,
      showEditDescriptionModal,
      takeShift,
      giveAway,
      undoGiveAway,
    },
  ] = useColumnDay({
    columnDayId,
    onSuccess: handleColumnChangeSuccess,
    onError: handleColumnChangeError,
  });

  const small = useIsMobile();

  const {
    availableUsers = [],
    date,
    end,
    header,
    isUserGivingAwayShift,
    isUserWorkingOnShift,
    start,
    workingUsers = [],
  } = columnDay;

  const showHeaderMenu = adminMode || isUserWorkingOnShift;
  const headerMenuItems = showHeaderMenu && [
    ...(adminMode
      ? [
          {
            label: <Trans id="shift.menu.edit" />,
            onClick: showEditModal,
          },
        ]
      : []),
    ...(isUserGivingAwayShift
      ? [
          {
            label: <Trans id="shift.menu.undo-give-away" />,
            onClick: undoGiveAway,
          },
        ]
      : isUserWorkingOnShift
        ? [{ label: <Trans id="shift.menu.give-away" />, onClick: giveAway }]
        : []),
  ];

  const duration = convertStartEndToPrettyDuration(start, end);

  return (
    <Loader loading={loading} error={error} pad="none">
      <Page header={<PageHeader backTo="/work/shifts" menuItems={headerMenuItems} />}>
        <Box direction="column">
          <Box margin={{ left: small ? '0px' : '16px' }}>
            <PageSection.Subtitle title={<Trans id="shift.header-subtitle" />} />
          </Box>
          <PageHeader title={header} hasLabel />
        </Box>
        <PageSection full noMargin>
          <Box gap="8px">
            <DetailsRow icon={<Calendar />}>
              <Box gap="6px" direction="row">
                <span>{getPrettyDayFromDate(date, 'DD.MM')},</span>

                <DayName>{getPrettyDayFromDate(date, 'dddd')}</DayName>
              </Box>
            </DetailsRow>

            <DetailsRow icon={<Clock />}>
              {start} - {end} ({duration}
              h)
            </DetailsRow>

            {isUserWorkingOnShift && (
              <DetailsRow icon={<Checkmark />}>
                <Trans id="shift.user-is-working-at-this-shift" />
              </DetailsRow>
            )}
          </Box>
        </PageSection>

        <PageSection title={<Trans id="shift.working-users" />} secondary>
          <Box direction="column" gap="8px" pad={{ vertical: 'small' }}>
            {workingUsers.length === 0 && (
              <WarningSubtitle>
                <Trans id="shift.no-users-working-at-this-shift" />
              </WarningSubtitle>
            )}
            {workingUsers.map(user => (
              <Box key={user.userTeamId} direction="row" justify="between" align="center">
                <UserName direction="row" gap="8px">
                  {user.displayname}{' '}
                  {user.description && <WarningSubtitle>({user.description})</WarningSubtitle>}
                </UserName>

                <Box direction="row" justify="flex-end" gap="8px" align="center">
                  {user.isGivingAway && <Sync size="14px" />}
                  {(adminMode || user.isGivingAway) && (
                    <DropdownButton
                      label={<Menu />}
                      items={[
                        ...(adminMode
                          ? [
                              {
                                label: <Trans id="shift.working-users.remove-from-shift" />,
                                onClick: () => removeUserFromShift(user),
                              },
                              {
                                label: <Trans id="shift-cell.menu.description" />,
                                onClick: () => {
                                  showEditDescriptionModal({
                                    columnDayId,
                                    userTeamId: user.userTeamId,
                                    date: columnDay.date,
                                    description: user.description,
                                  });
                                },
                              },
                            ]
                          : []),
                        ...(user.isGivingAway && !isUserWorkingOnShift
                          ? [
                              {
                                label: <Trans id="shift.working-users.take-shift" />,
                                onClick: () => takeShift(user),
                              },
                            ]
                          : []),
                      ]}
                    />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </PageSection>

        <PageSection title={<Trans id="shift.available-users" />} secondary>
          <Box direction="column" gap="8px" pad={{ vertical: 'small' }}>
            {availableUsers.length === 0 && (
              <WarningSubtitle>
                <Trans id="shift.no-available-users-for-this-shift" />
              </WarningSubtitle>
            )}
            {availableUsers.map(user => (
              <Box key={user.userTeamId} direction="row" justify="between" align="center">
                <UserName>{user.displayname}</UserName>

                {adminMode && (
                  <DropdownButton
                    label={<Menu />}
                    items={[
                      {
                        label: <Trans id="shift.working-users.assign-to-shift" />,
                        onClick: () => assignUserToShift(user),
                      },
                    ]}
                  />
                )}
              </Box>
            ))}
          </Box>
        </PageSection>
        <PageSection full />
      </Page>
    </Loader>
  );
}
