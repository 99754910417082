export const themeRGB = '134, 92, 214';
export const themeColor = `rgb(${themeRGB})`;

export const bodyBackgroundColor = '#f5f5fa';
export const criticalColor = '#ff324d';
export const successColor = '#8cc800';
export const warningColor = '#ffd602;';

export const groupHeightInPx = 35;
export const headerHeightInPx = 62;
export const sidebarWidthInPx = 120;
export const borderRadiusInPx = 10;

export const backgroundColor = '#fafafa';
export const backgroundSecondaryColor = '#f5f5f5';
export const itemBackgroundColor = '#c1f4ca';
export const textColor = '#313131';
export const sidebarColor = themeColor;
export const sidebarTextColor = textColor;
export const navbarTextColor = '#cccccc';
export const navbarActiveTextColor = themeColor;

export const defaultTableItemColor = themeColor;

export const pulsePrimaryColor = `rgba(${themeRGB}, 0.4)`;
export const pulseSecondaryColor = `rgba(${themeRGB}, 0.0)`;
