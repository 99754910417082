import { bodyBackgroundColor, navbarActiveTextColor } from 'config/colors';
import { Anchor, Box, Button, DropButton } from 'grommet';
import { useRef } from 'react';
import styled from 'styled-components';

const NavDropdownDrop = styled(Box)`
  background: #f0f0f0;
  border-radius: 10px;
  padding: 10px;

  color: ${navbarActiveTextColor};
`;

const NavDropButton = styled(DropButton)`
  font-weight: 600;
  color: ${navbarActiveTextColor};

  svg {
    fill: ${navbarActiveTextColor};
  }

  &:hover {
    color: white;
  }

  ${props =>
    props.isActive
      ? `
  background: ${bodyBackgroundColor};
  border-radius: 20px;
`
      : 'none'};

  border-radius: 20px;
  padding: 8px;
`;

const StyledAnchor = styled(Anchor)`
  color: ${navbarActiveTextColor} !important;
`;

// eslint-disable-next-line react/prop-types
export function DropdownButton({ items = [], label }) {
  const dropButtonNode = useRef();

  function handleClick(event) {
    dropButtonNode.current.click();
    dropButtonNode.current.blur();
    event.stopPropagation();
    event.preventDefault();
  }

  return (
    <NavDropButton
      ref={dropButtonNode}
      onClick={event => {
        event.stopPropagation();
      }}
      plain
      label={label}
      dropAlign={{ top: 'bottom', right: 'right' }}
      dropProps={{ plain: true }}
      dropContent={
        <NavDropdownDrop>
          <Box>
            {items.map(({ onClick, label }) => (
              <Button
                plain
                key={label}
                onClick={event => {
                  handleClick(event);
                  onClick(event);
                }}
              >
                <Box pad="small">
                  <StyledAnchor>{label}</StyledAnchor>
                </Box>
              </Button>
            ))}
          </Box>
        </NavDropdownDrop>
      }
    />
  );
}
