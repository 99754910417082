import AdjustmentsBox from 'components/AdjustmentsBox/AdjustmentsBox';
import Modal from 'components/Modal';
import { Grommet } from 'components/Grommet';
import PropTypes from 'prop-types';

const customStyles = {
  overlay: {
    zIndex: 10,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '30vw',
  },
};

export default function AdjustmentsBoxModal({ isOpen, ...props }) {
  return (
    <Modal isOpen={isOpen} styles={customStyles} shouldCloseOnOverlayClick={false}>
      <Grommet>
        <AdjustmentsBox
          {...props}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
        />
      </Grommet>
    </Modal>
  );
}

AdjustmentsBoxModal.propTypes = {
  columns: PropTypes.array,
  day: PropTypes.string,
  header: PropTypes.string,
  isOpen: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
};
