import { Trans } from '@lingui/react';
import facebookApi from 'api/facebook';
import Spinner from 'components/Spinner';
import { messengerNotificationsLink, supportMail } from 'config/supportAndService';
import copyToClipboard from 'copy-to-clipboard';
import { Anchor, Paragraph } from 'grommet';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import ServiceHeader from '../ServiceHeader';
import SetupNotifications from '../SetupNotifications';

export class FacebookNotificationsInner extends Component {
  static propTypes = {
    botLink: PropTypes.string,
    generateToken: PropTypes.func,
    isIntegrated: PropTypes.func,
    onCopyToClipboard: PropTypes.func,
  };

  state = {
    isIntegrated: null,
    loading: true,
  };

  async componentDidMount() {
    const { data: isIntegrated } = await this.props.isIntegrated();

    this.setState({ isIntegrated, loading: false });
  }

  render() {
    return (
      <Fragment>
        <ServiceHeader
          name="Facebook Messenger"
          iconUrl="https://1c7qp243xy9g1qeffp1k1nvo-wpengine.netdna-ssl.com/wp-content/uploads/2016/04/messenger_1.png"
        />
        <Paragraph>
          <Trans id="account.notifications.facebook-messenger.info" />
        </Paragraph>

        {this.state.loading ? (
          <div>
            <Spinner size="small" />
          </div>
        ) : this.state.isIntegrated ? (
          <Fragment>
            <Paragraph>
              <Trans id="account.notifications.facebook-messenger.integrated" />
            </Paragraph>
            <Paragraph>
              <Trans id="account.notifications.facebook-messenger.support-contact" />{' '}
              <Anchor label={supportMail} href={`mailto:${supportMail}`} target="_blank" />.
            </Paragraph>
          </Fragment>
        ) : (
          <SetupNotifications
            botLink={this.props.botLink}
            generateToken={this.props.generateToken}
            onCopyToClipboard={this.props.onCopyToClipboard}
          />
        )}
      </Fragment>
    );
  }
}

export default function FacebookNotifications() {
  return (
    <FacebookNotificationsInner
      botLink={messengerNotificationsLink}
      generateToken={facebookApi.generateToken}
      isIntegrated={facebookApi.isIntegrated}
      onCopyToClipboard={copyToClipboard}
    />
  );
}
