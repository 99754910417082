import styled from 'styled-components';
import { Heading } from 'grommet';

const FullscreenContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Page404() {
  return (
    <FullscreenContainer>
      <Heading level={2}>Not found</Heading>
    </FullscreenContainer>
  );
}
