import { Trans } from '@lingui/react';
import { DateFormField } from 'components/DateFormField';
import { Notification } from 'components/Notification';
import { TimeFormField } from 'components/TimeFormField';
import ModalLayer from 'components/ui/ModalLayer';
import { withFormik } from 'formik';
import { Box, Button, Footer, Form, FormField, Header, TextInput } from 'grommet';
import { I18nContextWrapper } from 'i18n';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useModal } from 'react-modal-hook';
import styled from 'styled-components';
import { convertStartEndToStartDuration } from 'utils/Dates';
import errorMessageHandler from 'utils/errorMessageHandler';

const ButtonContainer = styled.div`
  padding: 0px 5px;
`;

const EditColumnFormInner = props => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    onClose,
    status,
    touched,
    values,
  } = props;

  return (
    <Box
      id="edit-column-modal"
      fill="horizontal"
      justify="center"
      align="center"
      responsive
      pad="medium"
    >
      <Form>
        <Header>
          <h2>
            <Trans id="edit-column-modal.header" />{' '}
          </h2>
        </Header>
        <FormField
          label={<Trans id="edit-column-modal.form.header" />}
          error={touched.header && errors.header}
        >
          <TextInput
            id="edit-column-modal-header-input"
            value={values.header}
            name="header"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormField>

        <FormField
          label={<Trans id="edit-column-modal.form.date" />}
          className="rota-input-disabled"
        >
          <DateFormField
            id="edit-column-modal-date-input"
            format="DD-MM-YYYY"
            value={values.date}
            disabled
          />
        </FormField>

        <FormField
          label={<Trans id="edit-column-modal.form.from" />}
          error={touched.start && errors.start}
        >
          <TimeFormField
            id="edit-column-modal-start-input"
            name="start"
            format="H:mm"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.start}
          />
        </FormField>

        <FormField
          label={<Trans id="edit-column-modal.form.to" />}
          error={touched.end && errors.end}
        >
          <TimeFormField
            id="edit-column-modal-end-input"
            name="end"
            format="H:mm"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.end}
          />
        </FormField>
        <Footer>
          <Box direction="column" fill="horizontal">
            <Box justify="center" align="center" direction="row" pad="large" responsive={false}>
              <ButtonContainer>
                <Button
                  id="edit-column-modal-save-button"
                  box
                  size="xsmall"
                  label={<Trans id="edit-column-modal.form.save" />}
                  primary={true}
                  onClick={isSubmitting ? null : handleSubmit}
                />
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  box
                  size="xsmall"
                  label={<Trans id="edit-column-modal.form.close" />}
                  onClick={isSubmitting ? null : onClose}
                />
              </ButtonContainer>
            </Box>
            {status && <Notification message={status} size="medium" status="critical" />}
          </Box>
        </Footer>
      </Form>
    </Box>
  );
};

EditColumnFormInner.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  header: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onLogin: PropTypes.func,
  setFieldValue: PropTypes.func,
  status: PropTypes.any,
  teamName: PropTypes.string,
  touched: PropTypes.object,
  values: PropTypes.object,
};

const EditColumnForm = withFormik({
  mapPropsToValues: props => ({
    ...props.columnDay,
  }),
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { header, start, end, columnId, date } = values;
    const { onClose } = props;

    const { start: requestStart, duration } = convertStartEndToStartDuration(start, end);

    try {
      setStatus(null);

      const requestData = {
        duration,
        header,
        id: columnId,
        start: requestStart,
      };

      await props.onEdit(requestData, date);
      setStatus(null);
      setSubmitting(false);

      onClose();
    } catch (exception) {
      setStatus(errorMessageHandler(exception));
      setSubmitting(false);
    }
  },
  validate: values => {
    const errors = {};

    if (!values.header || values.header === '') {
      errors['header'] = 'Required!';
    }

    if ((values.hasDate && !values.date) || values.date === '') {
      errors['date'] = 'Required!';
    }

    // TODO: Add validation of date here
    if (!values.start || values.start === '') {
      errors['start'] = 'Required!';
    }

    if (!values.end || values.end === '') {
      errors['end'] = 'Required!';
    }

    return errors;
  },
})(EditColumnFormInner);

export default function EditColumnModal({ columnDay, day, onEdit, onClose, isOpen }) {
  return isOpen ? (
    <ModalLayer onEsc={onClose}>
      <EditColumnForm columnDay={columnDay} day={day} onEdit={onEdit} onClose={onClose} />
    </ModalLayer>
  ) : null;
}

export function useEditColumnModal({ columnDay, day, onEdit, onClose }) {
  const [showModal, hideModal] = useModal(
    () => (
      <ModalLayer onEsc={hideModal}>
        <I18nContextWrapper>
          <EditColumnForm
            columnDay={columnDay}
            day={day}
            onEdit={onEdit}
            onClose={(...args) => {
              if (onClose) {
                onClose(...args);
              }
              hideModal();
            }}
          />
        </I18nContextWrapper>
      </ModalLayer>
    ),
    [columnDay, day, onEdit, onClose],
  );

  return [showModal, hideModal];
}

export function useEditColumnModalWithParams({ columnDay: initialColumnDay, onColumnEdit }) {
  const [columnDay, setColumnDay] = useState(initialColumnDay);
  const [showModal, hideModal] = useModal(
    () => (
      <I18nContextWrapper>
        <EditColumnModal isOpen columnDay={columnDay} onClose={hideModal} onEdit={onColumnEdit} />
      </I18nContextWrapper>
    ),
    [columnDay],
  );

  const handleShowModal = useCallback(
    _columnDay => {
      if (_columnDay) {
        setColumnDay(_columnDay);
      }
      // setTimeout(() => {

      showModal();
      // }, 0)
    },
    [setColumnDay],
  );

  return [handleShowModal];
}

EditColumnModal.propTypes = {
  columnDay: PropTypes.object,
  day: PropTypes.string,
  header: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
};
