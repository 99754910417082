import { backgroundColor, backgroundSecondaryColor } from 'config/colors';
import { FormCheckmark, FormClose, FormDown, FormPrevious } from 'grommet-icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../itemCellColors';

const Wrapper = styled.div`
  text-align: right;
  margin: 2px 0px;
`;

const BaseButton = styled.button`
  border: none;
  margin: 0;
  padding: 5px;
`;

const AcceptButton = styled(BaseButton)`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  background: ${colors.foreground.YES};

  &:hover {
    cursor: pointer;
    background: ${colors.background.YES};
  }
`;

const DeclineButton = styled(BaseButton)`
  background: ${colors.foreground.NO};

  border-top-right-radius: ${props => (props.borderedRight ? '5px' : 0)};
  border-bottom-right-radius: ${props => (props.borderedRight ? '5px' : 0)};

  &:hover {
    cursor: pointer;
    background: ${colors.background.NO};
  }
`;

const ExpandButton = styled(BaseButton)`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  background: ${backgroundSecondaryColor};

  &:hover {
    cursor: pointer;
    background: ${backgroundColor};
  }
`;

export default function ControlsBox({ expandDisabled, onAccept, onDecline, onExpand, isExpanded }) {
  return (
    <Wrapper>
      <AcceptButton onClick={onAccept}>
        <FormCheckmark />
      </AcceptButton>
      <DeclineButton onClick={onDecline}>
        <FormClose />
      </DeclineButton>
      <ExpandButton onClick={onExpand} disabled={expandDisabled}>
        {isExpanded ? <FormPrevious /> : <FormDown />}
      </ExpandButton>
    </Wrapper>
  );
}

ControlsBox.propTypes = {
  expandDisabled: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  onExpand: PropTypes.func,
};

export function ItemControlsBox({ onAccept, onDecline }) {
  return (
    <Wrapper>
      <AcceptButton onClick={onAccept}>
        <FormCheckmark />
      </AcceptButton>
      <DeclineButton onClick={onDecline} borderedRight>
        <FormClose />
      </DeclineButton>
    </Wrapper>
  );
}

ItemControlsBox.propTypes = {
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
};
