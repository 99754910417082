import { Trans } from '@lingui/react';
import { ItemRow } from 'components/ui/ItemsCalendar/Item';
import { UserContext } from 'contexts/User/UserContext';
import { Box, Button } from 'grommet';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import errorMessageHandler from 'utils/errorMessageHandler';
import { ShiftBase } from './ShiftBase';
import { get } from 'lodash';
import shiftApi from 'contexts/Shift/api';

function ShiftToGiveAwayComponent({ onUndoGiveAway, shift }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleUndoGiveAway = useCallback(async event => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    await onUndoGiveAway();
    setIsLoading(false);
  }, []);

  return (
    <ShiftBase {...shift} disabled={isLoading}>
      <ItemRow>
        <Box direction="row" justify="end" align="end">
          <Button
            primary
            label={<Trans id="shift-cell.menu.undo" />}
            size="small"
            disabled={isLoading}
            onClick={handleUndoGiveAway}
          />
        </Box>
      </ItemRow>
    </ShiftBase>
  );
}

// eslint-disable-next-line react/prop-types
export function ShiftToGiveAway({ shift: propsShift, onRefresh }) {
  const { currentUserTeamId, getUser } = useContext(UserContext);
  const { addToast } = useToasts();
  const shift = useMemo(() => ({ ...propsShift, user: getUser(propsShift.userTeamId) }), [
    propsShift.userTeamId,
  ]);
  const isUserShift = shift.userTeamId === currentUserTeamId;

  const handleUndoGiveAway = useCallback(
    async () => {
      try {
        await shiftApi.undoGiveAwayShift(currentUserTeamId, shift.columnDayId);

        addToast(
          <Trans
            id="work.shifts-undo-give-away.succesfully"
            values={{
              shift: get(shift, 'column.header', '?'),
              start: get(shift, 'column.start'),
              date: shift.date,
            }}
          />,
          {
            appearance: 'success',
            autoDismiss: true,
          },
        );

        await onRefresh();
      } catch (exception) {
        const errorMessage = errorMessageHandler(exception);
        addToast(errorMessage, {
          appearance: 'error',
          autoDismiss: true,
        });
      }

      return true;
    },
    [shift, currentUserTeamId, addToast],
  );

  return (
    <ShiftToGiveAwayComponent
      onUndoGiveAway={handleUndoGiveAway}
      shift={shift}
      isUserShift={isUserShift}
    />
  );
}
