import { ResponsiveContext } from 'grommet';
import { useContext, useMemo } from 'react';

export function useIsMobile() {
  const size = useContext(ResponsiveContext);

  const isMobile = useMemo(() => size === 'small', [size]);

  return isMobile;
}

export function useScreenSize() {
  return useContext(ResponsiveContext);
}

export function getScreenSizeToDaySpan(screenSize) {
  switch (screenSize) {
    case 'small':
      return 1;
    case 'medium':
      return 3;
    case 'large':
      return 7;
    default:
      return 3;
  }
}
