import { nth } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { getCurrentMonth, getDaysWithExtrasForMonth, getRelativeMonth } from './Dates';

export function useDateFromToQueryParams({ initialDates }) {
  const initialRender = useRef(true);
  const [{ dateFrom, dateTo }, setQuery] = useQueryParams({
    dateFrom: StringParam,
    dateTo: StringParam,
  });

  useEffect(() => {
    if (initialDates) {
      setQuery(initialDates, 'replace');
    }

    if (initialRender.current) {
      initialRender.current = false;
    }
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleMoveForward = useCallback(days => {
    throw new Error('Not yet implemented');
  });

  // eslint-disable-next-line no-unused-vars
  const handleMoveBackward = useCallback(days => {
    throw new Error('Not yet implemented');
  });

  return {
    ...(initialRender.current
      ? !(dateFrom && dateTo)
        ? initialDates
        : { dateFrom, dateTo }
      : { dateFrom, dateTo }),
    onMoveForward: handleMoveForward,
    onMoveBackward: handleMoveBackward,
  };
}

function getExtrasFromMonth(month) {
  const days = getDaysWithExtrasForMonth(month);
  return {
    extendedDateFrom: days[0],
    extendedDateTo: nth(days, -1),
  };
}

export function useMonthQueryParam({ initialMonth } = { initialMonth: getCurrentMonth() }) {
  const initialRender = useRef(true);
  const [month, setMonth] = useQueryParam('month', StringParam);
  const [extras, setExtras] = useState(getExtrasFromMonth(month || initialMonth));

  useEffect(() => {
    if (initialMonth && !month) {
      setMonth(initialMonth, 'replace');
    }

    if (initialRender.current) {
      initialRender.current = false;
    }
  }, []);

  useEffect(
    () => {
      const validMonth = month || getCurrentMonth();
      setMonth(validMonth);
      setExtras(getExtrasFromMonth(validMonth));
    },
    [month, setExtras, setMonth],
  );

  const handleMoveBackward = useCallback(() => {
    setMonth(_month => getRelativeMonth(_month, -1));
  });

  const handleMoveForward = useCallback(() => {
    setMonth(_month => getRelativeMonth(_month, 1));
  });

  return {
    ...(initialRender.current ? (!month ? initialMonth : { month }) : { month }),
    dateFrom: extras.extendedDateFrom,
    dateTo: extras.extendedDateTo,
    onMoveForward: handleMoveForward,
    onMoveBackward: handleMoveBackward,
  };
}
