import { i18nMark } from '@lingui/react';
import analytics from 'analytics';
import { Box, Button, Heading } from 'grommet';
import { Close } from 'grommet-icons';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { Component } from 'react';
import translatedMessage from 'translatedMessage';
import AdjustmentRow from './AdjustmentRow';

export default class AdjustmentsBox extends Component {
  static propTypes = {
    adjustmentMinutes: PropTypes.number,
    column: PropTypes.object,
    isManager: PropTypes.bool,
    onAdjustmentAccept: PropTypes.func,
    onAdjustmentReject: PropTypes.func,
    onAdjustmentRemove: PropTypes.func,
    onAdjustmentSave: PropTypes.func,
    onAdjustmentSuggestion: PropTypes.func,
    onAdjustmentSuggestionRemove: PropTypes.func,
    onClose: PropTypes.func,
    suggestedAdjustmentMinutes: PropTypes.number,
  };

  state = {};

  render() {
    const { column, suggestedAdjustmentMinutes, adjustmentMinutes } = this.props;
    const showAdjustmentMinutes = typeof adjustmentMinutes === 'number';

    const { header, start, end, date } = column;
    return (
      <div>
        <div style={{ marginBottom: '18px' }}>
          <Box direction="row" align="center" justify="between">
            <Heading level={3} strong style={{ marginBottom: 0 }}>
              {header}
            </Heading>
            <Button icon={<Close />} onClick={this.props.onClose} />
          </Box>
          <Heading level={5}>
            {start} - {end}, {date}
          </Heading>
        </div>

        {showAdjustmentMinutes && (
          <AdjustmentRow {...this.getAcceptedAdjustmentRowData()} onClose={this.props.onClose} />
        )}
        {suggestedAdjustmentMinutes && (
          <AdjustmentRow {...this.getSuggestedAdjustmentRowData()} onClose={this.props.onClose} />
        )}
        <AdjustmentRow {...this.getNewAdjustmentRowData()} onClose={this.props.onClose} />
      </div>
    );
  }

  getAcceptedAdjustmentRowData = () => {
    i18nMark('adjustments-box.adjustment'); //adjustment
    i18nMark('adjustments-box.remove');

    const rowData = {
      header: translatedMessage('adjustments-box.adjustment'),
      initialDuration: this.props.adjustmentMinutes,
      readOnly: true,
    };

    if (this.props.isManager) {
      rowData['buttons'] = [
        { text: translatedMessage('adjustments-box.remove'), onClick: this.handleAdjustmentRemove },
      ];
    }

    return rowData;
  };

  getSuggestedAdjustmentRowData = () => {
    i18nMark('adjustments-box.user-suggested-adjustment'); //'User suggested adjustment'
    i18nMark('adjustments-box.you-suggested-adjustment'); //'',
    i18nMark('adjustments-box.remove');
    i18nMark('forms.accept');
    i18nMark('forms.reject');

    if (this.props.isManager) {
      return {
        header: translatedMessage('adjustments-box.user-suggested-adjustment'),
        initialDuration: this.props.suggestedAdjustmentMinutes,
        buttons: [
          { text: translatedMessage('forms.accept'), onClick: this.handleAdjustmentAccept },
          { text: translatedMessage('forms.reject'), onClick: this.handleAdjustmentReject },
        ],
        readOnly: true,
      };
    } else {
      return {
        header: translatedMessage('adjustments-box.you-suggested-adjustment'),
        initialDuration: this.props.suggestedAdjustmentMinutes,
        buttons: [
          {
            text: translatedMessage('adjustments-box.remove'),
            onClick: this.handleSuggestionRemove,
          },
        ],
        readOnly: true,
      };
    }
  };

  getNewAdjustmentRowData = () => {
    i18nMark('adjustments-box.make-an-adjustment');
    i18nMark('adjustments-box.suggest-adjustment');
    i18nMark('adjustments-box.save');
    i18nMark('adjustments-box.suggest');

    if (this.props.isManager) {
      return {
        header: translatedMessage('adjustments-box.make-an-adjustment'),
        buttons: [
          { text: translatedMessage('adjustments-box.save'), onClick: this.handleAdjustmentSave },
        ],
      };
    } else {
      return {
        header: translatedMessage('adjustments-box.suggest-adjustment'),
        buttons: [
          {
            text: translatedMessage('adjustments-box.suggest'),
            onClick: this.handleSuggestion,
          },
        ],
      };
    }
  };

  handleAdjustmentSave = adjustment => {
    const shiftParams = this._getShiftParams();
    analytics.track({ category: 'Adjustment suggestions', action: 'Save', label: 'Box' });
    this.props.onAdjustmentSave(shiftParams, adjustment);
  };

  handleAdjustmentAccept = () => {
    const shiftParams = this._getShiftParams();
    analytics.track({ category: 'Adjustment suggestions', action: 'Accept', label: 'Box' });
    return this.props.onAdjustmentAccept(shiftParams);
  };

  handleAdjustmentReject = () => {
    const shiftParams = this._getShiftParams();
    analytics.track({ category: 'Adjustment suggestions', action: 'Reject', label: 'Box' });
    return this.props.onAdjustmentReject(shiftParams);
  };

  handleAdjustmentRemove = () => {
    const shiftParams = this._getShiftParams();
    analytics.track({ category: 'Adjustment suggestions', action: 'Remove', label: 'Box' });
    return this.props.onAdjustmentRemove(shiftParams);
  };

  handleSuggestion = adjustment => {
    const shiftParams = this._getShiftParams();
    analytics.track({ category: 'Adjustment suggestions', action: 'Suggest', label: 'Box' });
    return this.props.onAdjustmentSuggestion(shiftParams, adjustment);
  };

  handleSuggestionRemove = () => {
    const shiftParams = this._getShiftParams();
    analytics.track({
      category: 'Adjustment suggestions',
      action: 'Remove suggestion',
      label: 'Box',
    });
    return this.props.onAdjustmentSuggestionRemove(shiftParams);
  };

  _getShiftParams = () => pick(this.props.shift, 'userTeamId', 'columnDayId', 'date');
}
