import { Component } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import moment from 'moment';

import {
  getItemsArrayFromTo,
  getColumnsFromTo,
  create2DTable,
  getDeadlinesForDays,
} from 'components/RotaTable/ItemModule';
import { getDeadlinesFromTo } from 'contexts/selectors';
import { sortUsersByDisplayNameOrEmail } from './ItemModule/itemModule';

export default class RotaTableFilter extends Component {
  static propTypes = {
    backgroundItems: PropTypes.any,
    calculateSums: PropTypes.bool,
    children: PropTypes.any,
    columns: PropTypes.any,
    currentUser: PropTypes.any,
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    deadlines: PropTypes.array,
    items: PropTypes.any.isRequired,
    users: PropTypes.any.isRequired,
  };

  // TODO: memoize each param?
  tableFilter = memoize(
    (
      users,
      items,
      columns,
      dateFrom,
      dateTo,
      backgroundItems,
      deadlines,
      calculateSums,
      currentUser,
    ) => {
      const sortedUsers = currentUser
        ? [
            currentUser,
            ...users
              .filter(user => user.userTeamId !== currentUser.userTeamId)
              .sort(sortUsersByDisplayNameOrEmail),
          ]
        : users.sort(sortUsersByDisplayNameOrEmail);

      // TODO: replace state callback with just arguments?
      const columnsFromTo = getColumnsFromTo(dateFrom, dateTo)({ columns });
      const itemsFromTo = getItemsArrayFromTo(dateFrom, dateTo)({ items });
      const backgroundItemsFromTo = getItemsArrayFromTo(dateFrom, dateTo)({
        items: backgroundItems,
      });

      const today = moment().format('YYYY-MM-DD');
      const deadlinesFromTo = getDeadlinesFromTo(today, dateFrom, dateTo)({ deadlines });
      const deadlinesLookup = getDeadlinesForDays(today, dateFrom, dateTo, deadlinesFromTo);

      const { table, reverseColumnLookup, sums } = create2DTable(
        sortedUsers,
        columnsFromTo,
        itemsFromTo,
        backgroundItemsFromTo,
        calculateSums,
      );

      return {
        table,
        columns: columnsFromTo,
        reverseColumnLookup,
        deadlinesLookup,
        sums,
        users: sortedUsers,
      };
    },
  );

  render() {
    const {
      backgroundItems,
      calculateSums,
      columns,
      dateFrom,
      dateTo,
      deadlines,
      items,
      users,
      currentUser,
    } = this.props;
    const {
      columns: columnsFromTo,
      reverseColumnLookup,
      table,
      deadlinesLookup,
      sums,
      users: filteredUsers,
    } = this.tableFilter(
      users,
      items,
      columns,
      dateFrom,
      dateTo,
      backgroundItems,
      deadlines,
      calculateSums,
      currentUser,
    );

    const getColumnData = columnIndex => reverseColumnLookup[columnIndex];

    return this.props.children({
      columns: columnsFromTo,
      deadlinesLookup,
      getColumnData,
      sums,
      table,
      users: filteredUsers,
    });
  }
}
