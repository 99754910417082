import { getDateKey } from 'utils/Dates';
import get from 'lodash/get';

export const getDeadlinesFromTo = (today, from, to) => state =>
  state.deadlines.filter(deadline => deadline.periodEnd >= from && deadline.periodStart <= to);

export const getTakeableShifts = (shifts, columns, users) => {
  if (Object.values(columns).length === 0 || users.length === 0) {
    return [];
  }

  const takeableShifts = [];
  Object.values(shifts).map(dayShifts =>
    takeableShifts.push(...Object.values(dayShifts).filter(shift => shift.exchangeable)),
  );

  return takeableShifts.map(shift => {
    const user = users.find(_user => _user.userTeamId === shift.userTeamId);

    const dateKey = getDateKey(shift.date);
    const column = get(columns, dateKey, []).find(column => column.id === shift.columnDayId);

    return {
      ...shift,
      userName: user.displayname,
      columnHeader: column.header,
    };
  });
};

// TODO: extract common logic from this and one above (getTakeableShifts)
export const getShiftsWithPendingAdjustments = (shifts, columns, users) => {
  if (Object.values(columns).length === 0 || users.length === 0) {
    return [];
  }

  const shiftsWithAdjustments = [];
  Object.values(shifts).map(dayShifts =>
    shiftsWithAdjustments.push(
      ...Object.values(dayShifts).filter(shift => Boolean(shift.suggestedAdjustmentMinutes)),
    ),
  );

  return shiftsWithAdjustments.map(shift => {
    const user = users.find(_user => _user.userTeamId === shift.userTeamId);

    const dateKey = getDateKey(shift.date);
    const column = get(columns, dateKey, []).find(column => column.id === shift.columnDayId);

    return {
      ...shift,
      userName: user.displayname,
      columnHeader: column.header,
      start: column.start,
      end: column.end,
    };
  });
};

export const getColumnsFromColumnDays = columnDays => {
  const columns = {};

  Object.entries(columnDays).forEach(([date, columnDaysArray]) => {
    columns[date] = columnDaysArray.map(columnDay => ({
      ...columnDay,
      id: columnDay.columnId,
      columnDayId: columnDay.id,
    }));
  });

  return columns;
};
