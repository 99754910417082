import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Box } from 'grommet';
import Toast from 'components/Toast';

import analytics from 'analytics';
import PendingAdjustmentsRow from './PendingAdjustmentsRow';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

import { Component } from 'react';
import { getItemCompositeId } from 'contexts/utils';
import errorMessageHandler from 'utils/errorMessageHandler';
import { Trans } from '@lingui/react';

export default class PendingExchanges extends Component {
  static propTypes = {
    adjustments: PropTypes.array,
    currentUserId: PropTypes.any,
    onAccept: PropTypes.func,
    onReject: PropTypes.func,
  };

  state = {
    error: null,
  };

  render() {
    return (
      <Box>
        {this.state.error && (
          <Toast status="critical">
            <Trans id="error" />: {this.state.error}
          </Toast>
        )}
        <h4>
          <Trans id="pending-adjustments.header" />
        </h4>
        <Wrapper>
          {this.props.adjustments.map(adjustment => (
            <PendingAdjustmentsRow
              key={getItemCompositeId(adjustment)}
              adjustment={adjustment}
              onAccept={this.handleAccept}
              onReject={this.handleReject}
            />
          ))}
        </Wrapper>
      </Box>
    );
  }

  handleAccept = async exchange => {
    this.setState({ error: null });

    try {
      await this.props.onAccept(exchange, this.props.currentUserId);
      analytics.track({ category: 'Adjustment suggestions', action: 'Accept', label: 'List' });
    } catch (error) {
      this.setState({ error: errorMessageHandler(error) });
      throw error;
    }
  };

  handleReject = async exchange => {
    this.setState({ error: null });

    try {
      await this.props.onReject(exchange, this.props.currentUserId);
      analytics.track({ category: 'Adjustment suggestions', action: 'Reject', label: 'List' });
    } catch (error) {
      this.setState({ error: errorMessageHandler(error) });
      throw error;
    }
  };
}
