import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tooltip from 'reactstrap/lib/Tooltip';
import { backgroundColor } from 'config/colors';
import { getItemCompositeId } from 'contexts/utils';
import { getDateKey } from 'utils/Dates';
import ControlsBox, { ItemControlsBox } from 'components/ChangeRequestsTable/ControlsBox';

const Row = styled.tr`
  background: ${backgroundColor};

  & + & {
    border-top: 2px solid #f5f5f5;
  }
`;

const AvailabilityChange = styled.span`
  color: ${props => (props.newAvailable ? 'green' : 'red')};
  margin-left: 5px;

  & + & {
    margin-left: 6px;

    ::before {
      color: #363636;
      content: '•';
      margin-right: 6px;
    }
  }
`;

export default class ChangeRequestRow extends Component {
  static propTypes = {
    changeRequests: PropTypes.array,
    date: PropTypes.string,
    getColumn: PropTypes.func,
    getUser: PropTypes.func,
    onAccept: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    user: PropTypes.any,
  };

  state = {
    isOpen: false,
    error: null,
  };

  static defaultProps = {
    changeRequests: [],
  };

  errorMessageTimeoutId = null;

  componentWillUnmount() {
    this._clearErrorMessageTimeout();
  }

  render() {
    const dropdownDisabled = this.props.changeRequests.length <= 1;
    const userName = this.props.getUser(this.props.user).displayname;

    const tooltipTarget = `change-request-action-tooltip-${this.props.user}-${this.props.date}`;

    return (
      <Fragment>
        <Row id={tooltipTarget}>
          <td>{userName}</td>
          <td style={{ minWidth: '90px' }}>{getDateKey(this.props.date)}</td>
          <td>
            {this.props.changeRequests.map(changeRequest => (
              <AvailabilityChange
                key={getItemCompositeId(changeRequest)}
                newAvailable={changeRequest.newAvailable}
              >
                {changeRequest.columnHeader}
              </AvailabilityChange>
            ))}
          </td>

          <td style={{ minWidth: '110px' }}>
            <ControlsBox
              onAccept={() => this.handleAccept(this.props.changeRequests)}
              onDecline={() => this.handleDecline(this.props.changeRequests)}
              onExpand={() => this.setState({ isOpen: !this.state.isOpen })}
              expandDisabled={dropdownDisabled}
              isExpanded={this.state.isOpen}
            />
          </td>
        </Row>
        {this.state.isOpen &&
          this.props.changeRequests.map(changeRequest => (
            <tr key={getItemCompositeId(changeRequest)} style={{ borderTop: '2px solid #f5f5f5' }}>
              <td />
              <td />
              <td>
                <AvailabilityChange newAvailable={changeRequest.newAvailable} border>
                  {changeRequest.columnHeader}
                </AvailabilityChange>
              </td>
              <td style={{ minWidth: '72px' }}>
                <ItemControlsBox
                  onAccept={() => this.handleAccept([changeRequest])}
                  onDecline={() => this.handleDecline([changeRequest])}
                />
              </td>
            </tr>
          ))}
        {this.state.error && (
          <Tooltip target={tooltipTarget} isOpen placement="right">
            {this.state.error}
          </Tooltip>
        )}
      </Fragment>
    );
  }

  handleAccept = changeRequests => {
    this._handleHelper(this.props.onAccept(changeRequests));
  };

  handleDecline = changeRequests => {
    this._handleHelper(this.props.onDecline(changeRequests));
  };

  _handleHelper = async request => {
    try {
      await request;
    } catch (exception) {
      const errorMessage = exception.message || exception.error;
      this.setState({ error: errorMessage });
    } finally {
      this._clearErrorMessageTimeout();
      this.errorMessageTimeoutId = setTimeout(() => {
        this.setState({ error: null });
      }, 3000);
    }
  };

  _clearErrorMessageTimeout = () => {
    if (this.errorMessageTimeoutId) {
      clearTimeout(this.errorMessageTimeoutId);
    }
  };
}
