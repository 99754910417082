import { MaskedInput } from 'grommet';
import { forwardRef } from 'react';

function getYearOptions() {
  const currentYear = parseInt(new Date().toISOString().substring(0, 4), 10);

  return new Array(4).fill(0).map((_, index) => currentYear - index);
}

export const MonthFormField = forwardRef(({ value, onChange, onBlur, ...props }, ref) => (
  <MaskedInput
    ref={ref}
    mask={[
      {
        length: [1, 2],
        regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
        options: new Array(12).fill(0).map((_, index) => index + 1),
        placeholder: 'MM',
      },
      { fixed: '/' },
      {
        length: 4,
        regexp: /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
        options: getYearOptions(),
        placeholder: 'YYYY',
      },
    ]}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    {...props}
  />
));
