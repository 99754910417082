import { i18nMark, Trans } from '@lingui/react';
import Toast from 'components/Toast';
import ModalLayer from 'components/ui/ModalLayer';
import { withFormik } from 'formik';
import { Box, Button, Footer, Form, FormField, Header, Select, TextInput } from 'grommet';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import translatedMessage from 'translatedMessage';
import errorMessageHandler from 'utils/errorMessageHandler';

i18nMark('user-type.employee');
i18nMark('user-type.manager');

const ButtonContainer = styled.div`
  padding: 0px 5px;
`;

const AddTeamMemberFormInner = props => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    onClose,
    setFieldValue,
    status,
    touched,
    values,
  } = props;

  return (
    <Form>
      {get(status, 'type') === 'error' && <Toast status="critical">Error: {status.message}</Toast>}
      <Header>
        <Box horizontal="full" direction="column" justify="center">
          <h2>
            <Trans id="add-user-modal.header" />
          </h2>
        </Box>
      </Header>
      <FormField label={<Trans id="forms.email" />} error={touched.email && errors.email}>
        <TextInput value={values.email} name="email" onChange={handleChange} onBlur={handleBlur} />
      </FormField>

      <FormField label={<Trans id="forms.role" />} error={touched.role && errors.role}>
        <Select
          options={[
            {
              value: 'employee',
              label: translatedMessage('user-type.employee'),
            },
            {
              value: 'manager',
              label: translatedMessage('user-type.manager'),
            },
          ]}
          labelKey="label"
          valueKey="value"
          value={values.role}
          onBlur={handleBlur}
          onChange={({ value }) => setFieldValue(`role`, value, true)}
        />
      </FormField>
      <Footer>
        <Box justify="center" align="center" direction="row" fill="horizontal" pad="large">
          <ButtonContainer>
            <Button label="Save" primary={true} onClick={isSubmitting ? null : handleSubmit} />
          </ButtonContainer>
          <ButtonContainer>
            <Button label="Cancel" onClick={isSubmitting ? null : onClose} />
          </ButtonContainer>
        </Box>
      </Footer>
    </Form>
  );
};

AddTeamMemberFormInner.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onLogin: PropTypes.func,
  setFieldValue: PropTypes.func,
  status: PropTypes.any,
  touched: PropTypes.object,
  values: PropTypes.object,
};

const AddTeamMemberForm = withFormik({
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const {
      email,
      role: { value: role },
    } = values;
    const { onSave, onClose } = props;

    const user = { email, admin: role === 'manager' };

    try {
      setStatus({});
      await onSave(user);
      setStatus({});
      setSubmitting(false);

      onClose();
    } catch (error) {
      setStatus({ type: 'error', message: errorMessageHandler(error) });
      setSubmitting(false);
    }
  },
  validate: values => {
    const errors = {};

    if (!values.email || values.email === '') {
      errors['email'] = <Trans id="forms.required" />;
    }

    if (!values.role || values.role === '') {
      errors['role'] = <Trans id="forms.required" />;
    }

    return errors;
  },
})(AddTeamMemberFormInner);

export default function AddChangeRequestModal({ onClose, onSave }) {
  return (
    <ModalLayer>
      <Box pad="medium">
        <AddTeamMemberForm onSave={onSave} onClose={onClose} />
      </Box>
    </ModalLayer>
  );
}

AddChangeRequestModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};
