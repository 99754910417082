export const DEADLINE_STATES = {
  AFTER_ALREADY: 2,
  AFTER_NOT_YET: 1,
  BEFORE_NOT_YET: null,
};

export const DEADLINE_STATE_TO_ICON_NAME = {
  [DEADLINE_STATES.AFTER_ALREADY]: 'lock',
  [DEADLINE_STATES.AFTER_NOT_YET]: 'lock_open',
  [DEADLINE_STATES.BEFORE_NOT_YET]: null,
};
