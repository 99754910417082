import { Component } from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';

import analytics from 'analytics';
import Table from './Table';
import TableHeader from './TableHeader';
import { getDayDifference } from 'utils/Dates';

const TableContainer = styled.div`
  padding: 10px;

  @media (max-width: 719px) {
    padding: 0px 0px 10px 0px;
  }
`;

export default class RotaTable extends Component {
  static propTypes = {
    columns: PropTypes.any,
    currentUser: PropTypes.object,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    deadlines: PropTypes.any,
    getBackgroundItemCellColor: PropTypes.func,
    getColumnData: PropTypes.func,
    getForegroundItemCellColor: PropTypes.func,
    hasUnsavedChanges: PropTypes.bool,
    modifyHandler: PropTypes.func,
    onCanvasClick: PropTypes.func,
    onItemClick: PropTypes.func,
    onMoveDate: PropTypes.func,
    onSave: PropTypes.func,
    onSetDateSpan: PropTypes.func,
    renderCanvas: PropTypes.func,
    renderItem: PropTypes.func,
    renderUser: PropTypes.func,
    sums: PropTypes.array,
    table: PropTypes.any,
    users: PropTypes.any,
  };

  state = {};

  static defaultProps = {
    onCanvasClick: noop,
    onItemClick: noop,
    onSave: noop,
  };

  render() {
    const { columns, deadlines, table, users, getColumnData, sums, currentUser } = this.props;

    return (
      <TableContainer>
        <TableHeader
          hasUnsavedChanges={this.props.hasUnsavedChanges}
          onJumpToDate={this.handleJumpToDate}
          onMoveDate={this.handleMoveDate}
          onSave={this.props.onSave}
          onSetDateSpan={this.props.onSetDateSpan}
        />
        <Table
          columns={columns}
          currentUser={currentUser}
          deadlines={deadlines}
          getBackgroundItemCellColor={this.props.getBackgroundItemCellColor}
          getColumnData={getColumnData}
          getForegroundItemCellColor={this.props.getForegroundItemCellColor}
          modifyHandler={this.props.modifyHandler}
          onCanvasClick={this.props.onCanvasClick}
          onItemClick={this.props.onItemClick}
          renderCanvas={this.props.renderCanvas}
          renderItem={this.props.renderItem}
          renderUser={this.props.renderUser}
          sums={sums}
          table={table}
          users={users}
        />
      </TableContainer>
    );
  }

  handleJumpToDate = async date => {
    const daysDiff = Math.floor(getDayDifference(this.props.dateFrom, date));
    await this.props.onMoveDate(daysDiff);
    analytics.track({ category: 'RotaTable navigation', action: 'Jump to date', value: daysDiff });
  };

  handleMoveDate = offset => {
    const visibleDays = 1 + getDayDifference(this.props.dateFrom, this.props.dateTo);
    this.props.onMoveDate(visibleDays * offset);
    analytics.track({ category: 'RotaTable navigation', action: 'Move date', value: offset });
  };
}
