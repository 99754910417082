import PropTypes from 'prop-types';
import { Fragment } from 'react';

import styled from 'styled-components';
import { Notification } from 'components/Notification';

import Spinner from 'components/Spinner';

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;

  background: white;
  z-index: 100;
  text-align: center;

  animation: fadein 0.125s linear 1;

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  margin-top: 1.5rem;
  font-size: 1.5rem;
`;

export default function FullscreenLoader({ children, text, error }) {
  return (
    <Wrapper>
      <Content>
        {error ? (
          <Fragment>
            <Notification message={error} size="medium" status="critical" />
            {children}
          </Fragment>
        ) : (
          <Fragment>
            <Spinner size="128px" />
            <Text>{text}</Text>
          </Fragment>
        )}
      </Content>
    </Wrapper>
  );
}

FullscreenLoader.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
  text: PropTypes.string,
};
