import { textColor } from 'config/colors';
import { Box } from 'grommet';
import styled from 'styled-components';

export const ItemRow = styled.div`
  margin-right: 8px;
  overflow: hidden;
  white-space: ${props => (props.wrap ? 'normal' : 'nowrap')};
  text-overflow: ellipsis;
`;

export const ItemWrapper = styled(Box)`
  background: rgb(255, 255, 255);
  padding: ${props => (props.plain ? '0px' : '10px')};
  border-bottom: 4px solid rgba(237, 237, 237, 1);
  border-radius: 10px;
  margin-bottom: 18px;

  font-size: ${props => (props.small ? '12px' : '16px')};
  font-weight: 600;

  color: ${props => (props.disabled ? 'gray' : textColor)};

  transition: background 0.4s, color 0.2s, box-shadow 0.4s;

  &:hover {
    text-decoration: none;
    box-shadow: 0px 0px 15px 3px rgba(134, 92, 214, 1);
  }
`;
