/* eslint-disable react/prop-types */
import columnApi from 'contexts/Column/api';
import { UserContext } from 'contexts/User/UserContext';
import { ItemsList } from 'page/components/ItemsList';
import qs from 'qs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useCallbacksSync } from 'utils/callbacksSync';
import errorMessageHandler from 'utils/errorMessageHandler';
import Network from 'utils/network';
import { ShiftBase } from './ShiftBase';

function mergeShiftsWithColumnDays(shifts, columns) {
  return shifts.map(shift => ({
    ...shift,
    column: columns[shift.date].find(column => column.id === shift.columnDayId),
  }));
}

export function useGetShifts({ url, dateFrom, dateTo }) {
  const [callCount, setCallCount] = useState(0);
  const { currentTeam } = useContext(UserContext);
  const queryParams = qs.stringify({ dateFrom, dateTo }, { addQueryPrefix: true });
  const URL = `${url.replace('{{ teamId }}', currentTeam.id)}${queryParams}`;

  const [shifts, setShifts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(
    () => {
      async function fetchData() {
        setIsLoading(true);
        try {
          const [{ data: shiftsData }, { data: columnsData }] = await Promise.all([
            Network.get(URL),
            columnApi.getTeamColumns(dateFrom, dateTo, currentTeam.id),
          ]);

          setShifts(mergeShiftsWithColumnDays(shiftsData, columnsData));

          setIsLoading(false);
        } catch (exception) {
          setError(errorMessageHandler(exception));
          setIsLoading(false);
        }
      }
      fetchData();
    },
    [currentTeam.id, dateFrom, dateTo, callCount],
  );

  const refetch = useCallback(
    () => {
      setCallCount(_callCount => _callCount + 1);
    },
    [setCallCount],
  );

  return [{ shifts, loading: isLoading, error }, refetch];
}

function renderShift({ item: shift }) {
  return <ShiftBase {...shift} />;
}

export function ShiftsList({
  url,
  renderItem = renderShift,
  filterItems = () => true,
  showWeeks,
  dateFrom,
  dateTo,
}) {
  const [{ shifts = [], loading, error }, refetch] = useGetShifts({ url, dateFrom, dateTo });
  const refreshAndSync = useCallbacksSync({ id: url, refetch });

  return (
    <ItemsList
      error={error}
      filterItems={filterItems}
      items={shifts}
      loading={loading}
      onRefresh={refreshAndSync}
      renderItem={renderItem}
      sortItems={(a, b) => a.date.localeCompare(b.date)}
      showWeeks={showWeeks}
    />
  );
}
