/* eslint-disable react/prop-types */
import { Trans } from '@lingui/react';
import analytics from 'analytics';
import { useGetTeamDeadlines } from 'api/deadline';
import { RelativeDeadlineIcon } from 'components/DeadlinedIcon';
import Spinner from 'components/Spinner';
import { CalendarBar } from 'components/ui/ItemsCalendar/CalendarBar';
import { ItemWrapper } from 'components/ui/ItemsCalendar/Item';
import { ItemsCalendar } from 'components/ui/ItemsCalendar/ItemsCalendar';
import { criticalColor, successColor } from 'config/colors';
import { UserContext } from 'contexts/User/UserContext';
import { Box } from 'grommet';
import { get, isNil } from 'lodash';
import { Loader } from 'page/components/routing';
import { useCallback, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import { useMonthQueryParam } from 'utils/hooks';
import { useAvailability } from './useAvailability';

function getAvailabilityStatusLabelBackground(available) {
  if (isNil(available)) {
    return 'lightgray';
  } else if (available) {
    return successColor;
  }
  return criticalColor;
}

const AvailabilityStatusLabel = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  height: 100%;

  background: ${props => getAvailabilityStatusLabelBackground(props.available)};

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const AvailabilityHeader = styled(Box)`
  font-size: 12px;

  @media (max-width: 719px) {
    font-size: 16px;
  }
`;

const AvailabilitySubtitle = styled(Box)`
  font-size: 11px;

  @media (max-width: 719px) {
    font-size: 14px;
  }
`;

const Wrapper = styled(ItemWrapper)`
  position: relative;
  &:hover {
    text-decoration: none;
    box-shadow: 0px 0px 15px 3px ${props => getAvailabilityStatusLabelBackground(props.available)};
  }
`;

const PendingIndicator = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(230, 230, 230, 0.7);
  border-radius: 10px;
`;

function AvailabilityItem({ item, onClick, ...props }) {
  const { available, pending, start, end } = item;

  function handleClick(event) {
    if (!pending) {
      onClick(event);
    }
  }

  return (
    <Wrapper plain available={available} onClick={handleClick} {...props}>
      <Box direction="row" align="center">
        <AvailabilityStatusLabel available={available} />

        <Box pad="10px" align="start" margin={{ left: '10px' }}>
          <AvailabilityHeader align="center">{item.header}</AvailabilityHeader>

          <AvailabilitySubtitle>
            {start} - {end}
          </AvailabilitySubtitle>
        </Box>

        {pending && (
          <PendingIndicator direction="row" align="center" justify="center">
            <Spinner size="16px" />
          </PendingIndicator>
        )}
      </Box>
    </Wrapper>
  );
}

export function Availability() {
  const { currentTeam, currentUserTeamId } = useContext(UserContext);
  const { dateFrom, dateTo, month, onMoveForward, onMoveBackward } = useMonthQueryParam();
  const { addToast } = useToasts();
  const handleChangeError = useCallback(
    message => {
      addToast(message, {
        appearance: 'error',
        autoDismiss: true,
      });
    },
    [addToast],
  );

  const [
    { availabilities, loading, error, onClick, onSetAllToYes, onSetAllToNo },
  ] = useAvailability({
    dateFrom,
    dateTo,
    teamId: currentTeam.id,
    userTeamId: currentUserTeamId,
    onError: handleChangeError,
  });
  const [{ deadlineDayStatus, loading: deadlinesLoading }] = useGetTeamDeadlines({
    dateFrom,
    dateTo,
    teamId: currentTeam.id,
  });

  return (
    <Loader loading={loading || deadlinesLoading} error={error} pad="none">
      <CalendarBar
        month={month}
        onMoveDateBackward={onMoveBackward}
        onMoveDateForward={onMoveForward}
      />
      <ItemsCalendar
        month={month}
        items={availabilities}
        renderItem={({ item, ...props }) => (
          <AvailabilityItem key={item.id} item={item} onClick={() => onClick(item)} {...props} />
        )}
        renderItemMobile={({ item, ...props }) => (
          <AvailabilityItem key={item.id} item={item} onClick={() => onClick(item)} {...props} />
        )}
        renderHeaderDetails={({ day }) => {
          const { status, date } = get(deadlineDayStatus, `${day}`, {});

          if (!status) {
            return null;
          }

          return (
            <RelativeDeadlineIcon status={status} id={`deadline-${day}-${status}`} date={date} />
          );
        }}
        sectionMenuItems={[
          {
            label: <Trans id="availibility-page.day-menu.set-all-to-yes" />,
            onClick: (event, items) => {
              analytics.track({
                category: 'Availability batch edit',
                action: 'Set all to yes',
                label: 'Menu',
              });
              onSetAllToYes(items);
            },
          },
          {
            label: <Trans id="availibility-page.day-menu.set-all-to-no" />,
            onClick: (event, items) => {
              analytics.track({
                category: 'Availability batch edit',
                action: 'Set all to no',
                label: 'Menu',
              });
              onSetAllToNo(items);
            },
          },
        ]}
      />
    </Loader>
  );
}
