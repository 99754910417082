import { i18nMark, Trans } from '@lingui/react';
import CenteredRow from 'components/CenteredRow';
import Spinner from 'components/Spinner';
import { StatusUnknown } from 'grommet-icons';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Alert from 'reactstrap/lib/Alert';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import styled from 'styled-components';
import translatedMessage from 'translatedMessage';
import { getDayByOffset } from 'utils/Dates';

function dateMessage() {
  i18nMark('add-deadline-modal.form.date.tooltip');
  return translatedMessage('add-deadline-modal.form.date.tooltip');
}

// eslint-disable-next-line react/prop-types
function Tooltip({ label }) {
  return (
    <span data-tip={label} style={{ marginLeft: '10px' }}>
      <StatusUnknown size="16px" />
      <ReactTooltip />
    </span>
  );
}

const FromToRow = styled.div`
  display: flex;
`;

const FromToCell = styled.div`
  padding: 5px;
  flex: 1;
`;

export default class AddDeadlineModal extends Component {
  static propTypes = {
    onAddDeadline: PropTypes.func,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    currentTeamId: PropTypes.number,
  };

  state = {
    deadlineDate: getDayByOffset(0),
    periodStart: getDayByOffset(1),
    periodEnd: getDayByOffset(4),
    loading: false,
    error: null,
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <ModalHeader>
            <Trans id="add-deadline-modal.header" />
          </ModalHeader>
          <ModalBody>
            <Form>
              <FromToCell>
                <FormGroup>
                  <Label for="exampleDate">
                    <Trans id="add-deadline-modal.form.date" />
                    <Tooltip label={dateMessage()} />
                  </Label>
                  <Input
                    type="date"
                    name="date"
                    value={this.state.deadlineDate}
                    onChange={this.setDeadlineDate}
                  />
                </FormGroup>
              </FromToCell>
              <FromToRow id="period-inputs">
                <FromToCell>
                  <FormGroup>
                    <Label for="exampleDate">
                      <Trans id="add-deadline-modal.form.from" />
                    </Label>
                    <Input
                      type="date"
                      value={this.state.periodStart}
                      onChange={this.setPeriodStart}
                    />
                  </FormGroup>
                </FromToCell>
                <FromToCell>
                  <FormGroup>
                    <Label for="exampleDate">
                      <Trans id="add-deadline-modal.form.to" />
                    </Label>
                    <Input type="date" value={this.state.periodEnd} onChange={this.setPeriodEnd} />
                  </FormGroup>
                </FromToCell>
              </FromToRow>
            </Form>

            <CenteredRow>
              {this.state.loading ? (
                <Spinner size="16px" />
              ) : (
                this.state.error && <Alert color="danger">{this.state.error}</Alert>
              )}
            </CenteredRow>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleAddDeadline}>
              <Trans id="add-deadline-modal.form.submit" />
            </Button>
            <Button color="secondary" onClick={this.props.toggle}>
              <Trans id="add-deadline-modal.form.cancel" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  handleAddDeadline = async () => {
    this.setState({ error: null, loading: true });

    const deadline = pick(this.state, 'deadlineDate', 'periodStart', 'periodEnd');

    try {
      await this.props.onAddDeadline(deadline);
      this.props.toggle();
    } catch (exception) {
      const errorMessage = exception.message || exception.error;
      this.setState({ error: errorMessage });
    } finally {
      this.setState({ loading: false });
    }
  };

  setValue = (event, type) => this.setState({ [type]: event.target.value });
  setDeadlineDate = event => this.setValue(event, 'deadlineDate');
  setPeriodStart = event => this.setValue(event, 'periodStart');
  setPeriodEnd = event => this.setValue(event, 'periodEnd');
}
