import { Trans } from '@lingui/react';
import Modal from 'components/Modal';
import { Notification } from 'components/Notification';
import { withFormik } from 'formik';
import { Box, Button, Footer, Form, FormField, Grommet, Header, TextInput } from 'grommet';
import { I18nContextWrapper } from 'i18n';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  padding: 0px 5px;
`;

const DayTemplateFormInner = props => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    onClose,
    touched,
    status,
    values,
  } = props;

  return (
    <Form>
      <Header>
        {/* TODO: translate this */}
        <h2>Save day template</h2>
      </Header>
      <FormField label="Header" error={touched.header && errors.header}>
        <TextInput
          value={values.header}
          name="header"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormField>
      <Footer>
        <Box justify="center" align="center" direction="row" fill="horizontal" pad="large">
          <ButtonContainer>
            <Button label="Save" primary={true} onClick={isSubmitting ? null : handleSubmit} />
          </ButtonContainer>
          <ButtonContainer>
            <Button label="Cancel" onClick={isSubmitting ? null : onClose} />
          </ButtonContainer>
        </Box>
      </Footer>
      <Box justify="center" align="center" direction="row" fill="horizontal" pad="medium">
        {status && <Notification message={status} size="medium" status="critical" />}
      </Box>
    </Form>
  );
};

DayTemplateFormInner.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onLogin: PropTypes.func,
  status: PropTypes.any,
  teamName: PropTypes.string,
  touched: PropTypes.object,
  values: PropTypes.object,
};

const DayTemplateForm = withFormik({
  mapPropsToValues: () => ({ header: '' }),
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { header } = values;
    const { columns, onClose } = props;

    try {
      setStatus(null);
      await props.onSave(header, columns);
      setStatus(null);
      setSubmitting(false);

      onClose();
    } catch (exception) {
      const errorMessage = exception.message || exception.error;

      setStatus(errorMessage);
      setSubmitting(false);
    }
  },
  validate: values => {
    const errors = {};

    if (!values.header || values.header === '') {
      errors['header'] = 'Required!';
    }

    return errors;
  },
})(DayTemplateFormInner);

export default function SaveDayTemplateModal({ columns, onSave, onClose, isOpen }) {
  return (
    <Modal isOpen={isOpen}>
      <Grommet>
        <DayTemplateForm onSave={onSave} columns={columns} onClose={onClose} />
      </Grommet>
    </Modal>
  );
}

SaveDayTemplateModal.propTypes = {
  columns: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export function useSaveDayTemplateModalWithParams({ columns: initialColumns = [], onSave }) {
  const [columns, setColumns] = useState(initialColumns);
  const { addToast } = useToasts();
  const [showModal, hideModal] = useModal(
    () => (
      <I18nContextWrapper>
        <SaveDayTemplateModal columns={columns} isOpen onClose={hideModal} onSave={onSave} />
      </I18nContextWrapper>
    ),
    [columns],
  );

  const handleShowModal = useCallback(
    _columns => {
      function checkColumnsLength(cols) {
        if (cols.length === 0) {
          addToast(<Trans id="save-day-template-modal.empty-day-error" />, {
            appearance: 'error',
            autoDismiss: true,
          });
          return false;
        }
        return true;
      }

      if (_columns) {
        if (!checkColumnsLength(_columns)) {
          return;
        }
        setColumns(_columns);
      } else {
        if (!checkColumnsLength(columns)) {
          return;
        }
      }

      showModal();
    },
    [setColumns],
  );

  return [handleShowModal];
}
