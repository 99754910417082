import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { themeColor } from 'config/colors';

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${themeColor};

  &:hover {
    text-decoration: none;
  }
`;
