import { Grommet as BaseGrommet } from 'grommet';
import { base } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

const theme = deepMerge(base, {
  global: {
    font: {
      family: 'Nunito Sans, Arial',
    },
    focus: {
      border: {
        color: 'none',
      },
    },
  },
});

// eslint-disable-next-line react/prop-types
export function Grommet({ children, ...props }) {
  return (
    <BaseGrommet theme={theme} {...props}>
      {children}
    </BaseGrommet>
  );
}
