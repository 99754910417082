import styled from 'styled-components';

export default styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;
