export default function downloadFile(response, filename, extension) {
  const url = window.URL.createObjectURL(new window.Blob([response.data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${filename}.${extension}`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}
