import { HUMAN_READABLE_FORMAT } from 'config/dates';
import { Box, Calendar, DropButton, Text } from 'grommet';
import { Schedule } from 'grommet-icons';
import { useState } from 'react';
import { convertFromFormatToISO, convertISOtoFormat } from 'utils/Dates';

// eslint-disable-next-line react/prop-types
const DropContent = ({ value, onChange, onBlur }) => {
  function handleChange(date) {
    if (onChange) {
      onChange(date);
    }
  }

  return (
    <Box align="center">
      <Calendar
        animate={false}
        date={value}
        onBlur={onBlur}
        onSelect={handleChange}
        showAdjacentDays={false}
      />
    </Box>
  );
};

// eslint-disable-next-line react/prop-types
export const DateFormField = ({ value, format = HUMAN_READABLE_FORMAT, onChange, onBlur }) => {
  const [open, setOpen] = useState();

  const onClose = () => {
    setOpen(false);
    setTimeout(() => setOpen(undefined), 1);
  };

  function handleChange(date) {
    const formattedDate = convertISOtoFormat(date, format);
    if (onChange) {
      onChange(formattedDate);
    }

    onClose();
  }

  const convertedDate = convertFromFormatToISO(value, format);

  return (
    <Box justify="between">
      <DropButton
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        dropContent={<DropContent value={convertedDate} onChange={handleChange} onBlur={onBlur} />}
      >
        <Box direction="row" gap="medium" justify="between" pad="small">
          <Text color={value ? undefined : 'dark-5'}>{value ? `${value}` : 'Select date'}</Text>
          <Schedule />
        </Box>
      </DropButton>
    </Box>
  );
};
