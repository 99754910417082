import { Trans } from '@lingui/react';
import { ItemRow } from 'components/ui/ItemsCalendar/Item';
import { UserContext } from 'contexts/User/UserContext';
import { Box, Button } from 'grommet';
import { get } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import errorMessageHandler from 'utils/errorMessageHandler';
import { ShiftBase } from './ShiftBase';
import { WarningSubtitle } from 'components/ui/WarningSubtitle';
import shiftApi from 'contexts/Shift/api';

const UnavailableShift = styled(WarningSubtitle)`
  line-height: 25px;
`;

export function ShiftToTakeComponent({ onTake, shift, isUserShift }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleTake = useCallback(async event => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    await onTake();
    setIsLoading(false);
  }, []);

  const canTakeShift = shift.userAvailableOnShift && !isUserShift;
  const isTakeButtonDisabled = isLoading || !shift.userAvailableOnShift;

  return (
    <ShiftBase {...shift} disabled={isLoading}>
      <ItemRow>
        <Box direction="row" justify="between" align="end">
          <Box gap="6px">
            <Box direction="row-responsive" gap="8px">
              <div>
                <Trans id="shift-to-take.from" />: {get(shift, 'user.displayname', 'Unknown user')}
              </div>
              {!canTakeShift && (
                <UnavailableShift>
                  (
                  {isUserShift ? (
                    <Trans id="shift-to-take.feedback.you-shift" /> // 'To Twoja zmiana'
                  ) : !shift.userAvailableOnShift ? (
                    <Trans id="shift-to-take.feedback.already-have-shift" />
                  ) : (
                    <Trans id="shift-to-take.feedback.unknown-error" />
                  )}
                  )
                </UnavailableShift>
              )}
            </Box>
          </Box>

          <Button
            primary
            label={<Trans id="shift-to-take.take" />}
            size="small"
            disabled={isTakeButtonDisabled}
            onClick={handleTake}
          />
        </Box>
      </ItemRow>
    </ShiftBase>
  );
}

// eslint-disable-next-line react/prop-types
export function ShiftToTake({ shift: propsShift, onRefresh, onTake }) {
  const { currentUserTeamId, getUser } = useContext(UserContext);
  const { addToast } = useToasts();
  const shift = useMemo(() => ({ ...propsShift, user: getUser(propsShift.userTeamId) }), [
    propsShift.userTeamId,
  ]);
  const isUserShift = shift.userTeamId === currentUserTeamId;

  const handleTake = useCallback(
    async () => {
      try {
        if (onTake) {
          await onTake(shift);
        }

        await shiftApi.takeShift(shift.userTeamId, shift.columnDayId, shift.date);

        addToast(
          <Trans
            id="work.shifts-to-take.succesfully-taken"
            values={{
              shift: get(shift, 'column.header', '?'),
              start: get(shift, 'column.start'),
              date: shift.date,
            }}
          />,
          {
            appearance: 'success',
            autoDismiss: true,
          },
        );

        await onRefresh();
      } catch (exception) {
        const errorMessage = errorMessageHandler(exception);
        addToast(errorMessage, {
          appearance: 'error',
          autoDismiss: true,
        });
      }

      return true;
    },
    [shift, currentUserTeamId, addToast],
  );

  return <ShiftToTakeComponent onTake={handleTake} shift={shift} isUserShift={isUserShift} />;
}
