/* eslint-disable react/prop-types */
import { Trans } from '@lingui/react';
import useAxios from 'axios-hooks';
import { useEditColumnModal } from 'components/ColumnCalendar/EditColumnModal';
import columnApi from 'contexts/Column/api';
import shiftApi from 'contexts/Shift/api';
import { UserContext } from 'contexts/User/UserContext';
import update, { extend } from 'immutability-helper';
import { omit, pick } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { convertStartDurationToStartEnd } from 'utils/Dates';
import errorMessageHandler from 'utils/errorMessageHandler';
import { useEditDescriptionModal } from 'components/RotaTable/DescriptionBoxModal';

extend('$removeUser', function(user, original) {
  return original.filter(item => item.userTeamId !== user.userTeamId);
});

extend('$modifyUser', function(user, original) {
  return original.map(
    item => (item.userTeamId === user.userTeamId ? Object.assign({}, item, user) : item),
  );
});

const OVERWRITE_COLUMN_DAY = 'OVERWRITE_COLUMN_DAY';
const ASSIGN_USER_TO_SHIFT = 'ASSIGN_USER_TO_SHIFT';
const REMOVE_USE_FROM_SHIFT = 'REMOVE_USE_FROM_SHIFT';
const EDIT_COLUMN_DAY = 'EDIT_COLUMN_DAY';
const GIVE_AWAY_SHIFT = 'GIVE_AWAY_SHIFT';
const UNDO_GIVE_AWAY_SHIFT = 'UNDO_GIVE_AWAY_SHIFT';
const TAKE_SHIFT = 'TAKE_SHIFT';
const UPDATE_USER_DESCRIPTION = 'UPDATE_USER_DESCRIPTION';

function getInitialState() {
  return {
    id: 0,
    teamId: 0,
    header: '',
    date: '2020-08-22',
    start: '12:00:00',
    duration: '01:00:00',
    availableUsers: [],
    workingUsers: [],
  };
}

function convertShiftToDTO({ id, date }) {
  return {
    columnDayId: id,
    date,
  };
}

function transformColumnDays(columnDay, getUser) {
  const { availableUsersTeamsIds = [], shifts = [] } = columnDay;
  const { start, end } = convertStartDurationToStartEnd(columnDay.start, columnDay.duration);
  return {
    ...omit(columnDay, ['availableUsersTeamsIds', 'shifts']),
    availableUsers: availableUsersTeamsIds
      .filter(userTeamId => !shifts.some(shift => shift.userTeamId === userTeamId))
      .map(user => ({
        ...getUser(user),
        isAvailable: true,
      })),
    workingUsers: shifts.map(shift => ({
      ...getUser(shift.userTeamId),
      isAvailable: availableUsersTeamsIds.includes(shift.userTeamId),
      isGivingAway: shift.exchangeable,
      description: shift.description,
    })),
    start,
    end,
  };
}

function useGetColumnDay(columnDayId, getUser) {
  const [columnDay, setColumnDay] = useState(getInitialState());
  const [{ data, loading, error }, refetch] = useAxios(`/columns-days/${columnDayId}`);

  useEffect(
    () => {
      if (data) {
        setColumnDay(transformColumnDays(data, getUser));
      }
    },
    [data],
  );

  return [{ columnDay, loading: loading || !data, error }, refetch];
}

function assignUserToShiftAction(user) {
  return { type: ASSIGN_USER_TO_SHIFT, payload: user };
}

function removeUserFromShiftAction(user) {
  return { type: REMOVE_USE_FROM_SHIFT, payload: user };
}

function editColumnDayAction(columnDay) {
  return { type: EDIT_COLUMN_DAY, payload: columnDay };
}

function giveAwayAction(userTeamId) {
  return { type: GIVE_AWAY_SHIFT, payload: userTeamId };
}

function undoGiveAwayAction(userTeamId) {
  return { type: UNDO_GIVE_AWAY_SHIFT, payload: userTeamId };
}

function takeShiftAction(userTo, userFrom) {
  return { type: TAKE_SHIFT, payload: { userTo, userFrom } };
}

function updateUserDescription(shift, newDescription) {
  return { type: UPDATE_USER_DESCRIPTION, payload: { shift, newDescription } };
}

function columnDayReducer(state, action) {
  switch (action.type) {
    case OVERWRITE_COLUMN_DAY:
      return action.payload;

    case GIVE_AWAY_SHIFT: {
      const userTeamId = action.payload;
      return update(state, {
        workingUsers: {
          $modifyUser: {
            userTeamId,
            isGivingAway: true,
          },
        },
      });
    }

    case TAKE_SHIFT: {
      const { userTo, userFrom } = action.payload;

      return update(state, {
        workingUsers: {
          $removeUser: userFrom,
          $push: [userTo],
        },
        availableUsers: {
          $removeUser: userTo,
        },
      });
    }

    case UNDO_GIVE_AWAY_SHIFT: {
      const userTeamId = action.payload;
      return update(state, {
        workingUsers: {
          $modifyUser: {
            userTeamId,
            isGivingAway: false,
          },
        },
      });
    }

    case EDIT_COLUMN_DAY: {
      const columnDay = action.payload;
      return update(state, { $merge: pick(columnDay, 'header', 'start', 'duration', 'end') });
    }

    case ASSIGN_USER_TO_SHIFT: {
      const user = action.payload;

      return update(state, {
        workingUsers: { $push: [user] },
        availableUsers: { $removeUser: user },
      });
    }

    case REMOVE_USE_FROM_SHIFT: {
      const user = action.payload;

      return update(state, {
        ...(user.isAvailable ? { availableUsers: { $push: [user] } } : {}),
        workingUsers: { $removeUser: user },
      });
    }

    case UPDATE_USER_DESCRIPTION: {
      const { shift, newDescription } = action.payload;

      return update(state, {
        workingUsers: {
          $modifyUser: {
            userTeamId: shift.userTeamId,
            description: newDescription,
          },
        },
      });
    }

    default:
      return state;
  }
}

function useEditDescription({ onEdit }) {
  const [shift, setShift] = useState(null);

  const [showModal] = useEditDescriptionModal({
    shift,
    onEdit,
  });

  const handleShowModal = useCallback(
    _shift => {
      setShift(_shift);
      showModal();
    },
    [showModal],
  );

  return [handleShowModal];
}

export function useColumnDay({ columnDayId, onError, onSuccess }) {
  const { getUser, currentUserTeamId } = useContext(UserContext);
  const [state, dispatch] = useReducer(columnDayReducer, getInitialState());
  const [{ loading, error, columnDay }, refetch] = useGetColumnDay(columnDayId, getUser);
  const [showEditModal] = useEditColumnModal({
    columnDay,
    day: columnDay.date,
    onEdit: async column => {
      await columnApi.editColumn({ ...column, id: columnDay.columnId });
      dispatch(editColumnDayAction(column));
      onSuccess(<Trans id="shift.succesfully-updated-column" />);
    },
  });

  const [showEditDescriptionModal] = useEditDescription({
    onEdit: async (shift, newDescription) => {
      dispatch(updateUserDescription(shift, newDescription));
      try {
        await shiftApi.editDescription(shift, newDescription);
      } catch (exception) {
        const errorMessage = errorMessageHandler(exception);
        onError(errorMessage);

        dispatch(updateUserDescription(shift, shift.description));
      }
    },
  });

  useEffect(
    () => {
      dispatch({ type: OVERWRITE_COLUMN_DAY, payload: columnDay });
    },
    [columnDay, dispatch],
  );

  const actionHelper = useCallback(
    async (actions, request, successMessage, errorAction, errorFallbackMessage) => {
      actions.forEach(dispatch);

      try {
        await request();
        onSuccess(successMessage);
      } catch (exception) {
        // eslint-disable-next-line no-console
        console.error('Exception occured: ', exception);
        if (errorAction) {
          dispatch(errorAction);
        } else {
          refetch();
        }

        const errorMessage = errorMessageHandler(exception, <Trans id="shift.unknown-error" />);
        onError(errorFallbackMessage(errorMessage));
      }
    },
    [dispatch, refetch],
  );

  const assignUserToShift = useCallback(
    user => {
      actionHelper(
        [assignUserToShiftAction(user)],
        () => {
          const data = convertShiftToDTO(state);
          return shiftApi.assignUserToShift(user, data);
        },
        <Trans id="shift.assign-user-to-shift-success" />,
        removeUserFromShiftAction(user),
        error => <Trans id="shift.assign-user-to-shift-fail" values={{ error }} />,
      );
    },
    [dispatch, actionHelper, state],
  );

  const removeUserFromShift = useCallback(
    user => {
      actionHelper(
        [removeUserFromShiftAction(user)],
        () => {
          const data = convertShiftToDTO(state);
          return shiftApi.removeUserFromShift(user, data);
        },
        <Trans id="shift.remove-user-from-shift-success" />,
        assignUserToShiftAction(user),
        error => <Trans id="shift.remove-user-from-shift-fail" values={{ error }} />,
      );
    },
    [dispatch, actionHelper, state],
  );

  const giveAway = useCallback(
    () => {
      actionHelper(
        [giveAwayAction(currentUserTeamId)],
        () => shiftApi.giveAwayShift(currentUserTeamId, state.id, state.date),
        <Trans id="shfit.give-away-success" />,
        undoGiveAwayAction(currentUserTeamId),
        error => <Trans id="shift.give-away-failure" values={{ error }} />,
      );
    },
    [actionHelper, currentUserTeamId, state],
  );

  const undoGiveAway = useCallback(
    () => {
      actionHelper(
        [undoGiveAwayAction(currentUserTeamId)],
        () => shiftApi.undoGiveAwayShift(currentUserTeamId, columnDayId, columnDay.date),
        <Trans id="shift.undo-give-away-success" />,
        giveAwayAction(currentUserTeamId),
        error => <Trans id="shift.undo-give-away-failure" values={{ error }} />,
      );
    },
    [actionHelper, currentUserTeamId],
  );

  const takeShift = useCallback(
    userFrom => {
      const currentUser = getUser(currentUserTeamId);
      actionHelper(
        [takeShiftAction(currentUser, userFrom)],
        () => shiftApi.takeShift(userFrom.userTeamId, state.id, state.date),
        <Trans id="shift.take-shift-success" />,
        null,
        error => <Trans id="shift.take-shift-failure" values={{ error }} />,
      );
    },
    [actionHelper, state, getUser, currentUserTeamId],
  );

  const stateWithSelectors = useMemo(
    () => ({
      ...state,
      isUserWorkingOnShift: state.workingUsers.some(user => user.userTeamId === currentUserTeamId),
      isUserGivingAwayShift: state.workingUsers.some(
        user => user.userTeamId === currentUserTeamId && user.isGivingAway,
      ),
    }),
    [state, currentUserTeamId],
  );

  return [
    {
      assignUserToShift,
      columnDay: stateWithSelectors,
      error,
      loading,
      refresh: refetch,
      removeUserFromShift,
      showEditModal,
      takeShift,
      undoGiveAway,
      giveAway,
      showEditDescriptionModal,
    },
  ];
}
