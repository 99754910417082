import { Trans } from '@lingui/react';
import { MonthFormField } from 'components/MonthFormField';
import { DATE_FORMAT_MONTHS } from 'config/dates';
import { Formik } from 'formik';
import { Box, Form, FormField } from 'grommet';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { getFromToDatesForMonth } from 'utils/Dates';
import GenerateButton from './GenerateButton';

function getPreviousMonth() {
  return moment()
    .subtract(1, 'months')
    .format('MM/YYYY');
}

export default function MonthForm({ onGenerateReport }) {
  return (
    <Formik
      initialValues={{ month: getPreviousMonth() }}
      validate={values => {
        const errors = {};

        if (!values.month) {
          errors['month'] = <Trans id="forms.required" />;
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const { month } = values;

        const parsedMonth = moment(month, 'MM/YYYY').format(DATE_FORMAT_MONTHS);
        const { dateFrom, dateTo } = getFromToDatesForMonth(parsedMonth);

        setSubmitting(true);
        try {
          await onGenerateReport(dateFrom, dateTo);
          setSubmitting(false);
        } finally {
          setSubmitting(false);
        }
      }}
      render={({ values, isSubmitting, handleBlur, handleChange, submitForm, touched, errors }) => (
        <Fragment>
          <Box pad="medium" direction="column" align="start">
            <Form>
              <FormField
                label={<Trans id="team.generate-reports.tab.month" />}
                error={touched.month && errors.month}
              >
                <MonthFormField
                  id="generate-report-month-input"
                  name="month"
                  format="MM/YYYY"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.month}
                />
              </FormField>
            </Form>
          </Box>
          <Box pad="medium" direction="column" align="start">
            <GenerateButton disabled={isSubmitting} onClick={isSubmitting ? null : submitForm} />
          </Box>
        </Fragment>
      )}
    />
  );
}

MonthForm.propTypes = {
  onGenerateReport: PropTypes.func,
};
