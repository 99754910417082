import { DATE_FORMAT, DATE_FORMAT_MONTHS, MAXIMUM_DAY_SPAN } from 'config/dates';
import moment from 'moment';
import qs from 'qs';

export default function getDateSpanFromLocation(location) {
  const { search } = location;

  const { from, to } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const momentFrom = moment(from, DATE_FORMAT);
  const momentTo = moment(to, DATE_FORMAT);

  const isValid = momentFrom.isValid() && momentTo.isValid();

  if (!isValid) {
    //eslint-disable-next-line
    console.warn(
      `Invalid query parameters date format (from, to). Valid format is: ${DATE_FORMAT}`,
    );
    return null;
  }

  const difference = momentTo.diff(momentFrom, 'days');
  if (difference < 1) {
    //eslint-disable-next-line
    console.warn(`Ending date is before the start date.`);
    return null;
  } else if (difference > MAXIMUM_DAY_SPAN) {
    //eslint-disable-next-line
    console.warn(`Maxiumim date span is ${MAXIMUM_DAY_SPAN}.`);
    return null;
  }

  if (from && to) {
    return { dateFrom: from, dateTo: to };
  } else {
    return null;
  }
}

export function getMonthFromLocation(location) {
  const { search } = location;

  const { month } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const momentMonth = moment(month, DATE_FORMAT_MONTHS);

  const isValid = momentMonth.isValid();

  if (!isValid) {
    //eslint-disable-next-line
    console.warn(`Invalid query parameters month format. Valid format is: ${DATE_FORMAT_MONTHS}`);
    return null;
  }

  return month;
}
