import ReactGA from 'react-ga';

import ENV from 'utils/env';

const devTrackerHandler = name => (...args) => {
  // eslint-disable-next-line
  console.log(`%canalytics.${name}(): ${JSON.stringify(args)}`, 'color: green;');
};

export default (ENV.NODE_ENV === 'prod' || window.location.hostname === 'app.rota.works'
  ? {
      pageview: path => ReactGA.pageview(path),
      track: ReactGA.event,
      timing: (category, variable, value, label) =>
        ReactGA.timing({ category, variable, value, label }),
    }
  : {
      pageview: devTrackerHandler('pageview'),
      track: devTrackerHandler('event'),
      timing: devTrackerHandler('timing'),
    });
