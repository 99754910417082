import { criticalColor, successColor } from 'config/colors';

const backgroundItemOpacity = '0.25';

export const colors = {
  foreground: {
    YES: successColor,
    NO: criticalColor,
  },
  background: {
    YES: `rgba(140, 200, 0, ${backgroundItemOpacity})`,
    NO: `rgba(255, 50, 77, ${backgroundItemOpacity})`,
  },
};

export const getAvailabilitiesForegroundColor = available =>
  available ? colors['foreground']['YES'] : colors['foreground']['NO'];

export const getAvailabilitiesBackgroundColor = available =>
  available ? colors['background']['YES'] : colors['background']['NO'];

export const shiftColor = colors.foreground.YES;

export const getShiftColor = () => shiftColor;
