import qs from 'qs';

import Network from 'utils/network';
import { processRequest } from 'contexts/utils';

export default {
  getTeamChangeRequests: (dateFrom, dateTo, teamId) => {
    const queryParams = qs.stringify({ dateFrom, dateTo, teamId }, { addQueryPrefix: true });
    return Network.get(`/change-requests/get/${queryParams}`);
  },

  acceptChangeRequests: changeRequests =>
    processRequest(Network.put('/change-requests/accept', changeRequests)),

  declineChangeRequests: changeRequests =>
    processRequest(Network.put('/change-requests/decline', changeRequests)),

  createChangeRequests: changeRequests =>
    processRequest(Network.post('/change-requests/create', changeRequests)),
};
