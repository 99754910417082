import FullscreenWrapper from 'components/FullscreenWrapper';
import { Notification } from 'components/Notification';
import Spinner from 'components/Spinner';
import { themeColor } from 'config/colors';
import { UserConsumer } from 'contexts';
import { loginAction } from 'contexts/actions';
import { setSession } from 'contexts/SessionResolver';
import { withFormik } from 'formik';
import {
  Anchor,
  Box,
  Button,
  Footer,
  Form,
  FormField,
  Header,
  Heading,
  Image,
  TextInput,
} from 'grommet';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

const UserLoginFormInner = props => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    status,
    touched,
    values,
  } = props;

  return (
    <FullscreenWrapper>
      <Box tag="section">
        <h2 />
      </Box>
      <Box tag="section">
        <Box fill="horizontal" justify="center" align="center" responsive pad="medium">
          <Form>
            <Header>
              <Box fill="horizontal" justify="center" align="center" direction="column">
                <Box width="medium" height="small">
                  <Image
                    src="https://s3.eu-central-1.amazonaws.com/app.rota.works.staticfiles/rota1.png"
                    fit="contain"
                  />
                </Box>
                <Heading level={3}>Logowanie</Heading>
              </Box>
            </Header>

            <FormField label="E-mail" error={touched.email && errors.email}>
              <TextInput
                type="email"
                id="login-input-email"
                value={values.email}
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormField>
            <FormField label="Hasło" error={touched.password && errors.password}>
              <TextInput
                type="password"
                id="login-input-password"
                value={values.password}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={event => {
                  const { charCode } = event;

                  if (charCode === 13) {
                    handleSubmit();
                  }
                }}
              />
            </FormField>

            <Footer>
              <Box justify="center" align="center" fill="horizontal" pad="large">
                <Button
                  id="login-button"
                  label="Zaloguj"
                  primary
                  onClick={isSubmitting ? null : handleSubmit}
                />
                <hr />
                <Link to="/password/request-reset">
                  <Anchor color="brand" style={{ color: themeColor }} label="Przypomnij hasło" />
                </Link>
                <div style={{ height: '40px', padding: '15px' }}>
                  {isSubmitting && <Spinner />}
                  {status && <Notification message={status} size="medium" status="critical" />}
                </div>
                <hr />
                <div>Nie posiadasz konta?</div>
                <Link to="/register">
                  <Anchor color="brand" style={{ color: themeColor }} label="Załóż zespół" />
                </Link>
              </Box>
            </Footer>
          </Form>
        </Box>
      </Box>
    </FullscreenWrapper>
  );
};

UserLoginFormInner.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onLogin: PropTypes.func,
  status: PropTypes.any,
  teamName: PropTypes.string,
  touched: PropTypes.object,
  values: PropTypes.object,
};

const UserLoginForm = withFormik({
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { email, password } = values;
    try {
      setStatus(null);
      await props.onLogin(email, password);
      setStatus(null);
      setSubmitting(false);
    } catch (exception) {
      const errorMessage = exception.message || exception.error;

      setStatus(errorMessage);
      setSubmitting(false);
    }
  },
  validate: values => {
    const errors = {};

    if (!values.email || values.email === '') {
      errors['email'] = 'Required!';
    }

    if (!values.password || values.password === '') {
      errors['password'] = 'Required!';
    }

    return errors;
  },
})(UserLoginFormInner);

export class UserLogin extends Component {
  static propTypes = {
    location: PropTypes.object,
    onLogin: PropTypes.func,
  };

  state = {
    toApp: false,
  };

  render() {
    const { search } = window.location;
    const { next } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    const toAppUrl = next || '';

    return this.state.toApp ? (
      <Redirect to={toAppUrl} />
    ) : this.state.loading ? (
      <div>Loading ...</div>
    ) : (
      <UserLoginForm teamName={this.state.teamName} onLogin={this.handleLogin} />
    );
  }

  handleLogin = async (email, password) => {
    await this.props.onLogin(email, password);
    this.setState({ toApp: true });
  };
}

export default function ContextWrapper(props) {
  return (
    <UserConsumer>
      {({ login, setAvailableTeams }) => (
        <UserLogin
          onLogin={loginAction({
            login,
            setAvailableTeams,
            setSession,
          })}
          {...props}
        />
      )}
    </UserConsumer>
  );
}
