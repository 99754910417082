import { Trans } from '@lingui/react';
import { Page, PageHeader, PageSection } from 'components/ui/Page';
// import EmailNotifications from './Email';
// import EventsConfig from './EventsConfig';
import FEATURE_FLAGS from 'config/featureFlags';
import FacebookNotifications from './Facebook';
import GoogleCalendarNotifications from './GoogleCalendar';
import TelegramNotifications from './Telegram';

export default function Notifications() {
  return (
    <Page header={<PageHeader title={<Trans id="account.menu.notifications" />} />}>
      {FEATURE_FLAGS.ENABLE_GOOGLE_CALENDAR_NOTIFICATIONS && (
        <PageSection full>
          <GoogleCalendarNotifications />
        </PageSection>
      )}
      {FEATURE_FLAGS.ENABLE_FACEBOOK_NOTIFICATIONS && (
        <PageSection full>
          <FacebookNotifications />
        </PageSection>
      )}
      {FEATURE_FLAGS.ENABLE_TELEGRAM_NOTIFICATIONS && (
        <PageSection full>
          <TelegramNotifications />
        </PageSection>
      )}
      {/* <EmailNotifications />
      <EventsConfig /> */}
    </Page>
  );
}
