import { AVAILABLE_LANGUAGES, changeLanguage, loadLanguage } from 'i18n';
import { Form, FormField, Paragraph, Select } from 'grommet';

import Toast from 'components/Toast';
import { Trans } from '@lingui/react';
import { getErrorMessage } from 'config/errors';
import { useI18n } from 'contexts/i18nWrapper';
import { useState } from 'react';

const ToastError = () => {
  const i18n = useI18n();
  return i18n._('error');
};

export default function ChangeLanguageForm() {
  const currentLanguage = loadLanguage();
  const [error, setError] = useState();
  const onChangeLanguage = ({ value: newLanguage }) => {
    if (newLanguage.value === currentLanguage.value) {
      return;
    }

    try {
      setError();
      changeLanguage(newLanguage.value);
    } catch (exception) {
      const errorMessage = getErrorMessage(exception.message);
      setError(errorMessage);
    }
  };

  return (
    <div>
      <Form>
        {error && (
          <Toast status="critical">
            <ToastError />: {error}{' '}
          </Toast>
        )}
        <FormField label={<Trans id="forms.language" />} error={null}>
          <Select
            options={AVAILABLE_LANGUAGES}
            valueKey="value"
            labelKey="label"
            value={currentLanguage}
            onChange={onChangeLanguage}
          />
        </FormField>
        <Paragraph>
          <Trans id="change-language-form.local-note" />
        </Paragraph>
      </Form>
    </div>
  );
}
