import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router';

// TODO m2: check session here on logout or something, bring back is logged?s
export class LoggedInContainer extends Component {
  static propTypes = {
    hasValidSession: PropTypes.func.isRequired,
    history: PropTypes.any,
    location: PropTypes.any,
    publicChildren: PropTypes.any.isRequired,
    securedChildren: PropTypes.any.isRequired,
  };

  checkedInitial = false;

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (!this.props.hasValidSession() && !this.checkedInitial && !this.isPublicPath()) {
      this.checkedInitial = true;
      this.redirect();
    }
  }

  isPublicPath = () => {
    const { pathname } = this.props.location;

    // TODO: Dirty hack :<<<<<<<<<<<<<<<<<<<<<<<<<<
    return (
      [
        '/join',
        '/login',
        '/logout',
        '/registration',
        '/register',
        '/password/request-reset',
        '/password/reset',
      ].indexOf(pathname) !== -1
    );
  };

  render() {
    if (this.isPublicPath()) {
      return this.props.publicChildren;
    } else if (this.props.hasValidSession()) {
      return this.props.securedChildren;
    } else {
      return this.props.publicChildren;
    }
  }

  redirect = () => {
    let redirectPath = '/login';

    const { pathname, search } = window.location;
    const nextPath = encodeURIComponent(pathname + search);

    if (nextPath !== '' && pathname !== '/') {
      redirectPath += `?next=${nextPath}`;
    }

    this.props.history.push(redirectPath);
  };
}

export default withRouter(LoggedInContainer);
