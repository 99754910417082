import { UserConsumer } from 'contexts';
import { Box, Button, Grommet } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import PropTypes from 'prop-types';
import { Component } from 'react';
import styled from 'styled-components';
import AddUsersForm from './AddUsersForm';

const ContentWrapper = styled.div`
  padding: 1rem;
  margin-top: 1rem;
`;

class TeamAddUsers extends Component {
  static propTypes = {
    onSave: PropTypes.func,
    teamId: PropTypes.any,
    teamName: PropTypes.string,
  };

  render() {
    return (
      <ContentWrapper>
        <Grommet>
          <Box direction="row" align="center" alignContent="center" style={{ marginBottom: '1em' }}>
            <span style={{ paddingRight: '2em' }}>
              <Button
                icon={<LinkPrevious />}
                label="Back"
                path="/team/manage"
                plain={true}
                size="xsmall"
              />
            </span>
            <h2 style={{ margin: 0 }}>
              Add members to <span style={{ fontWeight: 'bold' }}>{this.props.teamName}</span> team
            </h2>
          </Box>
          <AddUsersForm onSave={this.handleOnSave} />
        </Grommet>
      </ContentWrapper>
    );
  }

  handleOnSave = users => {
    const { teamId } = this.props;
    this.props.onSave(users, teamId);
  };
}

export default function ContextWrapper(props) {
  return (
    <UserConsumer>
      {({ currentTeam, addTeamMembers }) => (
        <TeamAddUsers
          onSave={addTeamMembers}
          teamId={currentTeam.id}
          teamName={currentTeam.name}
          {...props}
        />
      )}
    </UserConsumer>
  );
}
