import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ItemList from 'components/ItemList';
import PredefinedColumnsList from 'components/PredefinedColumnsList';
import { Box } from 'grommet';
import Toast from 'components/Toast';
import errorMessageHandler from 'utils/errorMessageHandler';
import analytics from 'analytics';
import { Trans, i18nMark } from '@lingui/react';
import translatedMessage from 'translatedMessage';
import { useI18n } from 'contexts/i18nWrapper';

const Wrapper = styled.div``;

const Spacer = styled.div`
  margin-top: 20px;
`;

export default function TemplatesColumn({
  predefinedColumns,
  onPredefinedColumnClick,
  onDeletePredefinedColumns,
  onCreatePredefinedColumns,
  dayTemplates,
  onDayTemplateClick,
  onDeleteDayDemplates,
}) {
  const [error, setError] = useState(null);
  const i18n = useI18n();

  async function handlePredefinedColumnDelete(column) {
    i18nMark('predefined-columns-list.confirm-delete');
    const confirmed = window.confirm(translatedMessage('predefined-columns-list.confirm-delete'));

    if (!confirmed) {
      return;
    }

    analytics.track({
      category: 'Predefined Columns',
      action: 'Delete Predefined Columns',
      label: 'Menu',
    });

    try {
      setError(null);
      await onDeletePredefinedColumns([column]);
    } catch (exception) {
      const error = errorMessageHandler(exception);
      setError(error);
    }
  }

  async function handleDayTemplateDelete(dayTemplate) {
    i18nMark('day-templates-columns.confirm-delete');
    const confirmed = window.confirm(translatedMessage('day-templates-columns.confirm-delete'));

    if (!confirmed) {
      return;
    }

    analytics.track({ category: 'Day Template', action: 'Delete Day Template', label: 'Menu' });

    try {
      setError(null);
      await onDeleteDayDemplates([dayTemplate]);
    } catch (exception) {
      const error = errorMessageHandler(exception);
      setError(error);
    }
  }

  return (
    <Wrapper>
      {error && (
        <Toast status="critical">
          <Trans id="error" />: {error}
        </Toast>
      )}
      <Box pad="medium">
        <PredefinedColumnsList
          items={predefinedColumns}
          onItemClick={onPredefinedColumnClick}
          onCreate={onCreatePredefinedColumns}
          listMenuOptions={[
            {
              id: 'templates-column-delete-predefined-column',
              title: i18n._('items-list.delete'),
              handler: handlePredefinedColumnDelete,
            },
          ]}
        />
        <Spacer />
        <ItemList
          header={<Trans id="day-templates-columns.header" />}
          items={dayTemplates}
          onItemClick={onDayTemplateClick}
          menuOptions={[
            {
              id: 'templates-column-delete-day-template',
              title: i18n._('items-list.delete'),
              handler: handleDayTemplateDelete,
            },
          ]}
        />
      </Box>
    </Wrapper>
  );
}

TemplatesColumn.propTypes = {
  predefinedColumns: PropTypes.array,
  onPredefinedColumnClick: PropTypes.func,
  onDeletePredefinedColumns: PropTypes.func,
  onCreatePredefinedColumns: PropTypes.func,
  dayTemplates: PropTypes.array,
  onDayTemplateClick: PropTypes.func,
  onDeleteDayDemplates: PropTypes.func,
};
