import PropTypes from 'prop-types';

import Container from 'reactstrap/lib/Container';
import { useIsMobile } from 'utils/responsive';
import { Box } from 'grommet';
import styled from 'styled-components';

const Content = styled(Box)`
  /* max-height: 100vh; */
  overflow-y: scroll;
  padding-bottom: 180px;
`;

const NavbarMobileContainer = styled(Box)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f0f0f0;
  z-index: 100;
`;

export default function UI({ children, navbar }) {
  const isMobile = useIsMobile();

  return (
    <Container fluid>
      <Content direction="column">
        {isMobile ? <NavbarMobileContainer>{navbar}</NavbarMobileContainer> : navbar}
        {children}
      </Content>
    </Container>
  );
}

UI.propTypes = {
  children: PropTypes.node,
  navbar: PropTypes.node,
};

UI.Content = Container;
