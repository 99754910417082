import { themeColor } from 'config/colors';
import { Box, Button } from 'grommet';
import { FormNextLink } from 'grommet-icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useIsMobile } from 'utils/responsive';
import { DropdownButton } from '../DropdownButton/DropdownButton';
import { DotMenuIcon } from '../DotMenuIcon';

const WrapperUp = styled(Box)`
  flex: 0 0 ${props => (props.isMobile || props.full ? '100%' : '50%')};

  margin-top: ${props => (props.noMargin ? '0px' : props.isMobile ? '24px' : '24px')};
  padding: ${props => (props.isMobile ? '0px' : '16px')};
`;

const Wrapper = styled(Box)`
  @media (min-width: 769px) {
    &:nth-child(even) {
      padding-left: 4vw;
    }
    &:nth-child(odd) {
      padding-right: 4vw;
    }
  }

  margin-bottom: 24px;
  h3 {
    color: ${props => (props.secondary ? '#888888' : 'inherit')};
    flex: 1;
    margin: 0;
    font-weight: 800;
  }
`;

// eslint-disable-next-line react/prop-types
export function PageSection({ title, to, children, full, secondary, noMargin }) {
  const isMobile = useIsMobile();
  return (
    <WrapperUp isMobile={isMobile} full={full} noMargin={noMargin}>
      <Wrapper fill="horizontal" secondary={secondary}>
        <Box
          align="center"
          direction="row"
          gap="24px"
          justify={isMobile ? 'between' : 'start'}
          fill="horizontal"
        >
          <h3>{title}</h3>

          {to && (
            <Link to={to}>
              <Button icon={<FormNextLink color={themeColor} size="32px" />} />
            </Link>
          )}
        </Box>
        {children}
      </Wrapper>
    </WrapperUp>
  );
}

const SubtitleWrapper = styled(Box)`
  flex: 1;
  color: #888888;
  h4 {
    font-size: 16px;
    margin: 18px 0 0 0;
    font-weight: 800;
  }

  button {
    padding: 0;
  }
`;

// eslint-disable-next-line react/prop-types
function Subtitle({ title, menuItems }) {
  return (
    <SubtitleWrapper align="center" direction="row" justify="between">
      <h4>{title}</h4>

      {menuItems && (
        <Box flex="grow" direction="row" align="center" justify="end">
          <DropdownButton label={<DotMenuIcon />} items={menuItems} />
        </Box>
      )}
    </SubtitleWrapper>
  );
}

PageSection.Subtitle = Subtitle;
