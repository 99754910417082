import { i18nMark, Trans } from '@lingui/react';
import { Button, TableRow } from 'grommet';
import { Trash } from 'grommet-icons';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import translatedMessage from 'translatedMessage';

export default class AdminUserListRow extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    onRemove: PropTypes.func,
  };

  state = {};

  render() {
    const { user } = this.props;

    return (
      <TableRow>
        <td>{user.displayname}</td>
        <td>{user.email}</td>
        <td>{user.admin ? <Trans id="user-type.manager" /> : <Trans id="user-type.employee" />}</td>
        <td>
          <Button onClick={this.handleRemove} icon={<Trash />} />
        </td>
      </TableRow>
    );
  }

  handleRemove = () => {
    const { userTeamId, displayname } = this.props.user;

    i18nMark('team.user-list.confirm-user-delete');
    const removeMember = window.confirm(
      `${translatedMessage('team.user-list.confirm-user-delete')} ${displayname}?`,
    );

    if (!removeMember) {
      return;
    }

    this.props.onRemove(userTeamId);
  };
}
