import PropTypes from 'prop-types';
import { Grommet } from 'components/Grommet';
import styled from 'styled-components';

import { ColumnConsumer, UserConsumer } from 'contexts';
import AddMultipleColumns from 'components/AddMultipleColumns';
import { Trans } from '@lingui/react';

const Container = styled.div`
  padding: 2em 0;
`;

export function Creator({ onCreateColumns }) {
  return (
    <Grommet>
      <Container>
        <h2>
          <Trans id="column-creator.header" />
        </h2>
        <AddMultipleColumns onCreateColumns={onCreateColumns} />
      </Container>
    </Grommet>
  );
}

Creator.propTypes = {
  onCreateColumns: PropTypes.func,
};

export default function ContextWrapper(props) {
  return (
    <UserConsumer>
      {({ currentTeam }) => (
        <ColumnConsumer>
          {({ createColumns }) => (
            <Creator
              onCreateColumns={columns => createColumns(columns, currentTeam.id)}
              {...props}
            />
          )}
        </ColumnConsumer>
      )}
    </UserConsumer>
  );
}
