import { Component } from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import memoize from 'memoize-one';
import groupBy from 'lodash/groupBy';

// import { getUserDateKey } from 'modules/Availabilities/Availabilities';
import ChangeRequestRow from 'components/ChangeRequestsTable/ChangeRequestRow';
import SpreadHeader from 'components/SpreadHeader';
import { AvailabilityConsumer, ChangeRequestConsumer } from 'contexts';
import { getItemCompositeId } from 'contexts/utils';
import { Trans } from '@lingui/react';

function processAggregatedChangeRequests(userDateChangeRequests) {
  const { userTeamId, date, columnDayId } = userDateChangeRequests[0];

  return {
    columnDayId,
    date,
    userTeamId,
    changeRequests: userDateChangeRequests,
  };
}

class ChangeRequestTable extends Component {
  static propTypes = {
    changeRequests: PropTypes.array,
    getColumn: PropTypes.func,
    getUser: PropTypes.func,
    onAcceptChangeRequests: PropTypes.func,
    onDeclineChangeRequests: PropTypes.func,
  };

  static defaultProps = {
    changeRequests: [],
  };

  changeRequestAggregator = memoize(changeRequests =>
    Object.values(
      groupBy(changeRequests, changeRequest => `${changeRequest.userTeamId}-${changeRequest.date}`),
    ).map(processAggregatedChangeRequests),
  );

  render() {
    const aggregatedChangeRequests = this.changeRequestAggregator(this.props.changeRequests);

    return (
      <Container>
        <SpreadHeader>
          <h4>
            <Trans id="change-requests-table.header" />
          </h4>
        </SpreadHeader>

        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>
                <Trans id="change-requests-table.person" />
              </th>
              <th>
                <Trans id="change-requests-table.date" />
              </th>
              <th>
                <Trans id="change-requests-table.changes" />
              </th>
              <th align="right" />
            </tr>
          </thead>
          <tbody>
            {aggregatedChangeRequests.map(userDateChangeRequest => (
              <ChangeRequestRow
                key={getItemCompositeId(userDateChangeRequest)}
                changeRequests={userDateChangeRequest.changeRequests}
                date={userDateChangeRequest.date}
                getColumn={this.props.getColumn}
                getUser={this.props.getUser}
                onAccept={this.props.onAcceptChangeRequests}
                onDecline={this.props.onDeclineChangeRequests}
                user={userDateChangeRequest.userTeamId}
              />
            ))}
          </tbody>
        </table>
      </Container>
    );
  }
}

export default function ContextWrapper(props) {
  return (
    <ChangeRequestConsumer>
      {({ changeRequests }) => (
        <AvailabilityConsumer>
          {() => (
            <ChangeRequestTable
              changeRequests={changeRequests}
              acceptChangeRequests={null}
              declineChangeRequests={null}
              {...props}
            />
          )}
        </AvailabilityConsumer>
      )}
    </ChangeRequestConsumer>
  );
}
