import Network from 'utils/network';
import { fromPairs } from 'lodash';
import get from 'lodash/get';
import nth from 'lodash/nth';
import { createContext, useCallback, useState } from 'react';
export const ShiftWorkingHoursContext = createContext({});

const COLOR_SCALE = ['#ff324d', '#ff4c22', '#ff8c12', '#ffd602', '#eeec00', '#bbdb00', '#8cc800'];

const { Provider } = ShiftWorkingHoursContext;

// eslint-disable-next-line no-unused-vars
function fetchData(dateFrom, teamId) {
  // eslint-disable-next-line no-unused-vars
  const month = dateFrom.slice(0, 7);

  return Network.get(`/teams/${teamId}/shifts-hour-summary?month=${month}`);
}

function convertWorkingHoursArrayToObject(workingHours) {
  return fromPairs(workingHours.map(({ userTeamId, hours }) => [userTeamId, hours]));
}

function getMaxWorkingHours(workingHours) {
  if (Object.keys(workingHours) === 0) {
    return 2137;
  }

  return Math.max(...Object.values(workingHours));
}

function getWorkingHoursColor(hours, maxHours) {
  if (hours === maxHours) {
    return nth(COLOR_SCALE, -1);
  }

  const colorIndex = Math.round((hours / maxHours) * COLOR_SCALE.length);

  if (colorIndex === COLOR_SCALE.length) {
    return nth(COLOR_SCALE, -1);
  }
  return nth(COLOR_SCALE, colorIndex);
}

// eslint-disable-next-line react/prop-types
export function ShiftWorkingHoursProvider({ children }) {
  // state is of the following shape:
  // { [userTeamId]: hours }}
  //
  // for example:
  // { 10: 120 }
  const [workingHours, setWorkingHours] = useState({});
  const maxWorkingHours = getMaxWorkingHours(workingHours);

  const modifyUserWorkingHours = useCallback(
    (userTeamId, delta) => {
      setWorkingHours(_state => ({ ..._state, [userTeamId]: get(_state, userTeamId, 0) + delta }));
    },
    [setWorkingHours],
  );

  const getUserWorkingHours = useCallback(
    userTeamId => {
      const hours = get(workingHours, userTeamId, 0);

      const color = getWorkingHoursColor(hours, maxWorkingHours);

      return { hours, color };
    },
    [workingHours],
  );

  const fetchWorkingHours = useCallback(async (...args) => {
    try {
      const { data = [] } = await fetchData(...args);
      setWorkingHours(convertWorkingHoursArrayToObject(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error getting working hours:', error);
    }
  });

  return (
    <Provider
      value={{
        fetchWorkingHours,
        modifyUserWorkingHours,
        getUserWorkingHours,
      }}
    >
      {children}
    </Provider>
  );
}
