import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { backgroundSecondaryColor } from 'config/colors';
import TakeButton from './TakeButton';

const Wrapper = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 0 0 0.5em !important;
  border-radius: 5px;
  margin: 0.2em 0.5em;

  background: ${backgroundSecondaryColor};

  & > * {
    flex: 1;
    margin-right: 3px;
  }
`;

export default class PendingExchangesRow extends PureComponent {
  state = {
    taking: false,
  };

  render() {
    const { userName, date, columnHeader } = this.props.exchange;

    return (
      <Wrapper>
        <div>{userName}</div>
        <div>{date}</div>
        <div>{columnHeader}</div>
        <div>
          <TakeButton taking={this.state.taking} onTake={this.handleTake} />
        </div>
      </Wrapper>
    );
  }

  handleTake = async () => {
    this.setState({ taking: true });

    try {
      await this.props.onTake(this.props.exchange);
    } catch (error) {
      this.setState({ taking: false });
    }
  };
}

PendingExchangesRow.propTypes = {
  column: PropTypes.string,
  date: PropTypes.string,
  exchange: PropTypes.object,
  onTake: PropTypes.func,
  user: PropTypes.string,
};
