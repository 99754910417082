import { Trans } from '@lingui/react';
import { DateFormField } from 'components/DateFormField';
import { Notification } from 'components/Notification';
import { TimeFormField } from 'components/TimeFormField';
import ModalLayer from 'components/ui/ModalLayer';
import { HUMAN_READABLE_FORMAT } from 'config/dates';
import { withFormik } from 'formik';
import { Box, Button, Footer, Form, FormField, Header, TextInput } from 'grommet';
import { I18nContextWrapper } from 'i18n';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useModal } from 'react-modal-hook';
import styled from 'styled-components';
import {
  convertStartEndToStartDuration,
  isStartEndOvernight,
  validateTimeFormat,
} from 'utils/Dates';

const ButtonContainer = styled.div`
  padding: 0px 5px;
`;

const AddColumnFormInner = props => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    header,
    isSubmitting,
    onClose,
    setFieldValue,
    status,
    touched,
    values,
  } = props;

  const isOvernight = touched.start && touched.end && isStartEndOvernight(values.start, values.end);
  const showOvernightWarning = touched.start && touched.end && isOvernight;

  return (
    <Box
      id="add-new-column-modal"
      fill="horizontal"
      justify="center"
      align="center"
      responsive
      pad="medium"
    >
      <Form>
        <Header>
          <h2>{header}</h2>
        </Header>
        <FormField
          label={<Trans id="add-column-modal.form.header" />}
          error={touched.header && errors.header}
          data-testid="field-header"
        >
          <TextInput
            id="add-new-column-modal-header-input"
            value={values.header}
            name="header"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormField>

        {values.hasDate && (
          <FormField
            label={<Trans id="add-column-modal.form.date" />}
            error={touched.date && errors.date}
            data-testid="field-date"
          >
            <DateFormField
              id="add-new-column-modal-date-input"
              name="date"
              format="DD-MM-YYYY"
              onChange={value => setFieldValue('date', value, true)}
              onBlur={handleBlur}
              value={values.date}
            />
          </FormField>
        )}

        <FormField
          label={<Trans id="add-column-modal.form.from" />}
          error={touched.start && errors.start}
          data-testid="field-start"
        >
          <TimeFormField
            id="add-new-column-modal-start-input"
            name="start"
            format="HH:mm"
            onChange={event => setFieldValue('start', event.target.value, true)}
            onBlur={handleBlur}
            value={values.start}
          />
        </FormField>

        <FormField
          label={<Trans id="add-column-modal.form.to" />}
          error={touched.end && errors.end}
          data-testid="field-end"
        >
          <TimeFormField
            id="add-new-column-modal-end-input"
            name="end"
            format="HH:mm"
            onChange={event => setFieldValue('end', event.target.value, true)}
            onBlur={handleBlur}
            value={values.end}
          />
        </FormField>
        <Footer>
          <Box full="horizontal">
            {showOvernightWarning && (
              <Box pad={{ vertical: 'small' }}>
                <div>
                  <Trans id="add-column-modal.form.overnight-shift-warning" />
                </div>
              </Box>
            )}
            <Box justify="center" align="center" direction="row" pad="large" responsive={false}>
              <ButtonContainer>
                <Button
                  id="add-new-column-modal-save-button"
                  box
                  size="xsmall"
                  label={<Trans id="add-column-modal.form.submit" />}
                  primary={true}
                  onClick={isSubmitting ? null : handleSubmit}
                />
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  box
                  size="xsmall"
                  label={<Trans id="add-column-modal.form.cancel" />}
                  onClick={isSubmitting ? null : onClose}
                />
              </ButtonContainer>
            </Box>
            {status && <Notification message={status} size="medium" status="critical" />}
          </Box>
        </Footer>
      </Form>
    </Box>
  );
};

AddColumnFormInner.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  header: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onLogin: PropTypes.func,
  setFieldValue: PropTypes.func,
  status: PropTypes.any,
  teamName: PropTypes.string,
  touched: PropTypes.object,
  values: PropTypes.object,
};

export const AddColumnForm = withFormik({
  mapPropsToValues: props => ({
    hasDate: Boolean(props.day),
    date: props.day && moment(props.day).format(HUMAN_READABLE_FORMAT),
    header: '',
    start: '',
    end: '',
  }),
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { header, date, start, end } = values;
    const { onClose } = props;

    const { start: requestStart, duration } = convertStartEndToStartDuration(start, end);

    try {
      setStatus(null);
      await props.onAdd([{ header, date, start: requestStart, duration }]);
      setStatus(null);
      setSubmitting(false);

      onClose();
    } catch (exception) {
      const errorMessage = exception.message || exception.error;

      setStatus(errorMessage);
      setSubmitting(false);
    }
  },
  validate: values => {
    const errors = {};

    if (!values.header || values.header === '') {
      errors['header'] = <Trans id="forms.required" />;
    }

    if ((values.hasDate && !values.date) || values.date === '') {
      errors['date'] = <Trans id="forms.required" />;
    }

    if (!values.start || values.start === '') {
      errors['start'] = <Trans id="forms.required" />;
    } else if (!validateTimeFormat(values.start)) {
      errors['start'] = <Trans id="forms.invalid-date" />;
    }

    if (!values.end || values.end === '') {
      errors['end'] = <Trans id="forms.required" />;
    } else if (!validateTimeFormat(values.end)) {
      errors['end'] = <Trans id="forms.invalid-date" />;
    }

    return errors;
  },
})(AddColumnFormInner);

export default function AddColumnModal({ columns, header, day, onAdd, onClose, isOpen }) {
  return isOpen ? (
    <ModalLayer onEsc={onClose}>
      <AddColumnForm columns={columns} day={day} header={header} onAdd={onAdd} onClose={onClose} />
    </ModalLayer>
  ) : null;
}

AddColumnModal.propTypes = {
  columns: PropTypes.array,
  day: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isOpen: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
};

export function useAddColumnModal({ day: initialDay, onCreateColumns }) {
  const [day, setDay] = useState(initialDay);
  const [showModal, hideModal] = useModal(
    () => (
      <I18nContextWrapper>
        <AddColumnModal
          day={day}
          header={<Trans id="add-column-modal.header" />}
          isOpen
          onAdd={onCreateColumns}
          onClose={hideModal}
        />
      </I18nContextWrapper>
    ),
    [day],
  );

  const handleShowModal = useCallback(
    _day => {
      setDay(_day);
      showModal();
    },
    [setDay],
  );

  return [handleShowModal];
}
