import { Trans } from '@lingui/react';
import Spinner from 'components/Spinner';
import { Anchor, Button, Paragraph } from 'grommet';
import { Copy, FormRefresh } from 'grommet-icons';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import styled from 'styled-components';

const TokenContainer = styled.pre`
  margin: 0;
  padding-right: 3em;
`;

export default class SetupNotifications extends Component {
  static propTypes = {
    botLink: PropTypes.string,
    generateToken: PropTypes.func,
    onCopyToClipboard: PropTypes.func,
  };

  state = {
    generatingToken: false,
    token: null,
  };

  async componentDidMount() {}

  render() {
    return (
      <Fragment>
        <Paragraph>
          <Trans id="account.notifications.facebook-messenger.setup.intro" />
        </Paragraph>
        <Paragraph>
          <ol>
            <li>
              <div>
                <div>
                  <Trans id="account.notifications.facebook-messenger.setup.copy-token" />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height: '4em' }}>
                  {this.state.token ? (
                    <Fragment>
                      <TokenContainer>{this.state.token}</TokenContainer>
                      <Button
                        icon={<Copy />}
                        label={<Trans id="forms.copy" />}
                        plain
                        onClick={this.handleCopy}
                      />
                    </Fragment>
                  ) : (
                    <div>
                      {this.state.generatingToken ? (
                        <Spinner />
                      ) : (
                        <Button
                          icon={<FormRefresh />}
                          label={
                            <Trans id="account.notifications.facebook-messenger.setup.generate-token" />
                          }
                          onClick={this.handleGenerateToken}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </li>
            <li>
              <Trans
                id="account.notifications.facebook-messenger.setup.send-and-copy-token"
                components={[<Anchor href={this.props.botLink} target="_blank" />]}
              />
            </li>
            <li>
              <Trans id="account.notifications.facebook-messenger.setup.done" />
            </li>
          </ol>
        </Paragraph>
      </Fragment>
    );
  }

  handleGenerateToken = async () => {
    this.setState({ generatingToken: true });

    const { data } = await this.props.generateToken();

    this.setState({ token: data, generatingToken: false });
  };

  handleCopy = () => {
    this.props.onCopyToClipboard(this.state.token);
  };
}
