import { Trans } from '@lingui/react';
import Modal from 'components/Modal';
import { withFormik } from 'formik';
import {
  Box,
  Button,
  Footer,
  Form,
  FormField,
  Grommet,
  Header,
  Paragraph,
  RadioButton,
  TextInput,
} from 'grommet';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  padding: 0px 5px;
`;

const AddChangeRequestFormInner = props => {
  const {
    columnHeader,
    currentAvailability,
    date,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    onClose,
    setFieldValue,
    touched,
    values,
  } = props;

  return (
    <Form>
      <Header>
        <Box horizontal="full" direction="column" justify="center">
          <div>
            <h2>
              <Trans id="add-change-request-modal.header" />
            </h2>
          </div>

          <Box direction="row" horizontal="full" justify="between">
            <div>
              <h3>{columnHeader}</h3>
            </div>
            <div>
              <h3>{date}</h3>
            </div>
          </Box>
        </Box>
      </Header>
      <FormField
        label={<Trans id="add-change-request-modal.form.description" />}
        error={touched.newDescription && errors.newDescription}
      >
        <TextInput
          value={values.newDescription}
          name="newDescription"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormField>

      <FormField>
        {currentAvailability !== true && (
          <RadioButton
            name="newAvailable"
            label={<Trans id="add-change-request-modal.form.available" />}
            checked={values.newAvailable === true}
            onChange={() => setFieldValue('newAvailable', true)}
          />
        )}
        {currentAvailability !== false && (
          <RadioButton
            name="newAvailable"
            label={<Trans id="add-change-request-modal.form.not-available" />}
            value={values.newAvailable}
            checked={values.newAvailable === false}
            onChange={() => setFieldValue('newAvailable', false)}
          />
        )}
        {currentAvailability !== null && (
          <RadioButton
            name="newAvailable"
            label={<Trans id="add-change-request-modal.form.unknown" />}
            value={values.newAvailable}
            checked={values.newAvailable === null}
            onChange={() => setFieldValue('newAvailable', null)}
          />
        )}
      </FormField>
      <Footer>
        <Box justify="center" align="center" direction="row" fill="horizontal" pad="large">
          <ButtonContainer>
            <Button label="Save" primary={true} onClick={isSubmitting ? null : handleSubmit} />
          </ButtonContainer>
          <ButtonContainer>
            <Button label="Cancel" onClick={isSubmitting ? null : onClose} />
          </ButtonContainer>
        </Box>
      </Footer>
      <Paragraph size="small">
        <Trans id="add-change-request-modal.deadlined-info" />
      </Paragraph>
    </Form>
  );
};

AddChangeRequestFormInner.propTypes = {
  columnHeader: PropTypes.string,
  currentAvailability: PropTypes.any,
  date: PropTypes.string,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onLogin: PropTypes.func,
  setFieldValue: PropTypes.func,
  status: PropTypes.any,
  teamName: PropTypes.string,
  touched: PropTypes.object,
  values: PropTypes.object,
};

const AddChangeRequestForm = withFormik({
  mapPropsToValues: props => ({
    userTeamId: props.userTeamId,
    columnDayId: props.columnDayId,
    date: props.date,
    newAvailable: false,
    newDescription: '',
  }),

  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { newAvailable, newDescription } = values;
    const { userTeamId, columnHeader, columnDayId, date, onClose } = props;

    try {
      setStatus(null);
      // TODO: pass propes values
      const changeRequest = {
        columnHeader,
        userTeamId,
        columnDayId,
        date,
        newAvailable,
        newDescription,
      };

      await props.onSave(changeRequest);
      setStatus(null);
      setSubmitting(false);

      onClose();
    } catch (exception) {
      const errorMessage = exception.message || exception.error;

      setStatus(errorMessage);
      setSubmitting(false);
    }
  },
})(AddChangeRequestFormInner);

const customStyles = {
  overlay: {
    zIndex: 10,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default function AddChangeRequestModal({
  columnHeader,
  columnDayId,
  currentAvailability,
  date,
  isOpen,
  onClose,
  onSave,
  userTeamId,
}) {
  return (
    <Modal isOpen={isOpen} styles={customStyles}>
      <Grommet>
        <AddChangeRequestForm
          currentAvailability={currentAvailability}
          columnHeader={columnHeader}
          columnDayId={columnDayId}
          date={date}
          onClose={onClose}
          onSave={onSave}
          userTeamId={userTeamId}
        />
      </Grommet>
    </Modal>
  );
}

AddChangeRequestModal.propTypes = {
  columnHeader: PropTypes.string,
  columnDayId: PropTypes.number,
  columns: PropTypes.array,
  currentAvailability: PropTypes.any,
  date: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  userTeamId: PropTypes.number,
};
