import { Trans } from '@lingui/react';
import DeadlinedIcon from 'components/DeadlinedIcon';
import { backgroundColor, defaultTableItemColor, themeColor } from 'config/colors';
import { DEADLINE_STATES } from 'config/deadlines';
import { getItemCompositeId } from 'contexts/utils';
import { User } from 'grommet-icons';
import get from 'lodash/get';
import noop from 'lodash/noop';
import moment from 'moment';
import { getUserTextForDisplay } from 'page/App/utils';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getShiftKey } from './ItemModule/itemModule';

const PlainLink = styled(Link)`
  color: ${themeColor};

  &:hover {
    text-decoration: none;
  }
`;

export const UndefinedColumnCell = styled.td`
  text-align: center;
  vertical-align: middle !important;
  color: gray;
  border-right: 3px solid #f0f0f0 !important;
`;

const TableItem = styled.td`
  min-width: 30vw;
  max-width: 30vw;
  height: 42px;

  border-right: ${props => (props.borderColumn ? '3px solid #f0f0f0 !important' : 'inherit')};
`;

const UserTableItemWrapper = styled.td`
  max-width: 35vw;
  min-height: 42px;

  background: white;

  @media (max-width: 719px) {
    font-size: 0.7rem;
  }
`;

const TableHeader = styled.th`
  font-weight: 400;
`;

const UserTableItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const getCellKey = (cell, rowIndex, itemIndex) =>
  get(cell, 'primaryItem')
    ? `prim-${getItemCompositeId(cell.primaryItem)}`
    : get(cell, 'backgroundItem')
      ? `back-${getItemCompositeId(cell.backgroundItem)}`
      : `${rowIndex}-${itemIndex}`;

export default class Table extends Component {
  state = { columnHeaderOffset: 0 };

  columnsRow = null;
  previousHeight = null;

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
    window.removeEventListener('resize', this.handleScroll, true);
  }

  render() {
    const {
      columns,
      currentUser,
      deadlines,
      getColumnData,
      modifyHandler,
      onCanvasClick,
      onItemClick,
      renderCanvas,
      renderItem,
      renderUser,
      sums,
      table,
      users,
    } = this.props;

    const columnsNumber = Object.keys(columns).length + 1;
    const columnWidth = 100 / columnsNumber + '%';
    let counter = 0;

    return (
      <div className="rota-rota-table-container">
        <table className="rota-rota-table">
          <thead>
            <tr ref={this.columnsRowRef}>
              <th style={{ top: 0 }} />
              {Object.entries(columns).map(([key, dayColumns]) => (
                <TableHeader
                  colSpan={dayColumns.length}
                  key={key}
                  style={{ textAlign: 'center', width: columnWidth }}
                >
                  {deadlines[key] && (
                    <span style={{ position: 'relative' }}>
                      <DeadlinedIcon
                        status={deadlines[key]}
                        date={key}
                        id={`deadline-icon-${key}`}
                      />
                    </span>
                  )}
                  {moment(key).format('ddd')} {key}
                </TableHeader>
              ))}
            </tr>
            <tr>
              <th style={{ top: this.state.columnHeaderOffset }} />
              {Object.entries(columns).map(([key, dayColumns]) => (
                <Fragment key={key}>
                  {dayColumns.map((dayColumn, index) => {
                    const columnData = getColumnData(counter);
                    counter++;

                    // todo: fix this ugly thing:
                    const column = dayColumns.find(dc => dc.id === columnData.id);
                    const borderColumn = index + 1 === dayColumns.length;

                    if (!columnData) {
                      //eslint-disable-next-line
                      console.warn(
                        `Column data undefined for itemIdex: ${index} and table: ${table}`,
                      );
                    }

                    return column.id ? (
                      <th
                        key={`${key}-${column.id}`}
                        style={{
                          fontSize: '12px',
                          textAlign: 'center',
                          textOverflow: 'ellipsis',
                          top: this.state.columnHeaderOffset,
                          background: 'white',
                          maxWidth: '30vw',
                          fontWeight: '400',
                          borderRight: borderColumn ? '3px solid #f0f0f0' : 'inherit',
                        }}
                      >
                        <PlainLink to={`work/shifts/${dayColumn.id}`}>{column.header}</PlainLink>
                        <div>
                          {column.start} - {column.end}
                        </div>
                      </th>
                    ) : (
                      <th
                        style={{
                          fontSize: '12px',
                          textAlign: 'center',
                          textOverflow: 'ellipsis',
                          top: this.state.columnHeaderOffset,
                          background: backgroundColor,
                          borderRight: borderColumn ? '3px solid #f0f0f0' : 'inherit',
                        }}
                      />
                    );
                  })}
                </Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.values(table).map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                <UserTableItemWrapper>
                  <UserTableItem>
                    {renderUser
                      ? renderUser(users[rowIndex])
                      : getUserTextForDisplay(users[rowIndex])}
                    {currentUser &&
                      currentUser.userTeamId === users[rowIndex].userTeamId && (
                        <User size="small" />
                      )}
                  </UserTableItem>
                </UserTableItemWrapper>
                {Object.values(row).map((cell, itemIndex) => {
                  const columnData = getColumnData(itemIndex);

                  if (!columnData) {
                    //eslint-disable-next-line
                    console.warn(
                      `Column data undefined for itemIdex: ${itemIndex} and table: ${table}`,
                    );
                  }

                  if (get(columnData, 'id')) {
                    const date = columnData.date;
                    const borderColumn = date !== get(getColumnData(itemIndex + 1), 'date');
                    const primaryItem = get(cell, 'primaryItem');
                    const backgroundItem = get(cell, 'backgroundItem');
                    const column = getColumnData(itemIndex);
                    const userTeamId = users[rowIndex].userTeamId;

                    const key = getCellKey(cell, rowIndex, itemIndex);
                    const id = `${users[rowIndex].displayname}-${column.date}-${column.header}`;
                    // const id = `shift-${users[rowIndex].userTeamId}-${column}`;
                    // todo: maybe just pass deadline: deadline[date], decide inside?
                    if (primaryItem || backgroundItem) {
                      return (
                        <TableItem key={key} borderColumn={borderColumn}>
                          {renderItem({
                            id,
                            key,
                            isDeadlined: deadlines[date] === DEADLINE_STATES.AFTER_ALREADY,
                            backgroundItem,
                            column,
                            onCanvasClick,
                            onClick: onItemClick,
                            modifyHandler,
                            primaryItem,
                            userTeamId,
                          })}
                        </TableItem>
                      );
                    } else {
                      return (
                        <TableItem key={key} borderColumn={borderColumn}>
                          {renderCanvas({
                            id,
                            key,
                            isDeadlined: deadlines[date] === DEADLINE_STATES.AFTER_ALREADY,
                            column,
                            onClick: onCanvasClick,
                            modifyHandler,
                            userTeamId,
                          })}
                        </TableItem>
                      );
                    }
                  } else {
                    return rowIndex === 0 ? (
                      <UndefinedColumnCell rowSpan={users.length}>
                        <Trans id="rota-table.not-yet-defined" />
                      </UndefinedColumnCell>
                    ) : null;
                  }
                })}
              </tr>
            ))}
            {sums && (
              <tr>
                <td style={{ background: 'white' }}>Sum:</td>

                {Object.values(sums).map((sum, index) => {
                  const columnData = getColumnData(index);

                  if (!columnData) {
                    //eslint-disable-next-line
                    console.warn(
                      `Column data undefined for itemIdex: ${index} and table: ${table}`,
                    );
                  }

                  if (get(columnData, 'id')) {
                    const { date, id } = columnData;
                    const key = getShiftKey(date, id);

                    return <td key={`sum-${index}-${sum}`}>{sums[key]}</td>;
                  } else {
                    return <td key={`sum-${index}-${sum}`}>0</td>;
                  }
                })}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  columnsRowRef = ref => {
    this.columnsRow = ref;

    if (ref) {
      this.handleUpdatePosition();
    }
  };

  handleScroll = () => {
    if (!this.animationFrame) {
      this.animationFrame = window.requestAnimationFrame(this.handleUpdatePosition);
    }
  };

  handleUpdatePosition = () => {
    const { height } = this.columnsRow.getBoundingClientRect();

    if (height !== this.previousHeight) {
      this.setState({ columnHeaderOffset: height });
      this.previousHeight = height;
    }

    this.animationFrame = null;
  };
}

Table.propTypes = {
  columns: PropTypes.any,
  currentUser: PropTypes.object,
  deadlines: PropTypes.any,
  getBackgroundItemCellColor: PropTypes.func,
  getColumnData: PropTypes.func,
  getForegroundItemCellColor: PropTypes.func,
  modifyHandler: PropTypes.func,
  onCanvasClick: PropTypes.func,
  onItemClick: PropTypes.func,
  renderCanvas: PropTypes.func,
  renderItem: PropTypes.func,
  renderUser: PropTypes.func,
  sums: PropTypes.array,
  table: PropTypes.any,
  users: PropTypes.any,
};

Table.defaultProps = {
  getBackgroundItemCellColor: noop,
  getForegroundItemCellColor: () => defaultTableItemColor,
};
