import { Trans } from '@lingui/react';
import { EditableColumnCalendarFilter } from 'components/ColumnCalendar';
import { SELECTION_TYPES } from 'components/ColumnCalendar/config';
import { CalendarBar } from 'components/ui/ItemsCalendar/CalendarBar';
import { ItemsCalendar } from 'components/ui/ItemsCalendar/ItemsCalendar';
import { DATE_FORMAT, HUMAN_READABLE_FORMAT } from 'config/dates';
import { Box } from 'grommet';
import { flatten } from 'lodash';
import moment from 'moment';
import NotificationsBar from 'page/components/NotificationsBar';
import { useEffect, useState } from 'react';
import errorMessageHandler from 'utils/errorMessageHandler';
import { useAddColumnModal } from './AddColumnModal';
import ColumnItem from './ColumnItem';
import { useEditColumnModalWithParams } from './EditColumnModal';
import { useSaveDayTemplateModalWithParams } from './SaveDayTemplateModal';

export function EditableColumnCalendar({
  adminMode,
  columns: propColumns,
  month: propMonth,
  onColumnCopy,
  onColumnEdit,
  onCreateColumns,
  onDayCopy,
  onDeleteColumns,
  onMoveDateBackward,
  onMoveDateForward,
  onResetSelection,
  onSaveDayTemplate,
  selectedItem,
  onError = () => {},
}) {
  const [columns, setColumns] = useState([]);
  const [month, setMonth] = useState(propMonth);

  useEffect(
    () => {
      setMonth(propMonth);
      setColumns(propColumns);
    },
    [propMonth, propColumns, setMonth, setColumns],
  );

  const [showAddColumnModal] = useAddColumnModal({ onCreateColumns });
  const [showEditColumnModal] = useEditColumnModalWithParams({ onColumnEdit });
  const [showSaveDayTemplateModal] = useSaveDayTemplateModalWithParams({
    onSave: onSaveDayTemplate,
  });

  function handleShowAddColumnModal(...args) {
    onResetSelection();
    showAddColumnModal(...args);
  }
  function handleShowEditColumnModal(...args) {
    onResetSelection();
    showEditColumnModal(...args);
  }
  function handleShowSaveDayTemplateModal(...args) {
    onResetSelection();
    showSaveDayTemplateModal(...args);
  }
  async function handleDeleteColumn(...args) {
    onResetSelection();

    try {
      await onDeleteColumns(...args);
    } catch (exception) {
      onError(errorMessageHandler(exception));
    }
  }

  async function handleDayCopy(columns) {
    if (columns.length === 0) {
      onError(<Trans id="calendar-day.copy-day.empty-error" />);
      return;
    }

    try {
      await onDayCopy(columns);
    } catch (exception) {
      onError(errorMessageHandler(exception));
    }
  }

  function handleDayClick(day) {
    if (selectedItem) {
      const { type, item } = selectedItem;
      switch (type) {
        case SELECTION_TYPES.COLUMN:
          return onCreateColumns([
            {
              ...item,
              date: moment(day, DATE_FORMAT).format(HUMAN_READABLE_FORMAT),
            },
          ]);

        case SELECTION_TYPES.DAY_TEMPLATE:
          // Prevent pasting day template on the same day
          if (item.columns.find(column => column.date === day)) {
            return;
          }

          return onCreateColumns(
            item.columns.map(column => ({
              ...column,
              date: moment(day, DATE_FORMAT).format(HUMAN_READABLE_FORMAT),
            })),
          );
      }
    }
  }

  return (
    <Box fill="horizontal">
      <CalendarBar
        leftControls={adminMode && <NotificationsBar />}
        month={month}
        onMoveDateBackward={onMoveDateBackward}
        onMoveDateForward={onMoveDateForward}
      />
      <EditableColumnCalendarFilter columns={columns} month={month}>
        {({ columns: filteredColumns }) => (
          <ItemsCalendar
            month={month}
            showEmptyDays
            items={flatten(Object.values(filteredColumns)).filter(c => c !== undefined)}
            onDayClick={handleDayClick}
            renderItem={({ item: column, ...props }) => (
              <ColumnItem
                adminMode={adminMode}
                column={column}
                onEdit={() => handleShowEditColumnModal(column)}
                onCopy={() => onColumnCopy(column)}
                onDelete={columns => handleDeleteColumn(columns)}
                {...props}
              />
            )}
            renderItemMobile={({ item: column, ...props }) => (
              <ColumnItem
                adminMode={adminMode}
                column={column}
                onEdit={handleShowEditColumnModal}
                onCopy={() => onColumnCopy(column)}
                onDelete={columns => onDeleteColumns(columns)}
                {...props}
              />
            )}
            sectionMenuItems={[
              {
                label: <Trans id="calendar-day.add-column" />,
                onClick: (event, columns, day) => {
                  handleShowAddColumnModal(day);
                },
              },
              {
                label: <Trans id="calendar-day.copy-day" />,
                onClick: (event, columns) => {
                  handleDayCopy(columns);
                },
              },
              {
                label: <Trans id="calendar-day.save-day-template" />,
                onClick: (event, columns) => {
                  handleShowSaveDayTemplateModal(columns);
                },
              },
            ]}
          />
        )}
      </EditableColumnCalendarFilter>
    </Box>
  );
}
