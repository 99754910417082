import { Trans } from '@lingui/react';
import { Notification } from 'components/Notification';
import Spinner from 'components/Spinner';
import Toast from 'components/Toast';
import { Page, PageHeader, PageSection } from 'components/ui/Page';
import userApi from 'contexts/User/api';
import { Formik } from 'formik';
import { Box, Button, Footer, Form, FormField, TextInput } from 'grommet';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';

function ChangePasswordForm({ onChangePassword, passwordRegex }) {
  return (
    <Formik
      initialValues={{ oldPassword: '', newPassword: '', passwordConfirmation: '' }}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        const { oldPassword, newPassword } = values;
        try {
          await onChangePassword(oldPassword, newPassword);
          setStatus({ type: 'success' });
          setSubmitting(false);
        } catch (exception) {
          setStatus({ type: 'error', message: 'Error occured. Please try again.' });
          setSubmitting(false);
        }
      }}
      validate={values => {
        const errors = {};

        if (!values['oldPassword'] || values['oldPassword'] === '') {
          errors['oldPassword'] = <Trans id="forms.required" />;
        }

        if (!passwordRegex.test(values.newPassword)) {
          errors['newPassword'] = <Trans id="forms.weak-password" />;
        }

        if (values.newPassword !== values.passwordConfirmation) {
          errors['passwordConfirmation'] = <Trans id="forms.passwords-dont-match" />;
          // 'Passwords do not match!';
        }

        return errors;
      }}
      render={({
        values,
        handleSubmit,
        handleBlur,
        handleChange,
        status,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form>
          <FormField
            label={<Trans id="account.change-password.form.old-password" />}
            error={touched.oldPassword && errors.oldPassword}
          >
            <TextInput
              type="password"
              value={values.oldPassword}
              name="oldPassword"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
          <FormField
            label={<Trans id="account.change-password.form.new-password" />}
            error={
              touched.newPassword &&
              errors.newPassword && (
                <span>
                  <Trans id="account.change-password.form.password-warning" />
                </span>
              )
            }
          >
            <TextInput
              type="password"
              value={values.newPassword}
              name="newPassword"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
          <FormField
            label={<Trans id="account.change-password.form.confirm-password" />}
            error={touched.passwordConfirmation && errors.passwordConfirmation}
          >
            <TextInput
              type="password"
              value={values.passwordConfirmation}
              name="passwordConfirmation"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
          <Footer>
            <Box justify="center" align="center" fill="horizontal" pad="large">
              <Button
                label={<Trans id="account.change-password.form.submit" />}
                primary={true}
                onClick={isSubmitting ? null : handleSubmit}
              />
              <div style={{ height: '40px', padding: '15px' }}>
                {isSubmitting && (
                  <div>
                    <Spinner size="small" />
                  </div>
                )}
                {get(status, 'type') === 'success' && (
                  <Toast status="success">
                    <Trans id="accounts.change-password.success" />
                  </Toast>
                )}
                {get(status, 'type') === 'error' && (
                  <Notification message={status.message} size="medium" status="critical" />
                )}
              </div>
            </Box>
          </Footer>
        </Form>
      )}
    />
  );
}

ChangePasswordForm.propTypes = {
  onChangePassword: PropTypes.func,
  passwordRegex: PropTypes.any,
};

export default class ChangePassword extends Component {
  static propTypes = {
    onChangePassword: PropTypes.func,
  };

  render() {
    return (
      <Page header={<PageHeader title={<Trans id="account.menu.change-password" />} />}>
        <PageSection>
          <ChangePasswordForm
            onChangePassword={userApi.changePassword}
            passwordRegex={/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/}
          />
        </PageSection>
      </Page>
    );
  }
}
