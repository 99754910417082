import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  cursor: ${props => (props.onClick ? 'pointer' : 'initial')};
  margin: 0;
  padding: 0;
  display: ${props => (props.inline ? 'inline' : 'flex')};
`;

const IWrapper = styled.i.attrs({ className: 'material-icons' })`
  font-size: ${props => props.size || 24}px;
`;

export default function Icon({ name, onClick, size, inline, ...props }) {
  return (
    <Wrapper onClick={onClick} inline={inline} {...props}>
      <IWrapper size={size}>{name}</IWrapper>
    </Wrapper>
  );
}

Icon.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number,
  inline: PropTypes.bool,
};
