import analytics from 'analytics';
import { ItemsCalendar } from 'components/ui/ItemsCalendar/ItemsCalendar';
import { UserContext } from 'contexts/User/UserContext';
import { Loader } from 'page/components/routing';
import { useCallback, useContext } from 'react';
import { getTwoWeeksDateInterval } from 'utils/Dates';
import { useDateFromToQueryParams } from 'utils/hooks';
import { ShiftsList, useGetShifts } from './components/ShiftsList';
import { ShiftToTake } from './components/ShiftToTake';

export function ShiftsToTake({ dateFrom, dateTo }) {
  const { currentUserTeamId } = useContext(UserContext);

  const handleTake = useCallback(() => {
    analytics.track({ category: 'Take shift', action: 'From shifts to take list' });
  }, []);

  return (
    <ShiftsList
      dateFrom={dateFrom}
      dateTo={dateTo}
      url="/shifts/exchangeable/{{ teamId }}"
      renderItem={({ item: shift, ...props }) => (
        <ShiftToTake shift={shift} {...props} onTake={handleTake} />
      )}
      // Skipping current user shifts
      filterItems={shift => shift.userTeamId !== currentUserTeamId}
    />
  );
}

export function ShiftsToTakeCalendar() {
  const { dateFrom, dateTo } = useDateFromToQueryParams({
    initialDates: getTwoWeeksDateInterval(),
  });

  const [{ shifts = [], loading, error }, refetch] = useGetShifts({
    url: '/shifts/exchangeable/{{ teamId }}',
    dateFrom,
    dateTo,
  });

  const handleTake = useCallback(() => {
    analytics.track({ category: 'Take shift', action: 'From shifts to take calendar' });
  }, []);

  return (
    <Loader loading={loading} error={error}>
      <ItemsCalendar
        items={shifts}
        renderItem={({ item: shift, ...props }) => (
          <ShiftToTake shift={shift} {...props} onTake={handleTake} />
        )}
        renderItemMobile={({ item: shift, ...props }) => (
          <ShiftToTake shift={shift} {...props} onTake={handleTake} />
        )}
        showWeeks
        dateFrom={dateFrom}
        dateTo={dateTo}
        onRefresh={refetch}
      />
    </Loader>
  );
}
