import { Trans } from '@lingui/react';
import { DateFormField } from 'components/DateFormField';
import Toast from 'components/Toast';
import { criticalColor } from 'config/colors';
import { HUMAN_READABLE_FORMAT } from 'config/dates';
import { useI18n } from 'contexts/i18nWrapper';
import { FieldArray, Formik } from 'formik';
import { Box, Button, Footer, Form, FormField, Menu, TextInput } from 'grommet';
import { Add as AddIcon, StatusCritical, Trash } from 'grommet-icons';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { convertStartEndToStartDuration } from 'utils/Dates';
import { useIsMobile } from 'utils/responsive';
import { TimeFormField } from 'components/TimeFormField';

export const RequiredFieldsWarning = styled.h4`
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 8px;

  color: ${criticalColor};
`;

const ColumnsAddedSuccesfully = () => {
  const i18n = useI18n();
  return i18n._('add-multiple-columns.added-succesfully');
};

const ToastError = () => {
  const i18n = useI18n();
  return i18n._('error');
};

const cx = (conditionalClasses, classNames) =>
  `${classNames} ` +
  Object.entries(conditionalClasses)
    .filter(entry => entry[1])
    .map(entry => entry[0])
    .join(' ');

export function AddMultipleColumnsInner({ onCreateColumns, mobile }) {
  return (
    <Formik
      initialValues={{ columns: [{ header: '', date: moment().format(HUMAN_READABLE_FORMAT) }] }}
      validate={values => {
        const errors = Object.assign(
          {},
          { columns: new Array(values.columns.length) },
          { anything: false },
        );

        const validate = condition => {
          if (condition) {
            errors.anything = true;
          }
          return condition;
        };

        values.columns.forEach((column, index) => {
          errors.columns[index] = {};

          if (validate(!column.header || column.header === '')) {
            errors.columns[index]['header'] = 'Required!';
          }

          if (validate(!moment(column.date, HUMAN_READABLE_FORMAT).isValid())) {
            errors.columns[index]['date'] = 'Required!';
          }
        });

        return errors.anything ? errors : {};
      }}
      onSubmit={async (values, { setStatus, setSubmitting, setValues }) => {
        const { columns } = values;

        const requestColumns = columns.map(column => ({
          ...column,
          ...convertStartEndToStartDuration(column.from, column.to),
        }));

        try {
          setStatus({});
          await onCreateColumns(requestColumns);
          setStatus({});
          setSubmitting(false);

          setStatus({ type: 'success' });
          setValues({ columns: [] });
        } catch (exception) {
          const errorMessage = exception.message || exception.error;

          setStatus({ type: 'error', message: errorMessage });
          setSubmitting(false);
        }
      }}
      render={({ values, submitForm, handleBlur, handleChange, status, errors, setFieldValue }) => (
        <div id="column-creator">
          {get(status, 'type') === 'success' && (
            <Toast status="success">
              <ColumnsAddedSuccesfully />
            </Toast>
          )}
          {get(status, 'type') === 'error' && (
            <Toast status="critical">
              <ToastError />: {status.message}
            </Toast>
          )}
          <Form className={cx({ 'rota-horizontal-form': !mobile }, 'rota-form-hide-error-message')}>
            <Box direction="row" align="center" justify="end" pad={{ between: 'medium' }}>
              <Button
                id="column-creator-save-columns-button"
                size="small"
                onClick={errors.columns ? null : submitForm}
                icon={<AddIcon />}
                label={<Trans id="add-multiple-columns.save" />}
              />
            </Box>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 10 }}>
                <h4>
                  <Trans id="add-multiple-columns.header" />
                </h4>
              </div>
              <div style={{ flex: 4 }}>
                <h4>
                  <Trans id="add-multiple-columns.date" />
                </h4>
              </div>
              <div style={{ flex: 2 }}>
                <h4>
                  <Trans id="add-multiple-columns.from" />
                </h4>
              </div>
              <div style={{ flex: 2 }}>
                <h4>
                  <Trans id="add-multiple-columns.to" />
                </h4>
              </div>
              <div style={{ flex: 1 }} />
            </div>
            <FieldArray
              name="columns"
              render={arrayHelpers => (
                <div>
                  {values.columns.map((column, index) => (
                    <div
                      style={{ display: 'flex', flexDirection: mobile ? 'column' : 'row' }}
                      key={index}
                      className="column-creator-form-row"
                    >
                      <div style={{ flex: 10 }}>
                        <FormField
                          error={
                            get(errors, `columns[${index}].header`)
                              ? errors.columns[index].header
                              : null
                          }
                        >
                          <TextInput
                            className="column-creator-header-input"
                            value={values.columns[index].header}
                            name={`columns.${index}.header`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormField>
                      </div>
                      <div style={{ flex: 4 }}>
                        <FormField
                          error={
                            get(errors, `columns[${index}].date`)
                              ? errors.columns[index].date
                              : null
                          }
                        >
                          <DateFormField
                            className="column-creator-date-input"
                            name="date"
                            format="DD-MM-YYYY"
                            onChange={value => setFieldValue(`columns.${index}.date`, value, true)}
                            onBlur={handleBlur}
                            placeHolder="Date"
                            value={values.columns[index].date}
                          />
                        </FormField>
                      </div>
                      <div style={{ flex: 2 }}>
                        <FormField>
                          <TimeFormField
                            className="column-creator-from-input"
                            value={values.columns[index].from}
                            name={`columns.${index}.from`}
                            onChange={handleChange}
                            format="HH:mm"
                            onBlur={handleBlur}
                          />
                        </FormField>
                      </div>
                      <div style={{ flex: 2 }}>
                        <FormField>
                          <TimeFormField
                            className="column-creator-to-input"
                            value={values.columns[index].to}
                            name={`columns.${index}.to`}
                            onChange={handleChange}
                            format="HH:mm"
                            onBlur={handleBlur}
                          />
                        </FormField>
                      </div>
                      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <Button
                          onClick={() => arrayHelpers.remove(index)}
                          size="small"
                          icon={<Trash />}
                        />
                      </div>
                      {mobile && <hr />}
                    </div>
                  ))}
                  <Footer justify="start">
                    <Box
                      direction="row"
                      fill="horizontal"
                      alignContent="between"
                      justify="between"
                      pad={{ between: 'medium' }}
                    >
                      <Menu
                        direction="row"
                        responsive={false}
                        size="small"
                        dropAlign={{ right: 'right' }}
                      >
                        <Button
                          id="column-creator-add-column-button"
                          onClick={() =>
                            arrayHelpers.push({
                              header: '',
                              date: moment().format(HUMAN_READABLE_FORMAT),
                            })
                          }
                          size="small"
                          plain
                          label={<Trans id="add-multiple-columns.add-column-row" />}
                          icon={<AddIcon />}
                        />
                      </Menu>

                      {errors.columns && (
                        <Box direction="row" align="center">
                          <StatusCritical />
                          <RequiredFieldsWarning>
                            <Trans id="add-multiple-columns.fill-the-required-fields" />
                          </RequiredFieldsWarning>
                        </Box>
                      )}
                    </Box>
                  </Footer>
                </div>
              )}
            />
          </Form>
        </div>
      )}
    />
  );
}

AddMultipleColumnsInner.propTypes = {
  mobile: PropTypes.bool,
  onCreateColumns: PropTypes.func,
};

export default function AddMultipleColumns(props) {
  const isMobile = useIsMobile();

  return <AddMultipleColumnsInner mobile={isMobile} {...props} />;
}
