import { i18nMark, Trans } from '@lingui/react';
import AddColumnModal from 'components/ColumnCalendar/AddColumnModal';
import ItemList from 'components/ItemList';
import { Box, Button } from 'grommet';
import { AddCircle } from 'grommet-icons';
import PropTypes from 'prop-types';
import { Component } from 'react';
import translatedMessage from 'translatedMessage';

i18nMark('predefined-columns-list.add-predefined-column');

export default class PredefinedColumnsList extends Component {
  static propTypes = {
    items: PropTypes.array,
    onCreate: PropTypes.func,
    onItemClick: PropTypes.func,
    listMenuOptions: PropTypes.array,
  };

  state = {
    showAddColumnModal: false,
  };

  render() {
    return (
      <Box>
        <AddColumnModal
          header={translatedMessage('predefined-columns-list.add-predefined-column')}
          isOpen={this.state.showAddColumnModal}
          onAdd={this.props.onCreate}
          onClose={this.handleCloseModal}
        />

        <Box
          direction="row"
          responsive={false}
          align="center"
          alignItems="center"
          justify="between"
          fill="horizontal"
        >
          <h4 style={{ margin: 0 }}>
            <Trans id="predefined-columns-list.header" />
          </h4>
          <div style={{ paddingLeft: '0.5em' }}>
            <Button icon={<AddCircle />} onClick={this.handleOpenModal} />
          </div>
        </Box>
        <ItemList
          items={this.props.items}
          onItemClick={this.props.onItemClick}
          menuOptions={this.props.listMenuOptions}
        />
      </Box>
    );
  }
  handleOpenModal = () => {
    this.setState({ showAddColumnModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showAddColumnModal: false });
  };
}
