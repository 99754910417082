import { Trans } from '@lingui/react';
import { EditableColumnCalendar } from 'components/ColumnCalendar';
import { SELECTION_TYPES } from 'components/ColumnCalendar/config';
import { getMonthFromLocation } from 'components/utils/getDateSpanFromLocation';
import { DATE_FORMAT_MONTHS } from 'config/dates';
import { ColumnConsumer, UserConsumer } from 'contexts';
import { fetchColumnCalendarDataAction } from 'contexts/actions';
import { getColumnsFromColumnDays } from 'contexts/selectors';
import { Anchor, Box, Footer, Menu } from 'grommet';
import { Multiple } from 'grommet-icons';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import last from 'lodash/last';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component, Fragment, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getDaysWithExtrasForMonth } from 'utils/Dates';
import { useIsMobile } from 'utils/responsive';
import TemplatesColumn from './TemplatesColumn';

function filterHackyUndefinedColumns(calendar) {
  return fromPairs(
    Object.entries(calendar).map(([date, columns]) => [
      date,
      columns.filter(column => Boolean(get(column, 'id'))),
    ]),
  );
}

export class Columns extends Component {
  static propTypes = {
    addColumns: PropTypes.func,
    adminMode: PropTypes.bool,
    columns: PropTypes.object,
    dayTemplates: PropTypes.array,
    fetchData: PropTypes.func,
    fetchDayTemplates: PropTypes.func,
    fetchPredefinedColumns: PropTypes.func,
    isMobile: PropTypes.bool,
    location: PropTypes.object,
    onColumnEdit: PropTypes.func,
    onCreateColumns: PropTypes.func,
    onCreatePredefinedColumns: PropTypes.func,
    onDeleteColumns: PropTypes.func,
    onDeleteDayTemplates: PropTypes.func,
    onDeletePredefinedColumns: PropTypes.func,
    onSaveDayTemplate: PropTypes.func,
    predefinedColumns: PropTypes.array,
    onError: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const currentMonth =
      getMonthFromLocation(props.location) || moment().format(DATE_FORMAT_MONTHS);

    this.state = {
      currentMonth,
      loading: true,
      // TODO: add something indicating current "UI state" and pass it down,
      //       components will decide later what cursor to show an similars.
      selectedItem: null,
    };
  }

  componentDidMount() {
    Promise.all([
      this.handleMoveDate(0),
      this.props.fetchPredefinedColumns(),
      this.props.fetchDayTemplates(),
    ]).then(() => {
      this.setState({ loading: false });
    });

    window.addEventListener('keydown', this.handleKeyDown);
    window.onpopstate = this.handleHistoryChange;
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    const { adminMode } = this.props;

    const fixedColumns = filterHackyUndefinedColumns(this.props.columns);

    return this.state.loading ? (
      <div>Loading ...</div>
    ) : (
      <Fragment>
        <Box direction="row-responsive" className="rota-Box-fix">
          {adminMode && (
            <Box
              width={this.props.isMobile ? '100%' : '280px'}
              style={{
                ...(this.props.isMobile
                  ? { width: '100%' }
                  : {
                      flex: '1 0 280px',
                    }),
              }}
            >
              <TemplatesColumn
                predefinedColumns={this.props.predefinedColumns}
                onPredefinedColumnClick={this.handlePredefinedColumnClick}
                onCreatePredefinedColumns={this.props.onCreatePredefinedColumns}
                onDeletePredefinedColumns={this.props.onDeletePredefinedColumns}
                dayTemplates={this.props.dayTemplates}
                onDayTemplateClick={this.handleDayTemplateClick}
                onDeleteDayDemplates={this.props.onDeleteDayTemplates}
              />
            </Box>
          )}
          <Box pad="medium" fill="horizontal">
            <EditableColumnCalendar
              adminMode={adminMode}
              columns={fixedColumns}
              month={this.state.currentMonth}
              onColumnCopy={this.handleColumnCopy}
              onColumnEdit={this.props.onColumnEdit}
              onCreateColumns={this.props.onCreateColumns}
              onDayCopy={this.handleDayCopy}
              onDeleteColumns={this.props.onDeleteColumns}
              onError={this.props.onError}
              onMoveDateBackward={() => this.handleMoveDate(-1)}
              onMoveDateForward={() => this.handleMoveDate(1)}
              onResetSelection={this.resetSelection}
              onSaveDayTemplate={this.handleSaveDayTemplate}
              selectedItem={this.state.selectedItem}
            />
            {adminMode && (
              <Footer justify="end">
                <Box direction="row" align="center" pad={{ between: 'medium' }}>
                  <Menu
                    responsive={false}
                    direction="row"
                    size="small"
                    dropAlign={{ right: 'right' }}
                  >
                    <Link to="/columns/creator">
                      <Anchor
                        label={<Trans id="columns.add-multiple-columns" />}
                        as="div"
                        icon={<Multiple />}
                      />
                    </Link>
                  </Menu>
                </Box>
              </Footer>
            )}
          </Box>
        </Box>
      </Fragment>
    );
  }

  handleMoveDate = async monthsOffset => {
    const targetMonth = moment(this.state.currentMonth)
      .add(monthsOffset, 'month')
      .format(DATE_FORMAT_MONTHS);

    const days = getDaysWithExtrasForMonth(targetMonth);

    const dateFromNext = days[0];
    const dateToNext = last(days);

    await this.props.fetchData(dateFromNext, dateToNext);

    this.setState({ currentMonth: targetMonth });
  };

  handleDayTemplateClick = dayTemplate => {
    this.setState({
      selectedItem: {
        type: SELECTION_TYPES.DAY_TEMPLATE,
        item: dayTemplate,
      },
    });
  };

  handlePredefinedColumnClick = column => {
    this.setState({
      selectedItem: {
        type: SELECTION_TYPES.COLUMN,
        item: column,
      },
    });
  };

  handleDayCopy = columns => {
    this.handleDayTemplateClick({ columns });
  };

  handleColumnCopy = column => {
    this.handlePredefinedColumnClick(column);
  };

  handleSaveDayTemplate = (header, columns) => this.props.onSaveDayTemplate(header, columns);

  handleKeyDown = event => {
    const { keyCode } = event;

    switch (keyCode) {
      // ESC
      case 27:
        this.resetSelection();
        break;
    }
  };

  resetSelection = () => {
    this.setState({ selectedItem: null });
  };
}

export default function ContextWrapper(props) {
  const isMobile = useIsMobile();
  const { addToast } = useToasts();

  const handleError = useCallback(
    errorMessage => {
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    },
    [addToast],
  );

  return (
    <UserConsumer>
      {({ currentTeam, isAdminInCurrentTeam }) => (
        <ColumnConsumer>
          {({
            addColumns,
            columns,
            createColumns,
            createPredefinedColumns,
            deletePredefinedColumns,
            dayTemplates,
            deleteColumns,
            editColumn,
            fetchColumns,
            fetchDayTemplates,
            fetchPredefinedColumns,
            predefinedColumns,
            saveDayTemplate,
            deleteDayTemplates,
          }) => (
            <Columns
              isMobile={isMobile}
              addColumns={addColumns}
              adminMode={isAdminInCurrentTeam()}
              columns={getColumnsFromColumnDays(columns)}
              dayTemplates={dayTemplates}
              onColumnEdit={editColumn}
              fetchData={fetchColumnCalendarDataAction({
                fetchColumnsAction: fetchColumns,
                teamId: currentTeam.id,
              })}
              fetchPredefinedColumns={() => {
                fetchPredefinedColumns(currentTeam.id);
              }}
              fetchDayTemplates={() => {
                fetchDayTemplates(currentTeam.id);
              }}
              predefinedColumns={predefinedColumns}
              {...props}
              onCreateColumns={columns => createColumns(columns, currentTeam.id)}
              onDeleteColumns={(columns, date) => deleteColumns(columns, date, currentTeam.id)}
              onSaveDayTemplate={(header, columns) =>
                saveDayTemplate(header, columns, currentTeam.id)
              }
              onDeleteDayTemplates={dayTemplates => deleteDayTemplates(dayTemplates)}
              onCreatePredefinedColumns={columns =>
                createPredefinedColumns(columns, currentTeam.id)
              }
              onDeletePredefinedColumns={columns => deletePredefinedColumns(columns)}
              onError={handleError}
            />
          )}
        </ColumnConsumer>
      )}
    </UserConsumer>
  );
}
