import { Trans } from '@lingui/react';
import analytics from 'analytics';
import HeaderButton from 'components/HeaderButton';
import ModalLayer from 'components/ui/ModalLayer';
import FEATURE_FLAGS from 'config/featureFlags';
import { Anchor, Box, Calendar, DropButton } from 'grommet';
import { Calendar as CalendarIcon, Configure, Next, Previous, Refresh } from 'grommet-icons';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div.attrs({ className: 'rota-small-grommet-buttons' })`
  display: flex;
  justify-content: center;
  padding: 5px 20px;
  padding-top: 10px;
`;

const HelperContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${props => (props.right ? 'flex-end' : props.left ? 'flex-start' : 'center')};
`;

export const RequiredFieldsWarning = styled.h5`
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 8px;

  color: #ffd602;
`;

// TODO: make this responsive as hell
export default class TableHeader extends PureComponent {
  static propTypes = {
    onJumpToDate: PropTypes.func,
    onMoveDate: PropTypes.func,
    onSetDateSpan: PropTypes.func,
  };

  state = {
    showJumpToDateModal: false,
  };

  render() {
    return (
      <Box className="rota-small-grommet-buttons rota-sgb-width-fix">
        {this.state.showJumpToDateModal && (
          <ModalLayer onEsc={this.handleHideJumpToDate}>
            <Box pad="small">
              <Calendar date={new Date().toISOString()} onSelect={this.handleJumpToDate} />
            </Box>
          </ModalLayer>
        )}

        <HeaderContainer>
          <HelperContainer left>
            <HeaderButton icon={<Refresh color="light-1" />} plain onClick={this.handleRefresh} />
          </HelperContainer>

          <HelperContainer>
            <HeaderButton
              icon={<Previous color="light-1" />}
              plain
              onClick={this.handleBackwardDate}
            />
            <HeaderButton icon={<Next color="light-1" />} plain onClick={this.handleForwardDate} />
          </HelperContainer>

          <HelperContainer right>
            <HeaderButton
              icon={<CalendarIcon color="light-1" />}
              plain
              onClick={this.handleOpenJumpToDate}
            />

            <DropButton
              icon={<Configure color="light-1" />}
              dropAlign={{ top: 'bottom', right: 'right' }}
              dropContent={
                <Box>
                  <Anchor onClick={this.handleSetOneDaySpan}>
                    <Box pad="small">
                      <Trans id="rota-table.options.one-day" />
                    </Box>
                  </Anchor>
                  <Anchor onClick={this.handleSetThreeDaySpan}>
                    <Box pad="small">
                      <Trans id="rota-table.options.three-days" />
                    </Box>
                  </Anchor>
                  {FEATURE_FLAGS.WEEK_OVERVIEW && (
                    <Anchor onClick={() => this.props.onSetDateSpan(7)}>
                      <Box pad="small">
                        <Trans id="rota-table.options.week" />
                      </Box>
                    </Anchor>
                  )}
                </Box>
              }
            />
          </HelperContainer>
        </HeaderContainer>
      </Box>
    );
  }

  handleSetOneDaySpan = () => {
    this.props.onSetDateSpan(1);
    analytics.track({ category: 'RotaTable navigation', action: 'Set one day span' });
  };

  handleSetThreeDaySpan = () => {
    this.props.onSetDateSpan(3);
    analytics.track({ category: 'RotaTable navigation', action: 'Set three day span' });
  };

  handleRefresh = () => {
    // TODO: Extract this as a prop, so it does not depend here onMoveDate? OTOH - YAGNI for now
    this.props.onMoveDate(0);
    analytics.track({ category: 'RotaTable navigation', action: 'Refresh' });
  };

  handleForwardDate = () => {
    this.props.onMoveDate(1);
  };

  handleBackwardDate = () => {
    this.props.onMoveDate(-1);
  };

  handleHideJumpToDate = () => {
    this.setState({ showJumpToDateModal: false });
  };

  handleOpenJumpToDate = () => {
    this.setState({ showJumpToDateModal: true });
  };

  handleJumpToDate = date => {
    this.handleHideJumpToDate();
    this.props.onJumpToDate(date);
  };
}
