import useAxios from 'axios-hooks';
import { getDeadlinesForDaysWithData } from 'components/RotaTable/ItemModule/itemModule';
import { processRequest } from 'contexts/utils';
import qs from 'qs';
import { useMemo } from 'react';
import Network from 'utils/network';

export function getUpcomingDeadlinesURL(teamId) {
  const queryParams = qs.stringify({ teamId }, { addQueryPrefix: true });
  return `/deadlines/get-upcoming/${queryParams}`;
}

export function getTeamDeadlinesURL(dateFrom, dateTo, teamId) {
  const queryParams = qs.stringify({ dateFrom, dateTo, teamId }, { addQueryPrefix: true });
  return `/deadlines/get/${queryParams}`;
}

export default {
  getTeamDeadlines: (dateFrom, dateTo, teamId) => {
    const url = getTeamDeadlinesURL(dateFrom, dateTo, teamId);
    return Network.get(url);
  },

  getUpcomingDeadlines: teamId => {
    const url = getUpcomingDeadlinesURL(teamId);
    return Network.get(url);
  },

  addDeadline: (deadline, teamId) =>
    processRequest(Network.post(`/deadlines/create`, { ...deadline, teamId })),

  removeDeadline: deadlineId => {
    const queryParams = qs.stringify({ deadlineId }, { addQueryPrefix: true });
    return processRequest(Network.delete(`/deadlines/delete/${queryParams}`));
  },
};

export function useGetUpcomingDeadlines(teamId) {
  const url = getUpcomingDeadlinesURL(teamId);
  return useAxios(url);
}

export function useGetTeamDeadlines({ dateFrom, dateTo, teamId }) {
  const url = getTeamDeadlinesURL(dateFrom, dateTo, teamId);
  const [{ data: deadlines = [], ...dataRest }, refetch] = useAxios(url);

  const deadlineDayStatus = useMemo(
    () => {
      const dateToday = new Date().toISOString();
      return getDeadlinesForDaysWithData(dateToday, dateFrom, dateTo, deadlines);
    },
    [deadlines],
  );

  return [{ deadlines, deadlineDayStatus, ...dataRest }, refetch];
}
