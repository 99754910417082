import PropTypes from 'prop-types';

import Cell from './Cell';

export default function ShiftCanvas({ onClick, column, userTeamId, ...props }) {
  return <Cell data-test-status="canvas" onClick={() => onClick(userTeamId, column)} {...props} />;
}

ShiftCanvas.propTypes = {
  onClick: PropTypes.func,
  column: PropTypes.object,
  userTeamId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
