import { Switch, Route } from 'react-router-dom';

import { Grommet } from 'components/Grommet';
import UI from 'components/UI';
import {
  Login,
  Logout,
  Register,
  Join,
  PasswordReset,
  RequestPasswordReset,
} from 'page/Public/Auth';

export default function App() {
  return (
    <Grommet>
      <UI>
        <Switch>
          <Route path="/password/request-reset" component={RequestPasswordReset} />
          <Route path="/password/reset" component={PasswordReset} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/register" component={Register} />
          <Route path="/join" component={Join} />
          <Route render={() => <div>404</div>} />
        </Switch>
      </UI>
    </Grommet>
  );
}
