import { Trans } from '@lingui/react';
import AddChangeRequestModal from 'components/AddChangeRequestModal';
import ChangeRequestsTable from 'components/ChangeRequestsTable';
import ConfirmationModal from 'components/ConfirmationModal';
import DeadlinesTable from 'components/DeadlinesTable';
import { getAvailabilitiesForegroundColor } from 'components/itemCellColors';
import { EditableRotaTable } from 'components/RotaTable';
import AvailabilityCanvas from 'components/RotaTable/Cells/AvailabilityCanvas';
import AvailabilityCell from 'components/RotaTable/Cells/AvailabilityCell';
import { getNextStatus } from 'components/RotaTable/ItemModule/itemModule';
import Toast from 'components/Toast';
import getDateSpanFromLocation from 'components/utils/getDateSpanFromLocation';
import updateDateSpanQueryParams from 'components/utils/updateDateSpanQueryParams';
import { defaultDateSpan, MAXIMUM_DAY_SPAN } from 'config/dates';
import {
  AvailabilityConsumer,
  ChangeRequestConsumer,
  ColumnConsumer,
  DeadlineConsumer,
  UserConsumer,
} from 'contexts';
import {
  addDeadlineAction,
  fetchColumnsAction,
  fetchRotaTableDataAction,
  removeDeadlineAction,
} from 'contexts/actions';
import { useI18n } from 'contexts/i18nWrapper';
import { getItemCompositeId } from 'contexts/utils';
import get from 'lodash/get';
import noop from 'lodash/noop';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { applyDaysOffset, getDateKey } from 'utils/Dates';
import errorMessageHandler from 'utils/errorMessageHandler';
import { getScreenSizeToDaySpan, useScreenSize } from 'utils/responsive';

const ToastError = () => {
  const i18n = useI18n();
  return i18n._('error');
};

//TODO:
// - get dateFrom, dateTo from query params?
export class AvailabilitiesEdit extends Component {
  static propTypes = {
    adminMode: PropTypes.bool,
    availabilities: PropTypes.object.isRequired,
    changeRequests: PropTypes.array,
    columns: PropTypes.any,
    currentUser: PropTypes.object,
    deadlines: PropTypes.any,
    fetchData: PropTypes.func,
    getColumn: PropTypes.func,
    getUser: PropTypes.func,
    onAcceptChangeRequests: PropTypes.func,
    onAddDeadline: PropTypes.func,
    onCreateChangeRequests: PropTypes.func,
    onDeclineChangeRequests: PropTypes.func,
    onRemoveDeadline: PropTypes.func,
    onSave: PropTypes.func,
    screenSize: PropTypes.string,
    users: PropTypes.any,
  };

  constructor(props) {
    super(props);
    const dateSpan = getDateSpanFromLocation(props.location) || defaultDateSpan;
    this.state = {
      ...dateSpan,
      loaded: false,
      newChangeRequestData: null,
      addChangeRequestError: null,
      deleteChangeRequestData: null,
      deleteChangeRequestError: null,
    };
  }

  async componentDidMount() {
    const dateSpan = getScreenSizeToDaySpan(this.props.screenSize);
    this.onSetDateSpan(dateSpan);

    await this.handleMoveDate(0);

    this.setState({ loaded: true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.screenSize !== this.props.screenSize) {
      const dateSpan = getScreenSizeToDaySpan(this.props.screenSize);
      this.onSetDateSpan(dateSpan);
    }
  }

  render() {
    const showAddChangeRequestModal = Boolean(this.state.newChangeRequestData);
    const showDeleteChangeRequestModal = Boolean(this.state.deleteChangeRequestData);
    return (
      <div>
        {showAddChangeRequestModal && (
          <AddChangeRequestModal
            isOpen={true}
            {...this.state.newChangeRequestData}
            onClose={this.handleCloseAddChangeRequestModal}
            onSave={this.handleCreateChangeRequest}
          />
        )}
        {this.state.addChangeRequestError && (
          <Toast status="critical">
            <ToastError />: {this.state.addChangeRequestError}
          </Toast>
        )}

        {showDeleteChangeRequestModal && (
          <ConfirmationModal
            message={<Trans id="availabilities-edit.confirmation-modal-header" />}
            isOpen={true}
            onClose={this.handleCloseDeleteChangeRequestModal}
            onConfirm={this.handleDeclineChangeRequest}
          />
        )}
        {this.state.deleteChangeRequestError && (
          <Toast status="critical">
            <ToastError />: {this.state.deleteChangeRequestError}
          </Toast>
        )}

        {this.state.loaded ? (
          <EditableRotaTable
            canEdit={this.canEdit}
            columns={this.props.columns}
            currentUser={{ ...this.props.currentUser, userTeamId: this.props.currentUserTeamId }}
            dateFrom={this.state.dateFrom}
            dateTo={this.state.dateTo}
            deadlines={this.props.deadlines}
            items={this.props.availabilities}
            renderItem={props => (
              <AvailabilityCell
                isAdmin={this.props.adminMode}
                onAddChangeRequest={this.handleAddChangeRequest}
                {...props}
              />
            )}
            renderCanvas={props => (
              <AvailabilityCanvas
                isAdmin={this.props.adminMode}
                onAddChangeRequest={this.handleAddChangeRequest}
                {...props}
              />
            )}
            getForegroundItemCellColor={getAvailabilitiesForegroundColor}
            getOriginalItem={this.getOriginalItem}
            onCanvasClick={this.handleCanvasClick}
            onItemClick={this.handleItemClick}
            onItemSave={this.handleItemSave}
            onMoveDate={this.handleMoveDate}
            onSetDateSpan={this.onSetDateSpan}
            users={this.props.users}
          />
        ) : (
          <div>Loading ...</div>
        )}
        <div>
          <Row>
            <Col>
              <DeadlinesTable
                adminMode={this.props.adminMode}
                deadlines={this.props.deadlines}
                onAddDeadline={this.props.onAddDeadline}
                onRemoveDeadline={this.props.onRemoveDeadline}
              />
            </Col>
            <Col>
              <ChangeRequestsTable
                changeRequests={this.props.changeRequests}
                getColumn={this.props.getColumn}
                getUser={this.props.getUser}
                onAcceptChangeRequests={this.props.onAcceptChangeRequests}
                onDeclineChangeRequests={this.props.onDeclineChangeRequests}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getOriginalItem = (item, propItems) => {
    const { date } = item;
    const dateKey = getDateKey(date);
    const compositeKey = getItemCompositeId(item);
    return get(propItems, `${dateKey}.${compositeKey}`, null);
  };

  handleCanvasClick = (item, propItems) => {
    const { userTeamId, columnDayId, date } = item;

    const originalItem = this.getOriginalItem(item, propItems);

    return get(originalItem, 'available') === true
      ? originalItem
      : {
          userTeamId,
          columnDayId,
          date,
          available: true,
        };
  };

  handleItemClick = (item, propItems) => {
    const nextStatus = getNextStatus(item.available);

    const originalItem = this.getOriginalItem(item, propItems);

    if (originalItem && originalItem.available === nextStatus) {
      return originalItem;
    }

    return nextStatus === null ? null : { ...item, available: nextStatus };
  };

  handleMoveDate = async (daysOffset = 1) => {
    const { dateFrom, dateTo } = this.state;

    const dateFromNext = applyDaysOffset(dateFrom, daysOffset);
    const dateToNext = applyDaysOffset(dateTo, daysOffset);

    await this.props.fetchData(dateFromNext, dateToNext);

    updateDateSpanQueryParams(this.props.history, {
      dateFrom: dateFromNext,
      dateTo: dateToNext,
    });

    this.setState({
      dateFrom: dateFromNext,
      dateTo: dateToNext,
      loaded: true,
    });
  };

  handleItemSave = modifyRequest => this.props.onSave([modifyRequest]);

  onSetDateSpan = daySpan => {
    this.setState({
      loaded: false,
    });

    const dateFrom =
      daySpan === MAXIMUM_DAY_SPAN
        ? moment(this.state.dateFrom).startOf('isoWeek')
        : this.state.dateFrom;
    const dateTo = applyDaysOffset(dateFrom, daySpan - 1);

    this.setState(
      {
        dateFrom,
        dateTo,
      },
      async () => {
        await this.handleMoveDate(0);
      },
    );
  };

  _getChangeRequest = ({ userTeamId, columnDayId, date }) =>
    this.props.changeRequests.find(
      changeRequest =>
        changeRequest.userTeamId === userTeamId &&
        changeRequest.columnDayId === columnDayId &&
        changeRequest.date === date,
    );

  handleAddChangeRequest = ({ userTeamId, columnDayId, date, available }) => {
    if (this._getChangeRequest({ userTeamId, columnDayId, date })) {
      this.setState({ deleteChangeRequestData: { userTeamId, columnDayId, date } });
      return;
    }

    const column = this.props.columns[date].find(column => column.id === columnDayId);

    this.setState({
      newChangeRequestData: {
        userTeamId,
        columnDayId,
        date,
        columnHeader: column.header,
        currentAvailability: available,
      },
    });
  };

  handleCreateChangeRequest = async ({
    columnHeader,
    userTeamId,
    columnDayId,
    date,
    newAvailable,
    newDescription,
  }) => {
    const changeRequest = {
      columnHeader,
      userTeamId,
      columnDayId,
      date,
      newAvailable,
      newDescription,
    };

    try {
      await this.props.onCreateChangeRequests([changeRequest]);
      this.handleCloseAddChangeRequestModal();
    } catch (error) {
      this.setState({ addChangeRequestError: errorMessageHandler(error) });
    }
  };

  handleCloseAddChangeRequestModal = () => {
    this.setState({ newChangeRequestData: null });
  };

  handleDeclineChangeRequest = async () => {
    const changeRequest = this.state.deleteChangeRequestData;

    try {
      await this.props.onDeclineChangeRequests([changeRequest]);
      this.handleCloseDeleteChangeRequestModal();
    } catch (error) {
      this.setState({ deleteChangeRequestError: errorMessageHandler(error) });
    }
  };

  handleCloseDeleteChangeRequestModal = () => {
    this.setState({ deleteChangeRequestData: null });
  };

  canEdit = userTeamId => userTeamId === this.props.currentUserTeamId || this.props.adminMode;
}

export default function ContextWrapper(props) {
  const screenSize = useScreenSize();

  return (
    <UserConsumer>
      {({ currentTeam, user, isAdminInCurrentTeam, getUser }) => (
        <ColumnConsumer>
          {({ addColumns, columns, getColumn }) => (
            <ChangeRequestConsumer>
              {({
                changeRequests,
                createChangeRequests,
                fetchChangeRequests,
                acceptChangeRequests,
                declineChangeRequests,
              }) => (
                <DeadlineConsumer>
                  {({ addDeadline, fetchDeadlines, removeDeadline, deadlines }) => (
                    <AvailabilityConsumer>
                      {({ availabilities, modifyAvailabilities, fetchAvailabilities }) => (
                        <AvailabilitiesEdit
                          screenSize={screenSize}
                          adminMode={isAdminInCurrentTeam()}
                          currentUser={{ ...user, admin: isAdminInCurrentTeam() }}
                          currentUserTeamId={currentTeam.userTeamId}
                          changeRequests={changeRequests}
                          availabilities={availabilities}
                          columns={columns}
                          deadlines={deadlines}
                          getColumn={getColumn}
                          getUser={getUser}
                          onAcceptChangeRequests={acceptChangeRequests}
                          onCreateChangeRequests={createChangeRequests}
                          onDeclineChangeRequests={declineChangeRequests}
                          onAddDeadline={addDeadlineAction({
                            addDeadline,
                            teamId: currentTeam.id,
                          })}
                          onRemoveDeadline={removeDeadlineAction({
                            removeDeadline,
                          })}
                          onSave={modifyAvailabilities}
                          fetchData={fetchRotaTableDataAction({
                            fetchAvailabilitiesAction: fetchAvailabilities,
                            fetchChangeRequestsAction: fetchChangeRequests,
                            fetchColumnsAction: fetchColumnsAction({
                              addColumns,
                              teamId: currentTeam.id,
                            }),
                            fetchDeadlinesAction: fetchDeadlines,
                            fetchShiftsAction: noop,
                            teamId: currentTeam.id,
                          })}
                          users={currentTeam.users}
                          {...props}
                        />
                      )}
                    </AvailabilityConsumer>
                  )}
                </DeadlineConsumer>
              )}
            </ChangeRequestConsumer>
          )}
        </ColumnConsumer>
      )}
    </UserConsumer>
  );
}
