import telegramApi from 'api/telegram';
import { supportMail, telegramNotificationsLink } from 'config/supportAndService';
import copyToClipboard from 'copy-to-clipboard';
import { Anchor, Paragraph } from 'grommet';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import ServiceHeader from '../ServiceHeader';
import SetupNotifications from '../SetupNotifications';

export class TelegramNotificationsInner extends Component {
  static propTypes = {
    botLink: PropTypes.string,
    generateToken: PropTypes.func,
    isIntegrated: PropTypes.func,
    onCopyToClipboard: PropTypes.func,
  };

  state = {
    isIntegrated: null,
    loading: true,
  };

  async componentDidMount() {
    const { data: isIntegrated } = await this.props.isIntegrated();

    this.setState({ isIntegrated, loading: false });
  }

  render() {
    return (
      <Fragment>
        <ServiceHeader
          name="Telegram"
          iconUrl="https://cdn0.iconfinder.com/data/icons/social-media-2092/100/social-56-512.png"
        />
        <Paragraph>Notifications delivered directly through Telegram!</Paragraph>

        {this.state.loading ? (
          <div>loading ...</div>
        ) : this.state.isIntegrated ? (
          <Fragment>
            <Paragraph>Your Telegram notifications are properly set up!</Paragraph>
            <Paragraph>
              If you have any issues, please contact us at{' '}
              <Anchor label={supportMail} href={`mailto:${supportMail}`} target="_blank" />.
            </Paragraph>
          </Fragment>
        ) : (
          <SetupNotifications
            botLink={this.props.botLink}
            generateToken={this.props.generateToken}
            onCopyToClipboard={this.props.onCopyToClipboard}
          />
        )}
      </Fragment>
    );
  }
}

export default function TelegramNotifications() {
  return (
    <TelegramNotificationsInner
      botLink={telegramNotificationsLink}
      generateToken={telegramApi.generateToken}
      isIntegrated={telegramApi.isIntegrated}
      onCopyToClipboard={copyToClipboard}
    />
  );
}
