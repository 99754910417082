import { Box, Anchor, Accordion, AccordionPanel } from 'grommet';
import { Link } from 'components/Link';
import { useIsMobile } from 'utils/responsive';
import { useRef } from 'react';
import styled from 'styled-components';

const NavItemsWrapper = styled(Box)`
  @media (min-width: 720px) {
    max-width: 280px;
    min-width: 280px;
  }
`;

function NavItemsList({ navItems, onClick }) {
  return navItems.map(({ to, label }) => (
    <Box key={to} pad="small">
      <Link to={to} onClick={onClick}>
        <Anchor label={label} />
      </Link>
    </Box>
  ));
}

// eslint-disable-next-line react/prop-types
function NavItemsResponsiveWrapper({ navItems }) {
  const isMobile = useIsMobile();
  const node = useRef();

  function handleClickItem() {
    if (node.current) {
      const button = node.current.querySelector('button');

      if (button) {
        button.click();
      }
    }
  }

  return isMobile ? (
    <Accordion>
      <AccordionPanel ref={node} label={<Box pad="medium">Menu</Box>}>
        <NavItemsList navItems={navItems} onClick={handleClickItem} />
      </AccordionPanel>
    </Accordion>
  ) : (
    <NavItemsList navItems={navItems} />
  );
}
// eslint-disable-next-line react/prop-types
export function PageNavContainer({ navItems = [], children }) {
  return (
    <Box direction="row-responsive" fill="horizontal">
      <NavItemsWrapper pad="small" gap="small" margin={{ bottom: 'small' }}>
        <NavItemsResponsiveWrapper navItems={navItems} />
      </NavItemsWrapper>

      <Box pad="small" fill="horizontal">
        {children}
      </Box>
    </Box>
  );
}
