import { Trans } from '@lingui/react';
import Modal from 'components/Modal';
import { Formik } from 'formik';
import { Box, Button, FormField, Grommet, Header, Heading, TextInput } from 'grommet';
import { Close } from 'grommet-icons';
import PropTypes from 'prop-types';
import { useModal } from 'react-modal-hook';
import ModalLayer from 'components/ui/ModalLayer';
import { I18nContextWrapper } from 'i18n';

const customStyles = {
  overlay: {
    zIndex: 10,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '30vw',
  },
};

const EditDescriptionForm = ({ shift, onEdit, onClose, ...props }) => {
  const showDeleteButton = Boolean(shift.description);

  return (
    <Formik
      initialValues={{ description: shift.description }}
      onSubmit={async (values, actions) => {
        const { description } = values;

        try {
          await onEdit(shift, description);
          onClose();
        } catch (error) {
          actions.setStatus(error.toString());

          // eslint-disable-next-line no-console
          console.log('Cannot edit description. Error:', error);
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {formikBag => (
        <Box {...props}>
          <Header>
            <Box direction="row" align="center" justify="between">
              <Heading level={3} strong style={{ marginBottom: 0 }}>
                <Trans id="edit-shift-description-modal.header" />
              </Heading>
              <Button icon={<Close />} onClick={onClose} />
            </Box>
          </Header>

          <FormField label={<Trans id="edit-shift-description-modal.field-label" />}>
            <TextInput
              name="description"
              onChange={formikBag.handleChange}
              onBlur={formikBag.handleBlur}
              value={formikBag.values.description}
            />
          </FormField>

          {formikBag.status && <Box pad={{ vertical: 'small' }}>{formikBag.status}</Box>}

          <Box direction="row" justify="center" pad={{ vertical: 'small' }}>
            <Box pad="small">
              <Button
                primary
                label={<Trans id="edit-shift-description-modal.save" />}
                disabled={formikBag.isSubmitting}
                onClick={formikBag.handleSubmit}
              />
            </Box>
            {showDeleteButton ? (
              <Box pad="small">
                <Button
                  label={<Trans id="edit-shift-description-modal.delete" />}
                  disabled={formikBag.isSubmitting}
                  onClick={event => {
                    formikBag.setFieldValue('description', '');

                    // TOOD: refactor this abomination
                    setTimeout(() => {
                      formikBag.handleSubmit(event);
                    });
                  }}
                />
              </Box>
            ) : (
              <span />
            )}
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export function DescriptionBoxModal({ isOpen, onEdit, onClose, shift }) {
  return (
    <Modal isOpen={isOpen} styles={customStyles} shouldCloseOnOverlayClick={false}>
      <Grommet>
        <EditDescriptionForm shift={shift} onEdit={onEdit} onClose={onClose} />
      </Grommet>
    </Modal>
  );
}

export function useEditDescriptionModal({ shift, onEdit, onClose }) {
  const [showModal, hideModal] = useModal(
    () => (
      <ModalLayer onEsc={hideModal}>
        <I18nContextWrapper>
          <Box pad="small">
            <EditDescriptionForm
              shift={shift}
              onEdit={onEdit}
              onClose={(...args) => {
                if (onClose) {
                  onClose(...args);
                }
                hideModal();
              }}
            />
          </Box>
        </I18nContextWrapper>
      </ModalLayer>
    ),
    [shift, onEdit, onClose],
  );

  return [showModal, hideModal];
}

DescriptionBoxModal.propTypes = {
  isOpen: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  shift: PropTypes.object,
};
