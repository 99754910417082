import { Trans } from '@lingui/react';
import Modal from 'components/Modal';
import { Grommet } from 'components/Grommet';
import { Box, Button, Form, Header } from 'grommet';
import PropTypes from 'prop-types';

export default function ConfirmationModal({ message, isOpen, onClose, onConfirm }) {
  return (
    <Modal isOpen={isOpen}>
      <Grommet>
        <Form>
          <Box justify="center" align="center" responsive pad="medium">
            <Header>
              <h3>{message}</h3>
            </Header>
            <Box
              fill="horizontal"
              direction="row"
              justify="between"
              align="center"
              responsive
              pad="medium"
            >
              <Button
                label={<Trans id="confirmation-modal.confirm" />}
                primary
                onClick={onConfirm}
              />
              <Button label={<Trans id="confirmation-modal.cancel" />} primary onClick={onClose} />
            </Box>
          </Box>
        </Form>
      </Grommet>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
