import get from 'lodash/get';
import { getErrorMessage } from 'config/errors';
import { supportMail } from 'config/supportAndService';

export default function errorMessageHandler(error, fallbackMessage) {
  const errorCode = get(error, 'response.data');

  if (typeof errorCode === 'object') {
    return (
      getErrorMessage(get(errorCode, 'error')) ||
      get(errorCode, 'error_description') ||
      fallbackMessage
    );
  }

  const response = getErrorMessage(errorCode) || fallbackMessage;

  if (response) {
    return response;
  } else {
    // eslint-disable-next-line
    console.error(error);

    return `Unknown error. Please try again or contact us at ${supportMail} if the problem persists.`;
  }
}
