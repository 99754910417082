import PropTypes from 'prop-types';

import Cell from './Cell';

export default function AvailabilityCanvas({
  column,
  isAdmin,
  isDeadlined,
  onAddChangeRequest,
  onClick,
  userTeamId,
  ...props
}) {
  const { date, id: columnDayId } = column;
  const handler =
    isDeadlined && !isAdmin
      ? () => onAddChangeRequest({ userTeamId, columnDayId, date, available: null })
      : () => onClick(userTeamId, column);

  return <Cell onClick={handler} {...props} />;
}

AvailabilityCanvas.propTypes = {
  column: PropTypes.object,
  isAdmin: PropTypes.bool,
  onClick: PropTypes.func,
  userTeamId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
