import 'regenerator-runtime/runtime';

import * as sentry from 'logging/sentry';
sentry.init();

import { render } from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import I18nProvider from './i18n';
import { QueryParamProvider } from 'use-query-params';
import { ModalProvider } from 'react-modal-hook';
import Page from 'page';
import deadlineApi from 'api/deadline';
import 'analytics/init';

import {
  AvailabilityProvider,
  ChangeRequestProvider,
  ColumnProvider,
  DeadlineProvider,
  ShiftProvider,
  UserProvider,
  ShiftWorkingHoursProvider,
} from 'contexts';
import { GlobalStyle } from 'page/styles';

render(
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <ModalProvider>
        <I18nProvider>
          <ToastProvider>
            {/* TODO: check if this can be side component or need to wrap children */}
            <GlobalStyle />
            <ChangeRequestProvider>
              <AvailabilityProvider>
                <DeadlineProvider api={deadlineApi}>
                  <ColumnProvider>
                    <ShiftProvider>
                      <UserProvider>
                        <ShiftWorkingHoursProvider>
                          <Page />
                        </ShiftWorkingHoursProvider>
                      </UserProvider>
                    </ShiftProvider>
                  </ColumnProvider>
                </DeadlineProvider>
              </AvailabilityProvider>
            </ChangeRequestProvider>
          </ToastProvider>
        </I18nProvider>
      </ModalProvider>
    </QueryParamProvider>
  </BrowserRouter>,
  window.document.getElementById('root'),
);
