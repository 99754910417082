import { Trans } from '@lingui/react';
import teamsApi from 'api/team';
import { List, ListItem } from 'components/List';
import Spinner from 'components/Spinner';
import Toast from 'components/Toast';
import { Page, PageHeader, PageSection } from 'components/ui/Page';
import { fetchInitialData } from 'contexts/actions';
import { UserContext } from 'contexts/User/UserContext';
import { Button } from 'grommet';
import PropTypes from 'prop-types';
import { Component, useContext } from 'react';
import styled from 'styled-components';
import errorMessageHandler from 'utils/errorMessageHandler';

const ListWrapper = styled.div`
  margin: 1em 0em;
`;

export class TeamsWrapper extends Component {
  state = {
    pendingInvites: [],
    pendingInviteRequests: [],
    errorMessage: null,
    isLoading: true,
  };

  static propTypes = {
    availableTeams: PropTypes.array,
    getPendingTeamInvitations: PropTypes.func.isRequired,
    acceptTeamInvitation: PropTypes.func.isRequired,
    rejectTeamInvitation: PropTypes.func.isRequired,
    reloadAvailableTeams: PropTypes.func,
  };

  async componentDidMount() {
    const { data: pendingInvites } = await this.props.getPendingTeamInvitations();
    this.setState({ pendingInvites, isLoading: false });
  }

  render() {
    return (
      <Page>
        <PageHeader title={<Trans id="account.menu.teams" />} />

        {this.state.errorMessage && (
          <Toast status="critical">Error: {this.state.errorMessage}</Toast>
        )}

        <PageSection full>
          <ListWrapper>
            <List>
              {this.props.availableTeams.map(availableTeam => (
                <ListItem key={availableTeam.id}>{availableTeam.displayname}</ListItem>
              ))}
            </List>
          </ListWrapper>
        </PageSection>

        <PageSection title={<Trans id="account.teams.invitations" />}>
          <ListWrapper>
            {this.state.isLoading ? (
              <div>
                <Spinner size="smalll" />
              </div>
            ) : this.state.pendingInvites.length === 0 ? (
              <div>
                <Trans id="items-list.none" />
              </div>
            ) : (
              <List pad="none">
                {this.state.pendingInvites.map(pendingInvite => (
                  <ListItem justify="between" key={pendingInvite.teamId}>
                    <span>{pendingInvite.displayname}</span>
                    {this.state.pendingInviteRequests.includes(pendingInvite.teamId) && <Spinner />}
                    <span>
                      <span>
                        <Button
                          label={<Trans id="forms.accept" />}
                          onClick={
                            this.state.pendingInviteRequests.includes(pendingInvite.teamId)
                              ? null
                              : () => this.handleAcceptInvitation(pendingInvite.teamId)
                          }
                        />
                      </span>
                      <span>
                        <Button
                          label={<Trans id="forms.reject" />}
                          onClick={
                            this.state.pendingInviteRequests.includes(pendingInvite.teamId)
                              ? null
                              : () => this.handleRejectInvitation(pendingInvite.teamId)
                          }
                        />
                      </span>
                    </span>
                  </ListItem>
                ))}
              </List>
            )}
          </ListWrapper>
        </PageSection>
      </Page>
    );
  }

  handleAcceptInvitation = teamId => {
    this.processInvitation(teamId, this.props.acceptTeamInvitation, 'Cannot accept invitation');
  };

  handleRejectInvitation = teamId => {
    this.processInvitation(teamId, this.props.rejectTeamInvitation, 'Cannot reject invitation');
  };

  async processInvitation(teamId, processFunction, rejectionMessage) {
    await new Promise(resolve => {
      this.setState(
        state => ({
          pendingInviteRequests: [...state.pendingInviteRequests, teamId],
          errorMessage: null,
        }),
        resolve,
      );
    });

    try {
      await processFunction(teamId);
      this.setState(state => ({
        pendingInvites: state.pendingInvites.filter(
          pendingInvite => pendingInvite.teamId !== teamId,
        ),
      }));

      await this.props.reloadAvailableTeams();
    } catch (error) {
      const errorMessage = errorMessageHandler(error, rejectionMessage);

      this.setState(state => ({
        pendingInviteRequests: state.pendingInviteRequests.filter(request => request !== teamId),
        errorMessage,
      }));
    }
  }
}

export default function Teams({ ...props }) {
  const { availableTeams, setAvailableTeams, user } = useContext(UserContext);

  const reloadAvailableTeams = async () => {
    const response = await fetchInitialData(user.id);
    const { availableTeams: newAvailableTeams } = response;

    setAvailableTeams(newAvailableTeams);
  };

  return (
    <TeamsWrapper
      availableTeams={availableTeams}
      reloadAvailableTeams={reloadAvailableTeams}
      acceptTeamInvitation={teamsApi.acceptTeamInvitation}
      rejectTeamInvitation={teamsApi.rejectTeamInvitation}
      getPendingTeamInvitations={teamsApi.getPendingTeamInvitations}
      {...props}
    />
  );
}
