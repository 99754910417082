import { Box, Image } from 'grommet';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    max-height: 48px;
    max-width: 48px;
  }
`;

const NameWrapper = styled.div`
  padding-left: 8px;
`;

export default function ServiceHeader({ iconUrl, name }) {
  return (
    <Box>
      <Header>
        <Image src={iconUrl} size="thumb" />
        <NameWrapper>{name}</NameWrapper>
      </Header>
    </Box>
  );
}

ServiceHeader.propTypes = {
  iconUrl: PropTypes.string,
  name: PropTypes.string,
};
