import { Layer, Box, Text, Button } from 'grommet';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormClose, StatusGood, StatusCritical, StatusWarning, StatusUnknown } from 'grommet-icons';
import styled from 'styled-components';

function getBackground(status) {
  switch (status) {
    case 'success':
      return 'status-ok';
    default:
      return `status-${status}`;
  }
}

const StatusWrapper = styled(Box)`
  height: 36px;
  width: 36px;
  border-radius: 36px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Toast = ({ open = true, children, status = 'success' }) => {
  const [isOpen, setOpen] = useState(open);

  const onClose = () => setOpen(undefined);

  return isOpen ? (
    <Layer
      position="top"
      modal={false}
      margin={{ vertical: 'medium', horizontal: 'small' }}
      onEsc={onClose}
      responsive={false}
      plain
    >
      <Box
        align="center"
        direction="row"
        gap="small"
        justify="between"
        round="medium"
        elevation="medium"
        pad={{ vertical: 'xsmall', horizontal: 'small' }}
        background="light-2"
      >
        <Box align="center" direction="row" gap="xsmall">
          <StatusWrapper background={getBackground(status)}>
            {status === 'success' ? (
              <StatusGood />
            ) : status === 'error' || status === 'critical' ? (
              <StatusCritical />
            ) : status === 'warning' ? (
              <StatusWarning />
            ) : (
              <StatusUnknown />
            )}
          </StatusWrapper>
          <Text>{children}</Text>
        </Box>
        <Button icon={<FormClose />} onClick={onClose} plain />
      </Box>
    </Layer>
  ) : null;
};

Toast.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.any,
  status: PropTypes.string,
};
