import { omit, uniqueId } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

const CallbacksSyncContext = createContext();

// eslint-disable-next-line react/prop-types
export function CallbacksSyncProvider({ children }) {
  const [callbacks, setCallbacks] = useState({});

  const addCallback = useCallback(
    (id, callback) => {
      setCallbacks(_callbacks =>
        Object.assign({
          ..._callbacks,
          [id]: callback,
        }),
      );
    },
    [setCallbacks],
  );

  const removeCallback = useCallback(
    id => {
      setCallbacks(_callbacks => Object.assign({}, omit(_callbacks, [id])));
    },
    [setCallbacks],
  );

  const sync = useCallback(
    () => {
      Object.values(callbacks).forEach(callback => callback());
    },
    [callbacks],
  );

  return (
    <CallbacksSyncContext.Provider value={{ addCallback, removeCallback, sync }}>
      {children}
    </CallbacksSyncContext.Provider>
  );
}

export function useCallbacksSync({ id = uniqueId(), refetch }) {
  const callbackId = useRef(id);
  const { addCallback, removeCallback, sync } = useContext(CallbacksSyncContext);

  useEffect(
    () => {
      addCallback(callbackId.current, refetch);

      return () => removeCallback(callbackId.current);
    },
    [refetch],
  );

  return async () => {
    await refetch();
    await sync();
  };
}
