import { Trans } from '@lingui/react';
import Toast from 'components/Toast';
import { Page, PageHeader, PageSection } from 'components/ui/Page';
import { useI18n } from 'contexts/i18nWrapper';
import { Tab, Tabs, Box } from 'grommet';
import PropTypes from 'prop-types';
import { Component } from 'react';
import errorMessageHandler from 'utils/errorMessageHandler';
import MonthForm from './MonthForm';
import TimePeriodForm from './TimePeriodForm';

const ToastError = () => {
  const i18n = useI18n();
  return i18n._('error');
};

export default class ReportGenerator extends Component {
  static propTypes = {
    onGenerateReport: PropTypes.func,
  };

  state = {
    activeTab: 0,
    error: null,
  };

  render() {
    return (
      <Page header={<PageHeader title={<Trans id="team.generate-reports" />} backTo="/team" />}>
        {this.state.error && (
          <Toast status="critical">
            <ToastError />: {this.state.error}
          </Toast>
        )}

        <PageSection>
          <Box pad={{ bottom: 'medium' }}>
            <PageSection.Subtitle title={<Trans id="team.generate-reports.explanation" />} />
          </Box>

          <Tabs
            activeIndex={this.state.activeTab}
            onActive={this.handleTabChange}
            justify="start"
            responsive={false}
            fill="horizontal"
          >
            <Tab title={<Trans id="team.generate-reports.tab.month" />}>
              <MonthForm onGenerateReport={this.handleGenerateReport} />
            </Tab>
            <Tab title={<Trans id="team.generate-reports.tab.time-period" />}>
              <TimePeriodForm onGenerateReport={this.handleGenerateReport} />
            </Tab>
          </Tabs>
        </PageSection>
      </Page>
    );
  }

  handleTabChange = activeTab => {
    this.setState({ activeTab });
  };

  handleGenerateReport = async (dateFrom, dateTo) => {
    this.setState({ error: null });
    try {
      await this.props.onGenerateReport(dateFrom, dateTo);
    } catch (error) {
      this.setState({ error: errorMessageHandler(error) });
    }
  };
}
