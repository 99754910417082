import { Trans } from '@lingui/react';
import {
  bodyBackgroundColor,
  navbarActiveTextColor,
  navbarTextColor,
  sidebarColor,
} from 'config/colors';
import {
  AvailabilityConsumer,
  ChangeRequestConsumer,
  ColumnConsumer,
  DeadlineConsumer,
  ShiftConsumer,
  UserConsumer,
} from 'contexts';
import { changeTeamAction, clearDataAction, logoutAction } from 'contexts/actions';
import { Accordion, AccordionPanel, Anchor, Box, DropButton, Menu, Text } from 'grommet';
import { Clock, Grow, Menu as MenuIcon, Schedule } from 'grommet-icons';
import { get } from 'lodash';
import { useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useIsMobile } from 'utils/responsive';

// TODO: possible refactor just with the render props?

const Navbar = styled.nav`
  background: ${sidebarColor};
  color: ${navbarTextColor};
  font-size: 1.25rem;
`;

Navbar.Brand = styled.div`
  margin-left: 12px;
  margin-right: 12px;

  @media (max-width: 719px) {
    display: none;
  }

  img {
    margin: 4px 0px;
    max-height: 40px;
  }
`;

Navbar.MenuIcon = styled.img`
  max-height: 36px;
`;

Navbar.Controls = styled(Box)`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

Navbar.BrandLogo = styled(Box)`
  margin: 8px 0px;
  background: white;
  border-radius: 40px;
  padding: 0px 4px;
`;

const NavDropButton = styled(DropButton)`
  font-weight: 600;
  color: ${props => (props.isActive ? navbarActiveTextColor : navbarTextColor)};

  &:hover {
    color: white;
  }

  ${props =>
    props.isActive
      ? `
    background: ${bodyBackgroundColor};
    border-radius: 20px;
  `
      : 'none'};

  border-radius: 20px;
  padding: 8px;
`;

const StyledAnchor = styled(Anchor)`
  color: ${navbarActiveTextColor} !important;
`;

const NavbarMenu = styled(Menu)`
  color: ${navbarTextColor};
  font-weight: 600;

  &:hover {
    color: white;
  }

  svg {
    fill: ${navbarTextColor};
    stroke: ${navbarTextColor};
  }
`;

// eslint-disable-next-line react/prop-types
function NavbarLink({ to, component }) {
  return (
    <NavLink to={to} activeClassName="navbar-link-active" className="navbar-link">
      {component}
    </NavLink>
  );
}

const MobileNavItemWrapper = styled(Box)`
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px 0px 6px 0px;
  font-size: 14px;
  color: ${props => (props.isActive ? navbarActiveTextColor : navbarTextColor)};

  svg {
    stroke: ${props => (props.isActive ? navbarActiveTextColor : navbarTextColor)};
    fill: ${props => (props.isActive ? navbarActiveTextColor : navbarTextColor)};
  }
`;

// eslint-disable-next-line react/prop-types
function MobileNavItem({ to, icon, label }) {
  return (
    <NavbarLink
      to={to}
      component={
        <MobileNavItemWrapper>
          <Box>
            <div>{icon}</div>
            <div className="navbar-mobile-link-label">{label}</div>
          </Box>
        </MobileNavItemWrapper>
      }
    />
  );
}

// eslint-disable-next-line react/prop-types
function MobileNavItemsDropdown({ icon, label, items = [] }) {
  const location = useLocation();
  const dropButtonNode = useRef();

  function handleClick() {
    dropButtonNode.current.click();
    dropButtonNode.current.blur();
  }

  const isActive = items.some(item => location.pathname.startsWith(item.to));

  return (
    <MobileNavItemWrapper className={isActive && 'navbar-link-active'}>
      <DropButton
        ref={dropButtonNode}
        plain
        label={
          <Box>
            <div>{icon}</div>
            <div>{label}</div>
          </Box>
        }
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropProps={{ plain: true }}
        dropContent={
          <NavDropdownDrop>
            <Box>
              {items.map(({ to, label }) => (
                <Link key={to} to={to} onClick={handleClick}>
                  <Box pad="small">
                    <StyledAnchor>{label}</StyledAnchor>
                  </Box>
                </Link>
              ))}
            </Box>
          </NavDropdownDrop>
        }
      />
    </MobileNavItemWrapper>
  );
}

// eslint-disable-next-line react/prop-types
function MobileNavContentDropdown({ icon, label, children, isActive }) {
  const dropButtonNode = useRef();

  function handleClick() {
    dropButtonNode.current.click();
    dropButtonNode.current.blur();
  }

  return (
    <MobileNavItemWrapper isActive={isActive}>
      <DropButton
        ref={dropButtonNode}
        plain
        label={
          <Box>
            <div>{icon}</div>
            <div>{label}</div>
          </Box>
        }
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropProps={{ plain: true }}
        dropContent={<NavDropdownDrop onClick={handleClick}>{children}</NavDropdownDrop>}
      />
    </MobileNavItemWrapper>
  );
}

const NavDropdownDrop = styled(Box)`
  background: #f0f0f0;
  border-radius: 10px;
  padding: 10px;

  color: ${navbarActiveTextColor};
`;

// eslint-disable-next-line react/prop-types
function NavDropdown({ items = [], label }) {
  const dropButtonNode = useRef();

  function handleClick() {
    dropButtonNode.current.click();
    dropButtonNode.current.blur();
  }

  const isActive = items.some(item => window.location.pathname.startsWith(item.to));

  return (
    <NavDropButton
      ref={dropButtonNode}
      isActive={isActive}
      // style={{ fontWeight: 600, color: isActive ? navbarActiveTextColor : navbarTextColor }}
      plain
      label={label}
      dropAlign={{ top: 'bottom', right: 'right' }}
      dropProps={{ plain: true }}
      dropContent={
        <NavDropdownDrop>
          <Box>
            {items.map(({ to, label }) => (
              <Link key={to} to={to} onClick={handleClick}>
                <Box pad="small">
                  <StyledAnchor>{label}</StyledAnchor>
                </Box>
              </Link>
            ))}
          </Box>
        </NavDropdownDrop>
      }
    />
  );
}

// eslint-disable-next-line react/prop-types
function Wrapper({ availableTeams, changeTeam, currentTeam, currentUser, logout }) {
  const location = useLocation();
  const isMobile = useIsMobile();

  const handleLogout = () => {
    try {
      logout();
    } catch (error) {
      //eslint-disable-next-line
      console.log("Cannot logout. ", error);
    }
  };

  const isMobileMenuActive = ['/team', '/account'].some(path => location.pathname.startsWith(path));

  return !isMobile ? (
    <Navbar>
      <Box pad="none" fill="horizontal" direction="row" align="center">
        <Navbar.Brand>
          <Link to="/">
            <Navbar.BrandLogo>
              <img src="/static/rota-logo.svg" />
            </Navbar.BrandLogo>
          </Link>
        </Navbar.Brand>
        <Navbar.Controls justify="end">
          <NavbarLink to="/work" component={<Trans id="navbar.user-shifts" />} />
          <NavbarLink to="/availability" component={<Trans id="navbar.user-availability" />} />

          <NavDropdown
            label={<Trans id="navbar.calendar" />}
            items={[
              { to: '/availabilities', label: <Trans id="navbar.availabilities" /> },
              { to: '/shifts', label: <Trans id="navbar.shifts" /> },
              { to: '/columns', label: <Trans id="navbar.columns" /> },
            ]}
          />
          <NavbarLink to="/team" component={<Trans id="navbar.team" />} />
          <NavbarLink to="/account" component={<Trans id="navbar.account" />} />

          <NavbarMenu
            label={currentTeam.name}
            items={[
              ...availableTeams.map(availableTeam => ({
                label: (
                  <Anchor
                    color="brand"
                    key={`team-${availableTeam.id}`}
                    onClick={() => changeTeam(availableTeam.id)}
                  >
                    {availableTeam.name}
                  </Anchor>
                ),
              })),
              {
                label: (
                  <Anchor onClick={handleLogout}>
                    <Trans id="navbar.team-dropdown.logout" />
                  </Anchor>
                ),
              },
            ]}
          />
        </Navbar.Controls>
      </Box>
    </Navbar>
  ) : (
    <Box direction="row" pad="0px 12px">
      <Box flex="grow">
        <MobileNavItem to="/work" label="Work" icon={<Grow />} />
      </Box>
      <Box flex="grow">
        <MobileNavItem to="/availability" label="Availability" icon={<Clock />} />
      </Box>
      <Box flex="grow">
        <MobileNavItemsDropdown
          icon={<Schedule />}
          label={<Trans id="navbar.calendar" />}
          items={[
            { to: '/availabilities', label: <Trans id="navbar.availabilities" /> },
            { to: '/shifts', label: <Trans id="navbar.shifts" /> },
            { to: '/columns', label: <Trans id="navbar.columns" /> },
          ]}
        />
      </Box>

      <Box flex="grow">
        <MobileNavContentDropdown icon={<MenuIcon />} label="Menu" isActive={isMobileMenuActive}>
          <Box pad="small">
            <Box pad={{ bottom: 'large' }}>
              <Text color={navbarTextColor}>{currentUser.displayname}</Text>
            </Box>

            <Box gap="medium">
              <Link to="/team">
                <StyledAnchor>
                  <Trans id="navbar.team" />
                </StyledAnchor>
              </Link>

              <Link to="/account">
                <StyledAnchor>
                  <Trans id="navbar.account" />
                </StyledAnchor>
              </Link>
            </Box>

            <Box pad={{ vertical: 'large' }}>
              <Accordion>
                <AccordionPanel
                  className="rota-accordion-no-padding"
                  label={`Team: ${currentTeam.name}`}
                  onClick={event => {
                    const classList = Array.from(get(event.target, 'classList', []));
                    if (!classList.includes('nav-menu-team')) {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }}
                >
                  {availableTeams.filter(team => team.id !== currentTeam.id).map(availableTeam => (
                    <Anchor
                      key={`team-${availableTeam.id}`}
                      onClick={() => changeTeam(availableTeam.id)}
                      className="nav-menu-team"
                    >
                      {availableTeam.name}
                    </Anchor>
                  ))}
                </AccordionPanel>
              </Accordion>
            </Box>

            <StyledAnchor onClick={handleLogout}>
              <Trans id="navbar.team-dropdown.logout" />
            </StyledAnchor>
          </Box>
        </MobileNavContentDropdown>
      </Box>
    </Box>
  );
}

export default function ContextWrapper(props) {
  return (
    <ColumnConsumer>
      {({ clearColumns }) => (
        <ChangeRequestConsumer>
          {({ clearChangeRequests }) => (
            <DeadlineConsumer>
              {({ clearDeadlines }) => (
                <ShiftConsumer>
                  {({ clearShifts }) => (
                    <AvailabilityConsumer>
                      {({ clearAvailabilities }) => (
                        <UserConsumer>
                          {({
                            availableTeams,
                            currentTeam,
                            isAdminInCurrentTeam,
                            setChangingTeam,
                            setCurrentTeam,
                            setTeamUsers,
                            user,
                          }) => (
                            <Wrapper
                              availableTeams={availableTeams}
                              currentTeam={currentTeam}
                              changeTeam={changeTeamAction({
                                setCurrentTeam,
                                setTeamUsers,
                                availableTeams,
                                clearDataAction: clearDataAction({
                                  clearAvailabilities,
                                  clearDeadlines,
                                  clearColumns,
                                  clearChangeRequests,
                                  clearShifts,
                                }),
                                setChangingTeam,
                              })}
                              isAdmin={isAdminInCurrentTeam()}
                              logout={logoutAction}
                              currentUser={user}
                              {...props}
                            />
                          )}
                        </UserConsumer>
                      )}
                    </AvailabilityConsumer>
                  )}
                </ShiftConsumer>
              )}
            </DeadlineConsumer>
          )}
        </ChangeRequestConsumer>
      )}
    </ColumnConsumer>
  );
}

// export default withRouter(ContextWrapper);
/*
  Same level as NavItem
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav caret>
      Options
    </DropdownToggle>
    <DropdownMenu>
      <DropdownItem>Option 1</DropdownItem>
      <DropdownItem>Option 2</DropdownItem>
      <DropdownItem divider />
      <DropdownItem>Reset</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
*/
