import cx from 'classnames';
import { Grommet } from 'components/Grommet';
import { Layer } from 'grommet';
import { merge } from 'lodash';
import { useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { useIsMobile } from 'utils/responsive';

export const Wrapper = styled(Layer)`
  border-radius: 10px;
  z-index: 1000;
`;

const customStyle = (overrides, isMobile) =>
  merge(
    {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        overflowY: 'scroll',
        zIndex: '10',
      },
      content: {
        position: 'absolute',
        top: isMobile ? '10px' : '40px',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        border: 'none',
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '20px',
        outline: 'none',
        padding: '0px',
      },
    },
    overrides,
  );

export const ModalLayer = ({ children, onEsc, className, styleOverrides = {}, full, ...props }) => {
  const [isOpen] = useState(true);
  const isMobile = useIsMobile();

  return (
    <ReactModal
      style={customStyle(styleOverrides, isMobile)}
      isOpen={isOpen}
      onRequestClose={onEsc}
      className={cx('RotaModalLayer', className, { full })}
      {...props}
    >
      <Grommet>{children}</Grommet>
    </ReactModal>
  );
};

export default ModalLayer;
